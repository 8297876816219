import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import Checkbox from "#components/utils/Checkbox";
import { PencilIcon, TrashIcon, PrinterIcon } from "@heroicons/react/outline";
import _ from "lodash";
const config = require("config");
import QRCode from "react-qr-code";

const TotesList = ({
  totes,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  selectedTotes,
  selectTote,
  queueSinglePrint,
  selectAllTotes,
  allTotesSelected,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-4">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full">
          <tr className="text-224E73 font-montserratborder-left">
            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium border-transparent border-l-8 pl-5"
            >
              <Checkbox
                role="checkbox"
                onChange={selectAllTotes}
                name="totes"
                value={"Select All Totes"}
                checked={allTotesSelected}
              />
            </th>
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {totes.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}
                  >
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}
                  ></td>
                )
              )}
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"></td>
            </tr>
          ) : null}
          {totes.map((tote, index) => (
            <tr
              key={tote.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
            >
              <td
                className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-224E73"
                }`}
              >
                <Checkbox
                  role="checkbox"
                  onChange={(e) => selectTote(tote.id)}
                  name="tote"
                  value={tote.id}
                  checked={selectedTotes.includes(tote.id)}
                />
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {tote.toteType}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {tote.barcode}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {moment(tote.updatedAt).format("MMM Do, YYYY")}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <div className="flex space-x-4 items-center">
                  <TableFieldButton
                    onClick={() => editButtonClicked(tote)}
                    text={<PencilIcon className="w-6 h-6" />}
                  />

                  <TableFieldButton
                    text={<TrashIcon className="w-6 h-6" />}
                    onClick={() => deleteButtonClicked(tote.id)}
                  />
                  <TableFieldButton
                    text={<PrinterIcon className="w-6 h-6" />}
                    onClick={() => queueSinglePrint(tote.id)}
                  />
                </div>
              </td>
              {config.NODE_ENV === "development" && (
                <td className="px-2 pl-4 py-4 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  <QRCode value={tote.barcode} size="100" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TotesList;
