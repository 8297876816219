import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";

const DevicesList = ({
  devices,
  editButtonClicked,
  deleteButtonClicked,
  passwordEditable,
  headers,
  noValuesText,
  customers,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full">
          <tr className="text-224E73 font-montserratborder-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {devices.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}
                  >
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}
                  ></td>
                )
              )}
            </tr>
          ) : null}
          {devices.map((device, index) => (
            <tr
              key={device.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
            >
              <td
                className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-224E73"
                }`}
              >
                {device.name}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {device.deviceType}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {device.hardwareId}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {device.customers
                  ? customers
                      .filter((item) => device.customers.includes(item.id))
                      .map((item) => item.name)
                      .join(", ")
                  : ""}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <div className="flex space-x-4 items-center">
                  <TableFieldButton
                    onClick={() => editButtonClicked(device)}
                    text={<PencilIcon className="w-6 h-6" />}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="w-6 h-6" />}
                    onClick={() => deleteButtonClicked(device.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DevicesList;
