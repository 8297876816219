const Button = ({ onClick, transparent, text, icon: Icon, styles }) => (
  // <button
  //   type="button"
  //   className={`px-4 py-2 flex items-center justify-center text-sm font-medium text-white ${
  //     transparent
  //       ? "bg-transparent border border-F4C261 text-B3BFCA"
  //       : "bg-2C7695"
  //   } font-montserrat font-medium border border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none`}
  //   onClick={onClick}
  // >
  //   {Icon && <Icon className="w-6 h-6 mr-2 text-white" />}
  //   {!Icon && <icon className="h-6" />}
  //   {text || "Button"}
  // </button>
  <button
    type="button"
    className={`flex items-center justify-center text-sm font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none p-0 ${
      styles ? styles.join(" ") : ""
    }`}
    onClick={onClick}
  >
    {Icon && (
      <>
        <Icon className="w-10 h-10 py-2 text-white border border-224E73 rounded-full bg-224E73" />
        <span className="px-2 pr-4">{text || "Button"}</span>
      </>
    )}
    {!Icon && (
      <>
        <span className="w-full p-3 px-6">{text || "Button"}</span>
      </>
    )}
  </button>
);

export default Button;
