import _ from "lodash";
import JSPM from "jsprintmanager";
JSPM.JSPrintManager.license_url = `https://api.delmar.hopstack.io/jspm`;
JSPM.JSPrintManager.auto_reconnect = true;
JSPM.JSPrintManager.start();
JSPM.JSPrintManager.WS.onStatusChanged = function () {
  if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
    JSPM.JSPrintManager.getPrinters()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }
};
import axios from "axios";
const config = require("config");
import {
  constructQrCodesForLabelPrint,
  constructBarcodesForLabelPrint,
  constructQrCodesForRegularPrint,
} from "#utils/barcodeGenerator";

export const printBarcodes = async ({
  selectedBarcodes,
  barcodeType,
  noOfCopies,
  appState,
  printer = "Zebra",
  dpi = 8,
  printerType = "Thermal",
}) => {
  let possiblePrinterNames = ["zebra", "zdesign", "thermal"];
  if (printer === "Rongta") {
    possiblePrinterNames = ["rongta", "rp421"];
  }

  if (printer === "XP") {
    possiblePrinterNames = ["xp"];
  }

  const printList = [];
  for (const selectedBarcode of selectedBarcodes) {
    for (let i = 0; i < noOfCopies; i++) {
      printList.push(selectedBarcode);
    }
  }

  let chunked = _.chunk(printList, 3);
  if (barcodeType === "2D Barcode" && printerType === "Thermal") {
    chunked = _.chunk(printList, 1);
  }

  // if (dpi === 6) {
  //   chunked = _.chunk(printList, 2);
  // }

  let printCommands = [];
  for (const chunk of chunked) {
    if (barcodeType === "2D Barcode" && printerType === "Thermal") {
      printCommands.push(constructQrCodesForLabelPrint(chunk));
    } else if (printerType !== "Thermal") {
      printCommands.push(constructQrCodesForRegularPrint(chunk));
    } else {
      printCommands.push(constructBarcodesForLabelPrint(chunk));
    }
  }

  if (printerType !== "Thermal") {
    if (printCommands.length > 0) {
      const printers = await JSPM.JSPrintManager.getPrinters();
      if (!printers || printers.length === 0) {
        appState.setAlert("No printers found", "error", 5000);
        return;
      }
      appState.setLoading();
      for (const printCommand of printCommands) {
        try {
          await triggerRegularPrints(printCommand);
        } catch (err) {
          alert(err);
          appState.setAlert("Could not print. Kindly contact support.");
        }
      }
    }
    appState.removeLoading();
    return;
  }

  try {
    const printers = await JSPM.JSPrintManager.getPrinters();
    if (!printers || printers.length === 0) {
      appState.setAlert("No printers found", "error", 5000);
      return;
    }

    // const findZebra = _.find(
    //   printers,
    //   (e) =>
    //     e.toLowerCase().includes("zdesign") ||
    //     e.toLowerCase().includes("zebra") ||
    //     e.toLowerCase().includes("thermal") ||
    //     e.toLowerCase().includes("xp") ||
    //     e.toLowerCase().includes("rongta") ||
    //     e.toLowerCase().includes("rp421")
    // );
    // if (findZebra) {
    for (const cmds of printCommands) {
      const cpj = new JSPM.ClientPrintJob();
      const myPrinter = new JSPM.DefaultPrinter();
      myPrinter.duplex = JSPM.DuplexMode.Simplex;
      cpj.clientPrinter = myPrinter;
      cpj.printerCommands = cmds;
      console.log(cmds);
      cpj.sendToClient();
    }
    // } else {
    //   appState.setAlert("No thermal printers found", "error", 5000);
    //   return;
    // }
  } catch (err) {
    appState.setAlert("Could not print", "error", 3000);
  }
  return;
};

const triggerRegularPrints = async (zpl) => {
  try {
    const resp = await axios.post(
      `${config.BASE_URL}/external-api/generate-barcodes`,
      {
        zpl,
      }
    );
    const response = resp.data;
    if (response.Location) {
      console.log(response.Location);
      const cpj = new JSPM.ClientPrintJob();
      const myPrinter = new JSPM.DefaultPrinter();
      myPrinter.duplex = JSPM.DuplexMode.Simplex;

      cpj.clientPrinter = myPrinter;
      const myFile = new JSPM.PrintFile(
        response.Location,
        JSPM.FileSourceType.URL,
        `${response.Key}`
      );
      myFile.printAutoCenter = false;
      myFile.pageSizing = JSPM.Sizing["Fit"];
      cpj.files.push(myFile);
      cpj.sendToClient();
    } else {
      throw new Error("Could not print. Please contact support.");
    }
  } catch (err) {
    throw err;
  }
};
