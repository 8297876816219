import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const HardwareIntegrationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedIntegration,
  onSubmit,
  customers,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Integration Details
        </span>
      </div>

      <div style={{ display: "none" }}>
        <Dropdown
          placeholder={"Select Customer"}
          list={customers}
          labelKey="name"
          valueKey="id"
          name="customer"
          setSelected={(e) => {
            onChangeDropdown("customer", e);
          }}
          selectedValue={selectedIntegration.customer}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="username"
          label="Username"
          placeholder=" "
          onChange={onChange}
          value={selectedIntegration.username}
        />
      </div>
      <div>
        <TextField
          type="password"
          id="password"
          label="Password"
          placeholder="Password"
          onChange={onChange}
          value={selectedIntegration.password}
        />
      </div>
    </div>
  </Modal>
);

export default HardwareIntegrationForm;
