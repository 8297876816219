// HOC/withAuth.jsx

import { useHistory } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import AdminLayout from "#components/layout/AdminLayout";
import AssociateLayout from "#components/layout/AssociateLayout";
// import verifyToken from "services/verifyToken";
import { useQuery } from "@apollo/client";
import { GET_LOGGED_IN_USER } from "#queries";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const AuthedLayout = () => {
  const appState = useContext(AppStateContext);
  const { loading, error, data } = useQuery(GET_LOGGED_IN_USER);
  const history = useHistory();
  useEffect(async () => {
    if (!loading) {
      appState.removeLoading();
    }
    if (!loading && error) {
      history.replace("/login");
    }
    if (loading) {
      appState.setLoading();
    }
  }, [loading, error, data]);
  if (data && data.me && ["Admin", "ADMIN"].includes(data.me.role)) {
    return <AdminLayout>{children}</AdminLayout>;
  }

  if (data && data.me && ["Warehouse Manager"].includes(data.me.role)) {
    return <AdminLayout warehouseManager={true}>{children}</AdminLayout>;
  }

  if (
    data &&
    data.me &&
    ["Admin", "ADMIN", "Warehouse Manager"].includes(data.me.role) === false
  ) {
    return <AssociateLayout>{children}</AssociateLayout>;
  }
  if (loading) return null;

  return null;
};

export default AuthedLayout;
