import React from "react";
import { render } from "react-dom";
import { createBrowserHistory } from "history";
import "regenerator-runtime/runtime.js";
import App from "#pages/App";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
  gql,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { AuthProvider } from "#contexts/auth";
import { AppStateProvider } from "#contexts/appState";
import { MasterDataProvider } from "#contexts/masterData";
const config = require("config");

const history = createBrowserHistory();

const httpLink = createHttpLink({
  uri: `${config.BASE_URL}/graphql`,
});

console.log(httpLink);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

render(
  <ApolloProvider client={client}>
    <AuthProvider>
      <AppStateProvider>
        <MasterDataProvider>
          <App history={history} />
        </MasterDataProvider>
      </AppStateProvider>
    </AuthProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
