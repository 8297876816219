import { Route, Switch } from "react-router-dom";

import PrivateRoute from "#config/PrivateRoute";

import NotFound from "#pages/404";

import Login from "#pages/login";
import Customers from "#pages/customers";
import Users from "#pages/users";
import Devices from "#pages/devices";
import Stations from "#pages/stations";
import Orders from "#pages/orders";
import OutboundExceptions from "#pages/outboundExceptions";
import Outbound from "#pages/outbound";
import Inbound from "#pages/inbound";
import InventoryOverview from "#pages/inventoryOverview";
import InventoryMeasurement from "#pages/inventoryMeasurement";
import Forecasts from "#pages/forecasts";
import Workflow from "#pages/workflow";
import Rules from "#pages/rules";
import Integrations from "#pages/integrations";
import PackingStations from "#pages/packingStations";
import Consignments from "#pages/consignments";
import Stats from "#pages/stats";
import Receiver from "#pages/receiver";
import Putaway from "#pages/putaway";
import ReceivedSkus from "#pages/receivedSkus";
import Dashboard from "#pages/dashboard";
import Simulations from "../pages/Simulations";
import AddGroup from "#pages/addGroup";
import AddRule from "#pages/addRule";
import Picker from "#pages/picker";
import Packer from "#pages/packer";
import SkuBinMappings from "#pages/skuBinMappings";
import Products from "#pages/products";
import QualityChecker from "#pages/qualityChecker";
import Totes from "#pages/totes";
import Locations from "#pages/locations";
import Workflows from "#pages/workflows";

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/simulations" component={Simulations} />
      <Route component={NotFound} />
    </Switch>
  </>
);

export default Routes;
