const Checkbox = ({ role, label, checked, name, id, onChange, value }) => (
  <div className="relative flex items-center">
    <div className="flex items-center h-5">
      <input
        id={id}
        aria-describedby="offers-description"
        name={name}
        type={role || "checkbox"}
        checked={checked}
        className={`focus:ring-0 h-6 w-6 text-2C7695 border-2C7695 ${
          role === "radio" ? "rounded-xl" : "rounded"
        }`}
        onChange={onChange}
        value={value}
      />
    </div>
    <div className="ml-2 text-sm">
      <label
        htmlFor="offers"
        className="font-medium text-gray-700 text-lg font-montserrat"
      >
        {label}
      </label>
    </div>
  </div>
);

export default Checkbox;
