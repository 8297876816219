import moment from "moment-timezone";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";

const ForecastsList = ({ forecasts, headers, noValuesText, getSku }) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      {" "}
      <SearchSortFilter
        onChangeSearchKeyword={() => {}}
        filters={{}}
        submitFilters={() => {}}
        clearKeyword={() => {}}
        setSort={() => {}}
        sort={null}
        setShowFilters={() => {}}
        clearFilters={() => {}}
      />
      <table className="min-w-full divide-y divide-gray-200 mb-10">
        <thead className="p-4 rounded-full w">
          <tr className="text-224E73 font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {forecasts.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}
                  >
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}
                  ></td>
                )
              )}
            </tr>
          ) : null}
          {forecasts.map((forecast, index) => (
            <tr
              key={forecast.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
            >
              <td
                className={`cursor-pointer p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-224E73"
                }`}
                onClick={() => getSku(forecast.sku)}
              >
                {forecast.sku}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <img
                  src={
                    forecast.orderLineItem.productImage
                      ? forecast.orderLineItem.productImage
                      : "https://hopstack-pub.s3.amazonaws.com/logo.png"
                  }
                  className="w-14"
                />
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {forecast.orderLineItem.productName}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                ${_.round(forecast.price, 2).toFixed(2)}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {moment(forecast.lastSold).format("MMM Do, YYYY")}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {moment().format("MMM Do, YYYY")}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {_.ceil(forecast.forecast.twoWeeks / 1.25)}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {forecast.forecast.twoWeeks}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {forecast.forecast.oneMonth}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {_.ceil(forecast.safetyStock)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        showingLhs={1}
        showingRhs={forecasts.length}
        showingTotal={forecasts.length}
        perPage={25}
        setPerPage={() => {}}
        pageNumber={1}
        checkPagination={() => {}}
      />
    </div>
  );
};

export default ForecastsList;
