import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import _ from "lodash";
import moment from "moment-timezone";

const CarrierPickupsByDate = ({ data, currentTimezone }) => {
  if (data) {
    data.sort((a, b) => {
      return new Date(a._id.day) - new Date(b._id.day);
    });
    data.map((obj) => {
      return (obj._id.day = moment(obj._id.day).format("MMM D, YYYY")); // June 1, 2019
    });
  }
  const renderColorfulLegendText = (value, entry) => {
    return <span className="text-gray-900 text-xl">{value}</span>;
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="_id.day" />
        <YAxis label={<CustomizedLabel value="On-Time Shipments %" />}></YAxis>
        <Legend formatter={renderColorfulLegendText} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="Total"
          stroke="#82ca9d"
          strokeWidth={3}
          name={`Date (${currentTimezone})`}
          stackId="a"
          fill="#000000"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomizedLabel = ({ value }) => (
  <text
    x={-60}
    y={10}
    dy={20}
    fill={"#000000"}
    fontSize={20}
    textAnchor="left"
    transform={`rotate(-90 100 100)`}
    letterSpacing="1.5"
  >
    {value}
  </text>
);

export default CarrierPickupsByDate;
