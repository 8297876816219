import withOverallOutboundSortingLogic from "#components/HOC/withOverallOutboundSortingLogic";
import Tabs from "#components/utils/Tabs";
import Stats from "#components/common/Stats";
import QRCode from "react-qr-code";
const config = require("config");

const OverallOutboundSorting = ({ sortingLocations, orders, sortings }) => {
  console.log(sortingLocations);
  const headers = [
    "SKU",
    "Collection",
    "Color",
    "Size",
    "Picker's Cart",
    "Position",
    "Order Tray",
    "Status",
  ];

  if (config.NODE_ENV === "development") {
    headers.push("Code");
  }

  return (
    <>
      {sortingLocations && sortingLocations.length > 0 && (
        <Stats
          stats={[
            {
              name: "Unoccupied Slots",
              stat: sortingLocations.filter(
                (item) => item.currentToteBarcode === null
              ).length,
            },
            {
              name: "Ready for Runner",
              stat: sortingLocations.filter(
                (item) =>
                  item.currentMainEntityId !== null &&
                  orders &&
                  orders[item.currentMainEntityId] &&
                  orders[item.currentMainEntityId]?.subStatus ===
                    "SORTING_COMPLETED"
              ).length,
            },
            {
              name: "Occupied Trays",
              stat: sortingLocations.filter(
                (item) =>
                  item.currentMainEntityId !== null &&
                  item.currentToteBarcode !== null
              ).length,
            },
            {
              name: "Empty Trays",
              stat: sortingLocations.filter(
                (item) =>
                  item.currentMainEntityId === null &&
                  item.currentToteBarcode !== null
              ).length,
            },
          ]}
        />
      )}

      <Tabs
        tabs={{
          "Sorting List": (
            <table className="px-2 min-w-full divide-y divide-gray-200 whitespace-nowrap border border-gray-700">
              <thead className="p-4 rounded-full px-10">
                <tr className="text-224E73 font-montserrat border-left">
                  {headers.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <th
                        scope="col"
                        className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                        key={headerIdx}
                      >
                        {header}
                      </th>
                    ) : (
                      <th
                        scope="col"
                        className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                        key={headerIdx}
                      >
                        {header}
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {sortings.length === 0 ? (
                  <tr className="bg-white px-10">
                    {headers.map((header, headerIdx) =>
                      headerIdx === 0 ? (
                        <td
                          className="p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                          key={headerIdx}
                        >
                          No Sortings Currently
                        </td>
                      ) : (
                        <td
                          className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                          key={headerIdx}
                        ></td>
                      )
                    )}
                  </tr>
                ) : null}
                {sortings.map((sortItem, index) => (
                  <tr
                    key={sortItem.id}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
                  >
                    <td
                      className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8  ${
                        index % 2 === 0 ? "border-F4C261" : "border-224E73"
                      }`}
                    >
                      {sortItem.sku}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {sortItem.attributes?.collection}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {sortItem.attributes?.color}
                    </td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {sortItem.attributes?.size}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {sortItem.originalTote}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      <b>
                        {
                          sortingLocations.find(
                            (item) =>
                              item.currentMainEntityId === sortItem.order
                          )?.position
                        }
                      </b>
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      <b>
                        {
                          sortingLocations.find(
                            (item) =>
                              item.currentMainEntityId === sortItem.order
                          )?.currentToteBarcode
                        }
                      </b>
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {sortItem.status}
                    </td>
                    {config.NODE_ENV === "development" && (
                      <td className="pl-4 px-1 py-4 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                        <div className="flex items-center justify-center text-center">
                          <QRCode value={sortItem.sku} size="100" />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ),
          "Sorting Locations": (
            <div className="flex-1">
              {" "}
              <ul
                role="list"
                className="grid grid-cols-1 gap-2 lg:grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1"
              >
                {sortingLocations.map((sortingLocation) => (
                  <li
                    key={sortingLocation.id}
                    className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 text-center text-2xl"
                  >
                    <div className="flex-1 flex flex-col p-8">
                      <h3 className="mt-6 text-gray-900 text-4xl font-medium">
                        Outbound Sorting
                      </h3>
                      <h3 className="mt-6 text-gray-900 text-4xl font-medium mb-4">
                        {sortingLocation.position}
                      </h3>
                      {!sortingLocation.currentToteBarcode && (
                        <div className="text-2xl text-red-500">UNOCCUPIED</div>
                      )}
                      {sortingLocation.currentToteBarcode && (
                        <div className="text-2xl text-gray-500">
                          Current Tray: {sortingLocation.currentToteBarcode}
                        </div>
                      )}
                      {config.NODE_ENV === "development" &&
                        sortingLocation.currentToteBarcode && (
                          <div className="flex items-center justify-center text-center">
                            <QRCode
                              value={sortingLocation.currentToteBarcode}
                            />
                          </div>
                        )}
                      {sortingLocation.currentMainEntityId && (
                        <>
                          <div className="text-2xl">
                            Order ID:{" "}
                            {
                              orders[sortingLocation.currentMainEntityId]
                                ?.orderId
                            }
                          </div>

                          <div className="text-2xl">
                            Status:{" "}
                            {orders[sortingLocation.currentMainEntityId]
                              ?.subStatus === "SORTING_COMPLETED" ? (
                              <span className="text-green-400">
                                SORTED. READY FOR RUNNER.
                              </span>
                            ) : (
                              orders[sortingLocation.currentMainEntityId]
                                ?.subStatus
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ),
        }}
      />
    </>
  );
};

export default withOverallOutboundSortingLogic(OverallOutboundSorting);
