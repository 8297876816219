import { useContext } from "react";

import Notification from "#components/utils/Notification";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import ConfirmationDialog from "#components/utils/ConfirmationDialog";
import { AppStateContext } from "#contexts/appState";

const Layout = ({ children }) => {
  const appState = useContext(AppStateContext);
  console.log(appState.confirmation);
  console.log("layout change");
  return (
    <div className="h-full">
      {appState.loading === true && <LoadingIndicator />}
      <div>
        <div>{children}</div>
      </div>

      <Notification alerts={appState.alerts} />
      <ConfirmationDialog confirmation={appState.confirmation} />
    </div>
  );
};

export default Layout;
