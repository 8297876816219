import { useEffect, useState } from "react";
import CustomerOrdersAndProductsStats from "../dashboard/CustomerOrdersAndProductsStats";
import cubejs from "@cubejs-client/core";
const config = require("config");

const withDashboardLogic = (WrappedComponent) => {
    return (props) => {
        const cubejsApi = cubejs(config.REACT_APP_CUBEJS_TOKEN, {
            apiUrl: config.REACT_APP_API_URL + "/cubejs-api/v1",
        });
        let ordersCountJSONQuery = {
            timeDimensions: [
                {
                    dimension: "Orders.createdat",
                    dateRange: "Last 7 days",
                },
            ],
            measures: ["Orders.count"],
            dimensions: ["Orders.customerid"],
            order: {
                "Orders.count": "desc",
            },
        };
        const customersJSONQuery = {
            dimensions: ["Customers.name", "Customers.id"],
            order: {
                "Customers.name": "asc",
            },
        };
        let lineItemsCountJSONQuery = {
            measures: ["Orderlineitems.count"],
            timeDimensions: [
                {
                    dimension: "Orderlineitems.createdat",
                    dateRange: "Last 7 days",
                },
            ],
            order: {
                "Orderlineitems.count": "desc",
            },
            dimensions: ["Orderlineitems.customerid"],
        };
        let activeSkusJSONQuery = {
            "measures": [
              "Orderlineitems.sku_count"
            ],
            "dimensions": [
              "Orderlineitems.customerid",
              "Orderlineitems.sku"
            ],
            "order": {
              "Orderlineitems.sku_count": "desc"
            },
            "timeDimensions": [
              {
                "dimension": "Orderlineitems.createdat",
                "dateRange": "Last 7 days"
              }
            ]
          };

        const [selectedTenant, setSelectedTenant] = useState("Delmar");
        const [dateRange, setDateRange] = useState("Last 7 days");
        const [customers, setCustomers] = useState([]);
        const [loading, setLoading] = useState(true);
        const [ordersCount, setOrdersCount] = useState(null);
        const [lineItemsCount, setLineItemsCount] = useState(null);
        const [activeSkusCount, setActiveSkusCount] = useState(null);
        useEffect(async () => {
            setOrdersCount(null)
            setActiveSkusCount(null)
            setLineItemsCount(null)
            setLoading(true)
            activeSkusJSONQuery["timeDimensions"] = activeSkusJSONQuery[
                "timeDimensions"
            ].map((dimension) => ({
                ...dimension,
                dateRange,
            }));
            ordersCountJSONQuery["timeDimensions"] = ordersCountJSONQuery[
                "timeDimensions"
            ].map((dimension) => ({
                ...dimension,
                dateRange,
            }));
            lineItemsCountJSONQuery["timeDimensions"] = lineItemsCountJSONQuery[
                "timeDimensions"
            ].map((dimension) => ({
                ...dimension,
                dateRange,
            }));
            let data = null;
            const activeSkusResultSet = await cubejsApi.load(
                activeSkusJSONQuery
            );
            setActiveSkusCount(activeSkusResultSet.chartPivot());
            const ordersCountResultSet = await cubejsApi.load(
                ordersCountJSONQuery
            );
            setOrdersCount(ordersCountResultSet.chartPivot());
            const lineItemsCountResultSet = await cubejsApi.load(
                lineItemsCountJSONQuery
            );
            setLineItemsCount(lineItemsCountResultSet.chartPivot());
        }, [dateRange]);
        useEffect(async () => {
            let data = null;
            const customersResultSet = await cubejsApi.load(customersJSONQuery);
            data = customersResultSet.chartPivot();
            const customersArray = data.map((e) => ({
                name: e["xValues"][0],
                id: e["xValues"][1],
            }));
            setCustomers(customersArray);
        }, []);
        const [tabs, setTabs] = useState({});
        useEffect(() => {
            if (
                customers &&
                tabs &&
                lineItemsCount &&
                ordersCount &&
                activeSkusCount
            ) {
                console.log("DONE")
                console.log(lineItemsCount)
                console.log(ordersCount)
                console.log(activeSkusCount)
                customers.forEach((customer) => {
                    customer = {
                        ...customer,
                        ordersCount: {
                            title: "Orders",
                            value: ordersCount.filter((elem => elem.x === customer.id))[0] ? ordersCount.filter((elem => elem.x === customer.id))[0]["Orders.count"] : 0,
                        },
                        lineItemsCount: {
                            title: "Line Items",
                            value: lineItemsCount.filter((elem => elem.x === customer.id))[0] ? lineItemsCount.filter((elem => elem.x === customer.id))[0]["Orderlineitems.count"] : 0,
                        },
                        activeSkuCount: {
                            title: "Active SKUs",
                            value: activeSkusCount.filter(elem => elem.xValues[0] === customer.id).length,
                        },
                    };
                    let t = tabs;
                    t[customer.name] = (
                        <CustomerOrdersAndProductsStats customer={customer} />
                    );
                    setTabs(t);
                    setLoading(false);
                });
            }
        }, [customers, tabs, lineItemsCount, ordersCount, activeSkusCount]);

        return (
            <WrappedComponent
                tabs={tabs}
                selectedTenant={selectedTenant}
                setSelectedTenant={setSelectedTenant}
                dateRange={dateRange}
                setDateRange={setDateRange}
                loading={loading}
            />
        );
    };
};

export default withDashboardLogic;
