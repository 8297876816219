const config = require("config");
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import Tabs from "#components/utils/Tabs";
import ShippingStats from "#components/dashboard/ShippingStats";
import OperationsStats from "#components/dashboard/OperationsStats";
import moment from "moment-timezone";
import numeral from "numeral";
import Dropdown from "#components/utils/Dropdown";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import cubejs from "@cubejs-client/core";
import Chart from "#components/utils/Chart";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    BarChart,
    Bar,
} from "recharts";
import withDashboardLogic from "../components/HOC/withDashboardLogic";

const numberFormatter = (item) => numeral(item).format("0,0");
const dateFormatter = (item) => moment(item).format("MMM YY");

const renderSingleValue = (resultSet, key) => (
    <h1 height={300}>{numberFormatter(resultSet.chartPivot()[0][key])}</h1>
);

const DateRangeOptions = [
    { name: "Today" },
    { name: "Yesterday" },
    { name: "This week" },
    { name: "This month" },
    { name: "Last 7 days" },
    { name: "Last 30 days" },
    { name: "Last week" },
    { name: "Last month" },
];

const Dashboard = ({
    tabs,
    selectedTenant,
    setSelectedTenant,
    dateRange,
    setDateRange,
    loading,
}) => {
    return (
        <div className="p-4">
            <PageTitle>Dashboard</PageTitle>
            <>
                <div className="flex mt-6">
                    <div className="mr-5 w-48">
                        <div>
                            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                                Select Tenant
                            </span>
                        </div>
                        <div>
                            <RoundedDropdown
                                placeholder={"Select Tenant"}
                                list={Object.keys(tabs).map((t) => ({
                                    name: t,
                                }))}
                                labelKey="name"
                                valueKey="name"
                                name="tenant"
                                setSelected={(e) => {
                                    setSelectedTenant(e);
                                }}
                                selectedValue={selectedTenant}
                            />
                        </div>
                    </div>
                    <div className="mr-auto w-48">
                        <div>
                            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                                Date Range
                            </span>
                        </div>
                        <div>
                            <RoundedDropdown
                                placeholder={"Date Range"}
                                list={DateRangeOptions}
                                labelKey="name"
                                valueKey="name"
                                name="deviceType"
                                selectedValue={dateRange}
                                setSelected={(e) => {
                                    setDateRange(e);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {loading ? <h4>Loading...</h4> : tabs[selectedTenant]}
            </>
        </div>
    );
};

export default withDashboardLogic(Dashboard);

{
    /* <Chart
        cubejsApi={cubejsApi}
        title="New Users Over Time"
        query={{
          measures: ["Users.count"],
          timeDimensions: [
            {
              dimension: "Users.createdAt",
              dateRange: ["2019-01-01", "2020-12-31"],
              granularity: "month",
            },
          ],
        }}
        render={(resultSet) => (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={resultSet.chartPivot()}>
              <XAxis dataKey="category" tickFormatter={dateFormatter} />
              <YAxis tickFormatter={numberFormatter} />
              <Tooltip labelFormatter={dateFormatter} />
              <Area
                type="monotone"
                dataKey="Users.count"
                name="Users"
                stroke="rgb(106, 110, 229)"
                fill="rgba(106, 110, 229, .16)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      /> */
}
