import withPickingsLogic from "#components/HOC/withPickingsLogic";
import moment from "moment-timezone";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import Stats from "#components/common/Stats";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";

const headers = [
  "Batch ID",
  "Assigned Time",
  "First Pick",
  "Last Pick",
  "# items",
  "Completion",
];
const noValuesText = "No Pickings";

const menuItems = [
  {
    title: "View Line Items",
    icon: EyeIcon,
  },
  {
    title: "View Picking",
    icon: DocumentSearchIcon,
    onClick: "getAudit",
  },
  {
    title: "View Packing",
    icon: DocumentSearchIcon,
  },
];

const PickingsList = ({
  pickings,
  displayBatches,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  allData,
  setBatchItems,
  batchItems,
}) => (
  <>
    <Stats
      stats={[
        {
          name: "Total Batches",
          stat: allData.total,
        },
        {
          name: "Completion",
          stat:
            allData.totalCompleted && allData.total
              ? allData.totalCompleted?.toString() +
                " / " +
                parseInt(
                  (allData.totalCompleted / allData.total) * 100
                ).toString() +
                "%"
              : "",
        },
        {
          name: "Picks per Hour",
          stat: allData.completionPerHour,
        },
        {
          name: "Items in Error",
          stat: allData.itemsInError,
        },
      ]}
    />
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-4">
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "createdAt",
          },
          {
            name: "First Picked",
            value: "attributes.firstCompletedTime",
          },
          {
            name: "Last Picked",
            value: "attributes.lastCompletedTime",
          },
          {
            name: "Completion",
            value: "completion",
          },
        ]}
      />
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full">
          <tr className="text-224E73 font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {displayBatches.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}
                  >
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}
                  ></td>
                )
              )}
            </tr>
          ) : null}
          {displayBatches.map((picking, index) => (
            <tr
              key={picking.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
            >
              <td
                className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 cursor-pointer  ${
                  index % 2 === 0 ? "border-F4C261" : "border-224E73"
                }`}
                onClick={() => setBatchItems(picking.items)}
              >
                {picking.id}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {moment(picking.createdAt).format("MMM Do YYYY, h:mm a")}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {picking.attributes?.firstCompletedTime &&
                  moment(picking.attributes.firstCompletedTime).format(
                    "MMM Do YYYY, h:mm a"
                  )}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {picking.attributes?.lastCompletedTime &&
                  moment(picking.attributes.lastCompletedTime).format(
                    "MMM Do YYYY, h:mm a"
                  )}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {picking.items?.length}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {picking.completion && `${picking.completion}%`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Pagination
      showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
      showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
      showingTotal={total}
      perPage={perPage}
      setPerPage={setPerPage}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
    />
    {batchItems && (
      <ExpandedBatch
        batchItems={batchItems}
        negativeAction={() => setBatchItems(null)}
      />
    )}
    {showFilters && (
      <BatchFilters
        negativeAction={() => setShowFilters(false)}
        onChangeFilter={onChangeFilter}
        filters={filters}
        onSubmit={submitFilters}
      />
    )}
  </>
);

export default withPickingsLogic(PickingsList);
