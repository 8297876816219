import {
  SearchIcon,
  XIcon,
  FilterIcon,
  DownloadIcon,
} from "@heroicons/react/solid";
import TableFieldButton from "#components/utils/TableFieldButton";
import SortDropdown from "#components/common/SortDropdown";

const SearchSortFilter = ({
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  onClick,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  choices,
  exportToCsv,
}) => {
  return (
    <div className="flex mb-2 items-center justify-center">
      <div className="flex-1">
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="keyword"
            id="keyword"
            className="p-2 block w-full pl-12 text-lg border-gray-300 rounded-md focus:outline-none"
            placeholder="Search"
            onChange={onChangeSearchKeyword}
            value={filters["keyword"] ? filters["keyword"] : ""}
            onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : null)}
          />
          <div
            className="absolute inset-y-0 right-2 pl-3 flex items-center cursor-pointer"
            onClick={clearKeyword}
          >
            <XIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="m-1">
        <button
          type="button"
          className={`bg-F8F8F8 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-5F666B font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}
          onClick={() => setShowFilters(true)}
        >
          <FilterIcon className="w-7 h-7 mr-1" />
          Filter
        </button>
      </div>
      <div className="m-1">
        <button
          type="button"
          className={`bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}
          onClick={clearFilters}
        >
          <XIcon className="w-7 h-7 mr-1" />
          Clear Filters
        </button>
      </div>
      <div className="m-1">
        <SortDropdown setSort={setSort} sort={sort} choices={choices} />
      </div>
      {/* <div className="m-1">
        <button
          type="button"
          className={`bg-F8F8F8 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-5F666B font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}
          onClick={exportToCsv}
        >
          <DownloadIcon className="w-7 h-7 mr-1" />
          Export
        </button>
      </div> */}
    </div>
  );
};

export default SearchSortFilter;
