import gql from "graphql-tag";

export const GET_LOGGED_IN_USER = `
  query GetUser {
    me {
      id
      name
      token
      role
      hopstackModules
      activity
      station
    }
  }
`;

export const GET_MASTER_DATA = `
  query GetMasterData {
    masterData {
      hopstackModules {
        name
        id
      }
      roles {
        name
        id
      }
      deviceTypes {
        name
        id
      }
      warehouses {
        name
        id
      }
    }
  }
`;

export const GET_RULE_ENGINE_MASTER_DATA = `
  query GetRuleEngineMasterData {
    masterData {
      comparisonOperators {
        name
        value
        scope
      }
      logicalOperators {
        name
        value
      }
      masterFields {
        name
        value
        scope
        fieldType
      }
      allCountries {
        name
      }
      allCustomers {
        name
      }
      allWarehouses {
        name
      }
      allGroups {
        name
      }
      allBooleanChoices {
        name
      }
    }
  }
`;

export const GET_SHIPPERS = gql`
  query GetShippers {
    shippers {
      name
      id
    }
  }
`;

export const GET_SHIPPER = gql`
  query GetShipper($id: ID!) {
    shipper(id: $id) {
      name
      id
    }
  }
`;

export const GET_PICKER_BAGS = gql`
  query GetPickerBags {
    pickerBags {
      barcode
      multiLineItem
      subBinBarcodes
      subBins
      id
    }
  }
`;

export const GET_PICKER_BAG = gql`
  query GetPickerBag($id: ID!) {
    pickerBag(id: $id) {
      barcode
      multiLineItem
      subBins
      id
      subBinBarcodes
    }
  }
`;

export const GET_PACKING_STATIONS = gql`
  query GetPackingStations {
    packingStations {
      computerName
      packingTable
      id
      user
    }
  }
`;

export const GET_PACKING_STATION = gql`
  query GetPackingStation($id: ID!) {
    packingStation(id: $id) {
      computerName
      packingTable
      id
      user
    }
  }
`;

export const GET_USERS = `
  query GetUsers($filters: UserFilter) {
    users(filters: $filters) {
      username
      id
      name
      hopstackModules
      role
      customers
    }
  }
`;

export const GET_USER = `
  query FetchUser($id: ID!) {
    user(id: $id) {
      username
      id
      name
      hopstackModules
      role
      password
      customers
    }
  }
`;

export const GET_STATIONS = `
  query GetStations($filters: StationFilter) {
    stations(filters: $filters) {
      name
      id
      hopstackModule
      devices
      customers
    }
  }
`;

export const GET_STATION = `
  query FetchStation($id: ID!) {
    station(id: $id) {
      name
      id
      hopstackModule
      devices
      customers
    }
  }
`;

export const GET_WORKFLOWS = `
  query GetWorkflows($filters: WorkflowFilter) {
    workflows(filters: $filters) {
      name
      id
      stations {
        name
        enabled
        sort
      }
      workflowType
      customer
    }
  }
`;

export const GET_WORKFLOW = `
  query FetchWorkflow($id: ID!) {
    workflow(id: $id) {
      name
      id
      stations {
        name
        enabled
        sort
      }
      workflowType
      customer
    }
  }
`;

export const GET_DEVICES = `
  query GetDevices($filters: DeviceFilter) {
    devices(filters: $filters) {
      name
      hardwareId
      deviceType
      updatedAt
      customers
      id
    }
  }
`;

export const GET_DEVICE = `
  query GetDevice($id: ID!) {
    device(id: $id) {
      name
      hardwareId
      deviceType
      updatedAt
      customers
      id
    }
  }
`;

export const GET_CUSTOMERS = `
  query GetCustomers {
    customers {
      name
      code
      updatedAt
      id
    }
  }
`;

export const GET_CUSTOMER = `
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      name
      code
      updatedAt
      id
    }
  }
`;

export const GET_HOPSTACK_MODULES = gql`
  query GetHopstackModules {
    getHopstackModules {
      name
      id
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query GetWarehouses {
    warehouses {
      name
      id
    }
  }
`;

// OUTBOUND

export const GET_BATCHES = `
  query GetBatches($perPage: Int, $pageNumber: Int, $sort: String, $filters: BatchFilter) {
    batches(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        createdAt
        rows
      }
      total
    }
  }
`;

export const GET_PICKINGS = `
  query GetPickings($perPage: Int, $pageNumber: Int, $sort: String, $filters: PickingFilter) {
    pickings(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        batch
        createdAt
        firstPickedTime
        lastPickedTime
        completion
        packingStation {
          computerName
        }
        workingList {
          sku
        }
      }
      total
    }
  }
`;

export const GET_ALL_BATCHES = `
  query GetAllBatches($perPage: Int, $pageNumber: Int, $sort: String, $filters: AllBatchFilter) {
    getAllBatches(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        createdAt
        attributes {
          firstCompletedTime
          lastCompletedTime
        }
        completion
        workingList {
          sku
        }
        items {
          sku
          binLocation
          productName
          quantity
          status
          id
          attributes {
            color
            collection
            size
          }
        }
      }
      itemsInError
      completionPerHour
      activityCompletion
      total
      totalCompleted
    }
  }
`;

export const GET_PACKINGS = `
  query GetPackings($perPage: Int, $pageNumber: Int, $sort: String, $filters: PackingFilter) {
    packings(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        batch
        createdAt
        firstPackedTime
        lastPackedTime
        completion
        packingStation {
          computerName
        }
        workingList {
          sku
        }
      }
      total
    }
  }
`;

export const GET_PARCEL_STATUSES = `
  query GetParcelStatuses($perPage: Int, $pageNumber: Int, $filters: ParcelStatusFilter, $sort: String) {
    parcelStatuses(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        trackingNumber
        carrier
        shippingBin
        bagNumber
        truckId
        status
        completionTime
        parcelError
        order
      }
      total
    }
  }
`;

export const GET_SHIPMENTS = `
  query GetShipments($perPage: Int, $pageNumber: Int, $filters: ShipmentFilter, $sort: String) {
    shipments(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        trackingNumber
        deliveryNumber
        palletId
        status
        door
        location
        pickupTime
        pickUpDelayInDays
        carrierCode
        salesOrder
      }
      total
    }
  }
`;

// INBOUND

export const GET_RECEIVINGS = `
  query GetReceivings($perPage: Int, $pageNumber: Int, $filters: ReceivingFilter, $sort: String) {
    receivings(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstReceivedTime
        lastReceivedTime
        status
        consignment
      }
      total
    }
  }
`;

export const GET_INBOUND_BATCHES = `
  query GetBatches($perPage: Int, $pageNumber: Int, $sort: String, $filters: BatchFilter) {
    inboundBatches(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        createdAt
        currentStationStatus
        multipleLineItems
        rows
        tags
      }
      total
      pickingCompleted
      packingCompleted
    }
  }
`;

export const GET_PUTAWAYS = `
  query GetPutaways($perPage: Int, $pageNumber: Int, $filters: PutawayFilter, $sort: String) {
    putaways(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstPutawayTime
        lastPutawayTime
        status
      }
      total
    }
  }
`;

export const GET_QUALITY_CHECKINGS = `
  query GetQualityCheckings($perPage: Int, $pageNumber: Int, $filters: QualityCheckingFilter, $sort: String) {
    qualityCheckings(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstQualityCheckingTime
        lastQualityCheckingTime
        status
      }
      total
    }
  }
`;

export const GET_GROUPS = `
  query GetGroups {
    groups {
      id
      name
      mainCriteria
      conditions {
        masterField
        value
        comparisonOperator
      }
    }
  }
`;

export const GET_GROUP = gql`
  query FetchGroup($id: ID!) {
    group(id: $id) {
      id
      name
      mainCriteria
      conditions {
        masterField
        value
        comparisonOperator
      }
    }
  }
`;

export const GET_FORECASTS = `
  query GetForecasts {
    forecasts {
      id
      sku
      price
      orderLineItem {
        productName
        productImage
      }
      lastSold
      forecast {
        twoWeeks
        oneMonth
      }
      createdAt
      safetyStock
    }
  }
`;

export const GET_INVENTORY = `
  query GetInventory($perPage: Int, $pageNumber: Int, $filters: InventoryFilter, $sort: String) {
    inventory(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        sku
        lastPutaway
        lastReceived
        lastReceivedQuantity
        lastPutawayQuantity
        lastFulfilled
        lastFulfilledQuantity
        stock
        image
        name
        binLocation
        currentReceivedQuantity
      }
      total
    }
  }
`;

export const GET_SKU = `
  query GetSpecificInventory($sku: String!) {
    specificInventory(sku: $sku) {
      name
      sku
      image
      id
      attributes {
        colorName
        sizeName
        collectionName
      }
      binLocation
      quantity
      stock
    }
  }
`;

// RECEIVER

export const SEARCH_CONSIGNMENT = gql`
  query SearchConsignment($consignmentNumber: String) {
    searchConsignment(consignmentNumber: $consignmentNumber) {
      consignmentNumber
      supplier
      carrier
      noOfItems
      status
      consignmentType
      id
    }
  }
`;

export const GET_CURRENT_RECEIVING = `
  query GetCurrentReceiving {
    getCurrentReceiving {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
      }
      currentItem {
        id
        name
        sku
        image
        quantity
      }
    }
  }
`;

export const SCAN_RECEIVER_BARCODE = gql`
  query ScanReceiverBarcode($typeOfInventory: String!) {
    scanReceiverBarcode(typeOfInventory: $typeOfInventory) {
      productImage
      sku
      binLocation
      quantity
      dateReceived
      typeOfInventory
      createdAt
      id
    }
  }
`;

// PUTAWAY

export const GET_CURRENT_PUTAWAY = `
  query GetCurrentPutaway {
    getCurrentPutaway {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
        binLocation
      }
      currentItem {
        id
        name
        sku
        image
        quantity
        binLocation
      }
    }
  }
`;

export const GET_NEW_PUTAWAY = `
  query GetNewPutaway {
    getNewPutaway {
      message
    }
  }
`;

// INTEGRATIONS

export const GET_INTEGRATIONS = `
  query GetIntegations($filters: IntegrationFilter) {
    integrations(filters: $filters) {
      integrationType
      username
      customer
      id
    }
  }
`;

export const GET_INTEGRATION_PRODUCTS = `
  query GetIntegationProducts($id: ID!) {
    integrationProducts(id: $id) {
      # id
      # title
      # body_html
      # vendor
      # product_type
      message
    }
  }
`;

export const GET_INTEGRATION_ORDERS = `
  query GetIntegationOrders($id: ID!) {
    integrationOrders(id: $id) {
      # id
      # created_at
      # current_subtotal_price
      # order_number
      # line_items {
      #   name
      # }
      message
    }
  }
`;

// ORDERS

export const GET_ORDER_MASTER_DATA = `
  query GetMasterData {
    masterData {
      allStatuses {
        name
        id
      }
      allSources {
        name
      }
      allCustomers {
        name
      }
      allCountries {
        name
      }
      allShippingNames {
        name
      }
    }
  }
`;

export const GET_ORDERS = `
  query GetOrders(
    $perPage: Int
    $pageNumber: Int
    $filters: OrderFilter
    $paginated: Boolean
    $sort: String
  ) {
    orders(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      paginated: $paginated
      sort: $sort
    ) {
      entities {
        orderValue
        orderStatus
        reference
        orderId
        carrier
        id
        orderDate
        shippingAddress {
          country
        }
        customerId
        exceptions {
          description
          typeOfException
        }
        shippingLabel
        tote
        cannotFulfil
      }
      total
    }
  }
`;

export const GET_AUDIT = `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      stages {
        status
        createdAt
        notes
      }
    }
  }
`;

export const CALCULATE_SHIPPING_RATES = `
  query CalculateShippingRates($orderNo: String!) {
    calculateShippingRates(orderNo: $orderNo) {
      serviceName
      chargeWeight {
        value
        unit
      }
      totalCharge {
        amount
        unit
      }
      selected
    }
  }
`;

export const FETCH_SPECIFIC_ORDER = `
  query FetchSpecificOrder($orderId: ID!) {
    order(orderId: $orderId) {
      id
      orderId
      reference
      orderStatus
      subStatus
      shippingLabel
      trackingNumber
      carrier
      tote
      cannotFulfil
      orderLineItems {
        sku
        binLocation
        id
        price
        quantity
        reference
        productName
        attributes {
          collection
          color
          size
        }
        status
      }
    }
  }
`;

// CONSIGNMENT

export const GET_CONSIGNMENTS = `
  query GetConsignments(
    $perPage: Int
    $pageNumber: Int
    $filters: ConsignmentFilter
    $sort: String
  ) {
    consignments(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        items {
          name
          sku
          price
          quantity
        }
        status
        createdAt
        consignmentType
        supplier
        consignmentNumber
        carrier
        noOfItems
      }
      total
    }
  }
`;

export const GET_CONSIGNMENT_AUDIT = `
  query GetConsignmentAudit($consignmentNumber: String!) {
    getConsignmentAudit(consignmentNumber: $consignmentNumber) {
      stages {
        status
        createdAt
        extra
      }
    }
  }
`;

export const GET_CONSIGNMENT_MASTER_DATA = `
  query GetConsignmentMasterData {
    masterData {
      allConsignmentStatuses {
        name
      }
      allConsignmentTypes {
        name
      }
    }
  }
`;

// RECEIVED SKUs

export const GET_RECEIVED_SKUS = `
  query GetReceivedSkus(
    $perPage: Int
    $pageNumber: Int
    $filters: ReceivedSkuFilter
    $sort: String
  ) {
    receivedSkus(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        status
        name
        sku
        dateReceived
        id
        quantity
        attributes {
          colorName
          sizeName
          collectionName
        }
      }
      total
    }
  }
`;

export const GET_RECEIVED_SKU = `
  query GetReceivedSku(
    $id: ID!
  ) {
    receivedSku(id: $id) {
      status
      name
      sku
      dateReceived
      id
      quantity
    }
  }
`;

export const GET_RECEIVED_SKU_AUDIT = `
  query GetReceivedSkuAudit($id: ID!) {
    getReceivedSkuAudit(id: $id) {
      stages {
        status
        createdAt
        extra
      }
    }
  }
`;

export const GET_RECEIVED_SKUS_MASTER_DATA = `
  query GetReceivedSkusMasterData {
    masterData {
      allReceivedSkuStatuses {
        name
      }
    }
  }
`;

export const GET_UNMAPPED_SKUS = `
  query GetUnmappedSkus {
    getUnmappedSkus {
      sku
    }
  }
`;

export const GET_UNMAPPED_BIN_LOCATIONS = `
  query GetUnmappedBinLocations {
    getUnmappedBinLocations {
      binLocation
    }
  }
`;

// PICKER
export const GET_CURRENT_PICKER_BATCH = `
  query GetCurrentPickerBatch {
    getCurrentPickerBatch {
      id
      tote
      status
      completion
      attributes {
        dropoffStation
      }
      currentItem {
        id
        sku
        binLocation
        productName
        status
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        attributes {
          color
          pattern
          size
          collection
        }
      }
      workingList {
        sku
        binLocation
        productName
        status
        quantity
        pickedQuantity
        attributes {
          color
          pattern
          size
          collection
        }
      }
      confirmationList {
        sku
        productName
        status
        attributes {
          color
          pattern
          size
          collection
        }
      }
      verificationNeeded
    }
  }
`;

export const GET_NEW_PICKER_BATCH = `
  query GetNewPickerBatch {
    getNewPickerBatch {
      message
    }
  }
`;

// PACKER

export const GET_CURRENT_PACKER_BATCH = `
  query GetCurrentPackerBatch {
    getCurrentPackerBatch {
      id
      status
      completion
      currentItem {
        uniqueIdentifier
        productImage
        sku
        productName
        order
        id
        attributes {
          color
          size
          collection
        }
      }
      workingList {
        uniqueIdentifier
        quantity
        sku
        status
        productName
        order
        id
      }
    }
  }
`;

export const GET_CURRENT_PUT = gql`
  query GetCurrentPut {
    getCurrentPut {
      id
      currentItem {
        sku
        binLocation
      }
    }
  }
`;

export const GET_SKU_BIN_MAPPINGS = `
  query GetSkuBinMappings(
    $perPage: Int
    $pageNumber: Int
    $filters: InventoryFilter
    $sort: String
  ) {
    skuBinMappings(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        sku
        binLocation
        id
      }
      total
    }
  }
`;

export const GET_PRODUCTS = `
  query GetProducts {
    products {
      entities {
        id
        title
        body_html
        image {
          src
        }
        tags
        vendor
        product_type
      }
      total
    }
  }
`;

// QUALITY CHECKING

export const GET_CURRENT_QUALITY_CHECKING = `
  query GetCurrentQualityChecking {
    getCurrentQualityChecking {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
        binLocation
      }
      currentItem {
        id
        name
        sku
        image
        quantity
        binLocation
      }
    }
  }
`;

export const GET_NEW_QUALITY_CHECKING = `
  query GetNewQualityChecking {
    getNewQualityChecking {
      message
    }
  }
`;

export const GET_PTL_DEVICES = `
  query GetPtlDevices {
    getPtlDevices {
      devices
      message
    }
  }
`;

export const GET_TOTES = `
  query GetTotes {
    totes {
      barcode
      toteType
      id
    }
  }
`;

export const GET_TOTE = `
  query FetchTote($id: ID!) {
    tote(id: $id) {
      barcode
      toteType
      id
    }
  }
`;

export const GET_BIN_LOCATIONS = `
  query GetBinLocations {
    binLocations {
      barcode
      binLocationType
      id
    }
  }
`;

export const GET_BIN_LOCATION = `
  query FetchBinLocation($id: ID!) {
    binLocation(id: $id) {
      barcode
      binLocationType
      id
    }
  }
`;

export const GET_SORTING_LOCATIONS = `
  query GetSortingLocations {
    sortingLocations {
      barcode
      sortingLocationType
      id
      position
      currentToteBarcode
      currentMainEntityId
    }
  }
`;

export const GET_SORTING_LOCATION = `
  query FetchSortingLocation($id: ID!) {
    sortingLocation(id: $id) {
      barcode
      sortingLocationType
      id
      position
    }
  }
`;

// OUTBOUND SORTED

export const GET_SORTINGS = `
  query GetSortings {
    getSortings {
      sku
      status
      originalTote
      attributes {
        color
        pattern
        size
        collection
      }
      id
      order
    }
  }
`;

// INWARD RECEIVING

export const GET_CURRENT_INWARD_ITEM = `
  query GetCurrentInwardItem {
    getCurrentInwardItem {
      name
      sku
      attributes {
        sizeName
        colorName
        collectionName
      }
      image
      quantity
      id
    }
  }
`;

// STANDALONE PUTAWAY

export const GET_CURRENT_STANDALONE_PUTAWAY_ITEM = `
  query GetCurrentStandalonePutawayItem {
    getCurrentStandalonePutawayItem {
      name
      sku
      attributes {
        sizeName
        colorName
        collectionName
      }
      binLocation
      id
      scannedBin
      quantity
    }
  }
`;
