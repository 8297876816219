import { gql } from "@apollo/client";

export const LOGOUT_USER = `
  mutation Logout {
    logout {
      message
    }
  }
`;

export const SET_ACTIVITY = `
  mutation setActivity($activity: String!) {
    setActivity(activity: $activity) {
      message
    }
  }
`;

export const SET_STATION = `
  mutation setStation($station: String!, $logoutAll: Boolean) {
    setStation(station: $station, logoutAll: $logoutAll) {
      message
    }
  }
`;

export const SAVE_USER = `
  mutation saveUser($name: String!, $role: String!, $hopstackModules: [String!], $id: ID, $password: String!, $customers: [ID!]) {
    saveUser(
      userInput: { name: $name, role: $role, hopstackModules: $hopstackModules, id: $id, password: $password, customers: $customers }
    ) {
      message
    }
  }
`;

export const DELETE_USER = `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`;

export const SAVE_STATION = `
  mutation saveStation(
    $name: String!
    $hopstackModule: String!
    $id: ID
    $devices: [ID!]
    $customers: [ID!]
  ) {
    saveStation(
      stationInput: {
        name: $name
        hopstackModule: $hopstackModule
        id: $id
        devices: $devices
        customers: $customers
      }
    ) {
      message
    }
  }
`;

export const DELETE_STATION = `
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id) {
      message
    }
  }
`;

export const SAVE_WORKFLOW = `
  mutation saveWorkflow(
    $name: String!
    $workflowType: String!
    $id: ID
    $customer: ID!
    $stations: [WorkflowStationInput!]
  ) {
    saveWorkflow(
      workflowInput: {
        name: $name
        workflowType: $workflowType
        id: $id
        customer: $customer
        stations: $stations
      }
    ) {
      message
    }
  }
`;

export const DELETE_WORKFLOW = `
  mutation deleteWorkflow($id: ID!) {
    deleteWorkflow(id: $id) {
      message
    }
  }
`;

export const SAVE_INTEGRATION = `
  mutation SaveIntegration(
    $username: String!
    $password: String!
    $storeName: String!
    $integrationType: String!
    $customer: ID!
    $id: ID
  ) {
    saveIntegration(
      integrationInput: {
        username: $username
        password: $password
        id: $id
        customer: $customer
        integrationType: $integrationType
        storeName: $storeName
      }
    ) {
      message
    }
  }
`;

export const SAVE_SHIPPER = gql`
  mutation saveShipper($name: String!, $id: ID) {
    saveShipper(shipperInput: { name: $name, id: $id }) {
      message
    }
  }
`;

export const DELETE_SHIPPER = gql`
  mutation deleteShipper($id: ID!) {
    deleteShipper(id: $id) {
      message
    }
  }
`;

export const SAVE_DEVICE = `
  mutation saveDevice(
    $name: String!
    $id: ID
    $hardwareId: String!
    $deviceType: String!
    $customers: [ID!]
  ) {
    saveDevice(
      deviceInput: {
        name: $name
        id: $id
        hardwareId: $hardwareId
        deviceType: $deviceType
        customers: $customers
      }
    ) {
      message
    }
  }
`;

export const DELETE_DEVICE = `
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      message
    }
  }
`;

export const SAVE_CUSTOMER = `
  mutation saveDevice(
    $name: String!
    $id: ID
    $code: String!
  ) {
    saveCustomer(
      customerInput: {
        name: $name
        id: $id
        code: $code
      }
    ) {
      message
    }
  }
`;

export const DELETE_CUSTOMER = `
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      message
    }
  }
`;

export const SAVE_PICKER_BAG = gql`
  mutation savePickerBag(
    $barcode: String!
    $id: ID
    $multiLineItem: Boolean!
    $subBinBarcodes: [String!]
  ) {
    savePickerBag(
      pickerBagInput: {
        barcode: $barcode
        id: $id
        multiLineItem: $multiLineItem
        subBinBarcodes: $subBinBarcodes
      }
    ) {
      message
    }
  }
`;

export const DELETE_PICKER_BAG = gql`
  mutation deletePickerBag($id: ID!) {
    deletePickerBag(id: $id) {
      message
    }
  }
`;

export const SAVE_PACKING_STATION = gql`
  mutation savePackingStation(
    $computerName: String!
    $id: ID
    $packingTable: Int!
    $user: ID
  ) {
    savePackingStation(
      packingStationInput: {
        computerName: $computerName
        id: $id
        packingTable: $packingTable
        user: $user
      }
    ) {
      message
    }
  }
`;

export const DELETE_PACKING_STATION = gql`
  mutation deletePackingStation($id: ID!) {
    deletePackingStation(id: $id) {
      message
    }
  }
`;

export const SAVE_GROUP = gql`
  mutation saveGroup(
    $name: String!
    $id: ID
    $mainCriteria: String!
    $conditions: [ConditionInput!]!
  ) {
    saveGroup(
      groupInput: {
        name: $name
        id: $id
        mainCriteria: $mainCriteria
        conditions: $conditions
      }
    ) {
      message
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      message
    }
  }
`;
// PICKER

export const SCAN_PICKER_BARCODE = `
    mutation ScanPickerBarcode($code: String!) {
    scanPickerBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_PICK_ITEM = `
  mutation ConfirmPickItem($id: ID!, $item: PickerConfirmItem!) {
    confirmPickItem(id: $id, item: $item) {
      message
    }
  }
`;

export const CONFIRM_PICKER_DROPOFF = `
  mutation ConfirmPickerDropoff($id: ID!) {
    confirmPickerDropoff(id: $id) {
      message
    }
  }
`;

// BATCH

export const TRY_RESOLVE_EXCEPTION = `
  mutation TryResolve($id: ID!) {
    tryResolve(id: $id) {
      message
    }
  }
`;

export const CREATE_MANUAL_ORDER_BATCH = `
  mutation CreateManualOrderBatch($ids: [ID!], $user: ID) {
    createManualOrderBatch(ids: $ids, user: $user) {
      message
    }
  }
`;

// export const CREATE_AUTO_ORDER_BATCHES = `
//   mutation CreateAutoOrderBatches($startDate: Date, $endDate: Date) {
//     createAutoOrderBatches(startDate: $startDate, endDate: $endDate) {
//       message
//     }
//   }
// `;

export const CREATE_AUTO_ORDER_BATCHES = `
  mutation CreateAutoOrderBatches {
    createAutoOrderBatches {
      message
    }
  }
`;

export const SIMULATE_AUTO_ORDER_BATCHES = `
  mutation SimulateAutoOrderBatches {
    simulateAutoOrderBatches {
      items {
        uniqueIdentifier
        binLocation
        sku
        quantity
        reference
        price
        attributes {
          color
          collection
          size
        }
      }
      rows
    }
  }
`;

// PACKER

export const SCAN_PACKER_BARCODE = `
  mutation ScanPackerBarcode($code: String!) {
    scanPackerBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_PACK_ITEM = `
  mutation ConfirmPackItem($id: ID!, $status: String!, $itemId: String) {
    confirmPackItem(id: $id, status: $status, itemId: $itemId) {
      message
    }
  }
`;

export const CONFIRM_PACKER_DROPOFF = `
  mutation ConfirmPackerDropoff($id: ID!) {
    confirmPackerDropoff(id: $id) {
      message
    }
  }
`;

export const SAVE_SKU_BIN_MAPPING = `
  mutation saveSkuBinMapping(
    $sku: String!
    $id: ID
    $binLocation: String!
  ) {
    saveSkuBinMapping(
      skuBinMappingInput: {
        sku: $sku
        binLocation: $binLocation
        id: $id
      }
    ) {
      message
    }
  }
`;

export const DELETE_SKU_BIN_MAPPING = `
  mutation deleteSkuBinMapping($id: ID!) {
    deleteSkuBinMapping(id: $id) {
      message
    }
  }
`;

export const SAVE_STANDALONE_SKU = `
  mutation SaveStandaloneSku($sku: String!) {
    saveStandaloneSku(sku: $sku) {
      message
    }
  }
`;

export const SAVE_STANDALONE_BIN_LOCATION = `
  mutation SaveStandaloneBinLocation($binLocation: String!) {
    saveStandaloneBinLocation(binLocation: $binLocation) {
      message
    }
  }
`;

export const RUN_COMBINED_ORDERS = `
  mutation RunCombinedOrders {
    runCombinedOrders {
      _id {
        name
        city
        state
        street
      }
      Counter
    }
  }
`;

export const MANUALLY_MARK_ORDERS = `
  mutation ManuallyMarkOrders($ids: [ID!], $status: String!) {
    manuallyMarkOrders(ids: $ids, status: $status) {
        message
    }
  }
`;

// RECEIVER

export const CONFIRM_RECEIVER_ITEM = `
  mutation ConfirmReceiverItem($id: ID!, $status: String!) {
    confirmReceiverItem(id: $id, status: $status) {
      message
    }
  }
  `;

export const START_RECEIVING = `
  mutation StartReceiving($consignmentId: ID!) {
    startReceiving(consignmentId: $consignmentId) {
      message
    }
  }
`;

// QUALITY CHECKING

export const CONFIRM_QUALITY_CHECKING_ITEM = `
  mutation confirmQualityCheckingItem($id: ID!, $status: String!) {
    confirmQualityCheckingItem(id: $id, status: $status) {
      message
    }
  }
`;

// PUTAWAY

export const CONFIRM_PUTAWAY_ITEM = `
  mutation confirmPutawayItem($id: ID!) {
    confirmPutawayItem(id: $id) {
      message
    }
  }
`;

export const SAVE_PRODUCT = `
    mutation saveProduct($title: String, $id: String, $vendor: String, $tags: String, $body_html: String, $changedImage: Boolean, $image: String) {
    saveProduct(
      productInput: {
        title: $title
        id: $id
        vendor: $vendor
        tags: $tags
        body_html: $body_html
        changedImage: $changedImage
        image: $image
        }
    ) {
      message
    }
  }
`;

export const DELETE_PRODUCT = `
  mutation deleteProduct($id: String) {
    deleteProduct(id: $id) {
        message
    }
  }
`;

export const SAVE_TOTE = `
  mutation saveTote(
    $barcode: String!
    $toteType: String!
    $id: ID
  ) {
    saveTote(
      toteInput: {
        barcode: $barcode
        id: $id
        toteType: $toteType
      }
    ) {
      message
    }
  }
`;

export const DELETE_TOTE = `
  mutation DeleteTote($id: ID!) {
    deleteTote(id: $id) {
      message
    }
  }
`;

export const SAVE_BIN_LOCATION = `
  mutation saveBinLocation(
    $barcode: String!
    $binLocationType: String!
    $id: ID
  ) {
    saveBinLocation(
      binLocationInput: {
        barcode: $barcode
        id: $id
        binLocationType: $binLocationType
      }
    ) {
      message
    }
  }
`;

export const DELETE_BIN_LOCATION = `
  mutation DeleteBinLocation($id: ID!) {
    deleteBinLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_SORTING_LOCATION = `
  mutation saveSortingLocation(
    $barcode: String!
    $sortingLocationType: String!
    $id: ID
    $position: Int
  ) {
    saveSortingLocation(
      sortingLocationInput: {
        barcode: $barcode
        id: $id
        sortingLocationType: $sortingLocationType
        position: $position
      }
    ) {
      message
    }
  }
`;

export const DELETE_SORTING_LOCATION = `
  mutation DeleteSortingLocation($id: ID!) {
    deleteSortingLocation(id: $id) {
      message
    }
  }
`;

export const SAVE_RECEIVED_SKU = `
  mutation SaveReceivedSku($id: ID, $quantity: Int) {
    saveReceivedSku(receivedSkuInput: { quantity: $quantity, id: $id }) {
      message
    }
  }
`;

// INVENTORY UPDATE

export const UPDATE_INVENTORY = `
  mutation UpdateInventory($id: ID!, $stock: Int!) {
    updateInventory(id: $id, stock: $stock) {
      message
    }
  }
`;

// INWARD RECEIVER

export const SCAN_INWARD_RECEIVER_BARCODE = `
  mutation ScanInwardReceiverBarcode($code: String!) {
    scanInwardReceiverBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_INWARD_ITEM = `
  mutation ConfirmInwardItem($item: InwardItemInput!) {
    confirmInwardItem(item: $item) {
      message
    }
  }
`;

// STANDALONE PUTAWAY

export const SCAN_STANDALONE_PUTAWAY_BARCODE = `
  mutation ScanStandalonePutawayBarcode($code: String!) {
    scanStandalonePutawayBarcode(code: $code) {
      message
    }
  }
`;

export const CONFIRM_STANDALONE_PUTAWAY_ITEM = `
  mutation ConfirmStandalonePutawayItem($item: StandalonePutawayItemInput!) {
    confirmStandalonePutawayItem(item: $item) {
      message
    }
  }
`;
