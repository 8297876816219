import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_DEVICES, GET_DEVICE, GET_CUSTOMERS } from "#queries";
import { SAVE_DEVICE, DELETE_DEVICE } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";

const withDevicesLogic = (WrappedComponent) => {
  return (props) => {
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const devicesQuery = useQuery(GET_DEVICES);
    const getDeviceQuery = useQuery(GET_DEVICE);
    const saveDeviceQuery = useQuery(SAVE_DEVICE);
    const deleteDeviceQuery = useQuery(DELETE_DEVICE);
    const customersQuery = useQuery(GET_CUSTOMERS);
    useEffect(() => {
      customersQuery.fetchData();
    }, []);

    useEffect(() => {
      if (customersQuery.data && customersQuery.data.customers.length > 0) {
        setSelectedCustomer(customersQuery.data.customers[0].id);

        devicesQuery.fetchData({
          filters: {
            customers: [customersQuery.data.customers[0].id],
          },
        });
      }
    }, [customersQuery.loading, customersQuery.data, customersQuery.error]);

    useEffect(() => {
      if (getDeviceQuery.data) {
        setSelectedDevice(getDeviceQuery.data.device);
      }

      if (getDeviceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [getDeviceQuery.loading, getDeviceQuery.data, getDeviceQuery.error]);

    useEffect(() => {
      if (saveDeviceQuery.data) {
        setSelectedDevice(null);
        devicesQuery.fetchData();
      }
      if (saveDeviceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveDeviceQuery.error) {
        console.log(saveDeviceQuery.error);
        appState.setAlert(saveDeviceQuery.error.message, "error", 5000);
      }
    }, [saveDeviceQuery.loading, saveDeviceQuery.data, saveDeviceQuery.error]);

    useEffect(() => {
      if (deleteDeviceQuery.data) {
        appState.hideConfirmation();
        devicesQuery.fetchData();
      }
      if (deleteDeviceQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteDeviceQuery.loading,
      deleteDeviceQuery.data,
      deleteDeviceQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this device?",
        () => {
          deleteDeviceQuery.fetchData({ id });
        },
        appState.hideConfirmation
      );
    };
    const roleWise = (devices, role) =>
      _.filter(devices, (e) => e.role.name === role);

    const onChange = (e) => {
      const device = {
        ...selectedDevice,
      };

      device[e.target.name] = e.target.value;
      setSelectedDevice(device);
    };

    const onChangeDropdown = (field, value) => {
      const device = {
        ...selectedDevice,
      };

      device[field] = value;
      setSelectedDevice(device);
    };

    const onChangeMultiSelect = (field, value) => {
      const device = {
        ...selectedDevice,
      };
      device[field] = value;
      setSelectedDevice(device);
      console.log(device);
    };

    console.log(devicesQuery.data?.devices);
    return (
      <WrappedComponent
        devices={devicesQuery.data ? devicesQuery.data.devices : []}
        masterData={masterData}
        fetchDevice={(id) => getDeviceQuery.fetchData({ id })}
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        saveDevice={(device) => {
          saveDeviceQuery.fetchData({ ...device });
        }}
        roleWise={roleWise}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={customersQuery.data ? customersQuery.data.customers : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          devicesQuery.fetchData({
            filters: {
              customers: [e],
            },
          });
        }}
      />
    );
  };
};

export default withDevicesLogic;
