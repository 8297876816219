import { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: true,
  token: localStorage?.getItem("token"),
};

const AuthContext = createContext({
  user: null,
  login: (userData) => {},
  logout: () => {},
  setUser: (userData) => {},
  token: localStorage?.getItem("token"),
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        token: action.payload.token,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
      };
    case "FETCH_USER":
      return {
        ...state,
        user: {
          ...action.payload,
        },
        isAuthenticated: true,
        loading: false,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    localStorage.setItem("token", userData.token);
    dispatch({
      type: "LOGIN",
      payload: userData,
    });
  }

  function logout() {
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
  }

  function setUser(userData) {
    dispatch({
      type: "FETCH_USER",
      payload: userData,
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        isAuthenticated: state.isAuthenticated,
        setUser,
        loading: state.loading,
        token: state.token,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
