const ModalButton = ({ onClick, transparent, text, className }) => (
  <button
    type="button"
    className={`inline-flex justify-center px-4 py-2 text-lg font-medium ${
      transparent
        ? "bg-transparent border border-F4C261 text-B3BFCA"
        : "bg-C2D2DF"
    } font-montserrat font-medium border rounded-md ring-0 focus:ring-0 outline-none focus:outline-none ${className}`}
    onClick={onClick}
  >
    {text || "Button"}
  </button>
);

export default ModalButton;
