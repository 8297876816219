import {
  SearchIcon,
  XIcon,
  FilterIcon,
  DownloadIcon,
} from "@heroicons/react/solid";

import { useState } from "react";
import AddButton from "#components/utils/AddButton";
import TextField from "#components/utils/TextField";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import axios from "axios";
const config = require("config");

const Spinner = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-10 w-10"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Simulations = () => {
  const [pickers, setPickers] = useState([]);
  const [dropoffBreak, setDropoffBreak] = useState(0);
  const [prioritisation, setPrioritisation] = useState("v2");
  const [batchSize, setBatchSize] = useState(15);
  const [maxOrdersInBatch, setmaxOrdersInBatch] = useState(6);
  const [completeOrderIdsMax, setcompleteOrderIdsMax] = useState(4);
  const [simState, setSimState] = useState(0);
  const [waveSize, setWaveSize] = useState(25);
  const [rowCongestion, setrowCongestion] = useState(0);
  const [smallBatches, setsmallBatches] = useState(0);
  const [avgSortingCongestion, setavgSortingCongestion] = useState(0);
  const [maxSortingCongestion, setmaxSortingCongestion] = useState(0);
  const [pickingSpeed, setpickingSpeed] = useState(0);
  const addPicker = () => {
    setPickers([
      ...pickers,
      {
        name: "",
        speed: 0,
        state: 0,
        work_share: 1,
      },
    ]);
  };

  const runSimulation = () => {
    setSimState(1);
    console.log("Sending request", config.SIMULATIONS_URL);

    try {
      axios
        .post(config.SIMULATIONS_URL, {
          pickers: pickers.map((p) => {
            return {
              ...p,
              speed: parseFloat(p.speed) * 1000,
            };
          }),
          waveSize,
          batchSize,
          maxOrdersInBatch,
          completeOrderIdsMax,
          dropoffBreak,
        })
        .then((response) => {
          const { data } = response;
          setrowCongestion(data.rowCongestion);
          setsmallBatches(data.smallBatches);
          setavgSortingCongestion(data.avgSortingCongestion);
          setmaxSortingCongestion(data.maxSortingCongestion);
          setpickingSpeed(data.pickingSpeed);
          setSimState(2);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }

    console.log("Sent request", config.SIMULATIONS_URL);
  };

  return (
    <div className="flex p-8">
      <div className="m-auto p-8 bg-white w-full rounded ">
        {simState === 0 && (
          <div>
            {/* <div className="py-5 flex">
              <div className="flex-1 justify-between flex min-w-max h-10 items-center mr-6 mt-6">
                <div className="w-full flex space-x-4">
                  <div className="w-full">
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Prioritisation Algorithm
                      </span>
                    </label>
                    <RoundedDropdown
                      placeholder={"Prioritisation"}
                      list={[
                        {
                          name: "v1",
                        },
                        {
                          name: "v2",
                        },
                      ]}
                      labelKey="name"
                      valueKey="name"
                      name="deviceType"
                      selectedValue={prioritisation}
                      setSelected={(e) => {
                        setPrioritisation(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex mt-4">
              <div className="flex-1 mr-6">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    Wave Size
                  </span>
                </label>
                <TextField
                  type="text"
                  id="name"
                  label="Wave Size"
                  placeholder="Wave Size"
                  value={waveSize}
                  onChange={(e) => {
                    setWaveSize(e.target.value);
                  }}
                />
              </div>
              <div className="flex-1">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    Batch Size
                  </span>
                </label>
                <TextField
                  type="text"
                  id="name"
                  label="Size"
                  placeholder="Size"
                  value={batchSize}
                  onChange={(e) => {
                    setBatchSize(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="flex-1 mr-6">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    Max Orders In Batch
                  </span>
                </label>
                <TextField
                  type="text"
                  id="name"
                  label="Max Orders"
                  placeholder="Max Orders"
                  value={maxOrdersInBatch}
                  onChange={(e) => {
                    setmaxOrdersInBatch(e.target.value);
                  }}
                />
              </div>
              <div className="flex-1">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    Complete Order IDs Max
                  </span>
                </label>
                <TextField
                  type="text"
                  id="name"
                  label="Max Complete"
                  placeholder="Max Complete"
                  value={completeOrderIdsMax}
                  onChange={(e) => {
                    setcompleteOrderIdsMax(e.target.value);
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="mb-4"></div>
            {pickers.map((picker, i) => (
              <div className="mt-2">
                <div className="flex">
                  <div className="flex-1  mr-6">
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Name
                      </span>
                    </label>
                    <TextField
                      type="text"
                      id="name"
                      label="Name"
                      placeholder="Name"
                      value={picker.name}
                      onChange={(e) => {
                        setPickers(
                          pickers.map((p, j) => {
                            if (j === i) {
                              return {
                                ...p,
                                name: e.target.value,
                              };
                            } else {
                              return p;
                            }
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Speed - Time per pick (seconds)
                      </span>
                    </label>
                    <TextField
                      type="text"
                      id="name"
                      label="Speed"
                      placeholder="Speed"
                      value={picker.speed}
                      onChange={(e) => {
                        setPickers(
                          pickers.map((p, j) => {
                            if (j === i) {
                              return {
                                ...p,
                                speed: e.target.value,
                              };
                            } else {
                              return p;
                            }
                          })
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="w-full flex">
                  <button
                    type="button"
                    className={`bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ml-auto`}
                    onClick={() => {
                      setPickers(
                        pickers.filter((p, j) => {
                          return j !== i;
                        })
                      );
                    }}
                  >
                    <XIcon className="w-7 h-7 mr-1" />
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-4">
              <AddButton text="Add Picker" onClick={addPicker}></AddButton>
            </div>

            <div className="flex-1 mt-4">
              <label className="block text-left mb-2">
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Time between dropoffs (seconds)
                </span>
              </label>
              <TextField
                type="text"
                id="name"
                label="Dropoff Break"
                placeholder="Dropoff Break"
                value={dropoffBreak}
                onChange={(e) => {
                  setDropoffBreak(e.target.value);
                }}
              />
            </div>
            <div className="w-full flex mt-4">
              <div className="m-auto">
                <AddButton
                  text="Run Simulation"
                  onClick={runSimulation}
                ></AddButton>
              </div>
            </div>
          </div>
        )}
        {simState === 1 && (
          <div>
            <div className="flex">
              <div className="m-auto">
                <Spinner />
              </div>
            </div>
            <div className="flex mt-4">
              <div className="m-auto">
                Running Simulation. Wait time is about 1 minute.
              </div>
            </div>
            <div className="flex mt-6">
              <div className="m-auto">
                <button
                  type="button"
                  className={`bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ml-auto`}
                  onClick={() => {
                    setSimState(0);
                  }}
                >
                  <XIcon className="w-7 h-7 mr-1" />
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {simState === 2 && (
          <div>
            <div className="flex">
              <table className="m-auto w-96">
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Row Congestion (%)
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Picking Speed (items/minute)
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Batches with &lt; 3 items (%)
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Avg Sorting Trays Used per wave
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Max Sorting Trays Used per wave
                  </th>
                </tr>
                <tr>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {rowCongestion}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {pickingSpeed}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {smallBatches}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {avgSortingCongestion}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {maxSortingCongestion}
                  </td>
                </tr>
              </table>
            </div>
            <div className="w-full flex mt-4">
              <div className="m-auto">
                <AddButton
                  text="Run another Simulation"
                  onClick={() => {
                    setSimState(0);
                  }}
                ></AddButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Simulations;
