import { useContext, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { useForm } from "#hooks/useForm";
import { MasterDataContext } from "#contexts/masterData";

const useLoginLogic = () => {
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const masterData = useContext(MasterDataContext);

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    username: "",
    password: "",
    logoutAll: false,
  });

  const loginUser = useQuery(LOGIN_USER);

  function loginUserCallback() {
    loginUser.fetchData(values);
  }

  useEffect(() => {
    if (loginUser.data) {
      auth.login(loginUser.data.login);
      masterData.fetchMasterData();
    }
    if (loginUser.error) {
      if (
        loginUser.error?.message &&
        loginUser.error.message.indexOf("already logged in") !== -1
      ) {
        appState.showConfirmation(
          "Error logging in",
          loginUser.error.message,
          () => {
            loginUser.fetchData({ ...values, logoutAll: true });
            appState.hideConfirmation();
          },
          appState.hideConfirmation
        );
      } else {
        appState.setAlert(loginUser.error.message, "error", 5000);
      }
    }
  }, [loginUser.loading, loginUser.data, loginUser.error]);

  return { onSubmit, values, onChange };
};

const LOGIN_USER = `
  mutation login($username: String!, $password: String!, $logoutAll: Boolean) {
    login(username: $username, password: $password, logoutAll: $logoutAll) {
      name
      token
      role
      hopstackModules
    }
  }
`;

export default useLoginLogic;
