import { useReducer, createContext } from "react";
import firestoreDb from "../initializeFirebase";
import { collection, getDocs } from "firebase/firestore";
import _ from "lodash";

const initialState = {};

const MasterDataContext = createContext({});

function masterDataReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_MASTER_DATA": {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}

function MasterDataProvider(props) {
  const [state, dispatch] = useReducer(masterDataReducer, initialState);

  function fetchMasterData() {
    Promise.all([
      fetchHopstackModules,
      fetchBinLocationTypes,
      fetchRoles,
      fetchSortingLocationTypes,
      fetchToteTypes,
      fetchDeviceTypes,
      fetchIntegrationProviders,
    ])
      .then((values) => {
        const payload = {
          hopstackModules: values[0],
          binLocationTypes: values[1],
          roles: values[2],
          sortingLocationTypes: values[3],
          toteTypes: values[4],
          deviceTypes: values[5],
          integrationProviders: values[6],
        };
        console.log(payload);
        dispatch({ type: "SET_MASTER_DATA", payload });
      })
      .catch((err) => {});
  }

  function clearMasterData() {
    dispatch({ type: "SET_MASTER_DATA", payload: {} });
  }

  return (
    <MasterDataContext.Provider
      value={{
        ...state,
        fetchMasterData,
        clearMasterData,
      }}
      {...props}
    />
  );
}

export { MasterDataContext, MasterDataProvider };

const fetchHopstackModules = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "hopstack-modules");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchBinLocationTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "bin-location-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchSortingLocationTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "sorting-location-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchRoles = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "roles");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchToteTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "tote-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchDeviceTypes = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "device-types");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});

const fetchIntegrationProviders = new Promise(async (resolve, reject) => {
  try {
    const col = collection(firestoreDb, "integrationproviders");
    const snapshot = await getDocs(col);
    resolve(snapshot.docs.map((doc) => doc.data()));
  } catch (err) {
    reject(err);
  }
});
