const TableFieldButton = ({ onClick, text, IconText }) => (
  <button
    type="button"
    className={`bg-E1D3B8 inline-flex justify-center px-2 py-2 text-md font-medium text-5F666B font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}
    onClick={onClick}
  >
    {IconText ? IconText : text || "Button"}
  </button>
);

export default TableFieldButton;
