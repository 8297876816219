import TextField from "#components/utils/TextField";

const LoginBox = ({ onSubmit, onChange, values: { username, password } }) => (
  <div className="bg-white xs:10/12 sm:w-8/12 md:6/12 max-w-xl min-w-xl rounded-2xl p-10 flex flex-col space-y-6 items-center justify-center">
    <div className="text-224E73 text-2xl font-medium">Login</div>
    <div className="text-454A4F text-2xl font-medium">
      Welcome to Hopstack Inc.
    </div>
    <form
      onSubmit={onSubmit}
      className="w-full flex-col space-y-6 items-center justify-center"
      noValidate
    >
      <TextField
        label="Username"
        id="username"
        type="text"
        placeholder=" "
        onChange={onChange}
        value={username}
      />
      <TextField
        label="Password"
        placeholder=" "
        id="password"
        type="password"
        onChange={onChange}
        value={password}
      />
      <div className="w-full flex items-center justify-center">
        <input
          type="submit"
          className={`cursor-pointer inline-flex justify-center p-4 text-2xl font-normal text-C2D2DF bg-224E73 font-montserrat border border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none w-64`}
          value="Login"
        />
      </div>
    </form>
  </div>
);

export default LoginBox;
