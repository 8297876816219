import { useReducer, createContext } from "react";
import _ from "lodash";

const initialState = {
  entities: [],
  total: 0,
  displayEntities: [],
  perPage: 25,
  pageNumber: 1,
  selectedEntities: [],
  filters: {},
  sort: "-createdAt",
};

const EntityContext = createContext(initialState);

function entityReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_ENTITIES": {
      const { entities, total, pageNumber, paginated, ...rest } = payload;
      return {
        ...state,
        entities: paginated ? [...state.entities, ...entities] : [...entities],
        total,
        displayEntities: entities,
        pageNumber,
        ...rest,
      };
    }
    case "PAGINATE": {
      const { pageNumber } = payload;
      const { perPage, entities } = state;
      return {
        ...state,
        pageNumber,
        displayEntities: entities.slice(
          (pageNumber - 1) * perPage,
          pageNumber * perPage
        ),
      };
    }
    case "SET_FILTER": {
      return {
        ...state,
        filters: payload,
      };
    }
    case "RESET_ENTITIES": {
      return {
        ...initialState,
      };
    }
    case "SET_PER_PAGE": {
      return {
        ...state,
        perPage: payload.perPage,
      };
    }
    case "SET_SORT": {
      return {
        ...state,
        sort: payload.sort,
      };
    }
    default:
      return state;
  }
}

function EntityProvider(props) {
  const [state, dispatch] = useReducer(entityReducer, initialState);

  function resetEntities() {
    dispatch({
      type: "RESET_ENTITIES",
    });
  }

  function setEntities(payload) {
    console.log(payload);
    dispatch({
      type: "SET_ENTITIES",
      payload,
    });
  }

  function paginate(payload) {
    dispatch({
      type: "PAGINATE",
      payload,
    });
  }

  function setFilters(payload) {
    dispatch({
      type: "SET_FILTER",
      payload,
    });
  }

  function setPerPage(payload) {
    dispatch({
      type: "SET_PER_PAGE",
      payload,
    });
  }

  function setSort(payload) {
    dispatch({
      type: "SET_SORT",
      payload,
    });
  }

  return (
    <EntityContext.Provider
      value={{
        ...state,
        setEntities,
        resetEntities,
        paginate,
        setFilters,
        setPerPage,
        setSort,
      }}
      {...props}
    />
  );
}

export { EntityContext, EntityProvider };
