import withStatsLogic from "#components/HOC/withStatsLogic";
import Stats from "#components/common/Stats";
import PickupsByTime from "#components/dashboard/PickupsByTime";
import AveragePickupDelay from "#components/dashboard/AveragePickupDelay";
import ShipmentsByDate from "#components/dashboard/ShipmentsByDate";
import CarrierPickupsByDate from "#components/dashboard/CarrierPickupsByDate";

const ShippingStats = ({ graphs }) => {
  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Deliveries / Total Picked Up",
            stat: "229 / 168",
          },
          {
            name: "On-Time Shipments",
            stat: "89.4%",
          },
          {
            name: "Delayed Pickups",
            stat: 21,
          },
          {
            name: "Avg. Pickup Delay(days)",
            stat: 0.13,
          },
        ]}
      />
      <div className="flex-col space-y-8">
        <div class="grid grid-cols-8 gap-4">
          <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg px-4 py-4">
            <div className="text-lg"># Pickups By Hour</div>
            <div className="w-full mt-5">
              <PickupsByTime
                data={graphs.hourlyPickups}
                currentTimezone={"America/New_York"}
              />
            </div>
          </div>
          <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg px-4 py-4">
            <div className="text-lg"># Delayed Pickups</div>
            <div className="w-full mt-5">
              <AveragePickupDelay
                data={graphs.averagePickupDelays}
                currentTimezone={"America/New_York"}
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-8 gap-4">
          <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg px-4 py-4">
            <div className="text-lg"># Deliveries</div>
            <div className="w-full mt-5">
              <ShipmentsByDate
                data={graphs.dailyShipments}
                currentTimezone={"America/New_York"}
              />
            </div>
          </div>
          <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg px-4 py-4">
            <div className="text-lg">On-Time Shipments %</div>
            <div className="w-full mt-5">
              <CarrierPickupsByDate
                data={graphs.onTimeShipmentsPercentage}
                currentTimezone={"America/New_York"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStatsLogic(ShippingStats);
