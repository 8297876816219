import _ from "lodash";

const CardDataTableReplenishments = ({ values, replenish, getSku }) => (
  // <div className={`grid grid-cols-${numOfColumns} gap-1`}>
  //   {headers.map((column) => {
  //     return (
  //       <div className={`text-sm text-gray col-span-${column.space}`}>
  //         {column.title}
  //       </div>
  //     );
  //   })}
  // </div>
  <div className="bg-F8F8F8 p-4">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-C2D2DF">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            SKU
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Title
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Stock
          </th>
          {replenish && (
            <th
              scope="col"
              className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
            >
              Replenish
            </th>
          )}

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider"
          >
            Cost
          </th>
        </tr>
      </thead>
      <tbody>
        {values.length > 0 &&
          values.map((forecast) => (
            <tr className={`border-t border-C2D2DF`} key={forecast._id}>
              <td
                scope="col"
                className="px-1 py-3 text-left font-medium text-2C7695 tracking-wider whitespace-nowrap pr-2"
                onClick={() => getSku(forecast.sku)}
              >
                {forecast.sku}
              </td>
              <td
                scope="col"
                className="px-1 py-3 text-left font-medium text-2C7695 tracking-wider"
              >
                {forecast.orderLineItem.productName}
              </td>
              <td
                scope="col"
                className="px-1 py-3 text-left font-medium text-2C7695 tracking-wider"
              >
                {_.ceil(forecast.forecast.twoWeeks / 1.25)}
              </td>
              {replenish && (
                <td
                  scope="col"
                  className="px-1 py-3 text-left font-medium text-2C7695 tracking-wider"
                >
                  {Math.max(
                    _.ceil(forecast.safetyStock) -
                      _.ceil(forecast.forecast.twoWeeks / 1.25),
                    1
                  )}
                </td>
              )}

              <td
                scope="col"
                className="px-1 py-3 text-left font-medium text-2C7695 tracking-wider"
              >
                $
                {_.round(
                  Math.max(
                    _.ceil(forecast.safetyStock) -
                      _.ceil(forecast.forecast.twoWeeks / 1.25),
                    1
                  ) * forecast.price,
                  2
                ).toFixed(2)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default CardDataTableReplenishments;
