import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

const PaginationNavigationButtons = ({
  pageNumber,
  total,
  perPage,
  prevPage,
  nextPage,
}) => (
  <div>
    <nav
      className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      aria-label="Pagination"
    >
      <div
        onClick={total > 0 && pageNumber > 1 ? prevPage : undefined}
        className={`cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 ${
          pageNumber === 1 && "text-gray-300"
        }`}
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span>Previous</span>
      </div>
      <div
        onClick={
          total > 0 && total > pageNumber * perPage ? nextPage : undefined
        }
        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
          !(total > 0 && total > pageNumber * perPage) && "text-gray-300"
        }`}
      >
        <span>Next</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </div>
    </nav>
  </div>
);

export default PaginationNavigationButtons;
