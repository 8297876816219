import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

const GroupsList = ({ groups, masterData }) => {
  const history = useHistory();
  if (!masterData || !masterData.comparisonOperators) {
    return null;
  }
  const { masterFields, comparisonOperators, logicalOperators } = masterData;
  const formString = (condition) => {
    const masterField = _.find(masterFields, { value: condition.masterField });
    const comparisonOperator = _.find(comparisonOperators, {
      value: condition.comparisonOperator,
    });
    return `${masterField.name} ${comparisonOperator.name} ${condition.value}`;
  };
  const renderMainCriteria = (mainCriteria) => {
    const criteria = _.find(
      masterData.logicalOperators,
      (e) => e.value === mainCriteria
    );
    return criteria.name;
  };
  return (
    <div className="grid xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2">
      {false &&
        groups.map((group, idx) => (
          <div className="border rounded-md shadow-md bg-F8F8F8" key={idx}>
            <div className="flex justify-between p-4 border-b bg-white rounded-tl-md rounded-tr-md">
              <div className="text-xl text-2C7695 font-medium">
                {group.name}
              </div>
              <div className="flex space-x-2">
                <PencilIcon
                  className="w-6 h-6 text-224E73"
                  onClick={() => history.push(`/group?groupId=${group.id}`)}
                />
                <TrashIcon className="w-6 h-6 text-224E73" />
              </div>
            </div>
            <div className="text-2xl p-4">
              WHEN {renderMainCriteria(group.mainCriteria)} of the conditions
              are met
            </div>
            <div className="m-4 p-10 pb-10 bg-EBEBEB border border-224E73 rounded-md shadow-md">
              {group.conditions.length > 1 &&
                group.conditions.map((condition, condIdx) => (
                  <div key={condIdx}>
                    <div className="w-full">
                      <div className="z-50 ml-6 bg-224E73 rounded-full w-full border border-F4C261 shadow-md text-EBEBEB p-4 px-4 w-80 text-center">
                        {formString(condition)}
                      </div>
                      {condIdx !== group.conditions.length - 1 && (
                        <div className="text-center w-80 text-224E73 text-lg ml-3 py-2">
                          {_.find(logicalOperators, {
                            value: group.mainCriteria,
                          })?.name === "All"
                            ? "AND"
                            : "OR"}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {group.conditions.length === 1 &&
                group.conditions.map((condition, condIdx) => (
                  <div key={condIdx} className="w-full">
                    <div className="z-50 ml-6 bg-224E73 rounded-full w-full border border-F4C261 shadow-md text-EBEBEB p-4 px-4 w-80 text-center">
                      {formString(condition)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}

      <div className="border rounded-md shadow-md bg-F8F8F8">
        <div className="flex justify-between p-4 border-b bg-white rounded-tl-md rounded-tr-md">
          <div className="text-xl text-2C7695 font-medium">Test Group</div>
          <div className="flex space-x-2">
            <PencilIcon className="w-6 h-6 text-224E73" />
            <TrashIcon className="w-6 h-6 text-224E73" />
          </div>
        </div>
        <div className="text-2xl p-4">WHEN ANY of the conditions are met</div>
        <div className="flex-col space-y-4 pb-4">
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-224E73 px-10 pl-2">
              <div className="text-xl font-medium">IF ANY</div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Destination is US
              </div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                {`Order Value is >= 1000`}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-224E73 px-10 pl-2">
              <div className="text-xl font-medium">IF ALL</div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Destination is US
              </div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is JCPenney
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-96 bg-EBEBEB rounded-xl p-2 items-center justify-center flex-col space-y-4 relative border border-224E73 px-10 pl-2">
              <div className="text-xl font-medium">IF NONE</div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is Target
              </div>
              <div className="ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 text-center">
                Customer is Costco
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupsList;

// {
//   <div className="flex justify-start items-center w-64 bg-white border border-224E73 rounded-full relative p-2 h-20">
// <div className="text-224E73 text-lg">OR</div>
// <div className="absolute mt-20 ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 w-48 text-center z-50">
//   Destination is US
// </div>
// </div>
// <div className="flex justify-start items-center w-64 bg-white border border-224E73 rounded-full relative p-2 h-20">
// <div className="text-224E73 text-lg">OR</div>
// <div className="absolute mt-20 ml-4 bg-224E73 rounded-full border border-F4C261 shadow-md text-EBEBEB p-2 px-4 w-48 text-center">
//   Destination is US
// </div>
// </div>
// </div>
// }
