import React from "react";
import PropTypes from "prop-types";
import { Spin, Row, Col, Statistic, Table } from "antd";
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
} from "recharts";

const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.cartesianChartData}>
      <XAxis dataKey="x" />
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip />
    </ChartComponent>
  </ResponsiveContainer>
);

const colors = [
  "#FF6492",
  "#141446",
  "#7A77FF",
  "#f0cd30",
  "#28229c",
  "#0d9900",
  "#f09630",
];
let colorIndexCounter = 0;

const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      return Boolean(value).toString();
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
        <Line
          key={resultSet.key}
          stackId="a"
          dataKey={resultSet.dataKey}
          type="monotone"
          name={resultSet.name || "Chart"}
          stroke={colors[colorIndexCounter++ % colors.length]}
        />
      </CartesianChart>
    );
  },
  bar: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
        <Bar
          key={resultSet.key}
          stackId="a"
          dataKey={resultSet.dataKey}
          name={resultSet.name || "Chart"}
          fill={colors[colorIndexCounter++ % colors.length]}
        />
      </CartesianChart>
    );
  },
  pie: ({ resultSet }) => {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={resultSet.data}
            nameKey="x"
            dataKey={resultSet.dataKey}
            fill="#8884d8"
          >
            {resultSet.data.map((e, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100%",
        }}
      >
        <Col>
          <Statistic value={resultSet.value} />
        </Col>
      </Row>
    );
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart =
  (Component) =>
  ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) ||
    (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState, name }) => {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  //   const renderProps = useCubeQuery(query);

  return (
    component &&
    renderChart(component)({
      resultSet: vizState.data,
      error: null,
    })
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  name: PropTypes.string,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
