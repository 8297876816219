import { useReducer, createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const initialState = {
  loading: false,
  alerts: [],
  pageTitle: "Hopstack Inc",
  confirmation: {
    open: false,
    mainText: "Are you sure",
    subText: "This action cannot be undone",
    negative: () => {},
    positive: () => {},
  },
  defaultTimezone: "EST",
  displayFields: [],
  enabledSettings: false,
};

const AppStateContext = createContext({});

function appStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REMOVE_LOADING": {
      return {
        ...state,
        loading: false,
      };
    }
    case "SET_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alerts: _.filter(state.alerts, (alert) => alert.id !== payload),
      };
    case "SHOW_CONFIRMATION": {
      return {
        ...state,
        confirmation: payload,
      };
    }
    case "HIDE_CONFIRMATION": {
      return {
        ...state,
        confirmation: initialState.confirmation,
      };
    }
    default:
      return state;
  }
}

function AppStateProvider(props) {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  function setLoading() {
    dispatch({
      type: "SET_LOADING",
    });
  }

  function removeLoading() {
    dispatch({
      type: "REMOVE_LOADING",
    });
  }

  function setAlert(msg, alertType, timeout = 5000) {
    const id = uuidv4();
    dispatch({
      type: "SET_ALERT",
      payload: {
        msg,
        alertType,
        id,
      },
    });

    setTimeout(() => dispatch({ type: "REMOVE_ALERT", payload: id }), timeout);
  }

  function showConfirmation(mainText, subText, positive, negative) {
    const payload = {
      open: true,
      mainText,
      subText,
      negative,
      positive,
    };

    dispatch({ type: "SHOW_CONFIRMATION", payload });
  }

  function hideConfirmation() {
    dispatch({ type: "HIDE_CONFIRMATION" });
  }
  return (
    <AppStateContext.Provider
      value={{
        ...state,
        setLoading,
        removeLoading,
        setAlert,
        showConfirmation,
        hideConfirmation,
      }}
      {...props}
    />
  );
}

export { AppStateContext, AppStateProvider };
