import { useState, useEffect, useRef } from "react";
import { XIcon } from "@heroicons/react/outline";

const MultiSelectAutoComplete = ({
  labelKey,
  valueKey,
  values,
  setValues,
  options,
  labelKey2,
  rounded,
}) => {
  labelKey = labelKey || "name";
  valueKey = valueKey || "id";
  options = options || [];
  values = values || [];
  if (!setValues) {
    setValues = () => {
      console.log("setting value");
    };
  }
  const [text, setText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();
  const unselectedOptions = options.filter(
    (option) => values.includes(option[valueKey]) === false
  );

  const select = (option) => {
    // onChange(option);
    setValues([...values, option]);
    setText("");
  };

  const removeItem = (option) => {
    const currentValues = [...values];
    const foundIndex = _.findIndex(values, (e) => e === option);
    if (foundIndex !== -1) {
      currentValues[foundIndex] = undefined;
      setValues([..._.compact(currentValues)]);
    }
  };

  const filteredOptions = unselectedOptions.filter((option) =>
    option[labelKey].toLowerCase().includes(text.toLowerCase())
  );

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (!ref || !ref.current || !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
      }
    };

    document.addEventListener("focusin", listener);
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("focusin", listener);
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  return (
    <div className="relative" ref={ref}>
      <div className="outline relative border-2 border-2C7695 rounded-lg overflow-visible w-full">
        {_.filter(options, (e) => values.includes(e[valueKey])).map(
          (option) => (
            <div
              className="m-1 rounded-full bg-224E73 text-white inline-block"
              key={option[valueKey]}
            >
              <div className="flex justify-between items-center h-8">
                <div className="flex-2 pl-3 pr-5">
                  {option[labelKey]}{" "}
                  {labelKey2 &&
                    ` - ${
                      option[labelKey2.split(".")[0]][labelKey2.split(".")[1]]
                    }`}
                </div>
                <XIcon
                  className="w-5 h-5 mr-2 cursor-pointer"
                  onClick={() => removeItem(option[valueKey])}
                />
              </div>
            </div>
          )
        )}
        <input
          type="text"
          className="inline-block p-4 text-lg appearance-none focus:outline-none focus:ring-0 border-0 focus:border-0 bg-transparent font-montserrat"
          onKeyDown={handleNav}
          onFocus={() => setShowOptions(true)}
          onChange={(e) => setText(e.target.value)}
          value={text}
          placeholder="Start Typing"
        />
      </div>
      <ul
        className={`absolute w-full rounded-lg shadow-lg ${
          !showOptions && "hidden"
        } select-none bg-sidebar-blue overflow-auto max-h-64 z-50`}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, i, arr) => {
            let className = `text-white cursor-default bg-224E73 select-none relative py-4 pl-4 pr-4 border-dropdown-item-bottom-border border-b hover:bg-2C7695 `;

            if (i === 0) className += "rounded-t-lg";
            else if (i === arr.length) className += "rounded-b-lg";
            else if (i === 0 && arr.length === 1) className += "rounded-lg";
            else className += "";

            if (cursor === i) {
              className += " bg-2C7695";
            }

            return (
              <li
                className={className}
                key={option[valueKey]}
                onClick={() => select(option[valueKey])}
              >
                {option[labelKey]}{" "}
                {labelKey2 &&
                  ` - ${
                    option[labelKey2.split(".")[0]][labelKey2.split(".")[1]]
                  }`}
              </li>
            );
          })
        ) : (
          <li className="text-white bg-224E73 cursor-default select-none relative py-4 pl-4 pr-4 border-dropdown-item-bottom-border border-b hover:bg-2C7695 hover:text-2C7695">
            No results
          </li>
        )}
      </ul>
    </div>
  );
};

export default MultiSelectAutoComplete;
