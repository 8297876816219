
import { useCubeQuery } from '@cubejs-client/react';

const statsComponent = (item) => {
  return (
    <div
      key={item.name}
      className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
    >
      <dt className="text-lg font-medium text-gray-500 truncate">
        {item.name}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">
        {item.stat === -1 && (
          <svg
            className="animate-spin -ml-1 mr-3 h-10 w-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        <div className="flex items-center">
          {item.stat !== -1 && item.stat}
          {item.arrow ? item.arrow : null}
        </div>
      </dd>
    </div>
  )
}

const renderStatsComponent = (item, {resultSet, error}) => {
  if (resultSet) {
    item.stat = resultSet.totalRow()[resultSet.seriesNames()[0].key]
  } else if (error && error.toString()) {
    item.stat = error.toString()
  }
  return statsComponent(item)
}

const Stats = ({ stats, grid }) => (
  <dl
    className={`${
      grid === "order-fill-rate" ? "grid grid-cols-2 gap-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 py-3" : (grid === "statistics"
        ? "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 py-3"
        : "grid grid-cols-2 gap-5 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 py-3")
    }`}
  >
    {stats.map((item) => {
      const renderProps = useCubeQuery(item.query)
      return renderStatsComponent(item, renderProps)
    })}
  </dl>
);

export default Stats;
