import Modal from "#components/utils/Modal";

const ExpandedSku = ({ negativeAction, fetchedSku }) => {
  const headers = ["SKU", "Collection", "Color", "Size", "Bin Location"];
  return (
    <Modal
      negativeAction={negativeAction}
      title={`SKU Details`}
      maxWidth="max-w-2xl"
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK"
    >
      <div className="bg-EFE9DC p-4">
        <table className="px-2 min-w-full divide-y divide-gray-200">
          <thead className="p-4 rounded-full bg-224E73 px-12">
            <tr className="text-C2D2DF font-montserrat border-left px-12 border-l-8 border-224E73">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}
                  >
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}
                  >
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            <tr key={fetchedSku.id} className="bg-white">
              <td
                className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8 border-224E73`}
              >
                {fetchedSku.sku}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {fetchedSku.attributes?.collectionName}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {fetchedSku.attributes?.colorName}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {fetchedSku.attributes?.sizeName}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {fetchedSku.binLocation}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedSku;
