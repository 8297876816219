import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import _ from "lodash";

const Dropdown = ({
  list,
  selectedValue,
  setSelected,
  placeholder,
  labelKey,
  valueKey,
  title,
  name,
}) => {
  labelKey = labelKey || "name";
  valueKey = valueKey || "id";
  return (
    <div className={`w-full top-16`}>
      <span className="font-medium font-montserrat text-454A4F text-lg pb-2">
        {title}
      </span>
      <Listbox value={selectedValue} onChange={setSelected} name={name}>
        <div className="relative mt-1 font-montserrat text-2C7695 font-medium text-sm">
          <Listbox.Button className="relative w-full p-4 pr-10 text-left border-2C7695 border-2 bg-white rounded-lg  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate font-medium text-lg text-gray-900">
              {_.find(list, (e) => e[valueKey] === selectedValue) ? (
                _.find(list, (e) => e[valueKey] === selectedValue)[labelKey]
              ) : (
                <span className="text-224E73 mr-4">{placeholder}</span>
              )}
            </span>
            <span className="absolute inset-y-0 right-2 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-8 h-8 text-2C7695 font-normal"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full overflow-auto text-base bg-224E73 shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 w-full">
              {list &&
                list.map((listItem, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `text-white cursor-default select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b ${
                        listItem["disabled"] && "text-gray-400"
                      } hover:bg-EBEBEB hover:text-2C7695`
                    }
                    value={listItem[valueKey]}
                    disabled={listItem["disabled"]}
                  >
                    {({ selected, active, disabled }) => (
                      <>
                        <span
                          className="block truncate text-base font-normal
                            
                          "
                        >
                          {listItem[labelKey]}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-amber-600" : "text-amber-600"
                            }
                                absolute inset-y-0 right-2 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Dropdown;
