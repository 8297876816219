const config = require("config");
import { useState } from "react";
import axios from "axios";

const initialState = {
  loading: false,
  data: null,
  error: null,
  variables: {},
};

export const useQuery = (query) => {
  const [state, setState] = useState(initialState);

  const fetchData = async (variables = {}) => {
    setState({
      ...initialState,
      loading: true,
    });
    try {
      const response = await axios.post(
        `${config.BASE_URL}/graphql`,
        {
          query,
          variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.errors) {
        throw response.data.errors[0];
      }
      setState({
        data: response.data.data,
        error: null,
        loading: false,
        variables,
      });
    } catch (err) {
      setState({
        data: null,
        error: err,
        loading: false,
        variables,
      });
    }
  };

  return {
    ...state,
    fetchData,
  };
};

axios.interceptors.request.use(
  function (config) {
    const { token } = localStorage;
    if (!!token) config.headers["authorization"] = `Bearer ${token}`;
    const tenant = {
      id: "62cdb0ac6227b7ed224d79aa",
      name: "Hopstack Inc",
      subdomain: "uat",
      socketService: "https://api.uat.ap-southeast-1.hopstack.io",
      enabledSimulations: false,
      active: true,
      cubeService: "https://apse1-uat-analytics.hopstack.io",
      typeOfCustomer: ["3PL", "Prep Center"],
      settings: {
        overReceivingOnConsignments: false,
        multiplePalletsAtOneLocation: false,
        metricsConfig: {
          preferredWeightUnit: ["pounds"],
          preferredDimensionUnit: ["inches"],
        },
        multiAccountIntegrationSupportEnabled: true,
      },
      features: { multiwarehouse: true, rateShopping: true, dropship: true },
      createdAt: 1689144680244,
      integrations: [],
      updatedAt: 1692217532012,
    };
    const subdomain = "uat";
    config.headers["subdomain"] = subdomain;
    config.headers["tenant"] = JSON.stringify(tenant);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
