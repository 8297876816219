import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTempQuery } from "#hooks/useTempQuery";
import { useQuery } from "#hooks/useQuery";
import {
  GET_GROUPS,
  GET_GROUP,
  GET_RULE_ENGINE_MASTER_DATA,
  GET_CUSTOMERS,
} from "#queries";
import { SAVE_GROUP, DELETE_GROUP } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const withGroupsLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const groupsQuery = useTempQuery(GET_GROUPS);
    const masterDataQuery = useTempQuery(GET_RULE_ENGINE_MASTER_DATA);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const [getGroup, getGroupQuery] = useLazyQuery(GET_GROUP);
    const [saveGroup, saveGroupQuery] = useMutation(SAVE_GROUP);
    const [deleteGroup, deleteGroupQuery] = useMutation(DELETE_GROUP);

    useEffect(() => {
      groupsQuery.fetchData();
      masterDataQuery.fetchData();
      customersQuery.fetchData();
    }, []);

    useEffect(() => {
      if (groupsQuery.loading || masterDataQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      groupsQuery.loading,
      groupsQuery.error,
      groupsQuery.data,
      masterDataQuery.loading,
      masterDataQuery.error,
      masterDataQuery.data,
    ]);
    useEffect(() => {
      const onCompleted = (data) => {
        if (data.group) {
          setSelectedGroup(data.group);
        }
      };
      const onError = (error) => {
        setSelectedGroup(null);
      };
      if (onCompleted || onError) {
        if (
          onCompleted &&
          !getGroupQuery.loading &&
          !getGroupQuery.error &&
          getGroupQuery.data
        ) {
          onCompleted(getGroupQuery.data);
        } else if (!getGroupQuery.loading && getGroupQuery.error) {
          onError(getGroupQuery.error);
        }
      }
    }, [getGroupQuery.loading, getGroupQuery.data, getGroupQuery.error]);

    useEffect(() => {
      const onCompleted = (data) => {
        groupsQuery.fetchData();
        setSelectedGroup(null);
      };
      const onError = (error) => {
        /* magic */
        console.log(error);
      };
      if (onCompleted || onError) {
        if (
          onCompleted &&
          !saveGroupQuery.loading &&
          !saveGroupQuery.error &&
          saveGroupQuery.data
        ) {
          onCompleted(saveGroupQuery.data);
        } else if (saveGroupQuery.error && !saveGroupQuery.loading) {
          onError(saveGroupQuery.error);
        }
      }
    }, [saveGroupQuery.loading, saveGroupQuery.data, saveGroupQuery.error]);

    useEffect(() => {
      const onCompleted = (data) => {
        appState.hideConfirmation();
        groupsQuery.fetchData();
      };
      const onError = (error) => {
        /* magic */
        console.log(error);
      };
      if (onCompleted || onError) {
        if (
          onCompleted &&
          !deleteGroupQuery.loading &&
          !deleteGroupQuery.error &&
          deleteGroupQuery.data
        ) {
          onCompleted(deleteGroupQuery.data);
        } else if (deleteGroupQuery.error && !deleteGroupQuery.loading) {
          onError(deleteGroupQuery.error);
        }
      }
    }, [
      deleteGroupQuery.loading,
      deleteGroupQuery.data,
      deleteGroupQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this group?",
        () => {
          deleteGroup({ variables: { id } });
        },
        appState.hideConfirmation
      );
    };

    const onChange = (e) => {
      const group = {
        ...selectedGroup,
      };

      group[e.target.name] = e.target.value;

      setSelectedGroup({ ...group });
    };

    if (!groupsQuery.loading || groupsQuery.data) {
      return (
        <WrappedComponent
          groups={groupsQuery.data ? groupsQuery.data.groups : []}
          fetchGroup={(id) => getGroup({ variables: { id } })}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          saveGroup={(group) => {
            console.log(group);
            saveGroup({ variables: { ...group } });
            getGroup({ variables: { id: null } });
          }}
          onChange={onChange}
          deleteButtonClicked={deleteButtonClicked}
          masterData={
            masterDataQuery.data ? masterDataQuery.data.masterData : {}
          }
          customers={customersQuery.data ? customersQuery.data.customers : []}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={(e) => {
            setSelectedCustomer(e);
          }}
        />
      );
    } else {
      return <LoadingIndicator />;
    }
  };
};

export default withGroupsLogic;
