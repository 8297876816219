const config = require("config");
import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_SORTINGS,
  GET_SORTING_LOCATIONS,
  FETCH_SPECIFIC_ORDER,
} from "#queries";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { io } from "socket.io-client";

const withOverallOutboundSortingLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const sortingLocationsQuery = useQuery(GET_SORTING_LOCATIONS);
    const fetchOrderQuery = useQuery(FETCH_SPECIFIC_ORDER);
    const sortingsQuery = useQuery(GET_SORTINGS);
    const [orders, setOrders] = useState({});

    useEffect(() => {
      const socket = io.connect(config.SOCKET_URL, {
        path: "/socket-service/socket.io",
      });

      socket.on("observeMessages", (payload) => {
        if (payload.error === true) {
          // dispatch(setAlert(payload.message, "error", 5000));
          appState.setAlert(payload.message, "error", 5000);
        } else if (payload.message) {
          sortingLocationsQuery.fetchData();
          sortingsQuery.fetchData();
        }
      });

      // socket.on("observeBatch", (payload) => {
      //   console.log("2", payload);
      //   if (payload && payload.prepackingId) {
      //     dispatch(fetchCurrentBatch());
      //   }
      // });

      // socket.on("authQrCode", (payload) => {
      //   dispatch({
      //     type: prepackerActions.FETCH_ASSOCIATED_SESSION.SUCCESS,
      //     payload,
      //   });
      //   dispatch(setAlert("Successfully validated scanner", "success", 5000));
      // });

      socket.on("connect", () => {
        console.log("connected!");
      });

      socket.on("disconnect", () => {
        console.log(socket.id); // undefined
      });
      socket.on("message", (message) => {
        console.log(message);
      });
      socket.emit("subscribe", { roomId: "overall_sorting_screen" });
    }, []);

    useEffect(() => {
      sortingLocationsQuery.fetchData();
      sortingsQuery.fetchData();
    }, []);

    useEffect(() => {
      if (sortingsQuery.data) {
      }
    }, [sortingsQuery.error, sortingsQuery.loading, sortingsQuery.data]);

    useEffect(() => {
      if (sortingLocationsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (sortingLocationsQuery.data) {
        const sortingLocationsWithOrders =
          sortingLocationsQuery.data.sortingLocations.filter(
            (item) =>
              item.sortingLocationType === "OUTBOUND" &&
              item.currentMainEntityId !== null &&
              item.currentMainEntityId !== undefined
          );
        if (sortingLocationsWithOrders.length > 0) {
          for (const order of sortingLocationsWithOrders) {
            fetchOrderQuery.fetchData({ orderId: order.currentMainEntityId });
          }
        }
      }
    }, [
      sortingLocationsQuery.data,
      sortingLocationsQuery.error,
      sortingLocationsQuery.loading,
    ]);

    useEffect(() => {
      if (fetchOrderQuery.data) {
        setOrders({
          ...orders,
          [fetchOrderQuery.data.order.id]: fetchOrderQuery.data.order,
        });
      }
    }, [fetchOrderQuery.loading, fetchOrderQuery.data, fetchOrderQuery.error]);
    console.log(orders);
    return (
      <WrappedComponent
        sortings={
          sortingsQuery.data
            ? sortingsQuery.data.getSortings.sort((a, b) => a.status - b.status)
            : []
        }
        sortingLocations={
          sortingLocationsQuery.data
            ? sortingLocationsQuery.data.sortingLocations
                .filter((item) => item.sortingLocationType === "OUTBOUND")
                .sort((a, b) => a.position - b.position)
            : []
        }
        orders={orders}
      />
    );
  };
};

export default withOverallOutboundSortingLogic;
