import { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_PACKER_BATCH,
  GET_STATIONS,
  FETCH_SPECIFIC_ORDER,
  GET_ORDERS,
} from "#queries";
import {
  SCAN_PACKER_BARCODE,
  CONFIRM_PACK_ITEM,
  CONFIRM_PACKER_DROPOFF,
  LOGOUT_USER,
  SET_STATION,
} from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { EntityContext } from "#contexts/entity";
const config = require("config");
// import { printShippingLabel } from "#utils/printShippingLabel";
import axios from "axios";

const withPackerLogic = (WrappedComponent) => {
  return (props) => {
    const entity = useContext(EntityContext);
    const [order, setOrder] = useState(null);
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const logoutQuery = useQuery(LOGOUT_USER);
    const setStationQuery = useQuery(SET_STATION);
    const [currentItemMismatch, setCurrentItemMismatch] = useState(null);
    const confirmDropoffQuery = useQuery(CONFIRM_PACKER_DROPOFF);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const stationsQuery = useQuery(GET_STATIONS);
    const currentBatchQuery = useQuery(GET_CURRENT_PACKER_BATCH);
    const ordersQuery = useQuery(GET_ORDERS);

    useEffect(() => {
      const filtersSet = {};
      entity.setFilters(filtersSet);
      ordersQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
      // masterDataQuery.fetchData();
      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (ordersQuery.data) {
        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    useEffect(() => {
      const socket = io(config.SOCKET_URL, {
        path: "/socket-service/socket.io",
      });

      socket.on("observeMessages", (payload) => {
        if (payload.error === true) {
          // dispatch(setAlert(payload.message, "error", 5000));
          appState.setAlert(payload.message, "error", 5000);
        } else if (payload.message) {
          appState.setAlert(payload.message, "success", 5000);
          currentBatchQuery.fetchData();
        }
      });

      // socket.on("observeBatch", (payload) => {
      //   console.log("2", payload);
      //   if (payload && payload.prepackingId) {
      //     dispatch(fetchCurrentBatch());
      //   }
      // });

      // socket.on("authQrCode", (payload) => {
      //   dispatch({
      //     type: prepackerActions.FETCH_ASSOCIATED_SESSION.SUCCESS,
      //     payload,
      //   });
      //   dispatch(setAlert("Successfully validated scanner", "success", 5000));
      // });

      socket.on("connect", function () {
        console.log("connected!");
      });

      socket.on("message", (message) => {
        console.log(message);
      });
      socket.emit("subscribe", { roomId: auth.user.id });
    }, []);

    useEffect(() => {
      if (setStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (setStationQuery.error) {
        if (
          setStationQuery.error?.message &&
          setStationQuery.error.message.indexOf("already") !== -1
        ) {
          appState.showConfirmation(
            "Error logging in",
            setStationQuery.error.message,
            () => {
              setStationQuery.fetchData({
                station: selectedStation,
                logoutAll: true,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation
          );
        } else {
          appState.setAlert(setStationQuery.error.message, "error", 5000);
        }
      }

      if (setStationQuery.data && setStationQuery.data) {
        // appState.setAlert(setStationQuery.data.message, "success", 5000);
        window.location = "/";
      }
    }, [setStationQuery.data, setStationQuery.error, setStationQuery.loading]);

    useEffect(() => {
      currentBatchQuery.fetchData();
      stationsQuery.fetchData();
    }, []);

    useEffect(() => {
      if (logoutQuery.data) {
        appState.setAlert(logoutQuery.data.logout.message);
        auth.logout();
      }

      if (logoutQuery.error) {
        auth.logout();
      }
    }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

    useEffect(() => {
      if (stationsQuery.error) {
        setStations([]);
      }

      if (stationsQuery.data && stationsQuery.data.stations) {
        const foundStations = stationsQuery.data.stations.filter(
          (item) => item.hopstackModule.toString().toLowerCase() === "packing"
        );
        setStations(foundStations);
      }
    }, [stationsQuery.data, stationsQuery.loading, stationsQuery.error]);

    const scanBarcodeQuery = useQuery(SCAN_PACKER_BARCODE);
    const confirmPackItemQuery = useQuery(CONFIRM_PACK_ITEM);

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          currentBatchQuery.data &&
          currentBatchQuery.data.getCurrentPackerBatch
        ) {
          setCurrentItemMismatch(null);
          try {
            fetchSpecificOrder.fetchData({
              orderId:
                currentBatchQuery.data.getCurrentPackerBatch.workingList[0]
                  .order,
            });
          } catch (err) {
            appState.setAlert(
              "Could not fetch specific order details. Please contact support."
            );
          }
        }
        appState.removeLoading();
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);

    useEffect(() => {
      if (fetchSpecificOrder.data) {
        setOrder(fetchSpecificOrder.data.order);
      }

      if (fetchSpecificOrder.error) {
        // alert(fetchSpecificOrder.error.message);
        appState.setAlert(fetchSpecificOrder.error.message, "error", 5000);
        setOrder(null);
      }

      if (fetchSpecificOrder.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      fetchSpecificOrder.loading,
      fetchSpecificOrder.error,
      fetchSpecificOrder.data,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.error) {
        appState.setAlert("Please try again", "error");
        appState.removeLoading();
      }
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
        setTimeout(() => {
          appState.removeLoading();
        }, 500);
      } else {
        console.log("data");
        console.log(scanBarcodeQuery.data);
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanPackerBarcode &&
          scanBarcodeQuery.data.scanPackerBarcode.message
        ) {
          appState.setAlert(
            scanBarcodeQuery.data.scanPackerBarcode.message,
            "success",
            2000
          );
          currentBatchQuery.fetchData();
          appState.removeLoading();
        }
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);
    useEffect(() => {
      if (confirmPackItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmPackItemQuery.data &&
          confirmPackItemQuery.data.confirmPackItem &&
          confirmPackItemQuery.data.confirmPackItem.message
        ) {
          setCurrentItemMismatch(null);
          currentBatchQuery.fetchData();
        } else if (confirmPackItemQuery.error) {
          appState.setAlert("Please try again", "error");
        }
        appState.removeLoading();
      }
    }, [
      confirmPackItemQuery.loading,
      confirmPackItemQuery.data,
      confirmPackItemQuery.error,
    ]);

    useEffect(() => {
      if (confirmDropoffQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffQuery.data &&
          confirmDropoffQuery.data.confirmPackerDropoff.message
        ) {
          appState.setAlert(
            confirmDropoffQuery.data.confirmPackerDropoff.message,
            "success",
            5000
          );
          currentBatchQuery.fetchData();
          ordersQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: {},
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffQuery.loading,
      confirmDropoffQuery.data,
      confirmDropoffQuery.error,
    ]);

    const triggerConfirmPackItem = (currentItem, status) => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to mark this item as ${status}?`,
        () => {
          confirmPackItemQuery.fetchData({
            itemId: currentItem,
            id: currentBatchQuery.data?.getCurrentPackerBatch.id,
            status,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation
      );
    };

    const simulateTote = async () => {
      if (entity.entities.length === 0) {
        appState.setAlert("No open orders found", "error", 5000);
        return;
      }

      const findOrder = entity.entities.find(
        (item) => item.orderStatus === "IN-PROCESS"
      );
      if (!findOrder) {
        appState.setAlert("No open order found", "error", 5000);
        return;
      }
      return await axios.post(`${config.BASE_URL}/api/scan`, {
        hostname: "Pack1",
        datalabel: findOrder.tote,
      });
    };

    const simulateItemScan = (item) => {
      return axios.post(`${config.BASE_URL}/api/scan`, {
        hostname: "Pack1",
        datalabel: item.sku,
      });
    };

    const printShippingLabel = () => {
      appState.setLoading();
      appState.setAlert("Printing shipping label", "success", 5000);

      setTimeout(() => {
        appState.removeLoading();
      }, 2000);
    };

    return (
      <WrappedComponent
        currentBatch={currentBatchQuery.data?.getCurrentPackerBatch}
        scanBarcode={(code) => scanBarcodeQuery.fetchData({ code })}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={(status) => {
          setCurrentItemMismatch(status);
          if (status === "CONFIRMED") {
            if (
              currentBatchQuery.data.getCurrentPackerBatch.workingList.filter(
                (item) => item.status !== "CONFIRMED"
              ).length === 1
            ) {
              printShippingLabel();
            }
            confirmPackItemQuery.fetchData({
              itemId:
                currentBatchQuery.data.getCurrentPackerBatch.currentItem
                  .uniqueIdentifier,
              id: currentBatchQuery.data.getCurrentPackerBatch.id,
              status,
            });
          }
        }}
        confirmPackItem={triggerConfirmPackItem}
        confirmDropoff={() =>
          confirmDropoffQuery.fetchData({
            id: currentBatchQuery.data.getCurrentPackerBatch.id,
          })
        }
        loading={currentBatchQuery.loading}
        currentUser={auth.user}
        logout={() => logoutQuery.fetchData()}
        onSelectStation={(e) => setSelectedStation(e)}
        onSubmitStation={() => {
          if (selectedStation) {
            setStationQuery.fetchData({ station: selectedStation });
          } else {
            appState.setAlert("Please select a station", "error", 5000);
          }
        }}
        selectedStation={selectedStation}
        stations={stations}
        printShippingLabel={printShippingLabel}
        simulateTote={simulateTote}
        simulateItemScan={simulateItemScan}
      />
    );
  };
};

export default withPackerLogic;
