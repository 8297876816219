import withGroupsLogic from "#components/HOC/withGroupsLogic";
import PageTitle from "#components/utils/PageTitle";
import GroupsList from "#components/groups/GroupsList";
import AddButton from "#components/utils/AddButton";
import { useHistory } from "react-router-dom";
import RoundedDropdown from "#components/utils/RoundedDropdown";

const Groups = ({
  groups,
  masterData,
  customers,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="flex items-center mb-4">
        <div className="flex-1">
          <PageTitle>Groups</PageTitle>
          <div className="w-96 flex space-x-4 mb-5">
            <RoundedDropdown
              placeholder={"Customer"}
              list={customers}
              labelKey="name"
              valueKey="id"
              name="customer"
              selectedValue={selectedCustomer}
              setSelected={setSelectedCustomer}
            />
          </div>
        </div>

        <div className="items-center justify-end">
          <AddButton text="Add Group" onClick={() => history.push("/group")} />
        </div>
      </div>

      {groups.length === 0 ? (
        <h1>No Groups</h1>
      ) : (
        <GroupsList groups={groups} masterData={masterData} />
      )}
    </div>
  );
};

export default withGroupsLogic(Groups);
