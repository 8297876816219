import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
import ModalButton from "#components/utils/ModalButton";
import { XIcon } from "@heroicons/react/solid";

const Modal = ({
  title,
  negativeText,
  positiveText,
  negativeAction,
  positiveAction,
  children,
  maxWidth,
  id,
  noPadding,
  onClose,
}) => {
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto userFormModal"
          onClose={onClose ? onClose : negativeAction}
          initialFocus={null}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block  ${
                  maxWidth ? "" : "w-full max-w-xl"
                } overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-3xl border-blue-500 overflow-visible`}
              >
                <div className="flex justify-center p-6 text-xl font-medium leading-6 text-224E73 border-b border-B3BFCA">
                  <h1 className="flex-1">{title}</h1>
                  <XIcon
                    className="w-6 h-6 cursor-pointer"
                    onClick={negativeAction}
                  />
                </div>
                <div className={`${!noPadding && "mx-4 py-4"}`}>{children}</div>
                {positiveAction && (
                  <div className="pt-4 w-full bg-2C7695 p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl">
                    <ModalButton
                      onClick={negativeAction}
                      transparent={true}
                      text={negativeText || "Cancel"}
                    />
                    <ModalButton
                      onClick={positiveAction}
                      text={positiveText || "Submit"}
                    />
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
