import _ from "lodash";
import JSPM from "jsprintmanager";
JSPM.JSPrintManager.license_url = `https://api.delmar.hopstack.io/jspm`;
JSPM.JSPrintManager.auto_reconnect = true;
JSPM.JSPrintManager.start();
JSPM.JSPrintManager.WS.onStatusChanged = function () {
  if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
    JSPM.JSPrintManager.getPrinters()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }
};
import { constructShippingLabel } from "#utils/barcodeGenerator";

export const printShippingLabel = async (shippingLabel, appState) => {
  // let possiblePrinterNames = ["zebra", "zdesign", "thermal"];
  // if (printer === "Rongta") {
  //   possiblePrinterNames = ["rongta", "rp421"];
  // }

  // if (printer === "XP") {
  //   possiblePrinterNames = ["xp"];
  // }

  let printCommands = [];
  printCommands.push(constructShippingLabel());

  console.log(printCommands[0]);

  try {
    const printers = await JSPM.JSPrintManager.getPrinters();
    if (!printers || printers.length === 0) {
      appState.setAlert("No printers found", "error", 5000);
      return;
    }

    // const findZebra = _.find(
    //   printers,
    //   (e) =>
    //     e.toLowerCase().includes("zdesign") ||
    //     e.toLowerCase().includes("zebra") ||
    //     e.toLowerCase().includes("thermal") ||
    //     e.toLowerCase().includes("xp") ||
    //     e.toLowerCase().includes("rongta") ||
    //     e.toLowerCase().includes("rp421")
    // );
    // if (findZebra) {
    // for (const cmds of printCommands) {
    const cpj = new JSPM.ClientPrintJob();
    const myPrinter = new JSPM.DefaultPrinter();
    myPrinter.duplex = JSPM.DuplexMode.Simplex;

    cpj.clientPrinter = myPrinter;
    let myFile;
    if (shippingLabel.indexOf(".png") === -1) {
      myFile = new JSPM.PrintFilePDF(
        shippingLabel,
        JSPM.FileSourceType.URL,
        "file.pdf"
      );
    } else {
      myFile = new JSPM.PrintFile(
        shippingLabel,
        JSPM.FileSourceType.URL,
        "file-PW=4-PH=6.png"
      );
    }
    myFile.printAutoCenter = true;
    myFile.pageSizing = JSPM.Sizing["None"];
    cpj.files.push(myFile);
    cpj.sendToClient();
    // }
    // } else {
    //   appState.setAlert("No thermal printers found", "error", 5000);
    //   return;
    // }
  } catch (err) {
    appState.setAlert("Could not print", "error", 3000);
  }
  return;
};
