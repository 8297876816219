import { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import {
  BellIcon,
  GlobeAltIcon,
  MenuAlt2Icon,
  XIcon,
} from "@heroicons/react/outline";
import {
  UserCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { LOGOUT_USER } from "#mutations";
import { useQuery } from "#hooks/useQuery";

// Images

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let navigation = [];
const AdminLayout = ({ children, warehouseManager }) => {
  const logoutQuery = useQuery(LOGOUT_USER);
  const history = window.location;
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(logoutQuery.data.logout.message);
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  navigation = [
    // {
    //   name: "Dashboard",
    //   href: "/dashboard",
    //   current: history.pathname === "/dashboard",
    //   selectedImage: "rules_selected.png",
    //   unselectedImage: "rules_unselected.png",
    // },
    {
      name: "Simulations",
      href: "/simulations",
      current: history.pathname === "/simulations",
      selectedImage: "rules_selected.png",
      unselectedImage: "rules_unselected.png",
    },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <LeftSidebarInner />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <LeftSidebarInner />
        </div>
        <div className="md:pl-72 flex flex-col">
          <div className="sticky top-0 z-50 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6">
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <GlobeAltIcon className="h-8 w-8" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            English(en)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Spanish(es)
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Simplified Chinese(zh)
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-8 w-8 rounded-full" alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              logoutQuery.fetchData();
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Logout
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 min-h-screen relative overflow-y-auto focus:outline-none bg-EBEBEB">
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

const Logo = () => (
  <div className="flex items-center justify-center space-x-2 py-2">
    <img
      src="https://hopstack-pub.s3.amazonaws.com/logo.png"
      alt="Picture of the author"
      className="w-10 h-10"
    />
    <span className="font-hammersmith text-white text-xl">Hopstack Inc</span>
  </div>
);

const LeftSidebarInner = () => (
  <div className="flex-1 flex flex-col min-h-0 bg-224E73 whitespace-nowrap">
    <Logo />
    <div className="flex-1 flex flex-col overflow-y-auto">
      <nav className="flex-1 px-2 py-4 space-y-4">
        {navigation.map((item) =>
          !item.children ? (
            <TopLevelItem item={item} key={item.name} />
          ) : (
            <InnerMostDisclosure item={item} key={item.name} />
          )
        )}
      </nav>
    </div>
  </div>
);

const TopLevelItem = ({ item }) => (
  <Link to={item.href ? item.href : item.name}>
    <div
      className={`ml-1 flex w-full items-center rounded-full rounded-tr-none rounded-br-none border-0 border-transparent p-0 ring-0 outline-0 ${
        item.current ? "text-1D3752 bg-EBEBEB" : "text-C2D2DF bg-1D3752"
      }`}
    >
      <RenderIcon item={item} />
    </div>
  </Link>
);

const NestedItem = ({ item }) =>
  item.current ? (
    <div className="flex w-full items-center rounded-full ml-8 border-0 border-transparent p-0 ring-0 outline-0 text-1D3752 bg-EBEBEB">
      {" "}
      <RenderIcon item={item} />
    </div>
  ) : (
    <div className="flex w-full items-center rounded-full ml-8 border-0 border-transparent p-0 ring-0 outline-0 text-C2D2DF bg-1D3752 cursor-pointer">
      <Link to={item.href ? item.href : item.name}>
        <RenderIcon item={item} current={item.current} />
      </Link>
    </div>
  );

const OuterDisclosure = ({ item }) => (
  <Disclosure as="div" defaultOpen={item.current}>
    {({ open }) => (
      <>
        <Disclosure.Button
          as="div"
          className={`rounded-full mx-4 border-2 border-F4C261 p-3.5 text-EAF2F8 flex justify-between items-center ${
            item.current && "bg-2C7695"
          }`}
        >
          {item.name}
          {open ? (
            <ChevronUpIcon className="w-7 h-7" />
          ) : (
            <ChevronDownIcon className="w-7 h-7" />
          )}
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500 space-y-4 mt-2">
          {item.children.map((inner, idx) =>
            inner.children ? (
              <InnerMostDisclosure item={inner} key={idx} />
            ) : (
              <NestedItem item={inner} key={idx} />
            )
          )}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

const InnerMostDisclosure = ({ item }) => (
  <Disclosure as="div" defaultOpen={item.current}>
    <div className="bg-1D3752 ml-1 rounded-3xl w-full">
      <Disclosure.Button
        as="div"
        className={`flex w-full items-center rounded-full rounded-tr-none rounded-br-none border-0 border-transparent p-0 ring-0 outline-0 ${
          item.current ? "text-1D3752 bg-EBEBEB" : "text-C2D2DF bg-1D3752"
        }`}
      >
        <RenderIcon item={item} />
      </Disclosure.Button>
      <Disclosure.Panel className="text-gray-500 space-y-2 mt-2">
        {item.children.map((inner, idx) => (
          <Link to={inner.href} key={idx}>
            <div
              className={`px-4 flex w-full items-center ml-12 p-0 py-3 pt-2 ring-0 outline-0 text-C2D2DF bg-1D3752 cursor-pointer ${
                idx != item.children.length - 1 ? "border-b" : "pb-4"
              } border-2C7695 ${inner.current && "text-F4C261"}`}
            >
              {inner.name}
            </div>
          </Link>
        ))}
      </Disclosure.Panel>
    </div>
  </Disclosure>
);

const RenderIcon = ({ item }) =>
  item.href ? (
    <div className="cursor-pointer flex items-center">
      {/* <UsersIcon
          className={`${
            item.current
              ? "font-light w-12 h-12 py-2.5 border border-F4C261 rounded-full bg-F4C261"
              : "font-light w-12 h-12 py-2.5 border border-C2D2DF rounded-full bg-1D3752"
          }`}
          alt=""
        /> */}
      {iconInner(item)}
    </div>
  ) : (
    <div className="cursor-pointer flex items-center">{iconInner(item)}</div>
  );

const iconInner = (item) =>
  item.current ? (
    <>
      <div className="font-light w-12 h-12 flex items-center justify-center py-2.5 border border-F4C261 rounded-full bg-F4C261">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.selectedImage}`}
          className="w-8"
        />
      </div>
      <span className="px-4">{item.name}</span>
    </>
  ) : (
    <>
      <div className="font-light w-12 h-12 flex items-center justify-center py-2.5 border border-C2D2DF rounded-full bg-1D3752">
        <img
          src={`https://hopstack-pub.s3.amazonaws.com/icons/${item.unselectedImage}`}
          className="w-7"
        />
      </div>
      <span className="px-4">{item.name}</span>
    </>
  );

export default AdminLayout;

// item.current ? (
//   <>
//     <UsersIcon
//       className=
//       alt=""
//     />
//     <span className="px-4">{item.name}</span>
//   </>
// ) : (
//   <Link to={item.href ? item.href : item.name}>
//     <div className="cursor-pointer flex items-center">
//       <UsersIcon
//         className="font-light w-12 h-12 py-2.5 border border-C2D2DF rounded-full bg-1D3752"
//         alt=""
//       />
//       <span className="px-4">{item.name}</span>
//     </div>
//   </Link>
// );
