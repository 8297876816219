import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_CUSTOMERS, GET_CUSTOMER } from "#queries";
import { SAVE_CUSTOMER, DELETE_CUSTOMER } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";

const withCustomersLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const getCustomerQuery = useQuery(GET_CUSTOMER);
    const saveCustomerQuery = useQuery(SAVE_CUSTOMER);
    const deleteCustomerQuery = useQuery(DELETE_CUSTOMER);
    useEffect(() => {
      customersQuery.fetchData();
    }, []);
    useEffect(() => {
      if (getCustomerQuery.data) {
        setSelectedCustomer(getCustomerQuery.data.customer);
      }

      if (getCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getCustomerQuery.loading,
      getCustomerQuery.data,
      getCustomerQuery.error,
    ]);

    useEffect(() => {
      if (saveCustomerQuery.data) {
        setSelectedCustomer(null);
        customersQuery.fetchData();
      }
      if (saveCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveCustomerQuery.error) {
        appState.setAlert(saveCustomerQuery.error.message, "error", 5000);
      }
    }, [
      saveCustomerQuery.loading,
      saveCustomerQuery.data,
      saveCustomerQuery.error,
    ]);

    useEffect(() => {
      if (deleteCustomerQuery.data) {
        appState.hideConfirmation();
        customersQuery.fetchData();
      }
      if (deleteCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteCustomerQuery.loading,
      deleteCustomerQuery.data,
      deleteCustomerQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this customer?",
        () => {
          deleteCustomerQuery.fetchData({ id });
        },
        appState.hideConfirmation
      );
    };
    const roleWise = (customers, role) =>
      _.filter(customers, (e) => e.role.name === role);

    const onChange = (e) => {
      const customer = {
        ...selectedCustomer,
      };

      customer[e.target.name] = e.target.value;
      setSelectedCustomer(customer);
    };

    const onChangeDropdown = (field, value) => {
      const customer = {
        ...selectedCustomer,
      };

      customer[field] = value;
      setSelectedCustomer(customer);
    };
    console.log(customersQuery.data?.customers);
    return (
      <WrappedComponent
        customers={customersQuery.data ? customersQuery.data.customers : []}
        fetchCustomer={(id) => getCustomerQuery.fetchData({ id })}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        saveCustomer={(customer) => {
          saveCustomerQuery.fetchData({ ...customer });
        }}
        roleWise={roleWise}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
      />
    );
  };
};

export default withCustomersLogic;
