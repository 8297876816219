import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_INTEGRATIONS,
  GET_CUSTOMERS,
  GET_INTEGRATION_ORDERS,
  GET_INTEGRATION_PRODUCTS,
} from "#queries";
import { SAVE_INTEGRATION } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";

const withHardwareIntegrationsLogic = (WrappedComponent) => {
  return (props) => {
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const [orders, setOrders] = useState(null);
    const [products, setProducts] = useState(null);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const saveIntegrationQuery = useQuery(SAVE_INTEGRATION);
    useEffect(() => {
      customersQuery.fetchData();
    }, []);

    useEffect(() => {
      if (customersQuery.data && customersQuery.data.customers.length > 0) {
        setSelectedCustomer(customersQuery.data.customers[0].id);

        integrationsQuery.fetchData({
          filters: {
            customer: customersQuery.data.customers[0].id,
          },
        });
      }
    }, [customersQuery.loading, customersQuery.data, customersQuery.error]);

    useEffect(() => {
      if (saveIntegrationQuery.data) {
        setSelectedIntegration(null);
        integrationsQuery.fetchData();
      }
      if (saveIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveIntegrationQuery.error) {
        console.log(saveIntegrationQuery.error);
        appState.setAlert(saveIntegrationQuery.error.message, "error", 5000);
      }
    }, [
      saveIntegrationQuery.loading,
      saveIntegrationQuery.data,
      saveIntegrationQuery.error,
    ]);

    const getProductsQuery = useQuery(GET_INTEGRATION_PRODUCTS);
    const getOrdersQuery = useQuery(GET_INTEGRATION_ORDERS);

    useEffect(() => {
      if (getProductsQuery.data) {
        console.log(getProductsQuery.data);
        // setProducts(getProductsQuery.data.integrationProducts);
        appState.setAlert(getProductsQuery.data.integrationProducts.message);
      }
    }, [
      getProductsQuery.loading,
      getProductsQuery.error,
      getProductsQuery.data,
    ]);

    useEffect(() => {
      if (getOrdersQuery.data) {
        console.log(getOrdersQuery.data);
        // setOrders(getOrdersQuery.data.integrationOrders);
        appState.setAlert(getOrdersQuery.data.integrationOrders.message);
      }
    }, [getOrdersQuery.loading, getOrdersQuery.error, getOrdersQuery.data]);

    const onChange = (e) => {
      const integration = {
        ...selectedIntegration,
      };

      integration[e.target.name] = e.target.value;
      setSelectedIntegration(integration);
    };

    const onChangeDropdown = (field, value) => {
      const integration = {
        ...selectedIntegration,
      };

      integration[field] = value;
      setSelectedIntegration(integration);
    };

    return (
      <WrappedComponent
        integrationProviders={masterData.integrationProviders?.filter(
          (item) => item.integrationType === "HARDWARE"
        )}
        getProducts={(id) => getProductsQuery.fetchData({ id })}
        getOrders={(id) => getOrdersQuery.fetchData({ id })}
        orders={orders}
        products={products}
        setProducts={setProducts}
        setOrders={setOrders}
        connectIntegration={(integrationType) =>
          setSelectedIntegration({
            integrationType,
            customer: selectedCustomer,
            username: "",
            password: "",
            storeName: "",
          })
        }
        customers={customersQuery.data ? customersQuery.data.customers : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          integrationsQuery.fetchData({
            filters: {
              customer: e,
            },
          });
        }}
        integrations={
          integrationsQuery.data ? integrationsQuery.data.integrations : []
        }
        selectedIntegration={selectedIntegration}
        setSelectedIntegration={setSelectedIntegration}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveIntegration={() => {
          saveIntegrationQuery.fetchData({ ...selectedIntegration });
        }}
      />
    );
  };
};

export default withHardwareIntegrationsLogic;
