import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const DeviceForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedDevice,
  onSubmit,
  deviceTypes,
  onChangeMultiSelect,
  customers,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Device Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedDevice.name}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="hardwareId"
          label="Hardware ID"
          placeholder="Hardware ID"
          onChange={onChange}
          value={selectedDevice.hardwareId}
        />
      </div>

      <div>
        <Dropdown
          placeholder={"Select Device Type"}
          list={deviceTypes}
          labelKey="name"
          valueKey="name"
          name="deviceType"
          setSelected={(e) => {
            onChangeDropdown("deviceType", e);
          }}
          selectedValue={selectedDevice.deviceType}
        />
      </div>
      <div>
        <div id="selectingStations">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Select Customers
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(e) => onChangeMultiSelect("customers", e)}
            values={selectedDevice.customers || []}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default DeviceForm;
