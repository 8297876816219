import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import AdminLayout from "#components/layout/AdminLayout";
import AssociateLayout from "#components/layout/AssociateLayout";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user, loading } = useContext(AuthContext);

  const renderRoute = (props) => {
    if (!isAuthenticated && !loading) {
      return <Redirect to="/" />;
    }

    if (user && ["Admin", "ADMIN", "Warehouse Manager"].includes(user.role)) {
      return (
        <AdminLayout warehouseManager={user.role === "Warehouse Manager"}>
          <Component {...props} />
        </AdminLayout>
      );
    }

    if (user && user.role === "Associate") {
      return (
        <AssociateLayout>
          <Component {...props} />
        </AssociateLayout>
      );
    }

    return null;
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? <Redirect to="/" /> : renderRoute(props)
      }
    />
  );
  // return <Route {...rest} render={(props) => renderRoute(props)} />;
};

export default PrivateRoute;
