import { Component } from "react";
import NotFound from "#components/layout/NotFound";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
    this.setState({ hasError: true, message: error.message });
  }

  render() {
    if (this.state.hasError) {
      return (
        <NotFound
          title={"Something went wrong!"}
          description={this.state.message}
        />
      );
    }
    return <>{this.props.children}</>;
  }
}
