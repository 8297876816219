import withTotesLogic from "#components/HOC/withTotesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import TotesList from "#components/totes/TotesList";
import ToteForm from "#components/totes/ToteForm";
import EmptyToteForm from "#components/totes/EmptyToteForm";
import { PrinterIcon, UserAddIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import Dropdown from "#components/utils/Dropdown";
const config = require("config");

const Totes = ({
  masterData,
  totes,
  selectedTote,
  setSelectedTote,
  fetchTote,
  saveTote,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  setEmptyTote,
  emptyTote,
  saveEmptyTote,
  selectedTotes,
  selectTote,
  selectedPrints,
  setSelectedPrints,
  printSelectedBarcodes,
  queueMultiplePrints,
  queueSinglePrint,
  selectAllTotes,
  allTotesSelected,
  uploadMultipleEntries,
}) => {
  const headers = ["Tote Type", "Barcode", "Last Updated", "Action"];
  if (config.NODE_ENV === "development") {
    headers.push("Code");
  }
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-1">
          <PageTitle>Tote Management</PageTitle>
        </div>
        {selectedTotes.length === 0 && (
          <div className="items-center justify-end flex space-x-2 mb-4">
            <AddButton
              text="Add Tote"
              onClick={() => setSelectedTote({})}
              icon={UserAddIcon}
            />
            <AddButton
              text="Add Multiple Totes"
              onClick={() => setEmptyTote({})}
              icon={UserAddIcon}
            />
            <label
              className={`flex items-center justify-center text-sm font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none p-0`}
              as="label"
            >
              <span className="w-full p-3 px-6">Upload File</span>
              <input
                type="file"
                hidden
                onChange={(e) => uploadMultipleEntries(e.target.files[0])}
                color="primary"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </label>
          </div>
        )}
        {selectedTotes.length > 0 && (
          <div className="items-center justify-end flex space-x-2 mb-4">
            <AddButton
              text="Print"
              onClick={queueMultiplePrints}
              icon={PrinterIcon}
            />
          </div>
        )}
      </div>
      <TotesList
        totes={totes}
        editButtonClicked={(e) => {
          fetchTote(e.id);
        }}
        deleteButtonClicked={(e) => deleteButtonClicked(e)}
        masterData={masterData}
        headers={headers}
        noValuesText="No Totes"
        selectedTotes={selectedTotes}
        selectTote={selectTote}
        queueSinglePrint={queueSinglePrint}
        selectAllTotes={selectAllTotes}
        allTotesSelected={allTotesSelected}
      />
      {selectedTote && (
        <ToteForm
          title={selectedTote.id ? "Edit Tote" : "Add Tote"}
          selectedTote={selectedTote}
          onChange={onChange}
          onClose={() => setSelectedTote(null)}
          onSubmit={() => saveTote(selectedTote)}
          hopstackModules={masterData?.hopstackModules}
          warehouses={masterData?.warehouses}
          onChangeDropdown={onChangeDropdown}
          devices={devices}
          onChangeMultiSelect={onChangeMultiSelect}
        />
      )}
      {emptyTote && (
        <EmptyToteForm
          title={"Add Tote"}
          selectedTote={emptyTote}
          onChange={(e) => onChange(e, "emptyTote")}
          onClose={() => setEmptyTote(null)}
          onSubmit={() => saveEmptyTote(emptyTote)}
          onChangeDropdown={(field, value) =>
            onChangeDropdown(field, value, "emptyTote")
          }
        />
      )}
      {selectedPrints && (
        <Modal negativeAction={() => setSelectedPrints(null)} title={`Print`}>
          <div className="space-y-4">
            <Dropdown
              placeholder={"Select Barcode Type"}
              list={[{ name: "1D Barcode" }, { name: "2D Barcode" }]}
              labelKey="name"
              valueKey="name"
              name="barcodeType"
              setSelected={(e) => {
                setSelectedPrints({
                  ...selectedPrints,
                  barcodeType: e,
                });
              }}
              selectedValue={selectedPrints.barcodeType}
            />
            <TextField
              type="number"
              id="noOfCopies"
              label="Number of Copies"
              placeholder=" "
              onChange={(e) =>
                setSelectedPrints({
                  ...selectedPrints,
                  noOfCopies: e.target.value,
                })
              }
              value={selectedPrints.noOfCopies}
            />
          </div>

          <div className="flex items-center justify-center mt-5">
            <ModalButton onClick={printSelectedBarcodes} text={"Print"} />
          </div>
        </Modal>
      )}
    </div>
  );
};
export default withTotesLogic(Totes);
