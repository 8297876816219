import _ from "lodash";
import JSPM from "jsprintmanager";
JSPM.JSPrintManager.license_url = `https://api.delmar.hopstack.io/jspm`;
JSPM.JSPrintManager.auto_reconnect = true;
JSPM.JSPrintManager.start();
JSPM.JSPrintManager.WS.onStatusChanged = function () {
  if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
    JSPM.JSPrintManager.getPrinters()
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }
};
import { constructBarcodesForLabelPrint } from "#utils/sortingBarcodeGenerator";

export const printBarcodes = async ({
  selectedBarcodes,
  barcodeType,
  noOfCopies,
  appState,
  printer = "Zebra",
  dpi = 8,
}) => {
  let possiblePrinterNames = ["zebra", "zdesign", "thermal"];
  if (printer === "Rongta") {
    possiblePrinterNames = ["rongta", "rp421"];
  }

  if (printer === "XP") {
    possiblePrinterNames = ["xp"];
  }

  const printList = [];
  for (const selectedBarcode of selectedBarcodes) {
    for (let i = 0; i < noOfCopies; i++) {
      printList.push({
        barcode: selectedBarcode.barcode,
        position: selectedBarcode.position,
      });
    }
  }

  let chunked = _.chunk(printList, 1);
  // if (barcodeType === "2D Barcode") {
  //   chunked = _.chunk(printList, 3);
  // }

  if (dpi === 6) {
    chunked = _.chunk(printList, 1);
  }

  let printCommands = [];
  for (const chunk of chunked) {
    printCommands.push(constructBarcodesForLabelPrint(chunk));
  }

  console.log(printCommands[0]);

  try {
    const printers = await JSPM.JSPrintManager.getPrinters();
    if (!printers || printers.length === 0) {
      appState.setAlert("No printers found", "error", 5000);
      return;
    }

    // const findZebra = _.find(
    //   printers,
    //   (e) =>
    //     e.toLowerCase().includes("zdesign") ||
    //     e.toLowerCase().includes("zebra") ||
    //     e.toLowerCase().includes("thermal") ||
    //     e.toLowerCase().includes("xp") ||
    //     e.toLowerCase().includes("rongta") ||
    //     e.toLowerCase().includes("rp421")
    // );
    // if (findZebra) {
    for (const cmds of printCommands) {
      const cpj = new JSPM.ClientPrintJob();
      const myPrinter = new JSPM.DefaultPrinter();
      myPrinter.duplex = JSPM.DuplexMode.Simplex;
      cpj.clientPrinter = myPrinter;
      cpj.printerCommands = cmds;
      console.log(cmds);
      cpj.sendToClient();
    }
    // } else {
    //   appState.setAlert("No thermal printers found", "error", 5000);
    //   return;
    // }
  } catch (err) {
    appState.setAlert("Could not print", "error", 3000);
  }
  return;
};
