import Stats from "../common/Stats"

const CustomerOrdersAndProductsStats = (props) => {
    return <>
      <Stats
      stats={Object.keys(props.customer).map(key => {
          if (key !== "name" && key !== "id") {
            return {
                "name": props.customer[key].title,
                "stat": props.customer[key].value
            }
          }
      }).filter(elem => elem)}
      />
    </>
}

export default CustomerOrdersAndProductsStats