import "#styles/globals.css";
import { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "#config/routes";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import Layout from "#components/common/Layout";
import { useQuery } from "#hooks/useQuery";
import { GET_LOGGED_IN_USER } from "#queries";
import { StatDashboardProvider } from "#contexts/statsDashboard";

import ErrorBoundary from "#components/common/ErrorBoundary";
import { BatchingProvider } from "#contexts/batching";
import { StatProvider } from "#contexts/stat";
import { EntityProvider } from "#contexts/entity";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
const API_URL = "http://localhost:4000";
const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTQ2OTc0MjcsImV4cCI6MTY1NDc4MzgyN30.eDigK9ESW78_sQmwi2t1SC5PLhHRejZfMRHEnqx080I";
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`,
});

const App = () => {
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const masterData = useContext(MasterDataContext);
  const { loading, error, data, fetchData } = useQuery(GET_LOGGED_IN_USER);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    if (!loading) {
      appState.removeLoading();
    }
    if (!loading && error) {
      auth.logout();
    }
    if (loading) {
      appState.setLoading();
    }
    if (data && data.me) {
      auth.login({
        ...data.me,
        token: localStorage.getItem("token"),
      });
      masterData.fetchMasterData();
    }
  }, [loading, error, data]);

  return (
    <ErrorBoundary>
      <BatchingProvider>
        <StatProvider>
          <CubeProvider cubejsApi={cubejsApi}>
            <EntityProvider>
              <StatDashboardProvider>
                <Layout>
                  <Router>
                    <Switch>
                      <Route component={Routes} />
                    </Switch>
                  </Router>
                </Layout>
              </StatDashboardProvider>
            </EntityProvider>
          </CubeProvider>
        </StatProvider>
      </BatchingProvider>
    </ErrorBoundary>
  );
};

export default App;
