import { Fragment, useContext, useEffect } from "react";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/outline";
import { AuthContext } from "#contexts/auth";
import { useHistory } from "react-router-dom";
import { AppStateContext } from "#contexts/appState";
import { LOGOUT_USER } from "#mutations";
import { useQuery } from "#hooks/useQuery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AssociateLayout = ({ children }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const logoutQuery = useQuery(LOGOUT_USER);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(logoutQuery.data.logout.message);
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);
  return (
    <div className="h-full min-h-screen bg-E5E5E5">
      <div className="w-full flex justify-between absolute top-0 left-0 bg-white shadow-md">
        {/*Logo*/}
        <div className="flex items-center justify-center space-x-4 py-2 px-2">
          <img
            src="https://hopstack-pub.s3.amazonaws.com/logo.png"
            alt="Picture of the author"
            className="w-12 h-12"
          />
          <span className="font-hammersmith text-224E73 text-2xl">
            Hopstack Inc
          </span>
        </div>
        {/*. User Details */}
        <div className="ml-4 flex items-center md:ml-6 mr-5">
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open user menu</span>
                <UserCircleIcon className="h-8 w-8 rounded-full" alt="" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        logoutQuery.fetchData();
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Logout
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AssociateLayout;
