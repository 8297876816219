import { useReducer, createContext } from "react";
import _ from "lodash";

const initialState = {
  batches: [],
  total: 0,
};

const BatchingContext = createContext({});

function batchingReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SIMULATE_BATCHES": {
      return {
        ...state,
        batches: payload,
        total: payload.length,
      };
    }
    default:
      return state;
  }
}

function BatchingProvider(props) {
  const [state, dispatch] = useReducer(batchingReducer, initialState);

  function getBatches(data) {
    dispatch({
      type: "SIMULATE_BATCHES",
      payload: dummyPayload,
    });
  }

  function clearBatches() {
    dispatch({
      type: "SIMULATE_BATCHES",
      payload: [],
    });
  }

  return (
    <BatchingContext.Provider
      value={{
        ...state,
        getBatches,
        clearBatches,
      }}
      {...props}
    />
  );
}

export { BatchingContext, BatchingProvider };

const dummyPayload = [
  {
    orderLineItems: [
      {
        stockBin: { row: "01", section: "B", bin: "27" },
        price: null,
        notes: [],
        isSet: false,
        _id: "6142c4ca5182ab6424a6eaaf",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363678",
            street: "1251 E LUGONIA AVE SPC 43",
            name: "SUSAN SUSANJAY",
            zip: "92374",
            city: "REDLANDS",
            country: "United States",
            state: "California",
          },
          orderValue: 0,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4ca5182ab6424a6eaaf"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4ca5182ab0113a6eaaa",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121371,
          orderNo: "JCP479860118_261-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP479860118_261",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363678,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552313",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/susan susanjay/1251 e lugonia ave spc 43//redlands/california/92374/united states",
          createdAt: "2021-09-16T04:15:06.104Z",
          updatedAt: "2021-09-16T04:15:06.250Z",
          labelFile: "5121371/labelFile.txt",
          zplFile: "5121371/zplFile.txt",
          pdfFile: "5121371/pdfFile.txt",
          addressString:
            "1251elugoniaavespc43redlandscalifornia92374unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "PAPS",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC29E3-MYBU",
        productName:
          "1/8 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Post Earrings 10k Yellow Gold I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC29E3-MYBU.jpg",
        reference: "JCP479860118_261-0",
        sku: "FC29E3-MYBU",
        binLocation: "01-B-27",
        boxCode: "",
        stockPickingId: "3115996",
        uniqueIdentifier: "5121371-3115996-4397842",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121371,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP479860118_261",
          sale_order_date: "2021-09-15",
          sale_order_total: 151.75,
          shipping_address_street: "1251 E LUGONIA AVE SPC 43",
          shipping_address_street_2: null,
          shipping_address_id: 8363678,
          shipping_address_name: "SUSAN SUSANJAY",
          shipping_address_zip: "92374",
          shipping_address_city: "REDLANDS",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP479860118_261-0",
          stock_picking_id: 3115996,
          total_price: 0,
          shipping_batch_id: -1,
          date: "2021-09-16 02:42:44",
          picking_write_date: "2021-09-16 03:11:40",
          address_grp_combined:
            "jcp/susan susanjay/1251 e lugonia ave spc 43//redlands/california/92374/united states",
          stock_move_is_set: false,
          stock_move_id: 78186864,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "01-B-27",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397842,
          sale_order_line_description: "10K YG PEARL EA",
          sale_order_line_customer_cost: null,
          sale_order_line_vendor_sku: "JCP006726",
          sale_order_line_size_id: null,
          product_product_default_code: "FC29E3-MYBU",
          product_product_name:
            "1/8 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Post Earrings 10k Yellow Gold I3",
          product_id: 3106004,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC29E3-MYBU.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552313",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T04:15:06.238Z",
        updatedAt: "2021-09-16T04:15:06.239Z",
        __v: 0,
      },
      {
        stockBin: { row: "01", section: "G", bin: "53" },
        price: 306.25,
        notes: [],
        isSet: false,
        _id: "6142cf7d5182ab6895a6eb33",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363727",
            street: "524 ALLEGHANY RD",
            name: "Karen Hedgepeth",
            zip: "28304-3220",
            city: "FAYETTEVILLE",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 306.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7d5182ab6895a6eb33"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7d5182ab2acfa6eb2e",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121402,
          orderNo: "BLK100980377-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100980377",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363727,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694343",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/karen hedgepeth/524 alleghany rd//fayetteville/north carolina/28304-3220/united states",
          createdAt: "2021-09-16T05:00:45.734Z",
          updatedAt: "2021-09-16T05:00:45.881Z",
          labelFile: "5121402/labelFile.txt",
          zplFile: "5121402/zplFile.txt",
          pdfFile: "5121402/pdfFile.txt",
          addressString:
            "524alleghanyrdfayettevillenorthcarolina283043220unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1ZBP-B9M5",
        productName:
          "1/4 CT Black and White Diamond TW Fashion Ring 10k Pink Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1ZBP-B9M5.jpg",
        reference: "BLK100980377-0",
        sku: "FC1ZBP-B9M5",
        binLocation: "01-G-53",
        boxCode: "",
        stockPickingId: "3116019",
        uniqueIdentifier: "5121402-3116019-4397878",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121402,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100980377",
          sale_order_date: "2021-09-15",
          sale_order_total: 161,
          shipping_address_street: "524 ALLEGHANY RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363727,
          shipping_address_name: "Karen Hedgepeth",
          shipping_address_zip: "28304-3220",
          shipping_address_city: "FAYETTEVILLE",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100980377-0",
          stock_picking_id: 3116019,
          total_price: 306.25,
          shipping_batch_id: -1,
          date: "2021-09-16 03:28:41",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined:
            "blk/karen hedgepeth/524 alleghany rd//fayetteville/north carolina/28304-3220/united states",
          stock_move_is_set: false,
          stock_move_id: 78186898,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "01-G-53",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397878,
          sale_order_line_description: "BW DIA BAR RG 10KR 6 DS",
          sale_order_line_customer_cost: "306.25",
          sale_order_line_vendor_sku: "686692273467",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC1ZBP-B9M5",
          product_product_name:
            "1/4 CT Black and White Diamond TW Fashion Ring 10k Pink Gold GH I2;I3",
          product_id: 468519,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1ZBP-B9M5.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694343",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:00:45.869Z",
        updatedAt: "2021-09-16T05:00:45.871Z",
        __v: 0,
      },
      {
        stockBin: { row: "01", section: "I", bin: "17" },
        price: 59.99,
        notes: [],
        isSet: false,
        _id: "6142c4ca5182ab1dcda6eabc",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363686",
            street: "2419 Walnut Ave",
            name: "Valerie Dennard",
            zip: "36201",
            city: "Anniston",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 59.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4ca5182ab1dcda6eabc"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4ca5182ab4bf9a6eab7",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121380,
          orderNo: "OS355679318-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355679318",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363686,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256283",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/valerie dennard/2419 walnut ave//anniston/alabama/36201/united states",
          createdAt: "2021-09-16T04:15:06.434Z",
          updatedAt: "2021-09-16T04:15:06.580Z",
          labelFile: "5121380/labelFile.txt",
          zplFile: "5121380/zplFile.txt",
          pdfFile: "5121380/pdfFile.txt",
          addressString: "2419walnutaveannistonalabama36201unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS5258ATN",
        productName: "4 3/5 CT TGW Amethyst Tanzanite Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS5258ATN.jpg",
        reference: "OS355679318-0",
        sku: "RGS5258ATN",
        binLocation: "01-I-17",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115997",
        uniqueIdentifier: "5121380-3115997-4397846",
        stockMoveSize: "9.5",
        orderLineSize: "9.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121380,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355679318",
          sale_order_date: "2021-09-15",
          sale_order_total: 45,
          shipping_address_street: "2419 Walnut Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363686,
          shipping_address_name: "Valerie Dennard",
          shipping_address_zip: "36201",
          shipping_address_city: "Anniston",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355679318-0",
          stock_picking_id: 3115997,
          total_price: 59.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:44:49",
          picking_write_date: "2021-09-16 03:11:35",
          address_grp_combined:
            "os/valerie dennard/2419 walnut ave//anniston/alabama/36201/united states",
          stock_move_is_set: false,
          stock_move_id: 78186866,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "01-I-17",
          stock_move_uom: "PCE",
          stock_move_size: "9.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397846,
          sale_order_line_description: "Size 9.5",
          sale_order_line_customer_cost: "59.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 12,
          product_product_default_code: "RGS5258ATN",
          product_product_name:
            "4 3/5 CT TGW Amethyst Tanzanite Fashion Ring Silver",
          product_id: 60814,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS5258ATN.jpg",
          sale_order_line_size: "9.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256283",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:15:06.568Z",
        updatedAt: "2021-09-16T04:15:06.569Z",
        __v: 0,
      },
      {
        stockBin: { row: "02", section: "E", bin: "09" },
        price: 249.98,
        notes: [],
        isSet: false,
        _id: "6142c4cc5182abb1b3a6eafe",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363697",
            street: "5000 SW WOODVALE RD",
            name: "ADITI BHOSALE",
            zip: "72713",
            city: "BENTONVILLE",
            country: "United States",
            state: "Arkansas",
          },
          orderValue: 249.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4cc5182abb1b3a6eafe"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4cc5182ab40cba6eaf9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121385,
          orderNo: "JCP355660328_292-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355660328_292",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363697,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552304",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/aditi bhosale/5000 sw woodvale rd//bentonville/arkansas/72713/united states",
          createdAt: "2021-09-16T04:15:08.720Z",
          updatedAt: "2021-09-16T04:15:08.933Z",
          labelFile: "5121385/labelFile.txt",
          zplFile: "5121385/zplFile.txt",
          pdfFile: "5121385/pdfFile.txt",
          addressString: "5000swwoodvalerdbentonvillearkansas72713unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "RZS6652",
        productName: "5.55 CT TGW Cubic Zirconia Fashion Ring Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/RZS6652.jpg",
        reference: "JCP355660328_292-0",
        sku: "RZS6652",
        binLocation: "02-E-09",
        boxCode: "",
        stockPickingId: "3116003",
        uniqueIdentifier: "5121385-3116003-4397855",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121385,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355660328_292",
          sale_order_date: "2021-09-15",
          sale_order_total: 24.75,
          shipping_address_street: "5000 SW WOODVALE RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363697,
          shipping_address_name: "ADITI BHOSALE",
          shipping_address_zip: "72713",
          shipping_address_city: "BENTONVILLE",
          shipping_address_state: "Arkansas",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355660328_292-0",
          stock_picking_id: 3116003,
          total_price: 249.98,
          shipping_batch_id: -1,
          date: "2021-09-16 02:55:19",
          picking_write_date: "2021-09-16 03:10:39",
          address_grp_combined:
            "jcp/aditi bhosale/5000 sw woodvale rd//bentonville/arkansas/72713/united states",
          stock_move_is_set: false,
          stock_move_id: 78186879,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-E-09",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397855,
          sale_order_line_description: "SS CZ RING",
          sale_order_line_customer_cost: "249.98",
          sale_order_line_vendor_sku: "JCP005843",
          sale_order_line_size_id: 7,
          product_product_default_code: "RZS6652",
          product_product_name:
            "5.55 CT TGW Cubic Zirconia Fashion Ring Silver White",
          product_id: 80904,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RZS6652.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552304",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T04:15:08.907Z",
        updatedAt: "2021-09-16T04:15:08.907Z",
        __v: 0,
      },
      {
        stockBin: { row: "02", section: "I", bin: "67" },
        price: 306.25,
        notes: [],
        isSet: false,
        _id: "6142b3375182ab2f45a6e980",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363609",
            street: "2624 S Highway 127",
            name: "Cherish Kean",
            zip: "42642",
            city: "Russell Springs",
            country: "United States",
            state: "Kentucky",
          },
          orderValue: 306.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3375182ab2f45a6e980"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3375182ab28f9a6e97b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121335,
          orderNo: "BLK100975989-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100975989",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363609,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694273",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/cherish kean/2624 s highway 127//russell springs/kentucky/42642/united states",
          createdAt: "2021-09-16T03:00:07.418Z",
          updatedAt: "2021-09-16T03:00:07.591Z",
          labelFile: "5121335/labelFile.txt",
          zplFile: "5121335/zplFile.txt",
          pdfFile: "5121335/pdfFile.txt",
          addressString:
            "2624shighway127russellspringskentucky42642unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC207P-0A6X",
        productName: "1/5 CT Diamond TW Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC207P-0A6X.jpg",
        reference: "BLK100975989-0",
        sku: "FC207P-0A6X",
        binLocation: "02-I-67",
        boxCode: "",
        stockPickingId: "3115957",
        uniqueIdentifier: "5121335-3115957-4397799",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121335,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100975989",
          sale_order_date: "2021-09-15",
          sale_order_total: 146,
          shipping_address_street: "2624 S Highway 127",
          shipping_address_street_2: null,
          shipping_address_id: 8363609,
          shipping_address_name: "Cherish Kean",
          shipping_address_zip: "42642",
          shipping_address_city: "Russell Springs",
          shipping_address_state: "Kentucky",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100975989-0",
          stock_picking_id: 3115957,
          total_price: 306.25,
          shipping_batch_id: -1,
          date: "2021-09-16 01:34:43",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "blk/cherish kean/2624 s highway 127//russell springs/kentucky/42642/united states",
          stock_move_is_set: false,
          stock_move_id: 78186805,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-I-67",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397799,
          sale_order_line_description: "DIA BRID RG SET SS 7 DS",
          sale_order_line_customer_cost: "306.25",
          sale_order_line_vendor_sku: "686692376328",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC207P-0A6X",
          product_product_name:
            "1/5 CT Diamond TW Fashion Ring Silver GH I2;I3",
          product_id: 858630,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC207P-0A6X.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694273",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:07.575Z",
        updatedAt: "2021-09-16T03:00:07.576Z",
        __v: 0,
      },
      {
        stockBin: { row: "02", section: "J", bin: "07" },
        price: 233.45,
        notes: [],
        isSet: false,
        _id: "6142f6025182ab4a67a6ed4c",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363891",
            street: "74 Florence St",
            name: "Rhonda Breed",
            zip: "28560-5754",
            city: "New Bern",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 233.45,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6025182ab4a67a6ed4c"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6025182ab4318a6ed47",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121487,
          orderNo: "BLK100985146-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100985146",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363891,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694435",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/rhonda breed/74 florence st//new bern/north carolina/28560-5754/united states",
          createdAt: "2021-09-16T07:45:06.362Z",
          updatedAt: "2021-09-16T07:45:06.522Z",
          labelFile: "5121487/labelFile.txt",
          zplFile: "5121487/zplFile.txt",
          pdfFile: "5121487/pdfFile.txt",
          addressString:
            "74florencestnewbernnorthcarolina285605754unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RYKT4450WS",
        productName:
          "5/8 CT TGW Created White Sapphire 3 Stone Ring 10k Yellow Gold",
        productImage: "https://partner.delmarintl.ca/catalog/RYKT4450WS.jpg",
        reference: "BLK100985146-0",
        sku: "RYKT4450WS",
        binLocation: "02-J-07",
        boxCode: "",
        stockPickingId: "3116103",
        uniqueIdentifier: "5121487-3116103-4397972",
        stockMoveSize: "6.5",
        orderLineSize: "6.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121487,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100985146",
          sale_order_date: "2021-09-15",
          sale_order_total: 115,
          shipping_address_street: "74 Florence St",
          shipping_address_street_2: null,
          shipping_address_id: 8363891,
          shipping_address_name: "Rhonda Breed",
          shipping_address_zip: "28560-5754",
          shipping_address_city: "New Bern",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100985146-0",
          stock_picking_id: 3116103,
          total_price: 233.45,
          shipping_batch_id: -1,
          date: "2021-09-16 06:22:25",
          picking_write_date: "2021-09-16 06:40:53",
          address_grp_combined:
            "blk/rhonda breed/74 florence st//new bern/north carolina/28560-5754/united states",
          stock_move_is_set: false,
          stock_move_id: 78187013,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-J-07",
          stock_move_uom: "PCE",
          stock_move_size: "6.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397972,
          sale_order_line_description: "CWS 3S EG RG 10KY 6.5 DS",
          sale_order_line_customer_cost: "233.45",
          sale_order_line_vendor_sku: "686692261419",
          sale_order_line_size_id: 6,
          product_product_default_code: "RYKT4450WS",
          product_product_name:
            "5/8 CT TGW Created White Sapphire 3 Stone Ring 10k Yellow Gold",
          product_id: 89370,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RYKT4450WS.jpg",
          sale_order_line_size: "6.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694435",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:45:06.510Z",
        updatedAt: "2021-09-16T07:45:06.512Z",
        __v: 0,
      },
      {
        stockBin: { row: "02", section: "N", bin: "63" },
        price: 80.97,
        notes: [],
        isSet: false,
        _id: "6142fd075182ab9388a6ed67",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363907",
            street: "1690 Clover Dr",
            name: "Sharen Moore",
            zip: "95401-4542",
            city: "Santa Rosa",
            country: "United States",
            state: "California",
          },
          orderValue: 80.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd075182ab9388a6ed67"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd075182ab05eea6ed62",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121495,
          orderNo: "HAU66355596-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355596",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363907,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552448",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/sharen moore/1690 clover dr//santa rosa/california/95401-4542/united states",
          createdAt: "2021-09-16T08:15:03.035Z",
          updatedAt: "2021-09-16T08:15:03.218Z",
          labelFile: "5121495/labelFile.txt",
          zplFile: "5121495/zplFile.txt",
          pdfFile: "5121495/pdfFile.txt",
          addressString: "1690cloverdrsantarosacalifornia954014542unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0LCG-U7GV",
        productName:
          "0.05 CT Diamond TW And 1 1/7 CT TGW Aquamarine Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LCG-U7GV.jpg",
        reference: "HAU66355596-0",
        sku: "FC0LCG-U7GV",
        binLocation: "02-N-63",
        boxCode: "",
        stockPickingId: "3116110",
        uniqueIdentifier: "5121495-3116110-4397983",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121495,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355596",
          sale_order_date: "2021-09-15",
          sale_order_total: 46,
          shipping_address_street: "1690 Clover Dr",
          shipping_address_street_2: "",
          shipping_address_id: 8363907,
          shipping_address_name: "Sharen Moore",
          shipping_address_zip: "95401-4542",
          shipping_address_city: "Santa Rosa",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355596-0",
          stock_picking_id: 3116110,
          total_price: 80.97,
          shipping_batch_id: -1,
          date: "2021-09-16 06:45:54",
          picking_write_date: "2021-09-16 07:10:57",
          address_grp_combined:
            "hau/sharen moore/1690 clover dr//santa rosa/california/95401-4542/united states",
          stock_move_is_set: false,
          stock_move_id: 78187021,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-N-63",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397983,
          sale_order_line_description:
            "0.05 CT Diamond TW And 1 1/7 CT TGW Aquamarine F",
          sale_order_line_customer_cost: "80.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0LCG-U7GV",
          product_product_name:
            "0.05 CT Diamond TW And 1 1/7 CT TGW Aquamarine Fashion Ring Silver GH I3",
          product_id: 89097,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LCG-U7GV.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552448",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:03.207Z",
        updatedAt: "2021-09-16T08:15:03.207Z",
        __v: 0,
      },
      {
        stockBin: { row: "03", section: "J", bin: "02" },
        price: 179.99,
        notes: [],
        isSet: false,
        _id: "614289205182ab37d9a6e7b5",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363420",
            street: "2000 S Melrose Dr, Apt 161",
            name: "Joseph Pincolic",
            zip: "92081-8775",
            city: "Vista",
            country: "United States",
            state: "California",
          },
          orderValue: 179.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289205182ab37d9a6e7b5"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289205182ab7cf4a6e7b0",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121234,
          orderNo: "GVX2783847-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2783847-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363420,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256351",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:32.370Z",
          updatedAt: "2021-09-16T00:00:32.534Z",
          labelFile: "5121234/labelFile.txt",
          zplFile: "5121234/zplFile.txt",
          pdfFile: "5121234/pdfFile.txt",
          addressString:
            "2000smelrosedrapt161vistacalifornia920818775unitedstates",
          normalizedCustomerName: "govx",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0PZT-5CVW",
        productName:
          "0.005 CT Diamond TW And 1 1/3 CT TGW Morganite Pink Tourmaline Heart Pendant With Chain Pink Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0PZT-5CVW.jpg",
        reference: "GVX2783847-DEL01-0",
        sku: "FC0PZT-5CVW",
        binLocation: "03-J-02",
        boxCode: "",
        stockPickingId: "3115863",
        uniqueIdentifier: "5121234-3115863-4397676",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121234,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2783847-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 116,
          shipping_address_street: "2000 S Melrose Dr",
          shipping_address_street_2: "Apt 161",
          shipping_address_id: 8363420,
          shipping_address_name: "Joseph Pincolic",
          shipping_address_zip: "92081-8775",
          shipping_address_city: "Vista",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2783847-DEL01-0",
          stock_picking_id: 3115863,
          total_price: 179.99,
          shipping_batch_id: -1,
          date: "2021-09-15 22:16:55",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186669,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "03-J-02",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397676,
          sale_order_line_description:
            "0.005 CT Diamond and 1 1/3 CT Morganite Pink Tourmaline Heart Pendant With Pink Silver Chain",
          sale_order_line_customer_cost: "179.99",
          sale_order_line_vendor_sku: "GVX000543",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0PZT-5CVW",
          product_product_name:
            "0.005 CT Diamond TW And 1 1/3 CT TGW Morganite Pink Tourmaline Heart Pendant With Chain Pink Silver GH I2;I3",
          product_id: 183793,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0PZT-5CVW.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256351",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T00:00:32.523Z",
        updatedAt: "2021-09-16T00:00:32.523Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "F", bin: "11" },
        price: 61.25,
        notes: [],
        isSet: false,
        _id: "6142b3375182abbf78a6e98d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363608",
            street: "49 Ashcraft Cir, Apt 405",
            name: "Diane Johnson",
            zip: "29585-7176",
            city: "Pawleys Island",
            country: "United States",
            state: "South Carolina",
          },
          orderValue: 61.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3375182abbf78a6e98d"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3375182ab8ceea6e988",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121337,
          orderNo: "BLK100975850-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100975850",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363608,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694262",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/diane johnson/49 ashcraft cir/apt 405/pawleys island/south carolina/29585-7176/united states",
          createdAt: "2021-09-16T03:00:07.772Z",
          updatedAt: "2021-09-16T03:00:07.942Z",
          labelFile: "5121337/labelFile.txt",
          zplFile: "5121337/zplFile.txt",
          pdfFile: "5121337/pdfFile.txt",
          addressString:
            "49ashcraftcirapt405pawleysislandsouthcarolina295857176unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0LWJ-Y9F1",
        productName:
          "0.04 CT Diamond TW And 4/5 CT TGW Amethyst Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LWJ-Y9F1.jpg",
        reference: "BLK100975850-0",
        sku: "FC0LWJ-Y9F1",
        binLocation: "04-F-11",
        boxCode: "",
        stockPickingId: "3115958",
        uniqueIdentifier: "5121337-3115958-4397797",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121337,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100975850",
          sale_order_date: "2021-09-15",
          sale_order_total: 35,
          shipping_address_street: "49 Ashcraft Cir",
          shipping_address_street_2: "Apt 405",
          shipping_address_id: 8363608,
          shipping_address_name: "Diane Johnson",
          shipping_address_zip: "29585-7176",
          shipping_address_city: "Pawleys Island",
          shipping_address_state: "South Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100975850-0",
          stock_picking_id: 3115958,
          total_price: 61.25,
          shipping_batch_id: -1,
          date: "2021-09-16 01:34:44",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "blk/diane johnson/49 ashcraft cir/apt 405/pawleys island/south carolina/29585-7176/united states",
          stock_move_is_set: false,
          stock_move_id: 78186804,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-F-11",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397797,
          sale_order_line_description: "X-SS RG AMY CUSH WDIA",
          sale_order_line_customer_cost: "61.25",
          sale_order_line_vendor_sku: "075000450674",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0LWJ-Y9F1",
          product_product_name:
            "0.04 CT Diamond TW And 4/5 CT TGW Amethyst Fashion Ring Silver I3",
          product_id: 90192,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LWJ-Y9F1.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694262",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:07.918Z",
        updatedAt: "2021-09-16T03:00:07.918Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "F", bin: "70" },
        price: 42.97,
        notes: [],
        isSet: false,
        _id: "6142d2d85182ab01afa6eb81",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363743",
            street: "21 S. New Ardmore Ave.",
            name: "Jennifer Frascella",
            zip: "19008",
            city: "Broomall",
            country: "United States",
            state: "Pennsylvania",
          },
          orderValue: 42.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d2d85182ab01afa6eb81"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d85182abbd63a6eb7c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121411,
          orderNo: "HAU66354557-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66354557",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363743,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552340",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/jennifer frascella/21 s. new ardmore ave.//broomall/pennsylvania/19008/united states",
          createdAt: "2021-09-16T05:15:04.074Z",
          updatedAt: "2021-09-16T05:15:04.236Z",
          labelFile: "5121411/labelFile.txt",
          zplFile: "5121411/zplFile.txt",
          pdfFile: "5121411/pdfFile.txt",
          addressString:
            "21snewardmoreavebroomallpennsylvania19008unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "HAU66354557-0",
        sku: "FC0T2V-GXKV",
        binLocation: "04-F-70",
        boxCode: "",
        stockPickingId: "3116027",
        uniqueIdentifier: "5121411-3116027-4397885",
        stockMoveSize: "5.5",
        orderLineSize: "5.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121411,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66354557",
          sale_order_date: "2021-09-15",
          sale_order_total: 24,
          shipping_address_street: "21 S. New Ardmore Ave.",
          shipping_address_street_2: "",
          shipping_address_id: 8363743,
          shipping_address_name: "Jennifer Frascella",
          shipping_address_zip: "19008",
          shipping_address_city: "Broomall",
          shipping_address_state: "Pennsylvania",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66354557-0",
          stock_picking_id: 3116027,
          total_price: 42.97,
          shipping_batch_id: -1,
          date: "2021-09-16 03:46:17",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "hau/jennifer frascella/21 s. new ardmore ave.//broomall/pennsylvania/19008/united states",
          stock_move_is_set: false,
          stock_move_id: 78186907,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-F-70",
          stock_move_uom: "PCE",
          stock_move_size: "5.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397885,
          sale_order_line_description:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sap",
          sale_order_line_customer_cost: "42.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 4,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "5.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552340",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:15:04.225Z",
        updatedAt: "2021-09-16T05:15:04.226Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "K", bin: "52" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "6142b3385182ab33dba6e9a7",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363607",
            street: "141 Erwin Cir",
            name: "Lynn Pater",
            zip: "29684-9127",
            city: "Starr",
            country: "United States",
            state: "South Carolina",
          },
          orderValue: 78.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3385182ab33dba6e9a7"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3385182ab144da6e9a2",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121336,
          orderNo: "BLK100976721-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100976721",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363607,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694284",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/lynn pater/141 erwin cir//starr/south carolina/29684-9127/united states",
          createdAt: "2021-09-16T03:00:08.474Z",
          updatedAt: "2021-09-16T03:00:08.630Z",
          labelFile: "5121336/labelFile.txt",
          zplFile: "5121336/zplFile.txt",
          pdfFile: "5121336/pdfFile.txt",
          addressString: "141erwincirstarrsouthcarolina296849127unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ECFKT208",
        productName:
          "10K 8-8.5mm Freshwater Cultured Round White Pearl Earrings",
        productImage: "https://partner.delmarintl.ca/catalog/ECFKT208.jpg",
        reference: "BLK100976721-0",
        sku: "ECFKT208",
        binLocation: "04-K-52",
        boxCode: "",
        stockPickingId: "3115960",
        uniqueIdentifier: "5121336-3115960-4397798",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121336,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100976721",
          sale_order_date: "2021-09-15",
          sale_order_total: 35,
          shipping_address_street: "141 Erwin Cir",
          shipping_address_street_2: null,
          shipping_address_id: 8363607,
          shipping_address_name: "Lynn Pater",
          shipping_address_zip: "29684-9127",
          shipping_address_city: "Starr",
          shipping_address_state: "South Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100976721-0",
          stock_picking_id: 3115960,
          total_price: 78.75,
          shipping_batch_id: -1,
          date: "2021-09-16 01:34:44",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "blk/lynn pater/141 erwin cir//starr/south carolina/29684-9127/united states",
          stock_move_is_set: false,
          stock_move_id: 78186807,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-K-52",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397798,
          sale_order_line_description: "FWP STUD ER 10KY DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692401280",
          sale_order_line_size_id: null,
          product_product_default_code: "ECFKT208",
          product_product_name:
            "10K 8-8.5mm Freshwater Cultured Round White Pearl Earrings",
          product_id: 72952,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ECFKT208.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694284",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:08.615Z",
        updatedAt: "2021-09-16T03:00:08.616Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "L", bin: "45" },
        price: 95.99,
        notes: [],
        isSet: false,
        _id: "6142c1585182ab8124a6e9fa",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363629",
            street: "5495 Monterey Circle, Unit 6",
            name: "Megan Schweitzer",
            zip: "33484",
            city: "Delray Beach",
            country: "United States",
            state: "Florida",
          },
          orderValue: 95.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c1585182ab8124a6e9fa"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c1575182abd377a6e9f5",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121347,
          orderNo: "OS355678256-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355678256",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363629,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256269",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/megan schweitzer/5495 monterey circle/unit 6/delray beach/florida/33484/united states",
          createdAt: "2021-09-16T04:00:23.961Z",
          updatedAt: "2021-09-16T04:00:24.176Z",
          labelFile: "5121347/labelFile.txt",
          zplFile: "5121347/zplFile.txt",
          pdfFile: "5121347/pdfFile.txt",
          addressString:
            "5495montereycircleunit6delraybeachflorida33484unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0LG2-PV62",
        productName: "1/8 CT Diamond TW Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LG2-PV62.jpg",
        reference: "OS355678256-0",
        sku: "FC0LG2-PV62",
        binLocation: "04-L-45",
        boxCode: "CRD-MIADORA:PC-MIADORA:GM5B",
        stockPickingId: "3115969",
        uniqueIdentifier: "5121347-3115969-4397810",
        stockMoveSize: "7.5",
        orderLineSize: "7.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121347,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355678256",
          sale_order_date: "2021-09-15",
          sale_order_total: 62,
          shipping_address_street: "5495 Monterey Circle",
          shipping_address_street_2: "Unit 6",
          shipping_address_id: 8363629,
          shipping_address_name: "Megan Schweitzer",
          shipping_address_zip: "33484",
          shipping_address_city: "Delray Beach",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355678256-0",
          stock_picking_id: 3115969,
          total_price: 95.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:04:29",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "os/megan schweitzer/5495 monterey circle/unit 6/delray beach/florida/33484/united states",
          stock_move_is_set: false,
          stock_move_id: 78186823,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-L-45",
          stock_move_uom: "PCE",
          stock_move_size: "7.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397810,
          sale_order_line_description: "7.5",
          sale_order_line_customer_cost: "95.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 8,
          product_product_default_code: "FC0LG2-PV62",
          product_product_name: "1/8 CT Diamond TW Fashion Ring Silver I3",
          product_id: 89442,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA:GM5B",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LG2-PV62.jpg",
          sale_order_line_size: "7.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256269",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:24.163Z",
        updatedAt: "2021-09-16T04:00:24.163Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "B", bin: "68" },
        price: 457.49,
        notes: [],
        isSet: false,
        _id: "6142b3375182ab4245a6e972",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363594",
            street: "406 S. Morgan St.",
            name: "Letecia Cambron",
            zip: "42437",
            city: "Morganfield",
            country: "United States",
            state: "Kentucky",
          },
          orderValue: 457.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3375182ab4245a6e972"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3365182abd407a6e96d",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121328,
          orderNo: "OS355673209-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355673209",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363594,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256276",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/letecia cambron/406 s. morgan st.//morganfield/kentucky/42437/united states",
          createdAt: "2021-09-16T03:00:06.876Z",
          updatedAt: "2021-09-16T03:00:07.080Z",
          labelFile: "5121328/labelFile.txt",
          zplFile: "5121328/zplFile.txt",
          pdfFile: "5121328/pdfFile.txt",
          addressString: "406smorganstmorganfieldkentucky42437unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1XTV-5AEY",
        productName:
          "0.06 CT Diamond TW And 2 CT TGW Diffused Sapphire White Sapphire Fashion Ring 10k White Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1XTV-5AEY.jpg",
        reference: "OS355673209-0",
        sku: "FC1XTV-5AEY",
        binLocation: "05-B-68",
        boxCode: "",
        stockPickingId: "3115954",
        uniqueIdentifier: "5121328-3115954-4397793",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121328,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355673209",
          sale_order_date: "2021-09-15",
          sale_order_total: 338,
          shipping_address_street: "406 S. Morgan St.",
          shipping_address_street_2: null,
          shipping_address_id: 8363594,
          shipping_address_name: "Letecia Cambron",
          shipping_address_zip: "42437",
          shipping_address_city: "Morganfield",
          shipping_address_state: "Kentucky",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355673209-0",
          stock_picking_id: 3115954,
          total_price: 457.49,
          shipping_batch_id: -1,
          date: "2021-09-16 01:22:08",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "os/letecia cambron/406 s. morgan st.//morganfield/kentucky/42437/united states",
          stock_move_is_set: false,
          stock_move_id: 78186801,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "05-B-68",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397793,
          sale_order_line_description: "6",
          sale_order_line_customer_cost: "457.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 5,
          product_product_default_code: "FC1XTV-5AEY",
          product_product_name:
            "0.06 CT Diamond TW And 2 CT TGW Diffused Sapphire White Sapphire Fashion Ring 10k White Gold GH I2;I3",
          product_id: 3102603,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1XTV-5AEY.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256276",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T03:00:07.065Z",
        updatedAt: "2021-09-16T03:00:07.066Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "C", bin: "25" },
        price: 67.14,
        notes: [],
        isSet: false,
        _id: "6142e1775182ab9a5da6ec4b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363805",
            street: "51 Friars Point Rd, Apt F",
            name: "Andrew Gilliand",
            zip: "38305",
            city: "Jackson",
            country: "United States",
            state: "Tennessee",
          },
          orderValue: 67.14,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142e1775182ab9a5da6ec4b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142e1775182abd948a6ec46",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121444,
          orderNo: "OS355692340-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355692340",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363805,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256566",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/andrew gilliand/51 friars point rd/apt f/jackson/tennessee/38305/united states",
          createdAt: "2021-09-16T06:17:27.636Z",
          updatedAt: "2021-09-16T06:17:27.895Z",
          labelFile: "5121444/labelFile.txt",
          zplFile: "5121444/zplFile.txt",
          pdfFile: "5121444/pdfFile.txt",
          addressString: "51friarspointrdaptfjacksontennessee38305unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0U7F-9Y71",
        productName:
          "0.06 CT Diamond TW And 1/4 CT TGW Created White Sapphire Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0U7F-9Y71.jpg",
        reference: "OS355692340-0",
        sku: "FC0U7F-9Y71",
        binLocation: "05-C-25",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116059",
        uniqueIdentifier: "5121444-3116059-4397923",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121444,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355692340",
          sale_order_date: "2021-09-15",
          sale_order_total: 58,
          shipping_address_street: "51 Friars Point Rd",
          shipping_address_street_2: "Apt F",
          shipping_address_id: 8363805,
          shipping_address_name: "Andrew Gilliand",
          shipping_address_zip: "38305",
          shipping_address_city: "Jackson",
          shipping_address_state: "Tennessee",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355692340-0",
          stock_picking_id: 3116059,
          total_price: 67.14,
          shipping_batch_id: -1,
          date: "2021-09-16 04:45:41",
          picking_write_date: "2021-09-16 06:15:07",
          address_grp_combined:
            "os/andrew gilliand/51 friars point rd/apt f/jackson/tennessee/38305/united states",
          stock_move_is_set: false,
          stock_move_id: 78186955,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "05-C-25",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397923,
          sale_order_line_description: "9",
          sale_order_line_customer_cost: "67.14",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 11,
          product_product_default_code: "FC0U7F-9Y71",
          product_product_name:
            "0.06 CT Diamond TW And 1/4 CT TGW Created White Sapphire Fashion Ring Silver GH I2;I3",
          product_id: 96442,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0U7F-9Y71.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256566",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T06:17:27.882Z",
        updatedAt: "2021-09-16T06:17:27.883Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "G", bin: "08" },
        price: 134.99,
        notes: [],
        isSet: false,
        _id: "6142d9e15182ab99f3a6ebe7",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363776",
            street: "3417 E 200  N",
            name: "Matthew Crabb",
            zip: "46952-8772",
            city: "Marion",
            country: "United States",
            state: "Indiana",
          },
          orderValue: 134.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e15182ab99f3a6ebe7"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e15182abda68a6ebe2",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121429,
          orderNo: "GVX2784382-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2784382-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363776,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256597",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:45:05.611Z",
          updatedAt: "2021-09-16T05:45:05.775Z",
          labelFile: "5121429/labelFile.txt",
          zplFile: "5121429/zplFile.txt",
          pdfFile: "5121429/pdfFile.txt",
          addressString: "3417e200nmarionindiana469528772unitedstates",
          normalizedCustomerName: "govx",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0H0J-XZP7",
        productName: "0.07 CT Diamond TW Bridal Set Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0H0J-XZP7.jpg",
        reference: "GVX2784382-DEL01-0",
        sku: "FC0H0J-XZP7",
        binLocation: "05-G-08",
        boxCode: "",
        stockPickingId: "3116047",
        uniqueIdentifier: "5121429-3116047-4397910",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121429,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2784382-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 85,
          shipping_address_street: "3417 E 200  N",
          shipping_address_street_2: "",
          shipping_address_id: 8363776,
          shipping_address_name: "Matthew Crabb",
          shipping_address_zip: "46952-8772",
          shipping_address_city: "Marion",
          shipping_address_state: "Indiana",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2784382-DEL01-0",
          stock_picking_id: 3116047,
          total_price: 134.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:16:31",
          picking_write_date: "2021-09-16 04:41:16",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186940,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "05-G-08",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397910,
          sale_order_line_description:
            "Diamond Bridal Set in Sterling Silver - 8",
          sale_order_line_customer_cost: "134.99",
          sale_order_line_vendor_sku: "GVX005447-8",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0H0J-XZP7",
          product_product_name:
            "0.07 CT Diamond TW Bridal Set Ring Silver GH I2;I3",
          product_id: 84746,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0H0J-XZP7.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256597",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T05:45:05.763Z",
        updatedAt: "2021-09-16T05:45:05.763Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "H", bin: "23" },
        price: 183.75,
        notes: [],
        isSet: false,
        _id: "6142c15b5182abf61da6ea61",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363658",
            street: "4818 MORNINGSIDE DR",
            name: "linda christensen",
            zip: "27106-1708",
            city: "WINSTON SALEM",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 183.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15b5182abf61da6ea61"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15b5182ab8ebfa6ea5c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121362,
          orderNo: "BLK100979221-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100979221",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363658,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694310",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/linda christensen/4818 morningside dr//winston salem/north carolina/27106-1708/united states",
          createdAt: "2021-09-16T04:00:27.078Z",
          updatedAt: "2021-09-16T04:00:27.237Z",
          labelFile: "5121362/labelFile.txt",
          zplFile: "5121362/zplFile.txt",
          pdfFile: "5121362/pdfFile.txt",
          addressString:
            "4818morningsidedrwinstonsalemnorthcarolina271061708unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1GLL-GMWR",
        productName: "1/10 CT Diamond TW Bridal Set Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1GLL-GMWR.jpg",
        reference: "BLK100979221-0",
        sku: "FC1GLL-GMWR",
        binLocation: "05-H-23",
        boxCode: "",
        stockPickingId: "3115983",
        uniqueIdentifier: "5121362-3115983-4397831",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121362,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100979221",
          sale_order_date: "2021-09-15",
          sale_order_total: 81,
          shipping_address_street: "4818 MORNINGSIDE DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363658,
          shipping_address_name: "linda christensen",
          shipping_address_zip: "27106-1708",
          shipping_address_city: "WINSTON SALEM",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100979221-0",
          stock_picking_id: 3115983,
          total_price: 183.75,
          shipping_batch_id: -1,
          date: "2021-09-16 02:23:32",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "blk/linda christensen/4818 morningside dr//winston salem/north carolina/27106-1708/united states",
          stock_move_is_set: false,
          stock_move_id: 78186845,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "05-H-23",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397831,
          sale_order_line_description: "DIA FLG 3PC RG SS 8 DS",
          sale_order_line_customer_cost: "183.75",
          sale_order_line_vendor_sku: "686692363564",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC1GLL-GMWR",
          product_product_name:
            "1/10 CT Diamond TW Bridal Set Ring Silver GH I2;I3",
          product_id: 225226,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1GLL-GMWR.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694310",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T04:00:27.226Z",
        updatedAt: "2021-09-16T04:00:27.227Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "A", bin: "68" },
        price: 411.25,
        notes: [],
        isSet: false,
        _id: "6142f6015182ab0f52a6ed32",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363889",
            street: "3525 James Phillips Way",
            name: "Laura Reagan",
            zip: "37876-6122",
            city: "Sevierville",
            country: "United States",
            state: "Tennessee",
          },
          orderValue: 411.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6015182ab0f52a6ed32"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6015182ab3538a6ed2d",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121486,
          orderNo: "BLK100984946-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100984946",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363889,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694424",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/laura reagan/3525 james phillips way//sevierville/tennessee/37876-6122/united states",
          createdAt: "2021-09-16T07:45:05.714Z",
          updatedAt: "2021-09-16T07:45:05.860Z",
          labelFile: "5121486/labelFile.txt",
          zplFile: "5121486/zplFile.txt",
          pdfFile: "5121486/pdfFile.txt",
          addressString:
            "3525jamesphillipswayseviervilletennessee378766122unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC26M5-BRAX",
        productName: "1/3 CT Diamond TW Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC26M5-BRAX.jpg",
        reference: "BLK100984946-0",
        sku: "FC26M5-BRAX",
        binLocation: "06-A-68",
        boxCode: "",
        stockPickingId: "3116101",
        uniqueIdentifier: "5121486-3116101-4397973",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121486,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100984946",
          sale_order_date: "2021-09-15",
          sale_order_total: 183,
          shipping_address_street: "3525 James Phillips Way",
          shipping_address_street_2: null,
          shipping_address_id: 8363889,
          shipping_address_name: "Laura Reagan",
          shipping_address_zip: "37876-6122",
          shipping_address_city: "Sevierville",
          shipping_address_state: "Tennessee",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100984946-0",
          stock_picking_id: 3116101,
          total_price: 411.25,
          shipping_batch_id: -1,
          date: "2021-09-16 06:22:25",
          picking_write_date: "2021-09-16 06:41:09",
          address_grp_combined:
            "blk/laura reagan/3525 james phillips way//sevierville/tennessee/37876-6122/united states",
          stock_move_is_set: false,
          stock_move_id: 78187012,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-A-68",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397973,
          sale_order_line_description: "DIA BRID RG SET SS 6 DS",
          sale_order_line_customer_cost: "411.25",
          sale_order_line_vendor_sku: "686692377714",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC26M5-BRAX",
          product_product_name:
            "1/3 CT Diamond TW Fashion Ring Silver GH I2;I3",
          product_id: 3100589,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC26M5-BRAX.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694424",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:45:05.847Z",
        updatedAt: "2021-09-16T07:45:05.848Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "C", bin: "16" },
        price: 333.32,
        notes: [],
        isSet: false,
        _id: "6142fd095182ab1ff8a6edbe",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363916",
            street: "12456 222ND ST",
            name: "MARTHA PERAZA",
            zip: "90716",
            city: "HAWAIIAN GARDENS",
            country: "United States",
            state: "California",
          },
          orderValue: 333.32,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd095182ab1ff8a6edbe"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd095182ab34d8a6edb9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121503,
          orderNo: "JCP479863035_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP479863035_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363916,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552500",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/martha peraza/12456 222nd st//hawaiian gardens/california/90716/united states",
          createdAt: "2021-09-16T08:15:05.535Z",
          updatedAt: "2021-09-16T08:15:05.684Z",
          labelFile: "5121503/labelFile.txt",
          zplFile: "5121503/zplFile.txt",
          pdfFile: "5121503/pdfFile.txt",
          addressString:
            "12456222ndsthawaiiangardenscalifornia90716unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1BX7-B24E",
        productName:
          "1/8 CT Diamond TW And 1 1/4 CT TGW Citrine Fashion Ring Yellow Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1BX7-B24E.jpg",
        reference: "JCP479863035_267-0",
        sku: "FC1BX7-B24E",
        binLocation: "06-C-16",
        boxCode: "",
        stockPickingId: "3116117",
        uniqueIdentifier: "5121503-3116117-4397989",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121503,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP479863035_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 91,
          shipping_address_street: "12456 222ND ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363916,
          shipping_address_name: "MARTHA PERAZA",
          shipping_address_zip: "90716",
          shipping_address_city: "HAWAIIAN GARDENS",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP479863035_267-0",
          stock_picking_id: 3116117,
          total_price: 333.32,
          shipping_batch_id: -1,
          date: "2021-09-16 06:54:55",
          picking_write_date: "2021-09-16 08:14:42",
          address_grp_combined:
            "jcp/martha peraza/12456 222nd st//hawaiian gardens/california/90716/united states",
          stock_move_is_set: false,
          stock_move_id: 78187031,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-C-16",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397989,
          sale_order_line_description: "COS CIT HALO RI",
          sale_order_line_customer_cost: "333.32",
          sale_order_line_vendor_sku: "JCP000902",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC1BX7-B24E",
          product_product_name:
            "1/8 CT Diamond TW And 1 1/4 CT TGW Citrine Fashion Ring Yellow Silver GH I2;I3",
          product_id: 227700,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1BX7-B24E.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552500",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:05.673Z",
        updatedAt: "2021-09-16T08:15:05.674Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "C", bin: "37" },
        price: 625,
        notes: [],
        isSet: false,
        _id: "61429a975182ab0c08a6e865",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363493",
            street: "18451 Benns Church Blvd.",
            name: "Leigh Ann Martin",
            zip: "23430",
            city: "Smithfield",
            country: "United States",
            state: "Virginia",
          },
          orderValue: 625,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61429a975182ab0c08a6e865"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61429a975182ab603ba6e860",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121276,
          orderNo: "KOH6179992106_1-0",
          customerId: 20,
          customerName: "Kohls",
          orderReference: "KOH6179992106_1",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363493,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400111895282849898471",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T01:15:03.434Z",
          updatedAt: "2021-09-16T01:15:03.586Z",
          labelFile: "5121276/labelFile.txt",
          zplFile: "5121276/zplFile.txt",
          pdfFile: "5121276/pdfFile.txt",
          addressString:
            "18451bennschurchblvdsmithfieldvirginia23430unitedstates",
          normalizedCustomerName: "kohls",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC29R3-RTYR",
        productName: "1/5 CT Diamond TW Bridal Set Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC29R3-RTYR.jpg",
        reference: "KOH6179992106_1-0",
        sku: "FC29R3-RTYR",
        binLocation: "06-C-37",
        boxCode: "",
        stockPickingId: "3115901",
        uniqueIdentifier: "5121276-3115901-4397721",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121276,
          sale_order_customer_id: 20,
          sale_order_customer_name: "Kohls",
          sale_order_order_reference: "KOH6179992106_1",
          sale_order_date: "2021-09-15",
          sale_order_total: 103,
          shipping_address_street: "18451 Benns Church Blvd.",
          shipping_address_street_2: "",
          shipping_address_id: 8363493,
          shipping_address_name: "Leigh Ann Martin",
          shipping_address_zip: "23430",
          shipping_address_city: "Smithfield",
          shipping_address_state: "Virginia",
          shipping_address_country: "United States",
          stock_picking_reference: "KOH6179992106_1-0",
          stock_picking_id: 3115901,
          total_price: 625,
          shipping_batch_id: -1,
          date: "2021-09-15 23:58:30",
          picking_write_date: "2021-09-16 01:14:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186719,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-C-37",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397721,
          sale_order_line_description:
            "1/5 CT Diamond TW Bridal Set Ring Silver I3",
          sale_order_line_customer_cost: "625.0",
          sale_order_line_vendor_sku: "70051318",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC29R3-RTYR",
          product_product_name: "1/5 CT Diamond TW Bridal Set Ring Silver I3",
          product_id: 3106835,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC29R3-RTYR.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400111895282849898471",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T01:15:03.574Z",
        updatedAt: "2021-09-16T01:15:03.575Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "M", bin: "14" },
        price: 267.49,
        notes: [],
        isSet: false,
        _id: "6142d9e05182abcdc4a6ebbf",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363749",
            street: "2527 Bosque Entrada Trl NW",
            name: "Erin Keener",
            zip: "87120",
            city: "Albuquerque",
            country: "United States",
            state: "New Mexico",
          },
          orderValue: 267.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e05182abcdc4a6ebbf"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e05182ab69f0a6ebba",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121415,
          orderNo: "OS355687920-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355687920",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363749,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256528",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/erin keener/2527 bosque entrada trl nw//albuquerque/new mexico/87120/united states",
          createdAt: "2021-09-16T05:45:04.282Z",
          updatedAt: "2021-09-16T05:45:04.436Z",
          labelFile: "5121415/labelFile.txt",
          zplFile: "5121415/zplFile.txt",
          pdfFile: "5121415/pdfFile.txt",
          addressString:
            "2527bosqueentradatrlnwalbuquerquenewmexico87120unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0M53-2ZW1",
        productName:
          "1/5 CT Diamond TW Semi-Eternity Ring 10k White Gold GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0M53-2ZW1.jpg",
        reference: "OS355687920-0",
        sku: "FC0M53-2ZW1",
        binLocation: "06-M-14",
        boxCode: "GW53:CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116031",
        uniqueIdentifier: "5121415-3116031-4397890",
        stockMoveSize: "5.0",
        orderLineSize: "5.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121415,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355687920",
          sale_order_date: "2021-09-15",
          sale_order_total: 181,
          shipping_address_street: "2527 Bosque Entrada Trl NW",
          shipping_address_street_2: null,
          shipping_address_id: 8363749,
          shipping_address_name: "Erin Keener",
          shipping_address_zip: "87120",
          shipping_address_city: "Albuquerque",
          shipping_address_state: "New Mexico",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355687920-0",
          stock_picking_id: 3116031,
          total_price: 267.49,
          shipping_batch_id: -1,
          date: "2021-09-16 04:04:31",
          picking_write_date: "2021-09-16 04:41:25",
          address_grp_combined:
            "os/erin keener/2527 bosque entrada trl nw//albuquerque/new mexico/87120/united states",
          stock_move_is_set: false,
          stock_move_id: 78186922,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-M-14",
          stock_move_uom: "PCE",
          stock_move_size: "5.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397890,
          sale_order_line_description: "5-White",
          sale_order_line_customer_cost: "267.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 3,
          product_product_default_code: "FC0M53-2ZW1",
          product_product_name:
            "1/5 CT Diamond TW Semi-Eternity Ring 10k White Gold GH I3",
          product_id: 106447,
          ship2store: false,
          is_no_resize: null,
          box_code: "GW53:CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0M53-2ZW1.jpg",
          sale_order_line_size: "5.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256528",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T05:45:04.424Z",
        updatedAt: "2021-09-16T05:45:04.425Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "O", bin: "54" },
        price: 312.48,
        notes: [],
        isSet: false,
        _id: "6142c4c95182abe267a6eaa2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363679",
            street: "8081NW 11TH STREET, 9A",
            name: "CHRIS COVERT",
            zip: "33063",
            city: "MARGATE",
            country: "United States",
            state: "Florida",
          },
          orderValue: 312.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4c95182abe267a6eaa2"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4c95182ab1841a6ea9d",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121372,
          orderNo: "JCP226002374_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP226002374_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363679,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552288",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/chris covert/8081nw 11th street/9a/margate/florida/33063/united states",
          createdAt: "2021-09-16T04:15:05.708Z",
          updatedAt: "2021-09-16T04:15:05.928Z",
          labelFile: "5121372/labelFile.txt",
          zplFile: "5121372/zplFile.txt",
          pdfFile: "5121372/pdfFile.txt",
          addressString: "8081nw11thstreet9amargateflorida33063unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC08CC-GAE7",
        productName:
          "0.07 CT Diamond TW And 1 1/7 CT TGW Morganite Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC08CC-GAE7.jpg",
        reference: "JCP226002374_267-0",
        sku: "FC08CC-GAE7",
        binLocation: "06-O-54",
        boxCode: "",
        stockPickingId: "3115995",
        uniqueIdentifier: "5121372-3115995-4397843",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121372,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP226002374_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 118,
          shipping_address_street: "8081NW 11TH STREET",
          shipping_address_street_2: "9A",
          shipping_address_id: 8363679,
          shipping_address_name: "CHRIS COVERT",
          shipping_address_zip: "33063",
          shipping_address_city: "MARGATE",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP226002374_267-0",
          stock_picking_id: 3115995,
          total_price: 312.48,
          shipping_batch_id: -1,
          date: "2021-09-16 02:42:02",
          picking_write_date: "2021-09-16 03:11:44",
          address_grp_combined:
            "jcp/chris covert/8081nw 11th street/9a/margate/florida/33063/united states",
          stock_move_is_set: false,
          stock_move_id: 78186863,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-O-54",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397843,
          sale_order_line_description: "Rings",
          sale_order_line_customer_cost: "312.48",
          sale_order_line_vendor_sku: "7500055813",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC08CC-GAE7",
          product_product_name:
            "0.07 CT Diamond TW And 1 1/7 CT TGW Morganite Fashion Ring Silver GH I2;I3",
          product_id: 206678,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC08CC-GAE7.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552288",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T04:15:05.914Z",
        updatedAt: "2021-09-16T04:15:05.915Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "R", bin: "23" },
        price: 167.49,
        notes: [],
        isSet: false,
        _id: "6143008a5182ab34e3a6edcb",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363901",
            street: "824 E Laurel Ave",
            name: "Christina Cooley",
            zip: "36535",
            city: "Foley",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 167.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143008a5182ab34e3a6edcb"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143008a5182abfe51a6edc6",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121492,
          orderNo: "OS355695816-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355695816",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363901,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256719",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/christina cooley/824 e laurel ave//foley/alabama/36535/united states",
          createdAt: "2021-09-16T08:30:02.595Z",
          updatedAt: "2021-09-16T08:30:02.849Z",
          labelFile: "5121492/labelFile.txt",
          zplFile: "5121492/zplFile.txt",
          pdfFile: "5121492/pdfFile.txt",
          addressString: "824elaurelavefoleyalabama36535unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RWKT4071BW",
        productName:
          "5.5 - 6 MM White Black Freshwater Cultured Pearl Fashion Ring 10k Yellow Gold",
        productImage: "https://partner.delmarintl.ca/catalog/RWKT4071BW.jpg",
        reference: "OS355695816-0",
        sku: "RWKT4071BW",
        binLocation: "06-R-23",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116107",
        uniqueIdentifier: "5121492-3116107-4397977",
        stockMoveSize: "5.5",
        orderLineSize: "5.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121492,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355695816",
          sale_order_date: "2021-09-15",
          sale_order_total: 118,
          shipping_address_street: "824 E Laurel Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363901,
          shipping_address_name: "Christina Cooley",
          shipping_address_zip: "36535",
          shipping_address_city: "Foley",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355695816-0",
          stock_picking_id: 3116107,
          total_price: 167.49,
          shipping_batch_id: -1,
          date: "2021-09-16 06:44:18",
          picking_write_date: "2021-09-16 08:14:49",
          address_grp_combined:
            "os/christina cooley/824 e laurel ave//foley/alabama/36535/united states",
          stock_move_is_set: false,
          stock_move_id: 78187018,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-R-23",
          stock_move_uom: "PCE",
          stock_move_size: "5.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397977,
          sale_order_line_description: "5.5",
          sale_order_line_customer_cost: "167.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 4,
          product_product_default_code: "RWKT4071BW",
          product_product_name:
            "5.5 - 6 MM White Black Freshwater Cultured Pearl Fashion Ring 10k Yellow Gold",
          product_id: 61776,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RWKT4071BW.jpg",
          sale_order_line_size: "5.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256719",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T08:30:02.821Z",
        updatedAt: "2021-09-16T08:30:02.822Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "T", bin: "25" },
        price: 105,
        notes: [],
        isSet: false,
        _id: "6142857f5182ab3fb6a6e76b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363433",
            street: "709 EARLY WALDEN RD",
            name: "Mandy Littlefield",
            zip: "36345-9475",
            city: "HEADLAND",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 105,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142857f5182ab3fb6a6e76b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142857f5182ab1e1da6e766",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121240,
          orderNo: "BLK100971140-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100971140",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363433,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694192",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/mandy littlefield/709 early walden rd//headland/alabama/36345-9475/united states",
          createdAt: "2021-09-15T23:45:03.582Z",
          updatedAt: "2021-09-15T23:45:03.856Z",
          labelFile: "5121240/labelFile.txt",
          zplFile: "5121240/zplFile.txt",
          pdfFile: "5121240/pdfFile.txt",
          addressString: "709earlywaldenrdheadlandalabama363459475unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0LX3-CJV9",
        productName:
          "0.06 CT Diamond TW And 1 CT TGW Aquamarine Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LX3-CJV9.jpg",
        reference: "BLK100971140-0",
        sku: "FC0LX3-CJV9",
        binLocation: "06-T-25",
        boxCode: "",
        stockPickingId: "3115869",
        uniqueIdentifier: "5121240-3115869-4397682",
        stockMoveSize: "5.0",
        orderLineSize: "5.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121240,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100971140",
          sale_order_date: "2021-09-15",
          sale_order_total: 53,
          shipping_address_street: "709 EARLY WALDEN RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363433,
          shipping_address_name: "Mandy Littlefield",
          shipping_address_zip: "36345-9475",
          shipping_address_city: "HEADLAND",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100971140-0",
          stock_picking_id: 3115869,
          total_price: 105,
          shipping_batch_id: -1,
          date: "2021-09-15 22:21:52",
          picking_write_date: "2021-09-15 22:40:44",
          address_grp_combined:
            "blk/mandy littlefield/709 early walden rd//headland/alabama/36345-9475/united states",
          stock_move_is_set: false,
          stock_move_id: 78186675,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-T-25",
          stock_move_uom: "PCE",
          stock_move_size: "5.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397682,
          sale_order_line_description: "X-RNG AQ E/C W/DIA SZ5",
          sale_order_line_customer_cost: "105.0",
          sale_order_line_vendor_sku: "075000450711",
          sale_order_line_size_id: 3,
          product_product_default_code: "FC0LX3-CJV9",
          product_product_name:
            "0.06 CT Diamond TW And 1 CT TGW Aquamarine Fashion Ring Silver I3",
          product_id: 90186,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LX3-CJV9.jpg",
          sale_order_line_size: "5.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694192",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T23:45:03.843Z",
        updatedAt: "2021-09-15T23:45:03.843Z",
        __v: 0,
      },
      {
        stockBin: { row: "06", section: "T", bin: "47" },
        price: 61.25,
        notes: [],
        isSet: false,
        _id: "6142c15b5182ab7210a6ea7b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363656",
            street: "500 LAMPLIGHTER LN",
            name: "Frances Scruggs",
            zip: "35214-4654",
            city: "BIRMINGHAM",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 61.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15b5182ab7210a6ea7b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15b5182ab2acaa6ea76",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121360,
          orderNo: "BLK100979409-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100979409",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363656,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694321",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/frances scruggs/500 lamplighter ln//birmingham/alabama/35214-4654/united states",
          createdAt: "2021-09-16T04:00:27.742Z",
          updatedAt: "2021-09-16T04:00:27.934Z",
          labelFile: "5121360/labelFile.txt",
          zplFile: "5121360/zplFile.txt",
          pdfFile: "5121360/pdfFile.txt",
          addressString:
            "500lamplighterlnbirminghamalabama352144654unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC115J-9WAR",
        productName: "1 1/3 CT TGW Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC115J-9WAR.jpg",
        reference: "BLK100979409-0",
        sku: "FC115J-9WAR",
        binLocation: "06-T-47",
        boxCode: "",
        stockPickingId: "3115985",
        uniqueIdentifier: "5121360-3115985-4397833",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121360,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100979409",
          sale_order_date: "2021-09-15",
          sale_order_total: 30,
          shipping_address_street: "500 LAMPLIGHTER LN",
          shipping_address_street_2: null,
          shipping_address_id: 8363656,
          shipping_address_name: "Frances Scruggs",
          shipping_address_zip: "35214-4654",
          shipping_address_city: "BIRMINGHAM",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100979409-0",
          stock_picking_id: 3115985,
          total_price: 61.25,
          shipping_batch_id: -1,
          date: "2021-09-16 02:23:33",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "blk/frances scruggs/500 lamplighter ln//birmingham/alabama/35214-4654/united states",
          stock_move_is_set: false,
          stock_move_id: 78186847,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-T-47",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397833,
          sale_order_line_description: "CWS BND RG SS 10 DS",
          sale_order_line_customer_cost: "61.25",
          sale_order_line_vendor_sku: "686692278295",
          sale_order_line_size_id: 13,
          product_product_default_code: "FC115J-9WAR",
          product_product_name:
            "1 1/3 CT TGW Created White Sapphire Fashion Ring Silver",
          product_id: 106785,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC115J-9WAR.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694321",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T04:00:27.923Z",
        updatedAt: "2021-09-16T04:00:27.924Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["01", "02", "03", "04", "05", "06"],
    _id: "6195ae14fc40c13f24ca03f9",
    createdAt: "2021-11-18T01:36:20.794Z",
    updatedAt: "2021-11-18T01:36:20.794Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 24,
    orderBins: {
      "6142857f5182ab1e1da6e766": "BLK100971140-0",
      "614289205182ab7cf4a6e7b0": "GVX2783847-DEL01-0",
      "61429a975182ab603ba6e860": "KOH6179992106_1-0",
      "6142b3365182abd407a6e96d": "OS355673209-0",
      "6142b3375182ab28f9a6e97b": "BLK100975989-0",
      "6142b3375182ab8ceea6e988": "BLK100975850-0",
      "6142b3385182ab144da6e9a2": "BLK100976721-0",
      "6142c1575182abd377a6e9f5": "OS355678256-0",
      "6142c15b5182ab2acaa6ea76": "BLK100979409-0",
      "6142c15b5182ab8ebfa6ea5c": "BLK100979221-0",
      "6142c4c95182ab1841a6ea9d": "JCP226002374_267-0",
      "6142c4ca5182ab0113a6eaaa": "JCP479860118_261-0",
      "6142c4ca5182ab4bf9a6eab7": "OS355679318-0",
      "6142c4cc5182ab40cba6eaf9": "JCP355660328_292-0",
      "6142cf7d5182ab2acfa6eb2e": "BLK100980377-0",
      "6142d2d85182abbd63a6eb7c": "HAU66354557-0",
      "6142d9e05182ab69f0a6ebba": "OS355687920-0",
      "6142d9e15182abda68a6ebe2": "GVX2784382-DEL01-0",
      "6142e1775182abd948a6ec46": "OS355692340-0",
      "6142f6015182ab3538a6ed2d": "BLK100984946-0",
      "6142f6025182ab4318a6ed47": "BLK100985146-0",
      "6142fd075182ab05eea6ed62": "HAU66355596-0",
      "6142fd095182ab34d8a6edb9": "JCP479863035_267-0",
      "6143008a5182abfe51a6edc6": "OS355695816-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "07", section: "A", bin: "10" },
        price: 225.49,
        notes: [],
        isSet: false,
        _id: "6142b3365182abc64ca6e964",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363596",
            street: "205 Austin Creek Ct",
            name: "Katrina Wrobleski",
            zip: "29483",
            city: "Summerville",
            country: "United States",
            state: "South Carolina",
          },
          orderValue: 225.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3365182abc64ca6e964"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3365182abee5da6e95f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121330,
          orderNo: "OS355671855-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355671855",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363596,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256214",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/katrina wrobleski/205 austin creek ct//summerville/south carolina/29483/united states",
          createdAt: "2021-09-16T03:00:06.389Z",
          updatedAt: "2021-09-16T03:00:06.555Z",
          labelFile: "5121330/labelFile.txt",
          zplFile: "5121330/zplFile.txt",
          pdfFile: "5121330/pdfFile.txt",
          addressString:
            "205austincreekctsummervillesouthcarolina29483unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0VPM-TUAL",
        productName:
          "0.05 CT Diamond TW And 1 3/5 CT TGW Blue Topaz - London Fashion Ring 10k White Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC0VPM-TUAL.jpg",
        reference: "OS355671855-0",
        sku: "FC0VPM-TUAL",
        binLocation: "07-A-10",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115953",
        uniqueIdentifier: "5121330-3115953-4397791",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121330,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355671855",
          sale_order_date: "2021-09-15",
          sale_order_total: 181,
          shipping_address_street: "205 Austin Creek Ct",
          shipping_address_street_2: null,
          shipping_address_id: 8363596,
          shipping_address_name: "Katrina Wrobleski",
          shipping_address_zip: "29483",
          shipping_address_city: "Summerville",
          shipping_address_state: "South Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355671855-0",
          stock_picking_id: 3115953,
          total_price: 225.49,
          shipping_batch_id: -1,
          date: "2021-09-16 01:22:08",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "os/katrina wrobleski/205 austin creek ct//summerville/south carolina/29483/united states",
          stock_move_is_set: false,
          stock_move_id: 78186800,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-A-10",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397791,
          sale_order_line_description: "8",
          sale_order_line_customer_cost: "225.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0VPM-TUAL",
          product_product_name:
            "0.05 CT Diamond TW And 1 3/5 CT TGW Blue Topaz - London Fashion Ring 10k White Gold GH I1;I2",
          product_id: 98060,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0VPM-TUAL.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256214",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T03:00:06.538Z",
        updatedAt: "2021-09-16T03:00:06.539Z",
        __v: 0,
      },
      {
        stockBin: { row: "07", section: "B", bin: "31" },
        price: 393.75,
        notes: [],
        isSet: false,
        _id: "6142d9e35182abf265a6ec24",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363794",
            street: "269 VALLEY POINT DR",
            name: "Randall Sexton",
            zip: "35466-3247",
            city: "GORDO",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 393.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e35182abf265a6ec24"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e35182abb8c4a6ec1f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121438,
          orderNo: "BLK100982106-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100982106",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363794,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694387",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/randall sexton/269 valley point dr//gordo/alabama/35466-3247/united states",
          createdAt: "2021-09-16T05:45:07.262Z",
          updatedAt: "2021-09-16T05:45:07.413Z",
          labelFile: "5121438/labelFile.txt",
          zplFile: "5121438/zplFile.txt",
          pdfFile: "5121438/pdfFile.txt",
          addressString: "269valleypointdrgordoalabama354663247unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0XHU-WB49",
        productName:
          "0.05 CT Diamond TW And 1 1/7 CT TGW Morganite Created White Sapphire 3 Stone Ring 10k Pink Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC0XHU-WB49.jpg",
        reference: "BLK100982106-0",
        sku: "FC0XHU-WB49",
        binLocation: "07-B-31",
        boxCode: "",
        stockPickingId: "3116053",
        uniqueIdentifier: "5121438-3116053-4397917",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121438,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100982106",
          sale_order_date: "2021-09-15",
          sale_order_total: 173,
          shipping_address_street: "269 VALLEY POINT DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363794,
          shipping_address_name: "Randall Sexton",
          shipping_address_zip: "35466-3247",
          shipping_address_city: "GORDO",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100982106-0",
          stock_picking_id: 3116053,
          total_price: 393.75,
          shipping_batch_id: -1,
          date: "2021-09-16 04:31:05",
          picking_write_date: "2021-09-16 04:41:02",
          address_grp_combined:
            "blk/randall sexton/269 valley point dr//gordo/alabama/35466-3247/united states",
          stock_move_is_set: false,
          stock_move_id: 78186948,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-B-31",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397917,
          sale_order_line_description: "MORG W CRTD WHT SAPPH W DIA 3STN RG",
          sale_order_line_customer_cost: "393.75",
          sale_order_line_vendor_sku: "686692115989",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0XHU-WB49",
          product_product_name:
            "0.05 CT Diamond TW And 1 1/7 CT TGW Morganite Created White Sapphire 3 Stone Ring 10k Pink Gold GH I1;I2",
          product_id: 111137,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0XHU-WB49.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694387",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:45:07.402Z",
        updatedAt: "2021-09-16T05:45:07.402Z",
        __v: 0,
      },
      {
        stockBin: { row: "07", section: "E", bin: "10" },
        price: 39.69,
        notes: [],
        isSet: false,
        _id: "614297175182ab8753a6e803",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363460",
            street: "5305 JILLSON ST",
            name: "ROCIO ATIENZO",
            zip: "90040",
            city: "COMMERCE",
            country: "United States",
            state: "California",
          },
          orderValue: 39.69,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614297175182ab8753a6e803"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297175182ab2d5da6e7fe",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121258,
          orderNo: "SAMS8775305624-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775305624",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363460,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594195",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T01:00:07.216Z",
          updatedAt: "2021-09-16T01:00:07.396Z",
          labelFile: "5121258/labelFile.txt",
          zplFile: "5121258/zplFile.txt",
          pdfFile: "5121258/pdfFile.txt",
          addressString: "5305jillsonstcommercecalifornia90040unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS3328GAWT",
        productName:
          "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
        reference: "SAMS8775305624-0",
        sku: "RGS3328GAWT",
        binLocation: "07-E-10",
        boxCode: "SCE",
        stockPickingId: "3115885",
        uniqueIdentifier: "5121258-3115885-4397700",
        stockMoveSize: "5.5",
        orderLineSize: "5.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121258,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775305624",
          sale_order_date: "2021-09-15",
          sale_order_total: 27,
          shipping_address_street: "5305 JILLSON ST",
          shipping_address_street_2: "",
          shipping_address_id: 8363460,
          shipping_address_name: "ROCIO ATIENZO",
          shipping_address_zip: "90040",
          shipping_address_city: "COMMERCE",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775305624-0",
          stock_picking_id: 3115885,
          total_price: 39.69,
          shipping_batch_id: -1,
          date: "2021-09-15 23:19:01",
          picking_write_date: "2021-09-16 00:45:06",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186698,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-E-10",
          stock_move_uom: "PCE",
          stock_move_size: "5.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397700,
          sale_order_line_description: "5.63 CT GEM RING 5.5",
          sale_order_line_customer_cost: "39.69",
          sale_order_line_vendor_sku: "SAMS00624/5.5",
          sale_order_line_size_id: 4,
          product_product_default_code: "RGS3328GAWT",
          product_product_name:
            "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
          product_id: 76957,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
          sale_order_line_size: "5.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594195",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:00:07.381Z",
        updatedAt: "2021-09-16T01:00:07.382Z",
        __v: 0,
      },
      {
        stockBin: { row: "07", section: "F", bin: "16" },
        price: 156.23,
        notes: [],
        isSet: false,
        _id: "6142b3355182abede6a6e93c",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363587",
            street: "203 CLOVER LN",
            name: "TIM FELSINGER",
            zip: "53073",
            city: "PLYMOUTH",
            country: "United States",
            state: "Wisconsin",
          },
          orderValue: 156.23,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3355182abede6a6e93c"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3345182ab6a08a6e937",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121325,
          orderNo: "JCP355657307_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355657307_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363587,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552242",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/tim felsinger/203 clover ln//plymouth/wisconsin/53073/united states",
          createdAt: "2021-09-16T03:00:04.967Z",
          updatedAt: "2021-09-16T03:00:05.293Z",
          labelFile: "5121325/labelFile.txt",
          zplFile: "5121325/zplFile.txt",
          pdfFile: "5121325/pdfFile.txt",
          addressString: "203cloverlnplymouthwisconsin53073unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0L97-7ZCH",
        productName:
          "1 4/5 CT TGW Amethyst Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L97-7ZCH.jpg",
        reference: "JCP355657307_267-0",
        sku: "FC0L97-7ZCH",
        binLocation: "07-F-16",
        boxCode: "",
        stockPickingId: "3115949",
        uniqueIdentifier: "5121325-3115949-4397787",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121325,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355657307_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 26.75,
          shipping_address_street: "203 CLOVER LN",
          shipping_address_street_2: null,
          shipping_address_id: 8363587,
          shipping_address_name: "TIM FELSINGER",
          shipping_address_zip: "53073",
          shipping_address_city: "PLYMOUTH",
          shipping_address_state: "Wisconsin",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355657307_267-0",
          stock_picking_id: 3115949,
          total_price: 156.23,
          shipping_batch_id: -1,
          date: "2021-09-16 01:09:49",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "jcp/tim felsinger/203 clover ln//plymouth/wisconsin/53073/united states",
          stock_move_is_set: false,
          stock_move_id: 78186796,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-F-16",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397787,
          sale_order_line_description: "SS AMET RING",
          sale_order_line_customer_cost: "156.23",
          sale_order_line_vendor_sku: "JCP004898",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC0L97-7ZCH",
          product_product_name:
            "1 4/5 CT TGW Amethyst Created White Sapphire Fashion Ring Silver",
          product_id: 88844,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L97-7ZCH.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552242",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T03:00:05.252Z",
        updatedAt: "2021-09-16T03:00:05.253Z",
        __v: 0,
      },
      {
        stockBin: { row: "07", section: "F", bin: "34" },
        price: 42.97,
        notes: [],
        isSet: false,
        _id: "6142d9e45182ab0d2ea6ec3e",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363810",
            street: "27721 Mahogany Row",
            name: "Delia Murillo",
            zip: "91351",
            city: "Canyon Country",
            country: "United States",
            state: "California",
          },
          orderValue: 42.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e45182ab0d2ea6ec3e"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e35182ab2beba6ec39",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121446,
          orderNo: "HAU66355115-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355115",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363810,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552386",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/delia murillo/27721 mahogany row//canyon country/california/91351/united states",
          createdAt: "2021-09-16T05:45:07.916Z",
          updatedAt: "2021-09-16T05:45:08.061Z",
          labelFile: "5121446/labelFile.txt",
          zplFile: "5121446/zplFile.txt",
          pdfFile: "5121446/pdfFile.txt",
          addressString:
            "27721mahoganyrowcanyoncountrycalifornia91351unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "HAU66355115-0",
        sku: "FC0T2V-GXKV",
        binLocation: "07-F-34",
        boxCode: "",
        stockPickingId: "3116061",
        uniqueIdentifier: "5121446-3116061-4397926",
        stockMoveSize: "4.5",
        orderLineSize: "4.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121446,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355115",
          sale_order_date: "2021-09-15",
          sale_order_total: 24,
          shipping_address_street: "27721 Mahogany Row",
          shipping_address_street_2: "",
          shipping_address_id: 8363810,
          shipping_address_name: "Delia Murillo",
          shipping_address_zip: "91351",
          shipping_address_city: "Canyon Country",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355115-0",
          stock_picking_id: 3116061,
          total_price: 42.97,
          shipping_batch_id: -1,
          date: "2021-09-16 04:46:48",
          picking_write_date: "2021-09-16 05:10:38",
          address_grp_combined:
            "hau/delia murillo/27721 mahogany row//canyon country/california/91351/united states",
          stock_move_is_set: false,
          stock_move_id: 78186958,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-F-34",
          stock_move_uom: "PCE",
          stock_move_size: "4.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397926,
          sale_order_line_description:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sap",
          sale_order_line_customer_cost: "42.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 2,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "4.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552386",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:45:08.051Z",
        updatedAt: "2021-09-16T05:45:08.051Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "D", bin: "19" },
        price: 61.25,
        notes: [],
        isSet: false,
        _id: "6142a1a25182ab4a46a6e8b8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363546",
            street: "108 Herrick Dr SW",
            name: "Dorothy Rowe",
            zip: "35824-2518",
            city: "Huntsville",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 61.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a1a25182ab4a46a6e8b8"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a1a25182ab28e2a6e8b3",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121304,
          orderNo: "BLK100975355-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100975355",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363546,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694251",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/dorothy rowe/108 herrick dr sw//huntsville/alabama/35824-2518/united states",
          createdAt: "2021-09-16T01:45:06.272Z",
          updatedAt: "2021-09-16T01:45:06.425Z",
          labelFile: "5121304/labelFile.txt",
          zplFile: "5121304/zplFile.txt",
          pdfFile: "5121304/pdfFile.txt",
          addressString: "108herrickdrswhuntsvillealabama358242518unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC115J-9WAR",
        productName: "1 1/3 CT TGW Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC115J-9WAR.jpg",
        reference: "BLK100975355-0",
        sku: "FC115J-9WAR",
        binLocation: "08-D-19",
        boxCode: "",
        stockPickingId: "3115928",
        uniqueIdentifier: "5121304-3115928-4397764",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121304,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100975355",
          sale_order_date: "2021-09-15",
          sale_order_total: 30,
          shipping_address_street: "108 Herrick Dr SW",
          shipping_address_street_2: null,
          shipping_address_id: 8363546,
          shipping_address_name: "Dorothy Rowe",
          shipping_address_zip: "35824-2518",
          shipping_address_city: "Huntsville",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100975355-0",
          stock_picking_id: 3115928,
          total_price: 61.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:32:45",
          picking_write_date: "2021-09-16 00:40:41",
          address_grp_combined:
            "blk/dorothy rowe/108 herrick dr sw//huntsville/alabama/35824-2518/united states",
          stock_move_is_set: false,
          stock_move_id: 78186763,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-D-19",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397764,
          sale_order_line_description: "CWS BND RG SS 8 DS",
          sale_order_line_customer_cost: "61.25",
          sale_order_line_vendor_sku: "686692278257",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC115J-9WAR",
          product_product_name:
            "1 1/3 CT TGW Created White Sapphire Fashion Ring Silver",
          product_id: 106785,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC115J-9WAR.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694251",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:06.413Z",
        updatedAt: "2021-09-16T01:45:06.414Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "F", bin: "02" },
        price: 312.48,
        notes: [],
        isSet: false,
        _id: "6142c15c5182ab135da6ea88",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363662",
            street: "44 CARY AVE",
            name: "KEVIN SCHROEDER",
            zip: "14125",
            city: "OAKFIELD",
            country: "United States",
            state: "New York",
          },
          orderValue: 312.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15c5182ab135da6ea88"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15c5182ab7545a6ea83",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121363,
          orderNo: "JCP226002213_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP226002213_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363662,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552260",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/kevin schroeder/44 cary ave//oakfield/new york/14125/united states",
          createdAt: "2021-09-16T04:00:28.110Z",
          updatedAt: "2021-09-16T04:00:28.257Z",
          labelFile: "5121363/labelFile.txt",
          zplFile: "5121363/zplFile.txt",
          pdfFile: "5121363/pdfFile.txt",
          addressString: "44caryaveoakfieldnewyork14125unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC08CC-GAE7",
        productName:
          "0.07 CT Diamond TW And 1 1/7 CT TGW Morganite Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC08CC-GAE7.jpg",
        reference: "JCP226002213_267-0",
        sku: "FC08CC-GAE7",
        binLocation: "08-F-02",
        boxCode: "",
        stockPickingId: "3115986",
        uniqueIdentifier: "5121363-3115986-4397834",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121363,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP226002213_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 118,
          shipping_address_street: "44 CARY AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363662,
          shipping_address_name: "KEVIN SCHROEDER",
          shipping_address_zip: "14125",
          shipping_address_city: "OAKFIELD",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP226002213_267-0",
          stock_picking_id: 3115986,
          total_price: 312.48,
          shipping_batch_id: -1,
          date: "2021-09-16 02:25:34",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "jcp/kevin schroeder/44 cary ave//oakfield/new york/14125/united states",
          stock_move_is_set: false,
          stock_move_id: 78186850,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-F-02",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397834,
          sale_order_line_description: "Rings",
          sale_order_line_customer_cost: "312.48",
          sale_order_line_vendor_sku: "7500055813",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC08CC-GAE7",
          product_product_name:
            "0.07 CT Diamond TW And 1 1/7 CT TGW Morganite Fashion Ring Silver GH I2;I3",
          product_id: 206678,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC08CC-GAE7.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552260",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T04:00:28.245Z",
        updatedAt: "2021-09-16T04:00:28.247Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "G", bin: "72" },
        price: 114.99,
        notes: [],
        isSet: false,
        _id: "6143040f5182abe699a6edf4",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363924",
            street: "467 Potomac St",
            name: "Cheryl A Spargo",
            zip: "89015",
            city: "Henderson",
            country: "United States",
            state: "Nevada",
          },
          orderValue: 114.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143040f5182abe699a6edf4"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143040e5182ab0287a6edef",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121508,
          orderNo: "OS355698185-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355698185",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363924,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256726",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/cheryl a spargo/467 potomac st//henderson/nevada/89015/united states",
          createdAt: "2021-09-16T08:45:02.810Z",
          updatedAt: "2021-09-16T08:45:03.037Z",
          labelFile: "5121508/labelFile.txt",
          zplFile: "5121508/zplFile.txt",
          pdfFile: "5121508/pdfFile.txt",
          addressString: "467potomacsthendersonnevada89015unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC07RM-60L3",
        productName:
          "0.04 CT Diamond TW And 3.45 CT TGW Ametrine Amethyst Fashion Ring Pink Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC07RM-60L3.jpg",
        reference: "OS355698185-0",
        sku: "FC07RM-60L3",
        binLocation: "08-G-72",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116122",
        uniqueIdentifier: "5121508-3116122-4397995",
        stockMoveSize: "5.0",
        orderLineSize: "5.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121508,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355698185",
          sale_order_date: "2021-09-15",
          sale_order_total: 74,
          shipping_address_street: "467 Potomac St",
          shipping_address_street_2: null,
          shipping_address_id: 8363924,
          shipping_address_name: "Cheryl A Spargo",
          shipping_address_zip: "89015",
          shipping_address_city: "Henderson",
          shipping_address_state: "Nevada",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355698185-0",
          stock_picking_id: 3116122,
          total_price: 114.99,
          shipping_batch_id: -1,
          date: "2021-09-16 07:22:49",
          picking_write_date: "2021-09-16 07:40:46",
          address_grp_combined:
            "os/cheryl a spargo/467 potomac st//henderson/nevada/89015/united states",
          stock_move_is_set: false,
          stock_move_id: 78187037,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-G-72",
          stock_move_uom: "PCE",
          stock_move_size: "5.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397995,
          sale_order_line_description: "5",
          sale_order_line_customer_cost: "114.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 3,
          product_product_default_code: "FC07RM-60L3",
          product_product_name:
            "0.04 CT Diamond TW And 3.45 CT TGW Ametrine Amethyst Fashion Ring Pink Silver GH I2;I3",
          product_id: 184775,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC07RM-60L3.jpg",
          sale_order_line_size: "5.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256726",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T08:45:03.022Z",
        updatedAt: "2021-09-16T08:45:03.023Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "H", bin: "19" },
        price: 208.32,
        notes: [],
        isSet: false,
        _id: "6142d2d95182abc4eaa6eba4",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363747",
            street: "555 ROSEMONT DR",
            name: "JEANNIE BERGERON",
            zip: "80911",
            city: "COLORADO SPRINGS",
            country: "United States",
            state: "Colorado",
          },
          orderValue: 208.32,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d2d95182abc4eaa6eba4"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d95182ab1898a6eb9f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121414,
          orderNo: "JCP479861161_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP479861161_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363747,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552359",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/jeannie bergeron/555 rosemont dr//colorado springs/colorado/80911/united states",
          createdAt: "2021-09-16T05:15:05.094Z",
          updatedAt: "2021-09-16T05:15:05.282Z",
          labelFile: "5121414/labelFile.txt",
          zplFile: "5121414/zplFile.txt",
          pdfFile: "5121414/pdfFile.txt",
          addressString:
            "555rosemontdrcoloradospringscolorado80911unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0U7G-1PXG",
        productName:
          "1/10 CT Diamond TW And 1/6 CT TGW Morganite Fashion Ring Pink Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0U7G-1PXG.jpg",
        reference: "JCP479861161_267-0",
        sku: "FC0U7G-1PXG",
        binLocation: "08-H-19",
        boxCode: "",
        stockPickingId: "3116030",
        uniqueIdentifier: "5121414-3116030-4397889",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121414,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP479861161_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 62,
          shipping_address_street: "555 ROSEMONT DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363747,
          shipping_address_name: "JEANNIE BERGERON",
          shipping_address_zip: "80911",
          shipping_address_city: "COLORADO SPRINGS",
          shipping_address_state: "Colorado",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP479861161_267-0",
          stock_picking_id: 3116030,
          total_price: 208.32,
          shipping_batch_id: -1,
          date: "2021-09-16 03:54:53",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "jcp/jeannie bergeron/555 rosemont dr//colorado springs/colorado/80911/united states",
          stock_move_is_set: false,
          stock_move_id: 78186921,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-H-19",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397889,
          sale_order_line_description: "Ring",
          sale_order_line_customer_cost: "208.32",
          sale_order_line_vendor_sku: "FC0U7G-1PX",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0U7G-1PXG",
          product_product_name:
            "1/10 CT Diamond TW And 1/6 CT TGW Morganite Fashion Ring Pink Silver GH I2;I3",
          product_id: 105663,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0U7G-1PXG.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552359",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:15:05.247Z",
        updatedAt: "2021-09-16T05:15:05.248Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "H", bin: "49" },
        price: 49.99,
        notes: [],
        isSet: false,
        _id: "614307955182ab90bfa6ee10",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363922",
            street: "3009 Eric Lane",
            name: "Veronica Shrum",
            zip: "75234",
            city: "Farmers Branch",
            country: "United States",
            state: "Texas",
          },
          orderValue: 49.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614307955182ab90bfa6ee10"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614307955182ab4428a6ee0b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121507,
          orderNo: "OS355695976-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355695976",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363922,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256771",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/veronica shrum/3009 eric lane//farmers branch/texas/75234/united states",
          createdAt: "2021-09-16T09:00:05.271Z",
          updatedAt: "2021-09-16T09:00:05.549Z",
          labelFile: "5121507/labelFile.txt",
          zplFile: "5121507/zplFile.txt",
          pdfFile: "5121507/pdfFile.txt",
          addressString: "3009ericlanefarmersbranchtexas75234unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS3328GAWT",
        productName:
          "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
        reference: "OS355695976-0",
        sku: "RGS3328GAWT",
        binLocation: "08-H-49",
        boxCode: "PC-MIADORA:CRD-MIADORA",
        stockPickingId: "3116121",
        uniqueIdentifier: "5121507-3116121-4397994",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121507,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355695976",
          sale_order_date: "2021-09-15",
          sale_order_total: 41,
          shipping_address_street: "3009 Eric Lane",
          shipping_address_street_2: null,
          shipping_address_id: 8363922,
          shipping_address_name: "Veronica Shrum",
          shipping_address_zip: "75234",
          shipping_address_city: "Farmers Branch",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355695976-0",
          stock_picking_id: 3116121,
          total_price: 49.99,
          shipping_batch_id: -1,
          date: "2021-09-16 07:22:42",
          picking_write_date: "2021-09-16 08:44:56",
          address_grp_combined:
            "os/veronica shrum/3009 eric lane//farmers branch/texas/75234/united states",
          stock_move_is_set: false,
          stock_move_id: 78187036,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-H-49",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397994,
          sale_order_line_description: "10",
          sale_order_line_customer_cost: "49.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 13,
          product_product_default_code: "RGS3328GAWT",
          product_product_name:
            "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
          product_id: 76957,
          ship2store: false,
          is_no_resize: null,
          box_code: "PC-MIADORA:CRD-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256771",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T09:00:05.530Z",
        updatedAt: "2021-09-16T09:00:05.531Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "P", bin: "41" },
        price: 541.65,
        notes: [],
        isSet: false,
        _id: "6142a5245182ab1175a6e8d2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363521",
            street: "9513 OAK HILL DR",
            name: "NINFA FUENTES",
            zip: "77318",
            city: "WILLIS",
            country: "United States",
            state: "Texas",
          },
          orderValue: 541.65,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a5245182ab1175a6e8d2"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a5245182ab0ea3a6e8cd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121290,
          orderNo: "JCP355656243_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355656243_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363521,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552224",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/ninfa fuentes/9513 oak hill dr//willis/texas/77318/united states",
          createdAt: "2021-09-16T02:00:04.092Z",
          updatedAt: "2021-09-16T02:00:04.418Z",
          labelFile: "5121290/labelFile.txt",
          zplFile: "5121290/zplFile.txt",
          pdfFile: "5121290/pdfFile.txt",
          addressString: "9513oakhilldrwillistexas77318unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC08EF-9VTP",
        productName:
          "0.02 CT Diamond TW And 4 1/6 CT TGW Created White Sapphire Citrine Fashion Ring 10k Yellow Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC08EF-9VTP.jpg",
        reference: "JCP355656243_267-0",
        sku: "FC08EF-9VTP",
        binLocation: "08-P-41",
        boxCode: "",
        stockPickingId: "3115915",
        uniqueIdentifier: "5121290-3115915-4397746",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121290,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355656243_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 169,
          shipping_address_street: "9513 OAK HILL DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363521,
          shipping_address_name: "NINFA FUENTES",
          shipping_address_zip: "77318",
          shipping_address_city: "WILLIS",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355656243_267-0",
          stock_picking_id: 3115915,
          total_price: 541.65,
          shipping_batch_id: -1,
          date: "2021-09-16 00:13:26",
          picking_write_date: "2021-09-16 01:44:45",
          address_grp_combined:
            "jcp/ninfa fuentes/9513 oak hill dr//willis/texas/77318/united states",
          stock_move_is_set: false,
          stock_move_id: 78186744,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-P-41",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397746,
          sale_order_line_description: "10K YG CIT RING",
          sale_order_line_customer_cost: "541.65",
          sale_order_line_vendor_sku: "JCP001448",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC08EF-9VTP",
          product_product_name:
            "0.02 CT Diamond TW And 4 1/6 CT TGW Created White Sapphire Citrine Fashion Ring 10k Yellow Gold GH I1;I2",
          product_id: 227682,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC08EF-9VTP.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552224",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T02:00:04.398Z",
        updatedAt: "2021-09-16T02:00:04.399Z",
        __v: 0,
      },
      {
        stockBin: { row: "08", section: "P", bin: "60" },
        price: 628.82,
        notes: [],
        isSet: false,
        _id: "6143040f5182ab5431a6ee02",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363923",
            street: "123 Glenn St",
            name: "Arthur Taylor",
            zip: "27520",
            city: "Clayton",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 628.82,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143040f5182ab5431a6ee02"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143040f5182abd57ca6edfd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121509,
          orderNo: "OS355697981-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355697981",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363923,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256795",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/arthur taylor/123 glenn st//clayton/north carolina/27520/united states",
          createdAt: "2021-09-16T08:45:03.549Z",
          updatedAt: "2021-09-16T08:45:03.719Z",
          labelFile: "5121509/labelFile.txt",
          zplFile: "5121509/zplFile.txt",
          pdfFile: "5121509/pdfFile.txt",
          addressString: "123glennstclaytonnorthcarolina27520unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2263-PK6W",
        productName:
          "1/10 CT Diamond TW And 2 CT TGW Created Moissanite-White Fashion Ring 14k Yellow Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC2263-PK6W.jpg",
        reference: "OS355697981-0",
        sku: "FC2263-PK6W",
        binLocation: "08-P-60",
        boxCode: "",
        stockPickingId: "3116123",
        uniqueIdentifier: "5121509-3116123-4397996",
        stockMoveSize: "9.5",
        orderLineSize: "9.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121509,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355697981",
          sale_order_date: "2021-09-15",
          sale_order_total: 417,
          shipping_address_street: "123 Glenn St",
          shipping_address_street_2: null,
          shipping_address_id: 8363923,
          shipping_address_name: "Arthur Taylor",
          shipping_address_zip: "27520",
          shipping_address_city: "Clayton",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355697981-0",
          stock_picking_id: 3116123,
          total_price: 628.82,
          shipping_batch_id: -1,
          date: "2021-09-16 07:22:51",
          picking_write_date: "2021-09-16 07:40:37",
          address_grp_combined:
            "os/arthur taylor/123 glenn st//clayton/north carolina/27520/united states",
          stock_move_is_set: false,
          stock_move_id: 78187038,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "08-P-60",
          stock_move_uom: "PCE",
          stock_move_size: "9.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397996,
          sale_order_line_description: "9.5",
          sale_order_line_customer_cost: "628.82",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 12,
          product_product_default_code: "FC2263-PK6W",
          product_product_name:
            "1/10 CT Diamond TW And 2 CT TGW Created Moissanite-White Fashion Ring 14k Yellow Gold GH I1;I2",
          product_id: 3103993,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2263-PK6W.jpg",
          sale_order_line_size: "9.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256795",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T08:45:03.709Z",
        updatedAt: "2021-09-16T08:45:03.710Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["07", "08"],
    _id: "6195ae14fc40c13f24ca03fa",
    createdAt: "2021-11-18T01:36:20.812Z",
    updatedAt: "2021-11-18T01:36:20.812Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 12,
    orderBins: {
      "614297175182ab2d5da6e7fe": "SAMS8775305624-0",
      "6142a1a25182ab28e2a6e8b3": "BLK100975355-0",
      "6142a5245182ab0ea3a6e8cd": "JCP355656243_267-0",
      "6142b3345182ab6a08a6e937": "JCP355657307_267-0",
      "6142b3365182abee5da6e95f": "OS355671855-0",
      "6142c15c5182ab7545a6ea83": "JCP226002213_267-0",
      "6142d2d95182ab1898a6eb9f": "JCP479861161_267-0",
      "6142d9e35182ab2beba6ec39": "HAU66355115-0",
      "6142d9e35182abb8c4a6ec1f": "BLK100982106-0",
      "6143040e5182ab0287a6edef": "OS355698185-0",
      "6143040f5182abd57ca6edfd": "OS355697981-0",
      "614307955182ab4428a6ee0b": "OS355695976-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "10", section: "A", bin: "47" },
        price: 58.995,
        notes: [],
        isSet: true,
        _id: "6142e1785182abbbcfa6ec59",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363804",
            street: "2316 Saint Marks Way",
            name: "Victoria Rivas",
            zip: "95864",
            city: "Sacramento",
            country: "United States",
            state: "California",
          },
          orderValue: 58.995,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142e1785182abbbcfa6ec59"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142e1785182ab2457a6ec54",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121443,
          orderNo: "OS355691183-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355691183",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363804,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256559",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/victoria rivas/2316 saint marks way//sacramento/california/95864/united states",
          createdAt: "2021-09-16T06:17:28.299Z",
          updatedAt: "2021-09-16T06:17:28.449Z",
          labelFile: "5121443/labelFile.txt",
          zplFile: "5121443/zplFile.txt",
          pdfFile: "5121443/pdfFile.txt",
          addressString:
            "2316saintmarkswaysacramentocalifornia95864unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 2,
        productCode: "FC0L5L-XEMV",
        productName: "1/5 CT Diamond TW Anniversary Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
        reference: "OS355691183-0",
        sku: "FC0L5L-XEMV",
        binLocation: "10-A-47",
        boxCode: "CRD-MIADORA:PC-MIADORA:GM5B",
        stockPickingId: "3116060",
        uniqueIdentifier: "5121443-3116060-4397925",
        stockMoveSize: "6.5",
        orderLineSize: "6.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121443,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355691183",
          sale_order_date: "2021-09-15",
          sale_order_total: 89,
          shipping_address_street: "2316 Saint Marks Way",
          shipping_address_street_2: null,
          shipping_address_id: 8363804,
          shipping_address_name: "Victoria Rivas",
          shipping_address_zip: "95864",
          shipping_address_city: "Sacramento",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355691183-0",
          stock_picking_id: 3116060,
          total_price: 117.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:46:35",
          picking_write_date: "2021-09-16 06:15:07",
          address_grp_combined:
            "os/victoria rivas/2316 saint marks way//sacramento/california/95864/united states",
          stock_move_is_set: true,
          stock_move_id: 78186956,
          stock_move_set_product_id: 467612,
          stock_move_quantity: 2,
          stock_move_bin: "10-A-47",
          stock_move_uom: "PCE",
          stock_move_size: "6.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397925,
          sale_order_line_description: "6.5",
          sale_order_line_customer_cost: "58.995",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 6,
          product_product_default_code: "FC0L5L-XEMV",
          product_product_name: "1/5 CT Diamond TW Anniversary Ring Silver I3",
          product_id: 90399,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA:GM5B",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
          sale_order_line_size: "6.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256559",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T06:17:28.437Z",
        updatedAt: "2021-09-16T06:17:28.438Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "B", bin: "03" },
        price: 253.75,
        notes: [],
        isSet: false,
        _id: "6142cf7e5182ab8140a6eb40",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363726",
            street: "9465 LAZY CIRCLES DR",
            name: "Melani McWhirter",
            zip: "37363-9320",
            city: "OOLTEWAH",
            country: "United States",
            state: "Tennessee",
          },
          orderValue: 253.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7e5182ab8140a6eb40"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7e5182ab40f8a6eb3b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121403,
          orderNo: "BLK100980200-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100980200",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363726,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694332",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/melani mcwhirter/9465 lazy circles dr//ooltewah/tennessee/37363-9320/united states",
          createdAt: "2021-09-16T05:00:46.057Z",
          updatedAt: "2021-09-16T05:00:46.204Z",
          labelFile: "5121403/labelFile.txt",
          zplFile: "5121403/zplFile.txt",
          pdfFile: "5121403/pdfFile.txt",
          addressString:
            "9465lazycirclesdrooltewahtennessee373639320unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RDGKTW5315A",
        productName:
          "0.03 CT Diamond TW And 1 2/5 CT TGW Amethyst Fashion Ring 10k White Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/RDGKTW5315A.jpg",
        reference: "BLK100980200-0",
        sku: "RDGKTW5315A",
        binLocation: "10-B-03",
        boxCode: "",
        stockPickingId: "3116020",
        uniqueIdentifier: "5121403-3116020-4397876",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121403,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100980200",
          sale_order_date: "2021-09-15",
          sale_order_total: 123,
          shipping_address_street: "9465 LAZY CIRCLES DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363726,
          shipping_address_name: "Melani McWhirter",
          shipping_address_zip: "37363-9320",
          shipping_address_city: "OOLTEWAH",
          shipping_address_state: "Tennessee",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100980200-0",
          stock_picking_id: 3116020,
          total_price: 253.75,
          shipping_batch_id: -1,
          date: "2021-09-16 03:28:41",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined:
            "blk/melani mcwhirter/9465 lazy circles dr//ooltewah/tennessee/37363-9320/united states",
          stock_move_is_set: false,
          stock_move_id: 78186900,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-B-03",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397876,
          sale_order_line_description: "X-RG AME BAG W/ DIA SIDES",
          sale_order_line_customer_cost: "253.75",
          sale_order_line_vendor_sku: "075000252131",
          sale_order_line_size_id: 5,
          product_product_default_code: "RDGKTW5315A",
          product_product_name:
            "0.03 CT Diamond TW And 1 2/5 CT TGW Amethyst Fashion Ring 10k White Gold GH I2;I3",
          product_id: 56111,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RDGKTW5315A.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694332",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:00:46.194Z",
        updatedAt: "2021-09-16T05:00:46.194Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "B", bin: "54" },
        price: 59.99,
        notes: [],
        isSet: false,
        _id: "6142c1585182abf755a6ea08",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363630",
            street: "212 N Main St",
            name: "Casey Conrad",
            zip: "14411",
            city: "Albion",
            country: "United States",
            state: "New York",
          },
          orderValue: 59.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c1585182abf755a6ea08"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c1585182ab56ada6ea03",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121350,
          orderNo: "OS355677750-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355677750",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363630,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256252",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/casey conrad/212 n main st//albion/new york/14411/united states",
          createdAt: "2021-09-16T04:00:24.495Z",
          updatedAt: "2021-09-16T04:00:24.662Z",
          labelFile: "5121350/labelFile.txt",
          zplFile: "5121350/zplFile.txt",
          pdfFile: "5121350/pdfFile.txt",
          addressString: "212nmainstalbionnewyork14411unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0W0X-PJRR",
        productName:
          "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
        reference: "OS355677750-0",
        sku: "FC0W0X-PJRR",
        binLocation: "10-B-54",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115972",
        uniqueIdentifier: "5121350-3115972-4397819",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121350,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355677750",
          sale_order_date: "2021-09-15",
          sale_order_total: 40,
          shipping_address_street: "212 N Main St",
          shipping_address_street_2: null,
          shipping_address_id: 8363630,
          shipping_address_name: "Casey Conrad",
          shipping_address_zip: "14411",
          shipping_address_city: "Albion",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355677750-0",
          stock_picking_id: 3115972,
          total_price: 59.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:05:53",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "os/casey conrad/212 n main st//albion/new york/14411/united states",
          stock_move_is_set: false,
          stock_move_id: 78186826,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-B-54",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397819,
          sale_order_line_description: "10",
          sale_order_line_customer_cost: "59.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 13,
          product_product_default_code: "FC0W0X-PJRR",
          product_product_name:
            "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 99326,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256252",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:24.638Z",
        updatedAt: "2021-09-16T04:00:24.639Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "B", bin: "54" },
        price: 34.97,
        notes: [],
        isSet: false,
        _id: "6142fd085182abc940a6eda4",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363910",
            street: "20 Spaulding Ave",
            name: "Shakira James",
            zip: "19320",
            city: "Coatesville",
            country: "United States",
            state: "Pennsylvania",
          },
          orderValue: 34.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd085182abc940a6eda4"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd085182ab4a77a6ed9f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121498,
          orderNo: "HAU66355695-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355695",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363910,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552475",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/shakira james/20 spaulding ave//coatesville/pennsylvania/19320/united states",
          createdAt: "2021-09-16T08:15:04.848Z",
          updatedAt: "2021-09-16T08:15:05.014Z",
          labelFile: "5121498/labelFile.txt",
          zplFile: "5121498/zplFile.txt",
          pdfFile: "5121498/pdfFile.txt",
          addressString:
            "20spauldingavecoatesvillepennsylvania19320unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0W0X-PJRR",
        productName:
          "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
        reference: "HAU66355695-0",
        sku: "FC0W0X-PJRR",
        binLocation: "10-B-54",
        boxCode: "",
        stockPickingId: "3116114",
        uniqueIdentifier: "5121498-3116114-4397984",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121498,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355695",
          sale_order_date: "2021-09-15",
          sale_order_total: 16.5,
          shipping_address_street: "20 Spaulding Ave",
          shipping_address_street_2: "",
          shipping_address_id: 8363910,
          shipping_address_name: "Shakira James",
          shipping_address_zip: "19320",
          shipping_address_city: "Coatesville",
          shipping_address_state: "Pennsylvania",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355695-0",
          stock_picking_id: 3116114,
          total_price: 34.97,
          shipping_batch_id: -1,
          date: "2021-09-16 06:46:15",
          picking_write_date: "2021-09-16 07:10:49",
          address_grp_combined:
            "hau/shakira james/20 spaulding ave//coatesville/pennsylvania/19320/united states",
          stock_move_is_set: false,
          stock_move_id: 78187026,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-B-54",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397984,
          sale_order_line_description:
            "4/5 CT TGW Created Blue Sapphire Created White S",
          sale_order_line_customer_cost: "34.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 13,
          product_product_default_code: "FC0W0X-PJRR",
          product_product_name:
            "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 99326,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552475",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:04.989Z",
        updatedAt: "2021-09-16T08:15:04.990Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "H", bin: "52" },
        price: 280,
        notes: [],
        isSet: false,
        _id: "6142a1a05182ab1e13a6e87f",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363538",
            street: "200 Dogwood Trl",
            name: "Susanne Gage",
            zip: "27909-3210",
            city: "Elizabeth City",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 280,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a1a05182ab1e13a6e87f"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a1a05182ab6aa4a6e87a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121301,
          orderNo: "BLK100974844-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974844",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363538,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694229",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/susanne gage/200 dogwood trl//elizabeth city/north carolina/27909-3210/united states",
          createdAt: "2021-09-16T01:45:04.665Z",
          updatedAt: "2021-09-16T01:45:04.829Z",
          labelFile: "5121301/labelFile.txt",
          zplFile: "5121301/zplFile.txt",
          pdfFile: "5121301/pdfFile.txt",
          addressString:
            "200dogwoodtrlelizabethcitynorthcarolina279093210unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC04EB-BJY8",
        productName:
          "0.04 CT Diamond TW And 3 1/3 CT TGW Ice Aquamarine Sapphire Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC04EB-BJY8.jpg",
        reference: "BLK100974844-0",
        sku: "FC04EB-BJY8",
        binLocation: "10-H-52",
        boxCode: "",
        stockPickingId: "3115924",
        uniqueIdentifier: "5121301-3115924-4397757",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121301,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974844",
          sale_order_date: "2021-09-15",
          sale_order_total: 125,
          shipping_address_street: "200 Dogwood Trl",
          shipping_address_street_2: null,
          shipping_address_id: 8363538,
          shipping_address_name: "Susanne Gage",
          shipping_address_zip: "27909-3210",
          shipping_address_city: "Elizabeth City",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974844-0",
          stock_picking_id: 3115924,
          total_price: 280,
          shipping_batch_id: -1,
          date: "2021-09-16 00:31:18",
          picking_write_date: "2021-09-16 00:40:49",
          address_grp_combined:
            "blk/susanne gage/200 dogwood trl//elizabeth city/north carolina/27909-3210/united states",
          stock_move_is_set: false,
          stock_move_id: 78186756,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-H-52",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397757,
          sale_order_line_description: "AQUA LBT DIA RG SS 8 DS",
          sale_order_line_customer_cost: "280.0",
          sale_order_line_vendor_sku: "686692167568",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC04EB-BJY8",
          product_product_name:
            "0.04 CT Diamond TW And 3 1/3 CT TGW Ice Aquamarine Sapphire Fashion Ring Silver GH I2;I3",
          product_id: 3099686,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC04EB-BJY8.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694229",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:04.817Z",
        updatedAt: "2021-09-16T01:45:04.818Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "N", bin: "28" },
        price: 113.83,
        notes: [],
        isSet: false,
        _id: "614297185182ab1b43a6e82a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363472",
            street: "1032 RAMOS STREET",
            name: "TENA JONES",
            zip: "70380",
            city: "MORGAN CITY",
            country: "United States",
            state: "Louisiana",
          },
          orderValue: 113.83,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614297185182ab1b43a6e82a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297185182ab655ea6e825",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121263,
          orderNo: "FGHPO000087801577-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087801577",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363472,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484721",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-16T01:00:08.453Z",
          updatedAt: "2021-09-16T01:00:08.655Z",
          labelFile: "5121263/labelFile.txt",
          zplFile: "5121263/zplFile.txt",
          pdfFile: "5121263/pdfFile.txt",
          addressString: "1032ramosstreetmorgancitylouisiana70380unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RZS6652",
        productName: "5.55 CT TGW Cubic Zirconia Fashion Ring Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/RZS6652.jpg",
        reference: "FGHPO000087801577-0",
        sku: "RZS6652",
        binLocation: "10-N-28",
        boxCode: "",
        stockPickingId: "3115890",
        uniqueIdentifier: "5121263-3115890-4397705",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121263,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087801577",
          sale_order_date: "2021-09-15",
          sale_order_total: 27,
          shipping_address_street: "1032 RAMOS STREET",
          shipping_address_street_2: null,
          shipping_address_id: 8363472,
          shipping_address_name: "TENA JONES",
          shipping_address_zip: "70380",
          shipping_address_city: "MORGAN CITY",
          shipping_address_state: "Louisiana",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087801577-0",
          stock_picking_id: 3115890,
          total_price: 113.83,
          shipping_batch_id: -1,
          date: "2021-09-15 23:22:50",
          picking_write_date: "2021-09-16 00:44:59",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186703,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-N-28",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397705,
          sale_order_line_description: "SS 5 3/5 CT TGW White Cubic Zi 8",
          sale_order_line_customer_cost: "113.83",
          sale_order_line_vendor_sku: "FGH004317",
          sale_order_line_size_id: 9,
          product_product_default_code: "RZS6652",
          product_product_name:
            "5.55 CT TGW Cubic Zirconia Fashion Ring Silver White",
          product_id: 80904,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RZS6652.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484721",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T01:00:08.634Z",
        updatedAt: "2021-09-16T01:00:08.635Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "N", bin: "48" },
        price: 150.1,
        notes: [],
        isSet: false,
        _id: "61430e9c5182ab69faa6ee2c",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363935",
            street: "416 PEARL ST",
            name: "SONIA IBARRA",
            zip: "76248",
            city: "KELLER",
            country: "United States",
            state: "Texas",
          },
          orderValue: 150.1,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61430e9c5182ab69faa6ee2c"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61430e9c5182ab381aa6ee27",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121514,
          orderNo: "FGHPO000087806284-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087806284",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363935,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484767",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-16T09:30:04.129Z",
          updatedAt: "2021-09-16T09:30:04.307Z",
          labelFile: "5121514/labelFile.txt",
          zplFile: "5121514/zplFile.txt",
          pdfFile: "5121514/pdfFile.txt",
          addressString: "416pearlstkellertexas76248unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ARGS00752",
        productName: "2.42 CT TGW Cubic Zirconia Fashion Ring Set Silver Rose",
        productImage: "https://partner.delmarintl.ca/catalog/ARGS00752.jpg",
        reference: "FGHPO000087806284-0",
        sku: "ARGS00752",
        binLocation: "10-N-48",
        boxCode: "",
        stockPickingId: "3116128",
        uniqueIdentifier: "5121514-3116128-4398001",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121514,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087806284",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "416 PEARL ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363935,
          shipping_address_name: "SONIA IBARRA",
          shipping_address_zip: "76248",
          shipping_address_city: "KELLER",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087806284-0",
          stock_picking_id: 3116128,
          total_price: 150.1,
          shipping_batch_id: -1,
          date: "2021-09-16 07:55:02",
          picking_write_date: "2021-09-16 09:14:54",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187045,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-N-48",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4398001,
          sale_order_line_description: "SS 2.42 CT CZ Ring Set 8",
          sale_order_line_customer_cost: "150.1",
          sale_order_line_vendor_sku: "FGH002426",
          sale_order_line_size_id: 9,
          product_product_default_code: "ARGS00752",
          product_product_name:
            "2.42 CT TGW Cubic Zirconia Fashion Ring Set Silver Rose",
          product_id: 220513,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ARGS00752.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484767",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T09:30:04.295Z",
        updatedAt: "2021-09-16T09:30:04.296Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "N", bin: "57" },
        price: 109.99,
        notes: [],
        isSet: false,
        _id: "6142d2d95182ab5609a6ebb1",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363750",
            street: "1172 Anderson Ave Apt 4A",
            name: "Latoya Martin",
            zip: "10452",
            city: "Bronx",
            country: "United States",
            state: "New York",
          },
          orderValue: 109.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d2d95182ab5609a6ebb1"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d95182abc280a6ebac",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121423,
          orderNo: "OS355686127-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355686127",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363750,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256573",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/latoya martin/1172 anderson ave apt 4a//bronx/new york/10452/united states",
          createdAt: "2021-09-16T05:15:05.469Z",
          updatedAt: "2021-09-16T05:15:05.618Z",
          labelFile: "5121423/labelFile.txt",
          zplFile: "5121423/zplFile.txt",
          pdfFile: "5121423/pdfFile.txt",
          addressString: "1172andersonaveapt4abronxnewyork10452unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1JUT-3GYE",
        productName: "4 1/5 CT TGW Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1JUT-3GYE.jpg",
        reference: "OS355686127-0",
        sku: "FC1JUT-3GYE",
        binLocation: "10-N-57",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116038",
        uniqueIdentifier: "5121423-3116038-4397900",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121423,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355686127",
          sale_order_date: "2021-09-15",
          sale_order_total: 85,
          shipping_address_street: "1172 Anderson Ave Apt 4A",
          shipping_address_street_2: null,
          shipping_address_id: 8363750,
          shipping_address_name: "Latoya Martin",
          shipping_address_zip: "10452",
          shipping_address_city: "Bronx",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355686127-0",
          stock_picking_id: 3116038,
          total_price: 109.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:07:13",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "os/latoya martin/1172 anderson ave apt 4a//bronx/new york/10452/united states",
          stock_move_is_set: false,
          stock_move_id: 78186929,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-N-57",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397900,
          sale_order_line_description: "9",
          sale_order_line_customer_cost: "109.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 11,
          product_product_default_code: "FC1JUT-3GYE",
          product_product_name:
            "4 1/5 CT TGW Created White Sapphire Fashion Ring Silver",
          product_id: 230257,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1JUT-3GYE.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256573",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T05:15:05.608Z",
        updatedAt: "2021-09-16T05:15:05.608Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "R", bin: "04" },
        price: 648.42,
        notes: [],
        isSet: false,
        _id: "6142eb785182ab522fa6ec9b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363837",
            street: "9513 OAK HILL DR",
            name: "NINFA FUENTES",
            zip: "77318",
            city: "WILLIS",
            country: "United States",
            state: "Texas",
          },
          orderValue: 648.42,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb785182ab522fa6ec9b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb775182ab5dc1a6ec96",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121463,
          orderNo: "SAMS8775308376-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775308376",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363837,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594254",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:00:07.870Z",
          updatedAt: "2021-09-16T07:00:08.087Z",
          labelFile: "5121463/labelFile.txt",
          zplFile: "5121463/zplFile.txt",
          pdfFile: "5121463/pdfFile.txt",
          addressString: "9513oakhilldrwillistexas77318unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0V63-P9KC",
        productName:
          "1/10 CT Diamond TW And 3 4/5 CT TGW Morganite Fashion Ring 14k Pink Gold GH I1",
        productImage: "https://partner.delmarintl.ca/catalog/FC0V63-P9KC.jpg",
        reference: "SAMS8775308376-0",
        sku: "FC0V63-P9KC",
        binLocation: "10-R-04",
        boxCode: "SCE",
        stockPickingId: "3116077",
        uniqueIdentifier: "5121463-3116077-4397946",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121463,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775308376",
          sale_order_date: "2021-09-15",
          sale_order_total: 512,
          shipping_address_street: "9513 OAK HILL DR",
          shipping_address_street_2: "",
          shipping_address_id: 8363837,
          shipping_address_name: "NINFA FUENTES",
          shipping_address_zip: "77318",
          shipping_address_city: "WILLIS",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775308376-0",
          stock_picking_id: 3116077,
          total_price: 648.42,
          shipping_batch_id: -1,
          date: "2021-09-16 05:18:54",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186980,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "10-R-04",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397946,
          sale_order_line_description: "3 CTTW GEM RING 9",
          sale_order_line_customer_cost: "648.42",
          sale_order_line_vendor_sku: "SAMS00200/9",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC0V63-P9KC",
          product_product_name:
            "1/10 CT Diamond TW And 3 4/5 CT TGW Morganite Fashion Ring 14k Pink Gold GH I1",
          product_id: 368836,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0V63-P9KC.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594254",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:00:08.057Z",
        updatedAt: "2021-09-16T07:00:08.058Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["10"],
    _id: "6195ae14fc40c13f24ca03fb",
    createdAt: "2021-11-18T01:36:20.821Z",
    updatedAt: "2021-11-18T01:36:20.821Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 9,
    orderBins: {
      "614297185182ab655ea6e825": "FGHPO000087801577-0",
      "6142a1a05182ab6aa4a6e87a": "BLK100974844-0",
      "6142c1585182ab56ada6ea03": "OS355677750-0",
      "6142cf7e5182ab40f8a6eb3b": "BLK100980200-0",
      "6142d2d95182abc280a6ebac": "OS355686127-0",
      "6142e1785182ab2457a6ec54": "OS355691183-0",
      "6142eb775182ab5dc1a6ec96": "SAMS8775308376-0",
      "6142fd085182ab4a77a6ed9f": "HAU66355695-0",
      "61430e9c5182ab381aa6ee27": "FGHPO000087806284-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "12", section: "H", bin: "14" },
        price: 40,
        notes: [],
        isSet: false,
        _id: "614297165182abadb7a6e7f6",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363457",
            street: "7754 Lake Andrea Cir",
            name: "Esther Haugabrooks",
            zip: "32757-7311",
            city: "Mount Dora",
            country: "United States",
            state: "Florida",
          },
          orderValue: 40,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614297165182abadb7a6e7f6"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297165182ab6fdfa6e7f1",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121256,
          orderNo: "TAR9484094753-0",
          customerId: 181,
          customerName: "Target",
          orderReference: "TAR9484094753",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363457,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552199",
          carrier: "UPS",
          shippingMethod: " UPS Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T01:00:06.821Z",
          updatedAt: "2021-09-16T01:00:07.021Z",
          labelFile: "5121256/labelFile.txt",
          zplFile: "5121256/zplFile.txt",
          pdfFile: "5121256/pdfFile.txt",
          addressString:
            "7754lakeandreacirmountdoraflorida327577311unitedstates",
          normalizedCustomerName: "target",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC10AP-WPT1",
        productName:
          "1/3 CT TGW Created White Sapphire Fashion Ring Pink Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC10AP-WPT1.jpg",
        reference: "TAR9484094753-0",
        sku: "FC10AP-WPT1",
        binLocation: "12-H-14",
        boxCode: "",
        stockPickingId: "3115884",
        uniqueIdentifier: "5121256-3115884-4397698",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121256,
          sale_order_customer_id: 181,
          sale_order_customer_name: "Target",
          sale_order_order_reference: "TAR9484094753",
          sale_order_date: "2021-09-15",
          sale_order_total: 31,
          shipping_address_street: "7754 Lake Andrea Cir",
          shipping_address_street_2: "",
          shipping_address_id: 8363457,
          shipping_address_name: "Esther Haugabrooks",
          shipping_address_zip: "32757-7311",
          shipping_address_city: "Mount Dora",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "TAR9484094753-0",
          stock_picking_id: 3115884,
          total_price: 40,
          shipping_batch_id: -1,
          date: "2021-09-15 23:15:36",
          picking_write_date: "2021-09-16 00:45:06",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186697,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "12-H-14",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397698,
          sale_order_line_description: "RINGS ALLURA",
          sale_order_line_customer_cost: "40.0",
          sale_order_line_vendor_sku: "7504181209",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC10AP-WPT1",
          product_product_name:
            "1/3 CT TGW Created White Sapphire Fashion Ring Pink Silver",
          product_id: 105729,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC10AP-WPT1.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " UPS Ground",
          order_tracking_number: "1Z8R9V140318552199",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T01:00:06.992Z",
        updatedAt: "2021-09-16T01:00:06.993Z",
        __v: 0,
      },
      {
        stockBin: { row: "12", section: "P", bin: "10" },
        price: 157.5,
        notes: [],
        isSet: false,
        _id: "6142f6025182ab0611a6ed3f",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363890",
            street: "165 S JACKSON ST",
            name: "Stephanie Brown",
            zip: "36451-3009",
            city: "GROVE HILL",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 157.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6025182ab0611a6ed3f"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6025182ab0e43a6ed3a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121488,
          orderNo: "BLK100985403-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100985403",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363890,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694446",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/stephanie brown/165 s jackson st//grove hill/alabama/36451-3009/united states",
          createdAt: "2021-09-16T07:45:06.037Z",
          updatedAt: "2021-09-16T07:45:06.187Z",
          labelFile: "5121488/labelFile.txt",
          zplFile: "5121488/zplFile.txt",
          pdfFile: "5121488/pdfFile.txt",
          addressString: "165sjacksonstgrovehillalabama364513009unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0V63-3TYB",
        productName:
          "1/10 CT Diamond TW And 4 CT TGW GREEN QUARTZ- Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0V63-3TYB.jpg",
        reference: "BLK100985403-0",
        sku: "FC0V63-3TYB",
        binLocation: "12-P-10",
        boxCode: "",
        stockPickingId: "3116102",
        uniqueIdentifier: "5121488-3116102-4397971",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121488,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100985403",
          sale_order_date: "2021-09-15",
          sale_order_total: 83,
          shipping_address_street: "165 S JACKSON ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363890,
          shipping_address_name: "Stephanie Brown",
          shipping_address_zip: "36451-3009",
          shipping_address_city: "GROVE HILL",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100985403-0",
          stock_picking_id: 3116102,
          total_price: 157.5,
          shipping_batch_id: -1,
          date: "2021-09-16 06:22:25",
          picking_write_date: "2021-09-16 06:41:06",
          address_grp_combined:
            "blk/stephanie brown/165 s jackson st//grove hill/alabama/36451-3009/united states",
          stock_move_is_set: false,
          stock_move_id: 78187014,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "12-P-10",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397971,
          sale_order_line_description: "X-SS RG GRN AMY CUSH",
          sale_order_line_customer_cost: "157.5",
          sale_order_line_vendor_sku: "075000450933",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0V63-3TYB",
          product_product_name:
            "1/10 CT Diamond TW And 4 CT TGW GREEN QUARTZ- Fashion Ring Silver GH I3",
          product_id: 96969,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0V63-3TYB.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694446",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:45:06.175Z",
        updatedAt: "2021-09-16T07:45:06.176Z",
        __v: 0,
      },
      {
        stockBin: { row: "13", section: "F", bin: "17" },
        price: 35,
        notes: [],
        isSet: false,
        _id: "6142eb765182ab62f2a6ec67",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363829",
            street: "205 North St",
            name: "Chasity macy",
            zip: "27045-9626",
            city: "Rural Hall",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 35,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb765182ab62f2a6ec67"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb765182ab007ca6ec62",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121456,
          orderNo: "TAR9948332286-0",
          customerId: 181,
          customerName: "Target",
          orderReference: "TAR9948332286",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363829,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552420",
          carrier: "UPS",
          shippingMethod: " UPS Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:00:06.125Z",
          updatedAt: "2021-09-16T07:00:06.358Z",
          labelFile: "5121456/labelFile.txt",
          zplFile: "5121456/zplFile.txt",
          pdfFile: "5121456/pdfFile.txt",
          addressString:
            "205northstruralhallnorthcarolina270459626unitedstates",
          normalizedCustomerName: "target",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0L97-2VX3",
        productName:
          "2 5/8 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L97-2VX3.jpg",
        reference: "TAR9948332286-0",
        sku: "FC0L97-2VX3",
        binLocation: "13-F-17",
        boxCode: "BW33",
        stockPickingId: "3116071",
        uniqueIdentifier: "5121456-3116071-4397939",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121456,
          sale_order_customer_id: 181,
          sale_order_customer_name: "Target",
          sale_order_order_reference: "TAR9948332286",
          sale_order_date: "2021-09-15",
          sale_order_total: 26,
          shipping_address_street: "205 North St",
          shipping_address_street_2: "",
          shipping_address_id: 8363829,
          shipping_address_name: "Chasity macy",
          shipping_address_zip: "27045-9626",
          shipping_address_city: "Rural Hall",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "TAR9948332286-0",
          stock_picking_id: 3116071,
          total_price: 35,
          shipping_batch_id: -1,
          date: "2021-09-16 05:06:52",
          picking_write_date: "2021-09-16 06:45:22",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186974,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "13-F-17",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397939,
          sale_order_line_description: "Crtd  Saphhi re ring",
          sale_order_line_customer_cost: "35.0",
          sale_order_line_vendor_sku: "FC0L97-2VX3/6",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0L97-2VX3",
          product_product_name:
            "2 5/8 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 88847,
          ship2store: false,
          is_no_resize: null,
          box_code: "BW33",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L97-2VX3.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " UPS Ground",
          order_tracking_number: "1Z8R9V140318552420",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T07:00:06.336Z",
        updatedAt: "2021-09-16T07:00:06.337Z",
        __v: 0,
      },
      {
        stockBin: { row: "13", section: "H", bin: "56" },
        price: 1541.65,
        notes: [],
        isSet: false,
        _id: "6142c4c95182ab3ea6a6ea95",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363680",
            street: "2031 DUKE ST",
            name: "CLARRISSA KISKADEN",
            zip: "41034",
            city: "DOVER",
            country: "United States",
            state: "Kentucky",
          },
          orderValue: 1541.65,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4c95182ab3ea6a6ea95"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4c95182abe02da6ea90",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121373,
          orderNo: "JCP226002490_272-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP226002490_272",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363680,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552297",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/clarrissa kiskaden/2031 duke st//dover/kentucky/41034/united states",
          createdAt: "2021-09-16T04:15:05.320Z",
          updatedAt: "2021-09-16T04:15:05.513Z",
          labelFile: "5121373/labelFile.txt",
          zplFile: "5121373/zplFile.txt",
          pdfFile: "5121373/pdfFile.txt",
          addressString: "2031dukestdoverkentucky41034unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "PAPS",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1CPJ-MPXR",
        productName:
          "1 1/2 CT Black and White Princess and Round Diamonds TW Fashion Ring Silver GH I2;I3 Black Rhodium Plated",
        productImage: "https://partner.delmarintl.ca/catalog/FC1CPJ-MPXR.jpg",
        reference: "JCP226002490_272-0",
        sku: "FC1CPJ-MPXR",
        binLocation: "13-H-56",
        boxCode: "",
        stockPickingId: "3115994",
        uniqueIdentifier: "5121373-3115994-4397844",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121373,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP226002490_272",
          sale_order_date: "2021-09-15",
          sale_order_total: 300,
          shipping_address_street: "2031 DUKE ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363680,
          shipping_address_name: "CLARRISSA KISKADEN",
          shipping_address_zip: "41034",
          shipping_address_city: "DOVER",
          shipping_address_state: "Kentucky",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP226002490_272-0",
          stock_picking_id: 3115994,
          total_price: 1541.65,
          shipping_batch_id: -1,
          date: "2021-09-16 02:41:57",
          picking_write_date: "2021-09-16 03:11:48",
          address_grp_combined:
            "jcp/clarrissa kiskaden/2031 duke st//dover/kentucky/41034/united states",
          stock_move_is_set: false,
          stock_move_id: 78186862,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "13-H-56",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397844,
          sale_order_line_description: "SS 1 1/2CT RNG",
          sale_order_line_customer_cost: "1541.65",
          sale_order_line_vendor_sku: "JCP004055",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC1CPJ-MPXR",
          product_product_name:
            "1 1/2 CT Black and White Princess and Round Diamonds TW Fashion Ring Silver GH I2;I3 Black Rhodium Plated",
          product_id: 216897,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1CPJ-MPXR.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552297",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T04:15:05.497Z",
        updatedAt: "2021-09-16T04:15:05.498Z",
        __v: 0,
      },
      {
        stockBin: { row: "13", section: "K", bin: "64" },
        price: 52.5,
        notes: [],
        isSet: false,
        _id: "614285805182ab5037a6e778",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363432",
            street: "10800 LAVERGNE AVE",
            name: "Michele Conroy",
            zip: "60453-5112",
            city: "OAK LAWN",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 52.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614285805182ab5037a6e778"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614285805182ab7c6fa6e773",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121241,
          orderNo: "BLK100969924-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100969924",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363432,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694181",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/michele conroy/10800 lavergne ave//oak lawn/illinois/60453-5112/united states",
          createdAt: "2021-09-15T23:45:04.042Z",
          updatedAt: "2021-09-15T23:45:04.214Z",
          labelFile: "5121241/labelFile.txt",
          zplFile: "5121241/zplFile.txt",
          pdfFile: "5121241/pdfFile.txt",
          addressString: "10800lavergneaveoaklawnillinois604535112unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC07LC-3WW9",
        productName:
          "0.01 CT Diamond TW And 3 3/4 CT TGW Smokey Quartz Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC07LC-3WW9.jpg",
        reference: "BLK100969924-0",
        sku: "FC07LC-3WW9",
        binLocation: "13-K-64",
        boxCode: "",
        stockPickingId: "3115870",
        uniqueIdentifier: "5121241-3115870-4397683",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121241,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100969924",
          sale_order_date: "2021-09-15",
          sale_order_total: 23,
          shipping_address_street: "10800 LAVERGNE AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363432,
          shipping_address_name: "Michele Conroy",
          shipping_address_zip: "60453-5112",
          shipping_address_city: "OAK LAWN",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100969924-0",
          stock_picking_id: 3115870,
          total_price: 52.5,
          shipping_batch_id: -1,
          date: "2021-09-15 22:21:52",
          picking_write_date: "2021-09-15 22:40:37",
          address_grp_combined:
            "blk/michele conroy/10800 lavergne ave//oak lawn/illinois/60453-5112/united states",
          stock_move_is_set: false,
          stock_move_id: 78186676,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "13-K-64",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397683,
          sale_order_line_description: "X-RNG SMKY QTZ RND W/DIA",
          sale_order_line_customer_cost: "52.5",
          sale_order_line_vendor_sku: "075000734668",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC07LC-3WW9",
          product_product_name:
            "0.01 CT Diamond TW And 3 3/4 CT TGW Smokey Quartz Fashion Ring Silver I3",
          product_id: 88331,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC07LC-3WW9.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694181",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T23:45:04.201Z",
        updatedAt: "2021-09-15T23:45:04.202Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "A", bin: "59" },
        price: 284.99,
        notes: [],
        isSet: false,
        _id: "614289165182ab7463a6e792",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363417",
            street: "1613 British Blvd",
            name: "Richard Mead",
            zip: "75050-7034",
            city: "Grand Prairie",
            country: "United States",
            state: "Texas",
          },
          orderValue: 284.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289165182ab7463a6e792"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289165182ab3f16a6e78d",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121231,
          orderNo: "GVX2783881-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2783881-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363417,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256368",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:22.531Z",
          updatedAt: "2021-09-16T00:00:22.676Z",
          labelFile: "5121231/labelFile.txt",
          zplFile: "5121231/zplFile.txt",
          pdfFile: "5121231/pdfFile.txt",
          addressString:
            "1613britishblvdgrandprairietexas750507034unitedstates",
          normalizedCustomerName: "govx",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC27RZ-9817",
        productName:
          "2 2/5 CT DEW Created Moissanite-White Heart Pendant With Chain Pink Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC27RZ-9817.jpg",
        reference: "GVX2783881-DEL01-0",
        sku: "FC27RZ-9817",
        binLocation: "14-A-59",
        boxCode: "",
        stockPickingId: "3115860",
        uniqueIdentifier: "5121231-3115860-4397673",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121231,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2783881-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 106,
          shipping_address_street: "1613 British Blvd",
          shipping_address_street_2: "",
          shipping_address_id: 8363417,
          shipping_address_name: "Richard Mead",
          shipping_address_zip: "75050-7034",
          shipping_address_city: "Grand Prairie",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2783881-DEL01-0",
          stock_picking_id: 3115860,
          total_price: 284.99,
          shipping_batch_id: -1,
          date: "2021-09-15 22:16:32",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186665,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-A-59",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397673,
          sale_order_line_description:
            "2 2/5 CT TGW Created Moissanite Heart Pendant with Chain in Rose Gold Plated Sterling Silver",
          sale_order_line_customer_cost: "284.99",
          sale_order_line_vendor_sku: "GVX006099",
          sale_order_line_size_id: null,
          product_product_default_code: "FC27RZ-9817",
          product_product_name:
            "2 2/5 CT DEW Created Moissanite-White Heart Pendant With Chain Pink Silver",
          product_id: 3110354,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC27RZ-9817.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256368",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T00:00:22.664Z",
        updatedAt: "2021-09-16T00:00:22.665Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "C", bin: "28" },
        price: 96.25,
        notes: [],
        isSet: false,
        _id: "6142a1a15182abd653a6e88c",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363544",
            street: "2394 Clara Strengthford Rd",
            name: "Devin Cherry",
            zip: "39367-7965",
            city: "Waynesboro",
            country: "United States",
            state: "Mississippi",
          },
          orderValue: 96.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a1a15182abd653a6e88c"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a1a15182ab6726a6e887",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121303,
          orderNo: "BLK100975028-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100975028",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363544,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694240",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/devin cherry/2394 clara strengthford rd//waynesboro/mississippi/39367-7965/united states",
          createdAt: "2021-09-16T01:45:05.010Z",
          updatedAt: "2021-09-16T01:45:05.165Z",
          labelFile: "5121303/labelFile.txt",
          zplFile: "5121303/zplFile.txt",
          pdfFile: "5121303/pdfFile.txt",
          addressString:
            "2394clarastrengthfordrdwaynesboromississippi393677965unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0L94-4F9J",
        productName:
          "1/8 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L94-4F9J.jpg",
        reference: "BLK100975028-0",
        sku: "FC0L94-4F9J",
        binLocation: "14-C-28",
        boxCode: "",
        stockPickingId: "3115926",
        uniqueIdentifier: "5121303-3115926-4397762",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121303,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100975028",
          sale_order_date: "2021-09-15",
          sale_order_total: 43,
          shipping_address_street: "2394 Clara Strengthford Rd",
          shipping_address_street_2: null,
          shipping_address_id: 8363544,
          shipping_address_name: "Devin Cherry",
          shipping_address_zip: "39367-7965",
          shipping_address_city: "Waynesboro",
          shipping_address_state: "Mississippi",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100975028-0",
          stock_picking_id: 3115926,
          total_price: 96.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:31:58",
          picking_write_date: "2021-09-16 00:40:47",
          address_grp_combined:
            "blk/devin cherry/2394 clara strengthford rd//waynesboro/mississippi/39367-7965/united states",
          stock_move_is_set: false,
          stock_move_id: 78186758,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-C-28",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397762,
          sale_order_line_description: "X-RNG FWP W/DIA SIDES",
          sale_order_line_customer_cost: "96.25",
          sale_order_line_vendor_sku: "075000566764",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0L94-4F9J",
          product_product_name:
            "1/8 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Ring Silver I3",
          product_id: 88761,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L94-4F9J.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694240",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:05.153Z",
        updatedAt: "2021-09-16T01:45:05.154Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "C", bin: "32" },
        price: 389.04,
        notes: [],
        isSet: false,
        _id: "6142eb775182ab759fa6ec8e",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363835",
            street: "16 SCENIC DR, T",
            name: "JENNIFER MCALLISTER",
            zip: "10520",
            city: "CROTON ON HUDSON",
            country: "United States",
            state: "New York",
          },
          orderValue: 389.04,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb775182ab759fa6ec8e"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb775182ab4daca6ec89",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121460,
          orderNo: "FGHPO000087805173-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087805173",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363835,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484749",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:00:07.481Z",
          updatedAt: "2021-09-16T07:00:07.689Z",
          labelFile: "5121460/labelFile.txt",
          zplFile: "5121460/zplFile.txt",
          pdfFile: "5121460/pdfFile.txt",
          addressString: "16scenicdrtcrotononhudsonnewyork10520unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1EK1-8E8E",
        productName: "1 CT TGW Morganite Fashion Post Earrings 10k Pink Gold",
        productImage: "https://partner.delmarintl.ca/catalog/FC1EK1-8E8E.jpg",
        reference: "FGHPO000087805173-0",
        sku: "FC1EK1-8E8E",
        binLocation: "14-C-32",
        boxCode: "",
        stockPickingId: "3116075",
        uniqueIdentifier: "5121460-3116075-4397943",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121460,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087805173",
          sale_order_date: "2021-09-15",
          sale_order_total: 105,
          shipping_address_street: "16 SCENIC DR",
          shipping_address_street_2: "T",
          shipping_address_id: 8363835,
          shipping_address_name: "JENNIFER MCALLISTER",
          shipping_address_zip: "10520",
          shipping_address_city: "CROTON ON HUDSON",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087805173-0",
          stock_picking_id: 3116075,
          total_price: 389.04,
          shipping_batch_id: -1,
          date: "2021-09-16 05:16:09",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186978,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-C-32",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397943,
          sale_order_line_description: "10K PG 1 CT Morg. Ear Pin Earr",
          sale_order_line_customer_cost: "389.04",
          sale_order_line_vendor_sku: "FGH004080",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1EK1-8E8E",
          product_product_name:
            "1 CT TGW Morganite Fashion Post Earrings 10k Pink Gold",
          product_id: 218215,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1EK1-8E8E.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484749",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T07:00:07.660Z",
        updatedAt: "2021-09-16T07:00:07.661Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "C", bin: "54" },
        price: 359.49,
        notes: [],
        isSet: false,
        _id: "6142f9b75182abcb56a6ed59",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363868",
            street: "5623 James Gunnell Ln",
            name: "Marc DeCourcey",
            zip: "22310",
            city: "Alexandria",
            country: "United States",
            state: "Virginia",
          },
          orderValue: 359.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f9b75182abcb56a6ed59"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f9b75182ab4493a6ed54",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121478,
          orderNo: "OS355695582-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355695582",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363868,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256733",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/marc decourcey/5623 james gunnell ln//alexandria/virginia/22310/united states",
          createdAt: "2021-09-16T08:00:55.789Z",
          updatedAt: "2021-09-16T08:00:55.948Z",
          labelFile: "5121478/labelFile.txt",
          zplFile: "5121478/zplFile.txt",
          pdfFile: "5121478/pdfFile.txt",
          addressString:
            "5623jamesgunnelllnalexandriavirginia22310unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC16Z7-XHTM",
        productName:
          "1/4 CT Diamond TW Necklace With Chain 10k White Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC16Z7-XHTM.jpg",
        reference: "OS355695582-0",
        sku: "FC16Z7-XHTM",
        binLocation: "14-C-54",
        boxCode: "CRD-MIADORA:PC-MIADORA:GW51",
        stockPickingId: "3116090",
        uniqueIdentifier: "5121478-3116090-4397961",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121478,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355695582",
          sale_order_date: "2021-09-15",
          sale_order_total: 287,
          shipping_address_street: "5623 James Gunnell Ln",
          shipping_address_street_2: null,
          shipping_address_id: 8363868,
          shipping_address_name: "Marc DeCourcey",
          shipping_address_zip: "22310",
          shipping_address_city: "Alexandria",
          shipping_address_state: "Virginia",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355695582-0",
          stock_picking_id: 3116090,
          total_price: 359.49,
          shipping_batch_id: -1,
          date: "2021-09-16 06:05:23",
          picking_write_date: "2021-09-16 07:44:43",
          address_grp_combined:
            "os/marc decourcey/5623 james gunnell ln//alexandria/virginia/22310/united states",
          stock_move_is_set: false,
          stock_move_id: 78187000,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-C-54",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397961,
          sale_order_line_description:
            "Miadora 10k White Gold 1/4ct TDW Diamond Infinity Necklace",
          sale_order_line_customer_cost: "359.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC16Z7-XHTM",
          product_product_name:
            "1/4 CT Diamond TW Necklace With Chain 10k White Gold GH I2;I3",
          product_id: 190923,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA:GW51",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC16Z7-XHTM.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256733",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T08:00:55.938Z",
        updatedAt: "2021-09-16T08:00:55.938Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "F", bin: "16" },
        price: 262.5,
        notes: [],
        isSet: false,
        _id: "6142cf7d5182abfc97a6eb26",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363728",
            street: "2405 Havershire Dr",
            name: "Pamela Hurley",
            zip: "27613-3667",
            city: "Raleigh",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 262.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7d5182abfc97a6eb26"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7d5182abdad5a6eb21",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121404,
          orderNo: "BLK100981930-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100981930",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363728,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694365",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/pamela hurley/2405 havershire dr//raleigh/north carolina/27613-3667/united states",
          createdAt: "2021-09-16T05:00:45.368Z",
          updatedAt: "2021-09-16T05:00:45.534Z",
          labelFile: "5121404/labelFile.txt",
          zplFile: "5121404/zplFile.txt",
          pdfFile: "5121404/pdfFile.txt",
          addressString:
            "2405havershiredrraleighnorthcarolina276133667unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ECKPW1278B",
        productName:
          "8 - 8.5 MM Dyed Brown Freshwater Cultured Pearl LeverBack Earrings 14k Pink Gold",
        productImage: "https://partner.delmarintl.ca/catalog/ECKPW1278B.jpg",
        reference: "BLK100981930-0",
        sku: "ECKPW1278B",
        binLocation: "14-F-16",
        boxCode: "",
        stockPickingId: "3116018",
        uniqueIdentifier: "5121404-3116018-4397877",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121404,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100981930",
          sale_order_date: "2021-09-15",
          sale_order_total: 123,
          shipping_address_street: "2405 Havershire Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363728,
          shipping_address_name: "Pamela Hurley",
          shipping_address_zip: "27613-3667",
          shipping_address_city: "Raleigh",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100981930-0",
          stock_picking_id: 3116018,
          total_price: 262.5,
          shipping_batch_id: -1,
          date: "2021-09-16 03:28:41",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined:
            "blk/pamela hurley/2405 havershire dr//raleigh/north carolina/27613-3667/united states",
          stock_move_is_set: false,
          stock_move_id: 78186899,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-F-16",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397877,
          sale_order_line_description: "BRWN FWP DRP ER 14KR DS",
          sale_order_line_customer_cost: "262.5",
          sale_order_line_vendor_sku: "686692401174",
          sale_order_line_size_id: null,
          product_product_default_code: "ECKPW1278B",
          product_product_name:
            "8 - 8.5 MM Dyed Brown Freshwater Cultured Pearl LeverBack Earrings 14k Pink Gold",
          product_id: 58855,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ECKPW1278B.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694365",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:00:45.521Z",
        updatedAt: "2021-09-16T05:00:45.521Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "J", bin: "32" },
        price: 62.49,
        notes: [],
        isSet: false,
        _id: "6142c4cb5182abed40a6eaca",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363685",
            street: "17041 Deerfield Ln",
            name: "Sherry Wildman",
            zip: "22724",
            city: "Jeffersonton",
            country: "United States",
            state: "Virginia",
          },
          orderValue: 62.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4cb5182abed40a6eaca"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4ca5182abcc6aa6eac5",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121378,
          orderNo: "OS355682152-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355682152",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363685,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256535",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/sherry wildman/17041 deerfield ln//jeffersonton/virginia/22724/united states",
          createdAt: "2021-09-16T04:15:06.902Z",
          updatedAt: "2021-09-16T04:15:07.048Z",
          labelFile: "5121378/labelFile.txt",
          zplFile: "5121378/zplFile.txt",
          pdfFile: "5121378/pdfFile.txt",
          addressString:
            "17041deerfieldlnjeffersontonvirginia22724unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "APWS02867C",
        productName:
          "0.819 CT TGW Cubic Zirconia And 9.5-10 mm Pink Freshwater Cultured Pearl Pendant With Chain Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/APWS02867C.jpg",
        reference: "OS355682152-0",
        sku: "APWS02867C",
        binLocation: "14-J-32",
        boxCode: "",
        stockPickingId: "3115998",
        uniqueIdentifier: "5121378-3115998-4397848",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121378,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355682152",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "17041 Deerfield Ln",
          shipping_address_street_2: null,
          shipping_address_id: 8363685,
          shipping_address_name: "Sherry Wildman",
          shipping_address_zip: "22724",
          shipping_address_city: "Jeffersonton",
          shipping_address_state: "Virginia",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355682152-0",
          stock_picking_id: 3115998,
          total_price: 62.49,
          shipping_batch_id: -1,
          date: "2021-09-16 02:44:53",
          picking_write_date: "2021-09-16 03:11:29",
          address_grp_combined:
            "os/sherry wildman/17041 deerfield ln//jeffersonton/virginia/22724/united states",
          stock_move_is_set: false,
          stock_move_id: 78186867,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-J-32",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397848,
          sale_order_line_description:
            "Miadora Sterling Silver Pink Cultured FW Pearl and CZ Swirl Halo Necklace (10-11 mm)",
          sale_order_line_customer_cost: "62.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "APWS02867C",
          product_product_name:
            "0.819 CT TGW Cubic Zirconia And 9.5-10 mm Pink Freshwater Cultured Pearl Pendant With Chain Silver White",
          product_id: 3097907,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/APWS02867C.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256535",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:15:07.035Z",
        updatedAt: "2021-09-16T04:15:07.036Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "B", bin: "30" },
        price: 109.99,
        notes: [],
        isSet: false,
        _id: "6142b3365182ab7f7ba6e956",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363595",
            street: "2965 gilead fairview rd.",
            name: "Roxie Perry",
            zip: "42746",
            city: "Hardyville",
            country: "United States",
            state: "Kentucky",
          },
          orderValue: 109.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3365182ab7f7ba6e956"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3355182ab5551a6e951",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121329,
          orderNo: "OS355673078-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355673078",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363595,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256245",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/roxie perry/2965 gilead fairview rd.//hardyville/kentucky/42746/united states",
          createdAt: "2021-09-16T03:00:05.870Z",
          updatedAt: "2021-09-16T03:00:06.046Z",
          labelFile: "5121329/labelFile.txt",
          zplFile: "5121329/zplFile.txt",
          pdfFile: "5121329/pdfFile.txt",
          addressString:
            "2965gileadfairviewrdhardyvillekentucky42746unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC22ME-X159",
        productName:
          "0.02 CT Diamond TW 11-12 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC22ME-X159.jpg",
        reference: "OS355673078-0",
        sku: "FC22ME-X159",
        binLocation: "15-B-30",
        boxCode: "",
        stockPickingId: "3115952",
        uniqueIdentifier: "5121329-3115952-4397792",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121329,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355673078",
          sale_order_date: "2021-09-15",
          sale_order_total: 73,
          shipping_address_street: "2965 gilead fairview rd.",
          shipping_address_street_2: null,
          shipping_address_id: 8363595,
          shipping_address_name: "Roxie Perry",
          shipping_address_zip: "42746",
          shipping_address_city: "Hardyville",
          shipping_address_state: "Kentucky",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355673078-0",
          stock_picking_id: 3115952,
          total_price: 109.99,
          shipping_batch_id: -1,
          date: "2021-09-16 01:22:08",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "os/roxie perry/2965 gilead fairview rd.//hardyville/kentucky/42746/united states",
          stock_move_is_set: false,
          stock_move_id: 78186799,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-B-30",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397792,
          sale_order_line_description:
            "Miadora Sterling Silver Cultured Freshwater Pearl and Diamond Drop Earrings (7.5-8mm)",
          sale_order_line_customer_cost: "109.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC22ME-X159",
          product_product_name:
            "0.02 CT Diamond TW 11-12 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver GH I2;I3",
          product_id: 3071767,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC22ME-X159.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256245",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T03:00:06.029Z",
        updatedAt: "2021-09-16T03:00:06.030Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "B", bin: "64" },
        price: 270.82,
        notes: [],
        isSet: false,
        _id: "6142eb765182ab53d7a6ec74",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363833",
            street: "70 ANTRIM ST",
            name: "KATHERINE CLOVER",
            zip: "02139",
            city: "CAMBRIDGE",
            country: "United States",
            state: "Massachusetts",
          },
          orderValue: 270.82,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb765182ab53d7a6ec74"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb765182ab2e31a6ec6f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121458,
          orderNo: "JCP226005379_267-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP226005379_267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363833,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552411",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/katherine clover/70 antrim st//cambridge/massachusetts/02139/united states",
          createdAt: "2021-09-16T07:00:06.611Z",
          updatedAt: "2021-09-16T07:00:06.894Z",
          labelFile: "5121458/labelFile.txt",
          zplFile: "5121458/zplFile.txt",
          pdfFile: "5121458/pdfFile.txt",
          addressString: "70antrimstcambridgemassachusetts02139unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "BGS169TN",
        productName:
          "4 1/3 CT TGW Tanzanite Bracelet Silver Length (inches): 7",
        productImage: "https://partner.delmarintl.ca/catalog/BGS169TN.jpg",
        reference: "JCP226005379_267-0",
        sku: "BGS169TN",
        binLocation: "15-B-64",
        boxCode: "",
        stockPickingId: "3116072",
        uniqueIdentifier: "5121458-3116072-4397941",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121458,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP226005379_267",
          sale_order_date: "2021-09-15",
          sale_order_total: 79,
          shipping_address_street: "70 ANTRIM ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363833,
          shipping_address_name: "KATHERINE CLOVER",
          shipping_address_zip: "02139",
          shipping_address_city: "CAMBRIDGE",
          shipping_address_state: "Massachusetts",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP226005379_267-0",
          stock_picking_id: 3116072,
          total_price: 270.82,
          shipping_batch_id: -1,
          date: "2021-09-16 05:09:41",
          picking_write_date: "2021-09-16 06:45:22",
          address_grp_combined:
            "jcp/katherine clover/70 antrim st//cambridge/massachusetts/02139/united states",
          stock_move_is_set: false,
          stock_move_id: 78186975,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-B-64",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397941,
          sale_order_line_description: "Bracelets",
          sale_order_line_customer_cost: "270.82",
          sale_order_line_vendor_sku: "BGS169TN",
          sale_order_line_size_id: null,
          product_product_default_code: "BGS169TN",
          product_product_name:
            "4 1/3 CT TGW Tanzanite Bracelet Silver Length (inches): 7",
          product_id: 77195,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/BGS169TN.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552411",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T07:00:06.874Z",
        updatedAt: "2021-09-16T07:00:06.875Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "D", bin: "51" },
        price: 183.32,
        notes: [],
        isSet: false,
        _id: "6142a5265182abb68aa6e8ec",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363532",
            street: "239 EMERSON ROAD",
            name: "KELLY WEBER",
            zip: "15209",
            city: "PITTSBURGH",
            country: "United States",
            state: "Pennsylvania",
          },
          orderValue: 183.32,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a5265182abb68aa6e8ec"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a5255182ab3c8ba6e8e7",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121296,
          orderNo: "JCP225999624_274-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225999624_274",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363532,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552215",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/kelly weber/239 emerson road//pittsburgh/pennsylvania/15209/united states",
          createdAt: "2021-09-16T02:00:05.710Z",
          updatedAt: "2021-09-16T02:00:06.117Z",
          labelFile: "5121296/labelFile.txt",
          zplFile: "5121296/zplFile.txt",
          pdfFile: "5121296/pdfFile.txt",
          addressString:
            "239emersonroadpittsburghpennsylvania15209unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0W0X-PJRR",
        productName:
          "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
        reference: "JCP225999624_274-0",
        sku: "FC0W0X-PJRR",
        binLocation: "15-D-51",
        boxCode: "",
        stockPickingId: "3115920",
        uniqueIdentifier: "5121296-3115920-4397752",
        stockMoveSize: "5.0",
        orderLineSize: "5.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121296,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225999624_274",
          sale_order_date: "2021-09-15",
          sale_order_total: 33,
          shipping_address_street: "239 EMERSON ROAD",
          shipping_address_street_2: null,
          shipping_address_id: 8363532,
          shipping_address_name: "KELLY WEBER",
          shipping_address_zip: "15209",
          shipping_address_city: "PITTSBURGH",
          shipping_address_state: "Pennsylvania",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225999624_274-0",
          stock_picking_id: 3115920,
          total_price: 183.32,
          shipping_batch_id: -1,
          date: "2021-09-16 00:24:45",
          picking_write_date: "2021-09-16 01:44:45",
          address_grp_combined:
            "jcp/kelly weber/239 emerson road//pittsburgh/pennsylvania/15209/united states",
          stock_move_is_set: false,
          stock_move_id: 78186751,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-D-51",
          stock_move_uom: "PCE",
          stock_move_size: "5.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397752,
          sale_order_line_description: "SS  SAPH RNG",
          sale_order_line_customer_cost: "183.32",
          sale_order_line_vendor_sku: "JCP004289",
          sale_order_line_size_id: 3,
          product_product_default_code: "FC0W0X-PJRR",
          product_product_name:
            "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 99326,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
          sale_order_line_size: "5.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552215",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T02:00:06.095Z",
        updatedAt: "2021-09-16T02:00:06.095Z",
        __v: 0,
      },
      {
        stockBin: { row: "16", section: "H", bin: "40" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "6142a5275182ab20b9a6e8f9",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363535",
            street: "1834 Thornblade Ridge Dr",
            name: "Helen Hudson",
            zip: "28105-0314",
            city: "Matthews",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 78.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a5275182ab20b9a6e8f9"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a5265182ab9a0aa6e8f4",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121300,
          orderNo: "BLK100974872-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974872",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363535,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694230",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/helen hudson/1834 thornblade ridge dr//matthews/north carolina/28105-0314/united states",
          createdAt: "2021-09-16T02:00:06.591Z",
          updatedAt: "2021-09-16T02:00:07.126Z",
          labelFile: "5121300/labelFile.txt",
          zplFile: "5121300/zplFile.txt",
          pdfFile: "5121300/pdfFile.txt",
          addressString:
            "1834thornbladeridgedrmatthewsnorthcarolina281050314unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "BLK100974872-0",
        sku: "FC0T2V-GXKV",
        binLocation: "16-H-40",
        boxCode: "",
        stockPickingId: "3115922",
        uniqueIdentifier: "5121300-3115922-4397756",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121300,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974872",
          sale_order_date: "2021-09-15",
          sale_order_total: 38,
          shipping_address_street: "1834 Thornblade Ridge Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363535,
          shipping_address_name: "Helen Hudson",
          shipping_address_zip: "28105-0314",
          shipping_address_city: "Matthews",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974872-0",
          stock_picking_id: 3115922,
          total_price: 78.75,
          shipping_batch_id: -1,
          date: "2021-09-16 00:31:16",
          picking_write_date: "2021-09-16 01:44:45",
          address_grp_combined:
            "blk/helen hudson/1834 thornblade ridge dr//matthews/north carolina/28105-0314/united states",
          stock_move_is_set: false,
          stock_move_id: 78186754,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "16-H-40",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397756,
          sale_order_line_description: "CR SPPH DIA RG SS 10 DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692416611",
          sale_order_line_size_id: 13,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694230",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T02:00:07.104Z",
        updatedAt: "2021-09-16T02:00:07.104Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "A", bin: "02" },
        price: 70,
        notes: [],
        isSet: false,
        _id: "6142d9e35182abd177a6ec17",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363793",
            street: "3009 PENN MEADE WAY",
            name: "Pam Miller",
            zip: "37214-1170",
            city: "NASHVILLE",
            country: "United States",
            state: "Tennessee",
          },
          orderValue: 70,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e35182abd177a6ec17"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e25182ab186da6ec12",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121437,
          orderNo: "BLK100982137-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100982137",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363793,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694398",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/pam miller/3009 penn meade way//nashville/tennessee/37214-1170/united states",
          createdAt: "2021-09-16T05:45:06.936Z",
          updatedAt: "2021-09-16T05:45:07.085Z",
          labelFile: "5121437/labelFile.txt",
          zplFile: "5121437/zplFile.txt",
          pdfFile: "5121437/pdfFile.txt",
          addressString:
            "3009pennmeadewaynashvilletennessee372141170unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "PDWS0179C",
        productName:
          "0.025 CT Diamond TW 9 - 9.5 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/PDWS0179C.jpg",
        reference: "BLK100982137-0",
        sku: "PDWS0179C",
        binLocation: "17-A-02",
        boxCode: "",
        stockPickingId: "3116052",
        uniqueIdentifier: "5121437-3116052-4397916",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121437,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100982137",
          sale_order_date: "2021-09-15",
          sale_order_total: 31,
          shipping_address_street: "3009 PENN MEADE WAY",
          shipping_address_street_2: null,
          shipping_address_id: 8363793,
          shipping_address_name: "Pam Miller",
          shipping_address_zip: "37214-1170",
          shipping_address_city: "NASHVILLE",
          shipping_address_state: "Tennessee",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100982137-0",
          stock_picking_id: 3116052,
          total_price: 70,
          shipping_batch_id: -1,
          date: "2021-09-16 04:31:05",
          picking_write_date: "2021-09-16 04:40:56",
          address_grp_combined:
            "blk/pam miller/3009 penn meade way//nashville/tennessee/37214-1170/united states",
          stock_move_is_set: false,
          stock_move_id: 78186947,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-A-02",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397916,
          sale_order_line_description: "X-PNDT WHT FWP W/DIA TOP",
          sale_order_line_customer_cost: "70.0",
          sale_order_line_vendor_sku: "075000566207",
          sale_order_line_size_id: null,
          product_product_default_code: "PDWS0179C",
          product_product_name:
            "0.025 CT Diamond TW 9 - 9.5 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver GH I3",
          product_id: 74857,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/PDWS0179C.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694398",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:45:07.074Z",
        updatedAt: "2021-09-16T05:45:07.074Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "E", bin: "10" },
        price: 105.54,
        notes: [],
        isSet: false,
        _id: "6142a8b85182ab6720a6e921",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363563",
            street: "105 WATERWITCH AVE",
            name: "ROBERT BAIER",
            zip: "07732",
            city: "HIGHLANDS",
            country: "United States",
            state: "New Jersey",
          },
          orderValue: 105.54,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a8b85182ab6720a6e921"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a8b75182abe1c9a6e91c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121313,
          orderNo: "FGHPO000087802624-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087802624",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363563,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484730",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-16T02:15:19.920Z",
          updatedAt: "2021-09-16T02:15:20.126Z",
          labelFile: "5121313/labelFile.txt",
          zplFile: "5121313/zplFile.txt",
          pdfFile: "5121313/pdfFile.txt",
          addressString: "105waterwitchavehighlandsnewjersey07732unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ARS01799",
        productName: "1.36 CT TGW Cubic Zirconia Bridal Set Ring Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/ARS01799.jpg",
        reference: "FGHPO000087802624-0",
        sku: "ARS01799",
        binLocation: "17-E-10",
        boxCode: "",
        stockPickingId: "3115937",
        uniqueIdentifier: "5121313-3115937-4397773",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121313,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087802624",
          sale_order_date: "2021-09-15",
          sale_order_total: 26,
          shipping_address_street: "105 WATERWITCH AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363563,
          shipping_address_name: "ROBERT BAIER",
          shipping_address_zip: "07732",
          shipping_address_city: "HIGHLANDS",
          shipping_address_state: "New Jersey",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087802624-0",
          stock_picking_id: 3115937,
          total_price: 105.54,
          shipping_batch_id: -1,
          date: "2021-09-16 00:57:43",
          picking_write_date: "2021-09-16 02:14:51",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186782,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-E-10",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397773,
          sale_order_line_description: "SS 1.36 CT CZ Ring Set. 7",
          sale_order_line_customer_cost: "105.54",
          sale_order_line_vendor_sku: "FGH002447",
          sale_order_line_size_id: 7,
          product_product_default_code: "ARS01799",
          product_product_name:
            "1.36 CT TGW Cubic Zirconia Bridal Set Ring Silver White",
          product_id: 343514,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ARS01799.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484730",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T02:15:20.114Z",
        updatedAt: "2021-09-16T02:15:20.115Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "H", bin: "12" },
        price: 80.1,
        notes: [],
        isSet: false,
        _id: "6142eb785182aba2a1a6eca8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363839",
            street: "2065 HANOVER CIRCLE",
            name: "SUE LYONS",
            zip: "77706",
            city: "BEAUMONT",
            country: "United States",
            state: "Texas",
          },
          orderValue: 80.1,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb785182aba2a1a6eca8"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb785182ab9acba6eca3",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121464,
          orderNo: "SAMS8775308371-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775308371",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363839,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594243",
          carrier: "Fedex",
          shippingMethod: " FedEx Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:00:08.268Z",
          updatedAt: "2021-09-16T07:00:08.439Z",
          labelFile: "5121464/labelFile.txt",
          zplFile: "5121464/zplFile.txt",
          pdfFile: "5121464/pdfFile.txt",
          addressString: "2065hanovercirclebeaumonttexas77706unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC27VX-EKM1",
        productName:
          "1 1/6 CT TGW White Topaz And 11-12 MM White Freshwater Cultured Pearl Clip Back Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC27VX-EKM1.jpg",
        reference: "SAMS8775308371-0",
        sku: "FC27VX-EKM1",
        binLocation: "17-H-12",
        boxCode: "SCE",
        stockPickingId: "3116078",
        uniqueIdentifier: "5121464-3116078-4397947",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121464,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775308371",
          sale_order_date: "2021-09-15",
          sale_order_total: 54,
          shipping_address_street: "2065 HANOVER CIRCLE",
          shipping_address_street_2: "",
          shipping_address_id: 8363839,
          shipping_address_name: "SUE LYONS",
          shipping_address_zip: "77706",
          shipping_address_city: "BEAUMONT",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775308371-0",
          stock_picking_id: 3116078,
          total_price: 80.1,
          shipping_batch_id: -1,
          date: "2021-09-16 05:19:08",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186981,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-H-12",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397947,
          sale_order_line_description: "PEARL EARRINGS",
          sale_order_line_customer_cost: "80.1",
          sale_order_line_vendor_sku: "SAMS03196",
          sale_order_line_size_id: null,
          product_product_default_code: "FC27VX-EKM1",
          product_product_name:
            "1 1/6 CT TGW White Topaz And 11-12 MM White Freshwater Cultured Pearl Clip Back Earrings Silver",
          product_id: 3102695,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC27VX-EKM1.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " FedEx Ground",
          order_tracking_number: "501515594243",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:00:08.425Z",
        updatedAt: "2021-09-16T07:00:08.425Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "K", bin: "03" },
        price: 167.99,
        notes: [],
        isSet: false,
        _id: "6142eb785182ab9ef4a6ecb5",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363848",
            street: "220 W Bluff Ave",
            name: "Kristine Walter",
            zip: "93711",
            city: "Fresno",
            country: "United States",
            state: "California",
          },
          orderValue: 167.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb785182ab9ef4a6ecb5"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb785182ab538ba6ecb0",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121465,
          orderNo: "OS355693221-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355693221",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363848,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256580",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/kristine walter/220 w bluff ave//fresno/california/93711/united states",
          createdAt: "2021-09-16T07:00:08.624Z",
          updatedAt: "2021-09-16T07:00:08.784Z",
          labelFile: "5121465/labelFile.txt",
          zplFile: "5121465/zplFile.txt",
          pdfFile: "5121465/pdfFile.txt",
          addressString: "220wbluffavefresnocalifornia93711unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0YJM-UJYZ",
        productName:
          "1/4 CT Black Diamond TW Hoop Earrings Silver Black Rhodium Plated",
        productImage: "https://partner.delmarintl.ca/catalog/FC0YJM-UJYZ.jpg",
        reference: "OS355693221-0",
        sku: "FC0YJM-UJYZ",
        binLocation: "17-K-03",
        boxCode: "PC-MIADORA:CRD-MIADORA",
        stockPickingId: "3116081",
        uniqueIdentifier: "5121465-3116081-4397949",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121465,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355693221",
          sale_order_date: "2021-09-15",
          sale_order_total: 113,
          shipping_address_street: "220 W Bluff Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363848,
          shipping_address_name: "Kristine Walter",
          shipping_address_zip: "93711",
          shipping_address_city: "Fresno",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355693221-0",
          stock_picking_id: 3116081,
          total_price: 167.99,
          shipping_batch_id: -1,
          date: "2021-09-16 05:21:48",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined:
            "os/kristine walter/220 w bluff ave//fresno/california/93711/united states",
          stock_move_is_set: false,
          stock_move_id: 78186984,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-K-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397949,
          sale_order_line_description:
            "Miadora Sterling Silver 1/4ct TDW Black Diamond Hoop Earrings",
          sale_order_line_customer_cost: "167.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC0YJM-UJYZ",
          product_product_name:
            "1/4 CT Black Diamond TW Hoop Earrings Silver Black Rhodium Plated",
          product_id: 102822,
          ship2store: false,
          is_no_resize: null,
          box_code: "PC-MIADORA:CRD-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0YJM-UJYZ.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256580",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T07:00:08.771Z",
        updatedAt: "2021-09-16T07:00:08.772Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "M", bin: "13" },
        price: 49.68,
        notes: [],
        isSet: false,
        _id: "6142a5255182ab7942a6e8df",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363523",
            street: "948 REDAN CRES",
            name: "VICTORIA HALL",
            zip: "30088",
            city: "STONE MOUNTAIN",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 49.68,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a5255182ab7942a6e8df"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a5245182abd522a6e8da",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121293,
          orderNo: "SAMS8775305910-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775305910",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363523,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594200",
          carrier: "Fedex",
          shippingMethod: " FedEx Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T02:00:04.908Z",
          updatedAt: "2021-09-16T02:00:05.288Z",
          labelFile: "5121293/labelFile.txt",
          zplFile: "5121293/zplFile.txt",
          pdfFile: "5121293/pdfFile.txt",
          addressString: "948redancresstonemountaingeorgia30088unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC27VT-L0UR",
        productName:
          "0.59 CT TGW White Topaz And 11-12 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC27VT-L0UR.jpg",
        reference: "SAMS8775305910-0",
        sku: "FC27VT-L0UR",
        binLocation: "17-M-13",
        boxCode: "SCU",
        stockPickingId: "3115918",
        uniqueIdentifier: "5121293-3115918-4397749",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121293,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775305910",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "948 REDAN CRES",
          shipping_address_street_2: "",
          shipping_address_id: 8363523,
          shipping_address_name: "VICTORIA HALL",
          shipping_address_zip: "30088",
          shipping_address_city: "STONE MOUNTAIN",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775305910-0",
          stock_picking_id: 3115918,
          total_price: 49.68,
          shipping_batch_id: -1,
          date: "2021-09-16 00:18:40",
          picking_write_date: "2021-09-16 01:44:45",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186749,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-M-13",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397749,
          sale_order_line_description: "PEARL PENDANT",
          sale_order_line_customer_cost: "49.68",
          sale_order_line_vendor_sku: "SAMS03195",
          sale_order_line_size_id: null,
          product_product_default_code: "FC27VT-L0UR",
          product_product_name:
            "0.59 CT TGW White Topaz And 11-12 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
          product_id: 3102694,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC27VT-L0UR.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " FedEx Ground",
          order_tracking_number: "501515594200",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T02:00:05.215Z",
        updatedAt: "2021-09-16T02:00:05.215Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["12", "13", "14", "15", "16", "17"],
    _id: "6195ae14fc40c13f24ca03fc",
    createdAt: "2021-11-18T01:36:20.831Z",
    updatedAt: "2021-11-18T01:36:20.831Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 20,
    orderBins: {
      "614285805182ab7c6fa6e773": "BLK100969924-0",
      "614289165182ab3f16a6e78d": "GVX2783881-DEL01-0",
      "614297165182ab6fdfa6e7f1": "TAR9484094753-0",
      "6142a1a15182ab6726a6e887": "BLK100975028-0",
      "6142a5245182abd522a6e8da": "SAMS8775305910-0",
      "6142a5255182ab3c8ba6e8e7": "JCP225999624_274-0",
      "6142a5265182ab9a0aa6e8f4": "BLK100974872-0",
      "6142a8b75182abe1c9a6e91c": "FGHPO000087802624-0",
      "6142b3355182ab5551a6e951": "OS355673078-0",
      "6142c4c95182abe02da6ea90": "JCP226002490_272-0",
      "6142c4ca5182abcc6aa6eac5": "OS355682152-0",
      "6142cf7d5182abdad5a6eb21": "BLK100981930-0",
      "6142d9e25182ab186da6ec12": "BLK100982137-0",
      "6142eb765182ab007ca6ec62": "TAR9948332286-0",
      "6142eb765182ab2e31a6ec6f": "JCP226005379_267-0",
      "6142eb775182ab4daca6ec89": "FGHPO000087805173-0",
      "6142eb785182ab538ba6ecb0": "OS355693221-0",
      "6142eb785182ab9acba6eca3": "SAMS8775308371-0",
      "6142f6025182ab0e43a6ed3a": "BLK100985403-0",
      "6142f9b75182ab4493a6ed54": "OS355695582-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "18", section: "B", bin: "10" },
        price: 116.58,
        notes: [],
        isSet: false,
        _id: "6142b3355182ab3404a6e949",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363588",
            street: "36668 CATALPA LN",
            name: "MARIE MAHONEY",
            zip: "48047",
            city: "NEW BALTIMORE",
            country: "United States",
            state: "Michigan",
          },
          orderValue: 116.58,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3355182ab3404a6e949"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3355182abfc19a6e944",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121327,
          orderNo: "SAMS8775306267-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775306267",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363588,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594210",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T03:00:05.497Z",
          updatedAt: "2021-09-16T03:00:05.689Z",
          labelFile: "5121327/labelFile.txt",
          zplFile: "5121327/zplFile.txt",
          pdfFile: "5121327/pdfFile.txt",
          addressString: "36668catalpalnnewbaltimoremichigan48047unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC156Z-MLTW",
        productName:
          "2 3/4 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC156Z-MLTW.jpg",
        reference: "SAMS8775306267-0",
        sku: "FC156Z-MLTW",
        binLocation: "18-B-10",
        boxCode: "SCU",
        stockPickingId: "3115950",
        uniqueIdentifier: "5121327-3115950-4397788",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121327,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775306267",
          sale_order_date: "2021-09-15",
          sale_order_total: 87,
          shipping_address_street: "36668 CATALPA LN",
          shipping_address_street_2: "",
          shipping_address_id: 8363588,
          shipping_address_name: "MARIE MAHONEY",
          shipping_address_zip: "48047",
          shipping_address_city: "NEW BALTIMORE",
          shipping_address_state: "Michigan",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775306267-0",
          stock_picking_id: 3115950,
          total_price: 116.58,
          shipping_batch_id: -1,
          date: "2021-09-16 01:18:44",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186797,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-B-10",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397788,
          sale_order_line_description: "7-9 MM PEARL PEND",
          sale_order_line_customer_cost: "116.58",
          sale_order_line_vendor_sku: "SAMS01949",
          sale_order_line_size_id: null,
          product_product_default_code: "FC156Z-MLTW",
          product_product_name:
            "2 3/4 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
          product_id: 184494,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC156Z-MLTW.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594210",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:05.668Z",
        updatedAt: "2021-09-16T03:00:05.668Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "F", bin: "06" },
        price: 275,
        notes: [],
        isSet: false,
        _id: "6142c1595182abb519a6ea24",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363643",
            street: "155 Glendale Dr",
            name: "Jessica Charran",
            zip: "50263",
            city: "Waukee",
            country: "United States",
            state: "Iowa",
          },
          orderValue: 275,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c1595182abb519a6ea24"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c1595182ab65cca6ea1f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121353,
          orderNo: "KOH6180004177_1-0",
          customerId: 20,
          customerName: "Kohls",
          orderReference: "KOH6180004177_1",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363643,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400111895282849891298",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T04:00:25.464Z",
          updatedAt: "2021-09-16T04:00:25.610Z",
          labelFile: "5121353/labelFile.txt",
          zplFile: "5121353/zplFile.txt",
          pdfFile: "5121353/pdfFile.txt",
          addressString: "155glendaledrwaukeeiowa50263unitedstates",
          normalizedCustomerName: "kohls",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0YPU-Y6JK",
        productName:
          "0.02 CT Diamond TW And 1 1/8 CT TGW Created Blue Sapphire Heart Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0YPU-Y6JK.jpg",
        reference: "KOH6180004177_1-0",
        sku: "FC0YPU-Y6JK",
        binLocation: "18-F-06",
        boxCode: "",
        stockPickingId: "3115976",
        uniqueIdentifier: "5121353-3115976-4397821",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121353,
          sale_order_customer_id: 20,
          sale_order_customer_name: "Kohls",
          sale_order_order_reference: "KOH6180004177_1",
          sale_order_date: "2021-09-15",
          sale_order_total: 44,
          shipping_address_street: "155 Glendale Dr",
          shipping_address_street_2: "",
          shipping_address_id: 8363643,
          shipping_address_name: "Jessica Charran",
          shipping_address_zip: "50263",
          shipping_address_city: "Waukee",
          shipping_address_state: "Iowa",
          shipping_address_country: "United States",
          stock_picking_reference: "KOH6180004177_1-0",
          stock_picking_id: 3115976,
          total_price: 275,
          shipping_batch_id: -1,
          date: "2021-09-16 02:07:14",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186834,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-F-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397821,
          sale_order_line_description:
            "0.02 CT Diamond TW And 1 1/8 CT TGW Created Blue",
          sale_order_line_customer_cost: "275.0",
          sale_order_line_vendor_sku: "95497889",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0YPU-Y6JK",
          product_product_name:
            "0.02 CT Diamond TW And 1 1/8 CT TGW Created Blue Sapphire Heart Pendant With Chain Silver GH I3",
          product_id: 103482,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0YPU-Y6JK.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400111895282849891298",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:25.598Z",
        updatedAt: "2021-09-16T04:00:25.599Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "G", bin: "11" },
        price: 40,
        notes: [],
        isSet: false,
        _id: "6142d9e15182ab0294a6ebcd",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363748",
            street: "436 Hayes St",
            name: "Amanda J Ponikiewski",
            zip: "75166-1885",
            city: "Lavon",
            country: "United States",
            state: "Texas",
          },
          orderValue: 40,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e15182ab0294a6ebcd"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e05182abf9cea6ebc8",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121416,
          orderNo: "TAR9948320910-0",
          customerId: 181,
          customerName: "Target",
          orderReference: "TAR9948320910",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363748,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552395",
          carrier: "UPS",
          shippingMethod: " UPS Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:45:04.883Z",
          updatedAt: "2021-09-16T05:45:05.075Z",
          labelFile: "5121416/labelFile.txt",
          zplFile: "5121416/zplFile.txt",
          pdfFile: "5121416/pdfFile.txt",
          addressString: "436hayesstlavontexas751661885unitedstates",
          normalizedCustomerName: "target",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RZS02169",
        productName: "SILVER 5CT TGW CUBIC ZIRCONIA ENGAGEMENT RING",
        productImage: "https://partner.delmarintl.ca/catalog/RZS02169.jpg",
        reference: "TAR9948320910-0",
        sku: "RZS02169",
        binLocation: "18-G-11",
        boxCode: "",
        stockPickingId: "3116032",
        uniqueIdentifier: "5121416-3116032-4397891",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121416,
          sale_order_customer_id: 181,
          sale_order_customer_name: "Target",
          sale_order_order_reference: "TAR9948320910",
          sale_order_date: "2021-09-15",
          sale_order_total: 32,
          shipping_address_street: "436 Hayes St",
          shipping_address_street_2: "",
          shipping_address_id: 8363748,
          shipping_address_name: "Amanda J Ponikiewski",
          shipping_address_zip: "75166-1885",
          shipping_address_city: "Lavon",
          shipping_address_state: "Texas",
          shipping_address_country: "United States",
          stock_picking_reference: "TAR9948320910-0",
          stock_picking_id: 3116032,
          total_price: 40,
          shipping_batch_id: -1,
          date: "2021-09-16 04:04:34",
          picking_write_date: "2021-09-16 04:41:22",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186923,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-G-11",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397891,
          sale_order_line_description: "RINGS ALLURA",
          sale_order_line_customer_cost: "40.0",
          sale_order_line_vendor_sku: "TAR010549-0800",
          sale_order_line_size_id: 9,
          product_product_default_code: "RZS02169",
          product_product_name: "SILVER 5CT TGW CUBIC ZIRCONIA ENGAGEMENT RING",
          product_id: 180997,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RZS02169.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " UPS Ground",
          order_tracking_number: "1Z8R9V140318552395",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:45:05.062Z",
        updatedAt: "2021-09-16T05:45:05.063Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "H", bin: "16" },
        price: null,
        notes: [],
        isSet: false,
        _id: "6142d2d75182abb8d7a6eb74",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363745",
            street: "1469 East 24th Street",
            name: "Silvia Spivakovsky",
            zip: "11210",
            city: "Brooklyn",
            country: "United States",
            state: "New York",
          },
          orderValue: 0,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d2d75182abb8d7a6eb74"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d75182ab643da6eb6f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121410,
          orderNo: "HAU66354395-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66354395",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363745,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552322",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/silvia spivakovsky/1469 east 24th street//brooklyn/new york/11210/united states",
          createdAt: "2021-09-16T05:15:03.662Z",
          updatedAt: "2021-09-16T05:15:03.881Z",
          labelFile: "5121410/labelFile.txt",
          zplFile: "5121410/zplFile.txt",
          pdfFile: "5121410/pdfFile.txt",
          addressString: "1469east24thstreetbrooklynnewyork11210unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "PAPS",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC2A1R-KCUT",
        productName:
          "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A1R-KCUT.jpg",
        reference: "HAU66354395-0",
        sku: "FC2A1R-KCUT",
        binLocation: "18-H-16",
        boxCode: "",
        stockPickingId: "3116026",
        uniqueIdentifier: "5121410-3116026-4397884",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121410,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66354395",
          sale_order_date: "2021-09-15",
          sale_order_total: 17,
          shipping_address_street: "1469 East 24th Street",
          shipping_address_street_2: "",
          shipping_address_id: 8363745,
          shipping_address_name: "Silvia Spivakovsky",
          shipping_address_zip: "11210",
          shipping_address_city: "Brooklyn",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66354395-0",
          stock_picking_id: 3116026,
          total_price: 0,
          shipping_batch_id: -1,
          date: "2021-09-16 03:46:17",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "hau/silvia spivakovsky/1469 east 24th street//brooklyn/new york/11210/united states",
          stock_move_is_set: false,
          stock_move_id: 78186906,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-H-16",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397884,
          sale_order_line_description:
            "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater C",
          sale_order_line_customer_cost: null,
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "FC2A1R-KCUT",
          product_product_name:
            "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
          product_id: 3110490,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A1R-KCUT.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552322",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:15:03.867Z",
        updatedAt: "2021-09-16T05:15:03.868Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "I", bin: "03" },
        price: null,
        notes: [],
        isSet: false,
        _id: "6142fd085182ab740fa6ed97",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363911",
            street: "3020 BARCLAY WAY",
            name: "Tae Kim",
            zip: "48105",
            city: "ANN ARBOR",
            country: "United States",
            state: "Michigan",
          },
          orderValue: 0,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd085182ab740fa6ed97"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd085182ab3990a6ed92",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121500,
          orderNo: "HAU66355830-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355830",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363911,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552493",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/tae kim/3020 barclay way//ann arbor/michigan/48105/united states",
          createdAt: "2021-09-16T08:15:04.500Z",
          updatedAt: "2021-09-16T08:15:04.666Z",
          labelFile: "5121500/labelFile.txt",
          zplFile: "5121500/zplFile.txt",
          pdfFile: "5121500/pdfFile.txt",
          addressString: "3020barclaywayannarbormichigan48105unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "PAPS",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "NYSY0298CWS",
        productName:
          "33.00 CT TGW Created White Sapphire Necklace Silver Yellow Tongue and Groove Clasp Length (inches): 17",
        productImage: "https://partner.delmarintl.ca/catalog/NYSY0298CWS.jpg",
        reference: "HAU66355830-0",
        sku: "NYSY0298CWS",
        binLocation: "18-I-03",
        boxCode: "",
        stockPickingId: "3116113",
        uniqueIdentifier: "5121500-3116113-4397986",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121500,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355830",
          sale_order_date: "2021-09-15",
          sale_order_total: 69,
          shipping_address_street: "3020 BARCLAY WAY",
          shipping_address_street_2: "",
          shipping_address_id: 8363911,
          shipping_address_name: "Tae Kim",
          shipping_address_zip: "48105",
          shipping_address_city: "ANN ARBOR",
          shipping_address_state: "Michigan",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355830-0",
          stock_picking_id: 3116113,
          total_price: 0,
          shipping_batch_id: -1,
          date: "2021-09-16 06:46:15",
          picking_write_date: "2021-09-16 07:10:47",
          address_grp_combined:
            "hau/tae kim/3020 barclay way//ann arbor/michigan/48105/united states",
          stock_move_is_set: false,
          stock_move_id: 78187025,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-I-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397986,
          sale_order_line_description:
            "33.00 CT TGW Created White Sapphire Necklace Sil",
          sale_order_line_customer_cost: null,
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "NYSY0298CWS",
          product_product_name:
            "33.00 CT TGW Created White Sapphire Necklace Silver Yellow Tongue and Groove Clasp Length (inches): 17",
          product_id: 3123970,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/NYSY0298CWS.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552493",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:04.652Z",
        updatedAt: "2021-09-16T08:15:04.653Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "M", bin: "18" },
        price: 79.99,
        notes: [],
        isSet: false,
        _id: "6142c1595182ab0fa4a6ea16",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363639",
            street: "4235 E 65th S",
            name: "Randy Duncan",
            zip: "83406",
            city: "Idaho Falls",
            country: "United States",
            state: "Idaho",
          },
          orderValue: 79.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c1595182ab0fa4a6ea16"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c1585182ab23dda6ea11",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121351,
          orderNo: "OS355675609-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355675609",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363639,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256221",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/randy duncan/4235 e 65th s//idaho falls/idaho/83406/united states",
          createdAt: "2021-09-16T04:00:24.981Z",
          updatedAt: "2021-09-16T04:00:25.152Z",
          labelFile: "5121351/labelFile.txt",
          zplFile: "5121351/zplFile.txt",
          pdfFile: "5121351/pdfFile.txt",
          addressString: "4235e65thsidahofallsidaho83406unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "NWKT1300",
        productName:
          '10KY 17" 5.5-6mm Freshwater Cultured Pearl "Tin-Cup"-Style Necklace on Rope 6R Chain w/ spring ring clasp',
        productImage: "https://partner.delmarintl.ca/catalog/NWKT1300.jpg",
        reference: "OS355675609-0",
        sku: "NWKT1300",
        binLocation: "18-M-18",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115974",
        uniqueIdentifier: "5121351-3115974-4397820",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121351,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355675609",
          sale_order_date: "2021-09-15",
          sale_order_total: 52,
          shipping_address_street: "4235 E 65th S",
          shipping_address_street_2: null,
          shipping_address_id: 8363639,
          shipping_address_name: "Randy Duncan",
          shipping_address_zip: "83406",
          shipping_address_city: "Idaho Falls",
          shipping_address_state: "Idaho",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355675609-0",
          stock_picking_id: 3115974,
          total_price: 79.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:07:09",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "os/randy duncan/4235 e 65th s//idaho falls/idaho/83406/united states",
          stock_move_is_set: false,
          stock_move_id: 78186833,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-M-18",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397820,
          sale_order_line_description:
            "Miadora 10k Yellow Gold White Cultured Freshwater Pearl Necklace (5.5-6mm)",
          sale_order_line_customer_cost: "79.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "NWKT1300",
          product_product_name:
            '10KY 17" 5.5-6mm Freshwater Cultured Pearl "Tin-Cup"-Style Necklace on Rope 6R Chain w/ spring ring clasp',
          product_id: 34345,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/NWKT1300.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256221",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:25.141Z",
        updatedAt: "2021-09-16T04:00:25.141Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "O", bin: "12" },
        price: 62.49,
        notes: [],
        isSet: false,
        _id: "6142cf7d5182ab10fda6eb18",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363722",
            street: "1231 Clydesdale Dr",
            name: "STACY LEE",
            zip: "72719",
            city: "Centerton",
            country: "United States",
            state: "Arkansas",
          },
          orderValue: 62.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7d5182ab10fda6eb18"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7c5182ab26d0a6eb13",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121401,
          orderNo: "OS355685889-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355685889",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363722,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256542",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/stacy lee/1231 clydesdale dr//centerton/arkansas/72719/united states",
          createdAt: "2021-09-16T05:00:44.848Z",
          updatedAt: "2021-09-16T05:00:45.039Z",
          labelFile: "5121401/labelFile.txt",
          zplFile: "5121401/zplFile.txt",
          pdfFile: "5121401/pdfFile.txt",
          addressString: "1231clydesdaledrcentertonarkansas72719unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC14WT-K1UJ",
        productName:
          "0.02 CT Black Diamond TW Animal Pendant With Chain Silver Black Rhodium Plated",
        productImage: "https://partner.delmarintl.ca/catalog/FC14WT-K1UJ.jpg",
        reference: "OS355685889-0",
        sku: "FC14WT-K1UJ",
        binLocation: "18-O-12",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116017",
        uniqueIdentifier: "5121401-3116017-4397875",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121401,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355685889",
          sale_order_date: "2021-09-15",
          sale_order_total: 46,
          shipping_address_street: "1231 Clydesdale Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363722,
          shipping_address_name: "STACY LEE",
          shipping_address_zip: "72719",
          shipping_address_city: "Centerton",
          shipping_address_state: "Arkansas",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355685889-0",
          stock_picking_id: 3116017,
          total_price: 62.49,
          shipping_batch_id: -1,
          date: "2021-09-16 03:21:58",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined:
            "os/stacy lee/1231 clydesdale dr//centerton/arkansas/72719/united states",
          stock_move_is_set: false,
          stock_move_id: 78186897,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-O-12",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397875,
          sale_order_line_description:
            "Miadora Sterling Silver Black Diamond Koala Bear Necklace",
          sale_order_line_customer_cost: "62.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC14WT-K1UJ",
          product_product_name:
            "0.02 CT Black Diamond TW Animal Pendant With Chain Silver Black Rhodium Plated",
          product_id: 183195,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC14WT-K1UJ.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256542",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T05:00:45.026Z",
        updatedAt: "2021-09-16T05:00:45.027Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["18"],
    _id: "6195ae14fc40c13f24ca03fd",
    createdAt: "2021-11-18T01:36:20.845Z",
    updatedAt: "2021-11-18T01:36:20.845Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 7,
    orderBins: {
      "6142b3355182abfc19a6e944": "SAMS8775306267-0",
      "6142c1585182ab23dda6ea11": "OS355675609-0",
      "6142c1595182ab65cca6ea1f": "KOH6180004177_1-0",
      "6142cf7c5182ab26d0a6eb13": "OS355685889-0",
      "6142d2d75182ab643da6eb6f": "HAU66354395-0",
      "6142d9e05182abf9cea6ebc8": "TAR9948320910-0",
      "6142fd085182ab3990a6ed92": "HAU66355830-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "20", section: "C", bin: "06" },
        price: 152.9,
        notes: [],
        isSet: false,
        _id: "614289245182abc42ea6e7dc",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363422",
            street: "150 RED CEDAR DR",
            name: "OLGA GEGELSKA",
            zip: "60107",
            city: "STREAMWOOD",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 152.9,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289245182abc42ea6e7dc"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289235182ab425ba6e7d7",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121239,
          orderNo: "SAMS8775304809-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775304809",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363422,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594162",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:35.995Z",
          updatedAt: "2021-09-16T00:00:36.227Z",
          labelFile: "5121239/labelFile.txt",
          zplFile: "5121239/zplFile.txt",
          pdfFile: "5121239/pdfFile.txt",
          addressString: "150redcedardrstreamwoodillinois60107unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1570-4V3H",
        productName:
          "2 7/8 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Post Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1570-4V3H.jpg",
        reference: "SAMS8775304809-0",
        sku: "FC1570-4V3H",
        binLocation: "20-C-06",
        boxCode: "SCU",
        stockPickingId: "3115868",
        uniqueIdentifier: "5121239-3115868-4397679",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121239,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775304809",
          sale_order_date: "2021-09-15",
          sale_order_total: 106,
          shipping_address_street: "150 RED CEDAR DR",
          shipping_address_street_2: "",
          shipping_address_id: 8363422,
          shipping_address_name: "OLGA GEGELSKA",
          shipping_address_zip: "60107",
          shipping_address_city: "STREAMWOOD",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775304809-0",
          stock_picking_id: 3115868,
          total_price: 152.9,
          shipping_batch_id: -1,
          date: "2021-09-15 22:19:01",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186674,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-C-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397679,
          sale_order_line_description: "6-8.5 MM PEARL EAR",
          sale_order_line_customer_cost: "152.9",
          sale_order_line_vendor_sku: "SAMS01683",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1570-4V3H",
          product_product_name:
            "2 7/8 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Post Earrings Silver",
          product_id: 184493,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1570-4V3H.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594162",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T00:00:36.215Z",
        updatedAt: "2021-09-16T00:00:36.216Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "D", bin: "07" },
        price: 109.99,
        notes: [],
        isSet: false,
        _id: "6142c4cb5182ab2210a6ead8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363684",
            street: "521 Ridgeview Dr",
            name: "heather scobell",
            zip: "16505",
            city: "Erie",
            country: "United States",
            state: "Pennsylvania",
          },
          orderValue: 109.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c4cb5182ab2210a6ead8"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c4cb5182ab486aa6ead3",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121379,
          orderNo: "OS355680603-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355680603",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363684,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256207",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/heather scobell/521 ridgeview dr//erie/pennsylvania/16505/united states",
          createdAt: "2021-09-16T04:15:07.361Z",
          updatedAt: "2021-09-16T04:15:07.509Z",
          labelFile: "5121379/labelFile.txt",
          zplFile: "5121379/zplFile.txt",
          pdfFile: "5121379/pdfFile.txt",
          addressString: "521ridgeviewdreriepennsylvania16505unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T30-YUE1",
        productName:
          "0.04 CT Diamond TW And 8.06 CT TGW Created Blue Sapphire Created White Sapphire LeverBack Earrings Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T30-YUE1.jpg",
        reference: "OS355680603-0",
        sku: "FC0T30-YUE1",
        binLocation: "20-D-07",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115999",
        uniqueIdentifier: "5121379-3115999-4397845",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121379,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355680603",
          sale_order_date: "2021-09-15",
          sale_order_total: 73,
          shipping_address_street: "521 Ridgeview Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363684,
          shipping_address_name: "heather scobell",
          shipping_address_zip: "16505",
          shipping_address_city: "Erie",
          shipping_address_state: "Pennsylvania",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355680603-0",
          stock_picking_id: 3115999,
          total_price: 109.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:45:01",
          picking_write_date: "2021-09-16 03:11:18",
          address_grp_combined:
            "os/heather scobell/521 ridgeview dr//erie/pennsylvania/16505/united states",
          stock_move_is_set: false,
          stock_move_id: 78186868,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-D-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397845,
          sale_order_line_description:
            "Miadora Sterling Silver Blue and White Sapphire Diamond Earrings",
          sale_order_line_customer_cost: "109.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T30-YUE1",
          product_product_name:
            "0.04 CT Diamond TW And 8.06 CT TGW Created Blue Sapphire Created White Sapphire LeverBack Earrings Silver GH I3",
          product_id: 101100,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T30-YUE1.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256207",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:15:07.497Z",
        updatedAt: "2021-09-16T04:15:07.497Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "04" },
        price: 19.97,
        notes: [],
        isSet: false,
        _id: "6142a8b75182ab32eaa6e914",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363558",
            street: "4997 Owens Mill Circle",
            name: "Jemekia Thornton",
            zip: "30083",
            city: "Stone Mountain",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 19.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a8b75182ab32eaa6e914"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a8b75182ab8fc5a6e90f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121310,
          orderNo: "HAU66353297-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66353297",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363558,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552233",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/jemekia thornton/4997 owens mill circle//stone mountain/georgia/30083/united states",
          createdAt: "2021-09-16T02:15:19.555Z",
          updatedAt: "2021-09-16T02:15:19.736Z",
          labelFile: "5121310/labelFile.txt",
          zplFile: "5121310/zplFile.txt",
          pdfFile: "5121310/pdfFile.txt",
          addressString:
            "4997owensmillcirclestonemountaingeorgia30083unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 2,
        productCode: "SW0673W",
        productName:
          '3pc Set of 6-7mm Freshwater Cultured Potato Pearl Elastic Bracelet, 18" Necklace w/ Silvertone Fish Eye Clasp & 6-7mm Freshwater Cultured Pearl Stud Earrings w/ Silvertone backs',
        productImage: "https://partner.delmarintl.ca/catalog/SW0673W.jpg",
        reference: "HAU66353297-0",
        sku: "SW0673W",
        binLocation: "20-F-04",
        boxCode: "",
        stockPickingId: "3115934",
        uniqueIdentifier: "5121310-3115934-4397770",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121310,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66353297",
          sale_order_date: "2021-09-15",
          sale_order_total: 24,
          shipping_address_street: "4997 Owens Mill Circle",
          shipping_address_street_2: "",
          shipping_address_id: 8363558,
          shipping_address_name: "Jemekia Thornton",
          shipping_address_zip: "30083",
          shipping_address_city: "Stone Mountain",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66353297-0",
          stock_picking_id: 3115934,
          total_price: 39.94,
          shipping_batch_id: -1,
          date: "2021-09-16 00:45:53",
          picking_write_date: "2021-09-16 01:10:40",
          address_grp_combined:
            "hau/jemekia thornton/4997 owens mill circle//stone mountain/georgia/30083/united states",
          stock_move_is_set: false,
          stock_move_id: 78186770,
          stock_move_set_product_id: -1,
          stock_move_quantity: 2,
          stock_move_bin: "20-F-04",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397770,
          sale_order_line_description:
            "3pc Set of 6-7mm Freshwater Cultured Potato Pear",
          sale_order_line_customer_cost: "19.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "SW0673W",
          product_product_name:
            '3pc Set of 6-7mm Freshwater Cultured Potato Pearl Elastic Bracelet, 18" Necklace w/ Silvertone Fish Eye Clasp & 6-7mm Freshwater Cultured Pearl Stud Earrings w/ Silvertone backs',
          product_id: 111469,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/SW0673W.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552233",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T02:15:19.724Z",
        updatedAt: "2021-09-16T02:15:19.725Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "05" },
        price: 85.95,
        notes: [],
        isSet: false,
        _id: "614297175182ab610aa6e810",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363459",
            street: "17147 W Hilton Ave",
            name: "Holly E Weiss",
            zip: "85338",
            city: "Goodyear",
            country: "United States",
            state: "Arizona",
          },
          orderValue: 85.95,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614297175182ab610aa6e810"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297175182abac99a6e80b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121257,
          orderNo: "SAMS8775305290-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775305290",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363459,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594184",
          carrier: "Fedex",
          shippingMethod: " FedEx Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T01:00:07.585Z",
          updatedAt: "2021-09-16T01:00:07.802Z",
          labelFile: "5121257/labelFile.txt",
          zplFile: "5121257/zplFile.txt",
          pdfFile: "5121257/pdfFile.txt",
          addressString: "17147whiltonavegoodyeararizona85338unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "BGYS0542BWS",
        productName:
          '14 1/4 CT TGW Created Blue & White Sapphire Bracelet Silver Length (inches): 7.25"',
        productImage: "https://partner.delmarintl.ca/catalog/BGYS0542BWS.jpg",
        reference: "SAMS8775305290-0",
        sku: "BGYS0542BWS",
        binLocation: "20-F-05",
        boxCode: "SCB",
        stockPickingId: "3115886",
        uniqueIdentifier: "5121257-3115886-4397699",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121257,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775305290",
          sale_order_date: "2021-09-15",
          sale_order_total: 59,
          shipping_address_street: "17147 W Hilton Ave",
          shipping_address_street_2: "",
          shipping_address_id: 8363459,
          shipping_address_name: "Holly E Weiss",
          shipping_address_zip: "85338",
          shipping_address_city: "Goodyear",
          shipping_address_state: "Arizona",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775305290-0",
          stock_picking_id: 3115886,
          total_price: 85.95,
          shipping_batch_id: -1,
          date: "2021-09-15 23:19:01",
          picking_write_date: "2021-09-16 00:45:06",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186699,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-F-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397699,
          sale_order_line_description: "14.2 CTTW SAPPH BRAC",
          sale_order_line_customer_cost: "85.95",
          sale_order_line_vendor_sku: "SAMS03156",
          sale_order_line_size_id: null,
          product_product_default_code: "BGYS0542BWS",
          product_product_name:
            '14 1/4 CT TGW Created Blue & White Sapphire Bracelet Silver Length (inches): 7.25"',
          product_id: 188985,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCB",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/BGYS0542BWS.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " FedEx Ground",
          order_tracking_number: "501515594184",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:00:07.781Z",
        updatedAt: "2021-09-16T01:00:07.782Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "G", bin: "05" },
        price: 128.23,
        notes: [],
        isSet: false,
        _id: "6142d9e25182abf55ea6ec0a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363785",
            street: "4771 ROLANDO BLVD.",
            name: "GINA CAVANAUGH",
            zip: "92115",
            city: "SAN DIEGO",
            country: "United States",
            state: "California",
          },
          orderValue: 128.23,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e25182abf55ea6ec0a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e25182ab121ea6ec05",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121434,
          orderNo: "SAMS8775307569-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775307569",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363785,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594232",
          carrier: "Fedex",
          shippingMethod: "  2nd Day",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:45:06.612Z",
          updatedAt: "2021-09-16T05:45:06.759Z",
          labelFile: "5121434/labelFile.txt",
          zplFile: "5121434/zplFile.txt",
          pdfFile: "5121434/pdfFile.txt",
          addressString: "4771rolandoblvdsandiegocalifornia92115unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "EGKP354100MR",
        productName: "1 CT TGW Morganite Fashion Post Earrings 14k Pink Gold",
        productImage: "https://partner.delmarintl.ca/catalog/EGKP354100MR.jpg",
        reference: "SAMS8775307569-0",
        sku: "EGKP354100MR",
        binLocation: "20-G-05",
        boxCode: "SCBP:SCE",
        stockPickingId: "3116049",
        uniqueIdentifier: "5121434-3116049-4397913",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121434,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775307569",
          sale_order_date: "2021-09-15",
          sale_order_total: 93,
          shipping_address_street: "4771 ROLANDO BLVD.",
          shipping_address_street_2: "",
          shipping_address_id: 8363785,
          shipping_address_name: "GINA CAVANAUGH",
          shipping_address_zip: "92115",
          shipping_address_city: "SAN DIEGO",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775307569-0",
          stock_picking_id: 3116049,
          total_price: 128.23,
          shipping_batch_id: -1,
          date: "2021-09-16 04:18:42",
          picking_write_date: "2021-09-16 04:41:05",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186944,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-G-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / NOCERT",
          sale_order_line_id: 4397913,
          sale_order_line_description: "1 CTTW GEM EARRINGS",
          sale_order_line_customer_cost: "128.23",
          sale_order_line_vendor_sku: "SAMS00195",
          sale_order_line_size_id: null,
          product_product_default_code: "EGKP354100MR",
          product_product_name:
            "1 CT TGW Morganite Fashion Post Earrings 14k Pink Gold",
          product_id: 111097,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCBP:SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/EGKP354100MR.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: "  2nd Day",
          order_tracking_number: "501515594232",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:45:06.748Z",
        updatedAt: "2021-09-16T05:45:06.749Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "H", bin: "07" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "6142cf7e5182abf5efa6eb4d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363729",
            street: "1574 HIBISCUS RD",
            name: "Sandra Hudson",
            zip: "29161-9024",
            city: "TIMMONSVILLE",
            country: "United States",
            state: "South Carolina",
          },
          orderValue: 78.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7e5182abf5efa6eb4d"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7e5182ab12a2a6eb48",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121405,
          orderNo: "BLK100980568-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100980568",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363729,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694354",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/sandra hudson/1574 hibiscus rd//timmonsville/south carolina/29161-9024/united states",
          createdAt: "2021-09-16T05:00:46.384Z",
          updatedAt: "2021-09-16T05:00:46.528Z",
          labelFile: "5121405/labelFile.txt",
          zplFile: "5121405/zplFile.txt",
          pdfFile: "5121405/pdfFile.txt",
          addressString:
            "1574hibiscusrdtimmonsvillesouthcarolina291619024unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2Y-3WR5",
        productName:
          "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
        reference: "BLK100980568-0",
        sku: "FC0T2Y-3WR5",
        binLocation: "20-H-07",
        boxCode: "",
        stockPickingId: "3116021",
        uniqueIdentifier: "5121405-3116021-4397879",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121405,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100980568",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "1574 HIBISCUS RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363729,
          shipping_address_name: "Sandra Hudson",
          shipping_address_zip: "29161-9024",
          shipping_address_city: "TIMMONSVILLE",
          shipping_address_state: "South Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100980568-0",
          stock_picking_id: 3116021,
          total_price: 78.75,
          shipping_batch_id: -1,
          date: "2021-09-16 03:29:19",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined:
            "blk/sandra hudson/1574 hibiscus rd//timmonsville/south carolina/29161-9024/united states",
          stock_move_is_set: false,
          stock_move_id: 78186901,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-H-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397879,
          sale_order_line_description: "CR SPPH CWS OV NK SS DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692415706",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T2Y-3WR5",
          product_product_name:
            "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
          product_id: 107203,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694354",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:00:46.517Z",
        updatedAt: "2021-09-16T05:00:46.518Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "B", bin: "14" },
        price: 45.97,
        notes: [],
        isSet: false,
        _id: "6142ef3b5182ab1d09a6ece6",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363860",
            street: "374 Nuez St",
            name: "Martina Renville",
            zip: "93012",
            city: "Camarillo",
            country: "United States",
            state: "California",
          },
          orderValue: 45.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142ef3b5182ab1d09a6ece6"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142ef3a5182abfd63a6ece1",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121471,
          orderNo: "HAU66355369-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355369",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363860,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552439",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/martina renville/374 nuez st//camarillo/california/93012/united states",
          createdAt: "2021-09-16T07:16:10.996Z",
          updatedAt: "2021-09-16T07:16:11.228Z",
          labelFile: "5121471/labelFile.txt",
          zplFile: "5121471/zplFile.txt",
          pdfFile: "5121471/pdfFile.txt",
          addressString: "374nuezstcamarillocalifornia93012unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC040W-XA9A",
        productName: "1/10 CT Diamond TW Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC040W-XA9A.jpg",
        reference: "HAU66355369-0",
        sku: "FC040W-XA9A",
        binLocation: "21-B-14",
        boxCode: "",
        stockPickingId: "3116086",
        uniqueIdentifier: "5121471-3116086-4397955",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121471,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355369",
          sale_order_date: "2021-09-15",
          sale_order_total: 20,
          shipping_address_street: "374 Nuez St",
          shipping_address_street_2: "",
          shipping_address_id: 8363860,
          shipping_address_name: "Martina Renville",
          shipping_address_zip: "93012",
          shipping_address_city: "Camarillo",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355369-0",
          stock_picking_id: 3116086,
          total_price: 45.97,
          shipping_batch_id: -1,
          date: "2021-09-16 05:45:44",
          picking_write_date: "2021-09-16 07:14:37",
          address_grp_combined:
            "hau/martina renville/374 nuez st//camarillo/california/93012/united states",
          stock_move_is_set: false,
          stock_move_id: 78186990,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-B-14",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397955,
          sale_order_line_description:
            "1/10 CT Diamond TW Fashion Ring Silver I3",
          sale_order_line_customer_cost: "45.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC040W-XA9A",
          product_product_name: "1/10 CT Diamond TW Fashion Ring Silver I3",
          product_id: 88631,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC040W-XA9A.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552439",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T07:16:11.209Z",
        updatedAt: "2021-09-16T07:16:11.210Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "C", bin: "02" },
        price: 148.08,
        notes: [],
        isSet: false,
        _id: "6142fd095182ab6f36a6edb1",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363912",
            street: "12050 PARK BLVD, APT 138",
            name: "ELIZABETH HOLLINGSWORTH",
            zip: "33772",
            city: "Seminole",
            country: "United States",
            state: "Florida",
          },
          orderValue: 148.08,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd095182ab6f36a6edb1"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd095182aba01ca6edac",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121502,
          orderNo: "FGHPO000087805933-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087805933",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363912,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484758",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-16T08:15:05.202Z",
          updatedAt: "2021-09-16T08:15:05.358Z",
          labelFile: "5121502/labelFile.txt",
          zplFile: "5121502/zplFile.txt",
          pdfFile: "5121502/pdfFile.txt",
          addressString: "12050parkblvdapt138seminoleflorida33772unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ABS03045",
        productName:
          "4 1/3 CT TGW Cubic Zirconia Bracelet Silver White Length (inches): 7",
        productImage: "https://partner.delmarintl.ca/catalog/ABS03045.jpg",
        reference: "FGHPO000087805933-0",
        sku: "ABS03045",
        binLocation: "21-C-02",
        boxCode: "",
        stockPickingId: "3116115",
        uniqueIdentifier: "5121502-3116115-4397987",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121502,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087805933",
          sale_order_date: "2021-09-15",
          sale_order_total: 36.5,
          shipping_address_street: "12050 PARK BLVD",
          shipping_address_street_2: "APT 138",
          shipping_address_id: 8363912,
          shipping_address_name: "ELIZABETH HOLLINGSWORTH",
          shipping_address_zip: "33772",
          shipping_address_city: "Seminole",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087805933-0",
          stock_picking_id: 3116115,
          total_price: 148.08,
          shipping_batch_id: -1,
          date: "2021-09-16 06:51:41",
          picking_write_date: "2021-09-16 08:14:42",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187029,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-C-02",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397987,
          sale_order_line_description: "SS 18.25 CT TGW Cubic Zirconia",
          sale_order_line_customer_cost: "148.08",
          sale_order_line_vendor_sku: "FGH004417",
          sale_order_line_size_id: null,
          product_product_default_code: "ABS03045",
          product_product_name:
            "4 1/3 CT TGW Cubic Zirconia Bracelet Silver White Length (inches): 7",
          product_id: 3100441,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ABS03045.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484758",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:05.346Z",
        updatedAt: "2021-09-16T08:15:05.346Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "D", bin: "14" },
        price: 466.65,
        notes: [],
        isSet: false,
        _id: "61428c995182ab2153a6e7e9",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363445",
            street: "621 N LA GRANGE RD",
            name: "HELEN GREEN",
            zip: "60526",
            city: "LA GRANGE PARK",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 466.65,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61428c995182ab2153a6e7e9"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61428c995182abb7b6a6e7e4",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121247,
          orderNo: "JCP355653983_293-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355653983_293",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363445,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552171",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/helen green/621 n la grange rd//la grange park/illinois/60526/united states",
          createdAt: "2021-09-16T00:15:21.350Z",
          updatedAt: "2021-09-16T00:15:21.541Z",
          labelFile: "5121247/labelFile.txt",
          zplFile: "5121247/zplFile.txt",
          pdfFile: "5121247/pdfFile.txt",
          addressString: "621nlagrangerdlagrangeparkillinois60526unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1FWX-GAT3",
        productName:
          "1/10 CT Diamond TW Bracelet With Chain Silver GH I2;I3 Length (inches): 7.5",
        productImage: "https://partner.delmarintl.ca/catalog/FC1FWX-GAT3.jpg",
        reference: "JCP355653983_293-0",
        sku: "FC1FWX-GAT3",
        binLocation: "21-D-14",
        boxCode: "",
        stockPickingId: "3115876",
        uniqueIdentifier: "5121247-3115876-4397689",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121247,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355653983_293",
          sale_order_date: "2021-09-15",
          sale_order_total: 100,
          shipping_address_street: "621 N LA GRANGE RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363445,
          shipping_address_name: "HELEN GREEN",
          shipping_address_zip: "60526",
          shipping_address_city: "LA GRANGE PARK",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355653983_293-0",
          stock_picking_id: 3115876,
          total_price: 466.65,
          shipping_batch_id: -1,
          date: "2021-09-15 22:54:40",
          picking_write_date: "2021-09-16 00:14:37",
          address_grp_combined:
            "jcp/helen green/621 n la grange rd//la grange park/illinois/60526/united states",
          stock_move_is_set: false,
          stock_move_id: 78186689,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-D-14",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397689,
          sale_order_line_description: "SS 1/10CTW DIA",
          sale_order_line_customer_cost: "466.65",
          sale_order_line_vendor_sku: "JCP001987",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1FWX-GAT3",
          product_product_name:
            "1/10 CT Diamond TW Bracelet With Chain Silver GH I2;I3 Length (inches): 7.5",
          product_id: 222382,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1FWX-GAT3.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552171",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T00:15:21.530Z",
        updatedAt: "2021-09-16T00:15:21.530Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "K", bin: "03" },
        price: 52.5,
        notes: [],
        isSet: false,
        _id: "6142d9e35182ab43fda6ec31",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363792",
            street: "9465 LAZY CIRCLES DR",
            name: "Melani McWhirter",
            zip: "37363-9320",
            city: "OOLTEWAH",
            country: "United States",
            state: "Tennessee",
          },
          orderValue: 52.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e35182ab43fda6ec31"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e35182ab15c0a6ec2c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121439,
          orderNo: "BLK100982065-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100982065",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363792,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694376",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/melani mcwhirter/9465 lazy circles dr//ooltewah/tennessee/37363-9320/united states",
          createdAt: "2021-09-16T05:45:07.593Z",
          updatedAt: "2021-09-16T05:45:07.744Z",
          labelFile: "5121439/labelFile.txt",
          zplFile: "5121439/zplFile.txt",
          pdfFile: "5121439/pdfFile.txt",
          addressString:
            "9465lazycirclesdrooltewahtennessee373639320unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0L61-3MVX",
        productName:
          "0.01 CT Diamond TW And 1 3/8 CT TGW Amethyst LeverBack Earrings Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L61-3MVX.jpg",
        reference: "BLK100982065-0",
        sku: "FC0L61-3MVX",
        binLocation: "21-K-03",
        boxCode: "",
        stockPickingId: "3116054",
        uniqueIdentifier: "5121439-3116054-4397918",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121439,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100982065",
          sale_order_date: "2021-09-15",
          sale_order_total: 30,
          shipping_address_street: "9465 LAZY CIRCLES DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363792,
          shipping_address_name: "Melani McWhirter",
          shipping_address_zip: "37363-9320",
          shipping_address_city: "OOLTEWAH",
          shipping_address_state: "Tennessee",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100982065-0",
          stock_picking_id: 3116054,
          total_price: 52.5,
          shipping_batch_id: -1,
          date: "2021-09-16 04:31:20",
          picking_write_date: "2021-09-16 04:40:44",
          address_grp_combined:
            "blk/melani mcwhirter/9465 lazy circles dr//ooltewah/tennessee/37363-9320/united states",
          stock_move_is_set: false,
          stock_move_id: 78186949,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-K-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397918,
          sale_order_line_description: "X-SS ER AMY HRT LB",
          sale_order_line_customer_cost: "52.5",
          sale_order_line_vendor_sku: "075000448497",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0L61-3MVX",
          product_product_name:
            "0.01 CT Diamond TW And 1 3/8 CT TGW Amethyst LeverBack Earrings Silver I3",
          product_id: 101616,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L61-3MVX.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694376",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T05:45:07.733Z",
        updatedAt: "2021-09-16T05:45:07.734Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "K", bin: "05" },
        price: 39.22,
        notes: [],
        isSet: false,
        _id: "6142c15a5182abd598a6ea47",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363648",
            street: "6062 TUSCANY LN",
            name: "BRI BROOKS",
            zip: "70778",
            city: "SORRENTO",
            country: "United States",
            state: "Louisiana",
          },
          orderValue: 39.22,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15a5182abd598a6ea47"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15a5182abce7da6ea42",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121359,
          orderNo: "SAMS8775306741-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775306741",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363648,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594221",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T04:00:26.415Z",
          updatedAt: "2021-09-16T04:00:26.562Z",
          labelFile: "5121359/labelFile.txt",
          zplFile: "5121359/zplFile.txt",
          pdfFile: "5121359/pdfFile.txt",
          addressString: "6062tuscanylnsorrentolouisiana70778unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS3328GAWT",
        productName:
          "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
        reference: "SAMS8775306741-0",
        sku: "RGS3328GAWT",
        binLocation: "21-K-05",
        boxCode: "SCE",
        stockPickingId: "3115980",
        uniqueIdentifier: "5121359-3115980-4397828",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121359,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775306741",
          sale_order_date: "2021-09-15",
          sale_order_total: 27,
          shipping_address_street: "6062 TUSCANY LN",
          shipping_address_street_2: "",
          shipping_address_id: 8363648,
          shipping_address_name: "BRI BROOKS",
          shipping_address_zip: "70778",
          shipping_address_city: "SORRENTO",
          shipping_address_state: "Louisiana",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775306741-0",
          stock_picking_id: 3115980,
          total_price: 39.22,
          shipping_batch_id: -1,
          date: "2021-09-16 02:19:37",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186842,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-K-05",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397828,
          sale_order_line_description: "5.63 CT GEM RING 8",
          sale_order_line_customer_cost: "39.22",
          sale_order_line_vendor_sku: "SAMS00624/8",
          sale_order_line_size_id: 9,
          product_product_default_code: "RGS3328GAWT",
          product_product_name:
            "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
          product_id: 76957,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594221",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T04:00:26.549Z",
        updatedAt: "2021-09-16T04:00:26.550Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "M", bin: "06" },
        price: 183.32,
        notes: [],
        isSet: false,
        _id: "614297185182abff71a6e81d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363471",
            street: "42 LISA DR",
            name: "SOPHIA FLETCHER",
            zip: "19702",
            city: "NEWARK",
            country: "United States",
            state: "Delaware",
          },
          orderValue: 183.32,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614297185182abff71a6e81d"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297185182ab5580a6e818",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121261,
          orderNo: "JCP225998229_274-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225998229_274",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363471,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552180",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/sophia fletcher/42 lisa dr//newark/delaware/19702/united states",
          createdAt: "2021-09-16T01:00:08.103Z",
          updatedAt: "2021-09-16T01:00:08.261Z",
          labelFile: "5121261/labelFile.txt",
          zplFile: "5121261/zplFile.txt",
          pdfFile: "5121261/pdfFile.txt",
          addressString: "42lisadrnewarkdelaware19702unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0W0X-PJRR",
        productName:
          "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
        reference: "JCP225998229_274-0",
        sku: "FC0W0X-PJRR",
        binLocation: "21-M-06",
        boxCode: "",
        stockPickingId: "3115889",
        uniqueIdentifier: "5121261-3115889-4397702",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121261,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225998229_274",
          sale_order_date: "2021-09-15",
          sale_order_total: 33,
          shipping_address_street: "42 LISA DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363471,
          shipping_address_name: "SOPHIA FLETCHER",
          shipping_address_zip: "19702",
          shipping_address_city: "NEWARK",
          shipping_address_state: "Delaware",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225998229_274-0",
          stock_picking_id: 3115889,
          total_price: 183.32,
          shipping_batch_id: -1,
          date: "2021-09-15 23:22:40",
          picking_write_date: "2021-09-16 00:45:06",
          address_grp_combined:
            "jcp/sophia fletcher/42 lisa dr//newark/delaware/19702/united states",
          stock_move_is_set: false,
          stock_move_id: 78186702,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-M-06",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397702,
          sale_order_line_description: "SS  SAPH RNG",
          sale_order_line_customer_cost: "183.32",
          sale_order_line_vendor_sku: "JCP004289",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0W0X-PJRR",
          product_product_name:
            "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 99326,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552180",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T01:00:08.245Z",
        updatedAt: "2021-09-16T01:00:08.246Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "M", bin: "13" },
        price: 249.98,
        notes: [],
        isSet: false,
        _id: "614289155182ab6813a6e785",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363416",
            street: "1224 WENDT DR",
            name: "KRISTY SEVIGNY",
            zip: "56701",
            city: "THIEF RIVER FALLS",
            country: "United States",
            state: "Minnesota",
          },
          orderValue: 249.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289155182ab6813a6e785"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289155182abdc65a6e780",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121230,
          orderNo: "JCP355652613_272-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355652613_272",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363416,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552162",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/kristy sevigny/1224 wendt dr//thief river falls/minnesota/56701/united states",
          createdAt: "2021-09-16T00:00:21.703Z",
          updatedAt: "2021-09-16T00:00:21.873Z",
          labelFile: "5121230/labelFile.txt",
          zplFile: "5121230/zplFile.txt",
          pdfFile: "5121230/pdfFile.txt",
          addressString: "1224wendtdrthiefriverfallsminnesota56701unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1H4T-2HTA",
        productName:
          "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
        reference: "JCP355652613_272-0",
        sku: "FC1H4T-2HTA",
        binLocation: "21-M-13",
        boxCode: "",
        stockPickingId: "3115859",
        uniqueIdentifier: "5121230-3115859-4397671",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121230,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355652613_272",
          sale_order_date: "2021-09-15",
          sale_order_total: 44,
          shipping_address_street: "1224 WENDT DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363416,
          shipping_address_name: "KRISTY SEVIGNY",
          shipping_address_zip: "56701",
          shipping_address_city: "THIEF RIVER FALLS",
          shipping_address_state: "Minnesota",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355652613_272-0",
          stock_picking_id: 3115859,
          total_price: 249.98,
          shipping_batch_id: -1,
          date: "2021-09-15 22:09:31",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined:
            "jcp/kristy sevigny/1224 wendt dr//thief river falls/minnesota/56701/united states",
          stock_move_is_set: false,
          stock_move_id: 78186664,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-M-13",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397671,
          sale_order_line_description: "SS SAPH RNG",
          sale_order_line_customer_cost: "249.98",
          sale_order_line_vendor_sku: "JCP004283",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC1H4T-2HTA",
          product_product_name:
            "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
          product_id: 227647,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552162",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T00:00:21.859Z",
        updatedAt: "2021-09-16T00:00:21.860Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "M", bin: "13" },
        price: 105,
        notes: [],
        isSet: false,
        _id: "6142c15b5182ab77f1a6ea6e",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363657",
            street: "6216 Woodcrest Ave",
            name: "Ruby McLendon",
            zip: "21209-3935",
            city: "Baltimore",
            country: "United States",
            state: "Maryland",
          },
          orderValue: 105,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15b5182ab77f1a6ea6e"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15b5182abe0e7a6ea69",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121361,
          orderNo: "BLK100977738-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100977738",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363657,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694300",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/ruby mclendon/6216 woodcrest ave//baltimore/maryland/21209-3935/united states",
          createdAt: "2021-09-16T04:00:27.413Z",
          updatedAt: "2021-09-16T04:00:27.563Z",
          labelFile: "5121361/labelFile.txt",
          zplFile: "5121361/zplFile.txt",
          pdfFile: "5121361/pdfFile.txt",
          addressString:
            "6216woodcrestavebaltimoremaryland212093935unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1H4T-2HTA",
        productName:
          "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
        reference: "BLK100977738-0",
        sku: "FC1H4T-2HTA",
        binLocation: "21-M-13",
        boxCode: "",
        stockPickingId: "3115984",
        uniqueIdentifier: "5121361-3115984-4397832",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121361,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100977738",
          sale_order_date: "2021-09-15",
          sale_order_total: 43,
          shipping_address_street: "6216 Woodcrest Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363657,
          shipping_address_name: "Ruby McLendon",
          shipping_address_zip: "21209-3935",
          shipping_address_city: "Baltimore",
          shipping_address_state: "Maryland",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100977738-0",
          stock_picking_id: 3115984,
          total_price: 105,
          shipping_batch_id: -1,
          date: "2021-09-16 02:23:32",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined:
            "blk/ruby mclendon/6216 woodcrest ave//baltimore/maryland/21209-3935/united states",
          stock_move_is_set: false,
          stock_move_id: 78186846,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-M-13",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397832,
          sale_order_line_description: "CWS EG RG SET SS 7 DS",
          sale_order_line_customer_cost: "105.0",
          sale_order_line_vendor_sku: "686692375574",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC1H4T-2HTA",
          product_product_name:
            "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
          product_id: 227647,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694300",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T04:00:27.550Z",
        updatedAt: "2021-09-16T04:00:27.551Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "M", bin: "16" },
        price: 249.98,
        notes: [],
        isSet: false,
        _id: "6142eb775182ab8be5a6ec81",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363834",
            street: "2104 VERMONT AVE",
            name: "AKUA BOATENG",
            zip: "20785",
            city: "LANDOVER",
            country: "United States",
            state: "Maryland",
          },
          orderValue: 249.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb775182ab8be5a6ec81"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb775182abd9cca6ec7c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121459,
          orderNo: "JCP226005360_272-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP226005360_272",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363834,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552402",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/akua boateng/2104 vermont ave//landover/maryland/20785/united states",
          createdAt: "2021-09-16T07:00:07.078Z",
          updatedAt: "2021-09-16T07:00:07.282Z",
          labelFile: "5121459/labelFile.txt",
          zplFile: "5121459/zplFile.txt",
          pdfFile: "5121459/pdfFile.txt",
          addressString: "2104vermontavelandovermaryland20785unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1H4T-2HTA",
        productName:
          "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
        reference: "JCP226005360_272-0",
        sku: "FC1H4T-2HTA",
        binLocation: "21-M-16",
        boxCode: "",
        stockPickingId: "3116073",
        uniqueIdentifier: "5121459-3116073-4397940",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121459,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP226005360_272",
          sale_order_date: "2021-09-15",
          sale_order_total: 44,
          shipping_address_street: "2104 VERMONT AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363834,
          shipping_address_name: "AKUA BOATENG",
          shipping_address_zip: "20785",
          shipping_address_city: "LANDOVER",
          shipping_address_state: "Maryland",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP226005360_272-0",
          stock_picking_id: 3116073,
          total_price: 249.98,
          shipping_batch_id: -1,
          date: "2021-09-16 05:09:41",
          picking_write_date: "2021-09-16 06:45:22",
          address_grp_combined:
            "jcp/akua boateng/2104 vermont ave//landover/maryland/20785/united states",
          stock_move_is_set: false,
          stock_move_id: 78186976,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-M-16",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397940,
          sale_order_line_description: "SS SAPH RNG",
          sale_order_line_customer_cost: "249.98",
          sale_order_line_vendor_sku: "JCP004283",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC1H4T-2HTA",
          product_product_name:
            "1 1/2 CT TGW Created White Sapphire Bridal Set Ring Silver",
          product_id: 227647,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1H4T-2HTA.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552402",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T07:00:07.260Z",
        updatedAt: "2021-09-16T07:00:07.261Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "N", bin: "02" },
        price: 127.99,
        notes: [],
        isSet: false,
        _id: "6142ac2e5182ab7d76a6e92e",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363551",
            street: "3076 Ashbourne Cir",
            name: "Uzra Karan",
            zip: "94583",
            city: "San Ramon",
            country: "United States",
            state: "California",
          },
          orderValue: 127.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142ac2e5182ab7d76a6e92e"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142ac2e5182ab6338a6e929",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121307,
          orderNo: "OS355669800-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355669800",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363551,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256238",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/uzra karan/3076 ashbourne cir//san ramon/california/94583/united states",
          createdAt: "2021-09-16T02:30:06.103Z",
          updatedAt: "2021-09-16T02:30:06.266Z",
          labelFile: "5121307/labelFile.txt",
          zplFile: "5121307/zplFile.txt",
          pdfFile: "5121307/pdfFile.txt",
          addressString: "3076ashbournecirsanramoncalifornia94583unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1BX3-0PU4",
        productName:
          "0.06 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl LeverBack Earrings Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1BX3-0PU4.jpg",
        reference: "OS355669800-0",
        sku: "FC1BX3-0PU4",
        binLocation: "21-N-02",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115929",
        uniqueIdentifier: "5121307-3115929-4397765",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121307,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355669800",
          sale_order_date: "2021-09-15",
          sale_order_total: 86,
          shipping_address_street: "3076 Ashbourne Cir",
          shipping_address_street_2: null,
          shipping_address_id: 8363551,
          shipping_address_name: "Uzra Karan",
          shipping_address_zip: "94583",
          shipping_address_city: "San Ramon",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355669800-0",
          stock_picking_id: 3115929,
          total_price: 127.99,
          shipping_batch_id: -1,
          date: "2021-09-16 00:42:45",
          picking_write_date: "2021-09-16 02:14:56",
          address_grp_combined:
            "os/uzra karan/3076 ashbourne cir//san ramon/california/94583/united states",
          stock_move_is_set: false,
          stock_move_id: 78186765,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-N-02",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397765,
          sale_order_line_description:
            "Miadora 8-8.5mm White Freshwater Cultured Pearl and Diamond Twist Leverback Earrings in Sterling Silver (G-H, I2-I3)",
          sale_order_line_customer_cost: "127.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC1BX3-0PU4",
          product_product_name:
            "0.06 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl LeverBack Earrings Silver GH I2;I3",
          product_id: 207779,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1BX3-0PU4.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256238",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T02:30:06.254Z",
        updatedAt: "2021-09-16T02:30:06.255Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "C", bin: "14" },
        price: 385,
        notes: [],
        isSet: false,
        _id: "6142f6015182ab5adea6ed25",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363887",
            street: "627 Flower fields ln",
            name: "Khanh Truong",
            zip: "32824",
            city: "Orlando",
            country: "United States",
            state: "Florida",
          },
          orderValue: 385,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6015182ab5adea6ed25"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6015182ab3557a6ed20",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121485,
          orderNo: "SKS7637461-0",
          customerId: 914,
          customerName: "Saks Off Fifth",
          orderReference: "SKS7637461",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363887,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594265",
          carrier: "Fedex",
          shippingMethod: " FedEx Home Delivery",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:45:05.380Z",
          updatedAt: "2021-09-16T07:45:05.533Z",
          labelFile: "5121485/labelFile.txt",
          zplFile: "5121485/zplFile.txt",
          pdfFile: "5121485/pdfFile.txt",
          addressString: "627flowerfieldslnorlandoflorida32824unitedstates",
          normalizedCustomerName: "saks off fifth",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "EDKW54325",
        productName:
          "1/4 CT Diamond TW LeverBack Earrings 14k Gold White GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/EDKW54325.jpg",
        reference: "SKS7637461-0",
        sku: "EDKW54325",
        binLocation: "22-C-14",
        boxCode: "",
        stockPickingId: "3116100",
        uniqueIdentifier: "5121485-3116100-4397970",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121485,
          sale_order_customer_id: 914,
          sale_order_customer_name: "Saks Off Fifth",
          sale_order_order_reference: "SKS7637461",
          sale_order_date: "2021-09-15",
          sale_order_total: 153,
          shipping_address_street: "627 Flower fields ln",
          shipping_address_street_2: "",
          shipping_address_id: 8363887,
          shipping_address_name: "Khanh Truong",
          shipping_address_zip: "32824",
          shipping_address_city: "Orlando",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "SKS7637461-0",
          stock_picking_id: 3116100,
          total_price: 385,
          shipping_batch_id: -1,
          date: "2021-09-16 06:11:36",
          picking_write_date: "2021-09-16 06:41:14",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187011,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-C-14",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397970,
          sale_order_line_description:
            "14K White Gold & White Diamond Leverback Earrings / NO COLOR / One Size",
          sale_order_line_customer_cost: "385.0",
          sale_order_line_vendor_sku: "SKSC00867",
          sale_order_line_size_id: null,
          product_product_default_code: "EDKW54325",
          product_product_name:
            "1/4 CT Diamond TW LeverBack Earrings 14k Gold White GH I3",
          product_id: 39951,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/EDKW54325.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " FedEx Home Delivery",
          order_tracking_number: "501515594265",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:45:05.521Z",
        updatedAt: "2021-09-16T07:45:05.522Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "F", bin: "10" },
        price: 283.49,
        notes: [],
        isSet: false,
        _id: "6142a8b75182ab23e8a6e906",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363550",
            street: "26046 Matthews St",
            name: "Alia Abrams",
            zip: "19966",
            city: "Millsboro",
            country: "United States",
            state: "Delaware",
          },
          orderValue: 283.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a8b75182ab23e8a6e906"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a8b65182ab1d24a6e901",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121306,
          orderNo: "OS355668143-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355668143",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363550,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256306",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/alia abrams/26046 matthews st//millsboro/delaware/19966/united states",
          createdAt: "2021-09-16T02:15:18.975Z",
          updatedAt: "2021-09-16T02:15:19.169Z",
          labelFile: "5121306/labelFile.txt",
          zplFile: "5121306/zplFile.txt",
          pdfFile: "5121306/pdfFile.txt",
          addressString: "26046matthewsstmillsborodelaware19966unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0VJJ-EHJH",
        productName:
          "1/10 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Post Earrings 10k White Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0VJJ-EHJH.jpg",
        reference: "OS355668143-0",
        sku: "FC0VJJ-EHJH",
        binLocation: "22-F-10",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3115930",
        uniqueIdentifier: "5121306-3115930-4397767",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121306,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355668143",
          sale_order_date: "2021-09-15",
          sale_order_total: 205,
          shipping_address_street: "26046 Matthews St",
          shipping_address_street_2: null,
          shipping_address_id: 8363550,
          shipping_address_name: "Alia Abrams",
          shipping_address_zip: "19966",
          shipping_address_city: "Millsboro",
          shipping_address_state: "Delaware",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355668143-0",
          stock_picking_id: 3115930,
          total_price: 283.49,
          shipping_batch_id: -1,
          date: "2021-09-16 00:42:50",
          picking_write_date: "2021-09-16 01:10:42",
          address_grp_combined:
            "os/alia abrams/26046 matthews st//millsboro/delaware/19966/united states",
          stock_move_is_set: false,
          stock_move_id: 78186766,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-F-10",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397767,
          sale_order_line_description:
            "Miadora 10k White Gold Cultured Freshwater Pearl and 1/10ct TDW Diamond Stud Earrings (H-I, I2-I3) (8-8.5 mm)",
          sale_order_line_customer_cost: "283.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC0VJJ-EHJH",
          product_product_name:
            "1/10 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Post Earrings 10k White Gold GH I2;I3",
          product_id: 97484,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0VJJ-EHJH.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256306",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T02:15:19.157Z",
        updatedAt: "2021-09-16T02:15:19.158Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "H", bin: "17" },
        price: 47.49,
        notes: [],
        isSet: false,
        _id: "6143008b5182ab9d8fa6edd9",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363902",
            street: "8948 S Throop St",
            name: "Claretha Grimes",
            zip: "60620",
            city: "Chicago",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 47.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143008b5182ab9d8fa6edd9"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143008b5182abb63ea6edd4",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121493,
          orderNo: "OS355696248-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355696248",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363902,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256740",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/claretha grimes/8948 s throop st//chicago/illinois/60620/united states",
          createdAt: "2021-09-16T08:30:03.337Z",
          updatedAt: "2021-09-16T08:30:03.538Z",
          labelFile: "5121493/labelFile.txt",
          zplFile: "5121493/zplFile.txt",
          pdfFile: "5121493/pdfFile.txt",
          addressString: "8948sthroopstchicagoillinois60620unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "SRS8855P",
        productName:
          "Set of Silver 8-8.5mm Rice Pink & 5.5-6mm Button Pink Pearl Earring & 8-8.5mm Rice Pink Pearl Pendant w/ Chain",
        productImage: "https://partner.delmarintl.ca/catalog/SRS8855P.jpg",
        reference: "OS355696248-0",
        sku: "SRS8855P",
        binLocation: "22-H-17",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116108",
        uniqueIdentifier: "5121493-3116108-4397978",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121493,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355696248",
          sale_order_date: "2021-09-15",
          sale_order_total: 32,
          shipping_address_street: "8948 S Throop St",
          shipping_address_street_2: null,
          shipping_address_id: 8363902,
          shipping_address_name: "Claretha Grimes",
          shipping_address_zip: "60620",
          shipping_address_city: "Chicago",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355696248-0",
          stock_picking_id: 3116108,
          total_price: 47.49,
          shipping_batch_id: -1,
          date: "2021-09-16 06:44:19",
          picking_write_date: "2021-09-16 08:14:49",
          address_grp_combined:
            "os/claretha grimes/8948 s throop st//chicago/illinois/60620/united states",
          stock_move_is_set: false,
          stock_move_id: 78187019,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-H-17",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397978,
          sale_order_line_description:
            "Miadora Sterling Silver Pink Pearl Earring and Necklace Set",
          sale_order_line_customer_cost: "47.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "SRS8855P",
          product_product_name:
            "Set of Silver 8-8.5mm Rice Pink & 5.5-6mm Button Pink Pearl Earring & 8-8.5mm Rice Pink Pearl Pendant w/ Chain",
          product_id: 78696,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/SRS8855P.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256740",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T08:30:03.519Z",
        updatedAt: "2021-09-16T08:30:03.520Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "H", bin: "18" },
        price: 74.99,
        notes: [],
        isSet: false,
        _id: "6142d9e15182ab01b2a6ebda",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363779",
            street: "3606 UTAH AVE",
            name: "JOHN HOURIGAN",
            zip: "74006-2936",
            city: "BARTLESVILLE",
            country: "United States",
            state: "Oklahoma",
          },
          orderValue: 74.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142d9e15182ab01b2a6ebda"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d9e15182ab1a2aa6ebd5",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121430,
          orderNo: "EVN912988_1_1_1-0",
          customerId: 948,
          customerName: "Evine.com",
          orderReference: "EVN912988_1_1_1",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363779,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552368",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "evn/john hourigan/3606 utah ave//bartlesville/oklahoma/74006-2936/united states",
          createdAt: "2021-09-16T05:45:05.262Z",
          updatedAt: "2021-09-16T05:45:05.425Z",
          labelFile: "5121430/labelFile.txt",
          zplFile: "5121430/zplFile.txt",
          pdfFile: "5121430/pdfFile.txt",
          addressString: "3606utahavebartlesvilleoklahoma740062936unitedstates",
          normalizedCustomerName: "evine.com",
          classification: "PAPS",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ABS02039",
        productName:
          '3.78 CT TGW Garnet ADJUSTABLE TASSEL BRACELET SILVER PINK LENGTH (INCHES): 5-10"',
        productImage: "https://partner.delmarintl.ca/catalog/ABS02039.jpg",
        reference: "EVN912988_1_1_1-0",
        sku: "ABS02039",
        binLocation: "22-H-18",
        boxCode: "",
        stockPickingId: "3116046",
        uniqueIdentifier: "5121430-3116046-4397908",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121430,
          sale_order_customer_id: 948,
          sale_order_customer_name: "Evine.com",
          sale_order_order_reference: "EVN912988_1_1_1",
          sale_order_date: "2021-09-15",
          sale_order_total: 32,
          shipping_address_street: "3606 UTAH AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363779,
          shipping_address_name: "JOHN HOURIGAN",
          shipping_address_zip: "74006-2936",
          shipping_address_city: "BARTLESVILLE",
          shipping_address_state: "Oklahoma",
          shipping_address_country: "United States",
          stock_picking_reference: "EVN912988_1_1_1-0",
          stock_picking_id: 3116046,
          total_price: 74.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:16:30",
          picking_write_date: "2021-09-16 04:41:18",
          address_grp_combined:
            "evn/john hourigan/3606 utah ave//bartlesville/oklahoma/74006-2936/united states",
          stock_move_is_set: false,
          stock_move_id: 78186939,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-H-18",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397908,
          sale_order_line_description:
            "JULES BY JULIANNA B. PLATED SS 3.8CTW GARNET ADJUSTABLE BRACELET",
          sale_order_line_customer_cost: "74.99",
          sale_order_line_vendor_sku: "EVN002001",
          sale_order_line_size_id: null,
          product_product_default_code: "ABS02039",
          product_product_name:
            '3.78 CT TGW Garnet ADJUSTABLE TASSEL BRACELET SILVER PINK LENGTH (INCHES): 5-10"',
          product_id: 378390,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ABS02039.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552368",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:45:05.411Z",
        updatedAt: "2021-09-16T05:45:05.411Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "K", bin: "08" },
        price: 38.51,
        notes: [],
        isSet: false,
        _id: "614289215182aba11ea6e7cf",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363421",
            street: "300 S MARSHALL ST",
            name: "STEPHANIE LAWSON",
            zip: "88120",
            city: "GRADY",
            country: "United States",
            state: "New Mexico",
          },
          orderValue: 38.51,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289215182aba11ea6e7cf"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289215182ab4f9ea6e7ca",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121235,
          orderNo: "SAMS8775304897-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775304897",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363421,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594173",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:33.043Z",
          updatedAt: "2021-09-16T00:00:33.214Z",
          labelFile: "5121235/labelFile.txt",
          zplFile: "5121235/zplFile.txt",
          pdfFile: "5121235/pdfFile.txt",
          addressString: "300smarshallstgradynewmexico88120unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS3328GAWT",
        productName:
          "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
        reference: "SAMS8775304897-0",
        sku: "RGS3328GAWT",
        binLocation: "22-K-08",
        boxCode: "SCE",
        stockPickingId: "3115865",
        uniqueIdentifier: "5121235-3115865-4397678",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121235,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775304897",
          sale_order_date: "2021-09-15",
          sale_order_total: 27,
          shipping_address_street: "300 S MARSHALL ST",
          shipping_address_street_2: "",
          shipping_address_id: 8363421,
          shipping_address_name: "STEPHANIE LAWSON",
          shipping_address_zip: "88120",
          shipping_address_city: "GRADY",
          shipping_address_state: "New Mexico",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775304897-0",
          stock_picking_id: 3115865,
          total_price: 38.51,
          shipping_batch_id: -1,
          date: "2021-09-15 22:18:48",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186671,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-K-08",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397678,
          sale_order_line_description: "5.63 CT GEM RING 9",
          sale_order_line_customer_cost: "38.51",
          sale_order_line_vendor_sku: "SAMS00624/9",
          sale_order_line_size_id: 11,
          product_product_default_code: "RGS3328GAWT",
          product_product_name:
            "5 5/8 CT TGW GREEN QUARTZ- White Topaz Fashion Ring Silver",
          product_id: 76957,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS3328GAWT.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594173",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T00:00:33.180Z",
        updatedAt: "2021-09-16T00:00:33.181Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "P", bin: "08" },
        price: 26.97,
        notes: [],
        isSet: false,
        _id: "6142fd085182ab40b4a6ed8a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363906",
            street: "130 Hillsdale Mall, c/o Nordstrom Order Pickup #420",
            name: "Nawras Aliakbar",
            zip: "94403",
            city: "San Mateo",
            country: "United States",
            state: "California",
          },
          orderValue: 26.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142fd085182ab40b4a6ed8a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd085182ab84caa6ed85",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121499,
          orderNo: "HAU66355769-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355769",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363906,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552484",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/nawras aliakbar/130 hillsdale mall/c/o nordstrom order pickup #420/san mateo/california/94403/united states",
          createdAt: "2021-09-16T08:15:04.130Z",
          updatedAt: "2021-09-16T08:15:04.324Z",
          labelFile: "5121499/labelFile.txt",
          zplFile: "5121499/zplFile.txt",
          pdfFile: "5121499/pdfFile.txt",
          addressString:
            "130hillsdalemallconordstromorderpickup420sanmateocalifornia94403unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "AEWS00847",
        productName:
          "7 - 7.5 mm White Freshwater Cultured and 10 - 10.5 mm White Freshwater Cultured Fashion Pearls Earrings Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/AEWS00847.jpg",
        reference: "HAU66355769-0",
        sku: "AEWS00847",
        binLocation: "22-P-08",
        boxCode: "",
        stockPickingId: "3116112",
        uniqueIdentifier: "5121499-3116112-4397985",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121499,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355769",
          sale_order_date: "2021-09-15",
          sale_order_total: 16,
          shipping_address_street: "130 Hillsdale Mall",
          shipping_address_street_2: "c/o Nordstrom Order Pickup #420",
          shipping_address_id: 8363906,
          shipping_address_name: "Nawras Aliakbar",
          shipping_address_zip: "94403",
          shipping_address_city: "San Mateo",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355769-0",
          stock_picking_id: 3116112,
          total_price: 26.97,
          shipping_batch_id: -1,
          date: "2021-09-16 06:45:58",
          picking_write_date: "2021-09-16 07:10:52",
          address_grp_combined:
            "hau/nawras aliakbar/130 hillsdale mall/c/o nordstrom order pickup #420/san mateo/california/94403/united states",
          stock_move_is_set: false,
          stock_move_id: 78187024,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-P-08",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397985,
          sale_order_line_description:
            "7 - 7.5 mm White Freshwater Cultured and 10 - 10",
          sale_order_line_customer_cost: "26.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "AEWS00847",
          product_product_name:
            "7 - 7.5 mm White Freshwater Cultured and 10 - 10.5 mm White Freshwater Cultured Fashion Pearls Earrings Silver White",
          product_id: 220561,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/AEWS00847.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552484",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:04.313Z",
        updatedAt: "2021-09-16T08:15:04.314Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "P", bin: "08" },
        price: 26.97,
        notes: [],
        isSet: false,
        _id: "6143008c5182ab0dfba6ede7",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363908",
            street: "105 Natures View Lane",
            name: "Stephanie Richardson",
            zip: "35824",
            city: "Huntsville",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 26.97,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143008c5182ab0dfba6ede7"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143008b5182abcb94a6ede2",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121497,
          orderNo: "HAU66355630-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355630",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363908,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552466",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/stephanie richardson/105 natures view lane//huntsville/alabama/35824/united states",
          createdAt: "2021-09-16T08:30:03.875Z",
          updatedAt: "2021-09-16T08:30:04.026Z",
          labelFile: "5121497/labelFile.txt",
          zplFile: "5121497/zplFile.txt",
          pdfFile: "5121497/pdfFile.txt",
          addressString: "105naturesviewlanehuntsvillealabama35824unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "AEWS00847",
        productName:
          "7 - 7.5 mm White Freshwater Cultured and 10 - 10.5 mm White Freshwater Cultured Fashion Pearls Earrings Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/AEWS00847.jpg",
        reference: "HAU66355630-0",
        sku: "AEWS00847",
        binLocation: "22-P-08",
        boxCode: "",
        stockPickingId: "3116109",
        uniqueIdentifier: "5121497-3116109-4397981",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121497,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355630",
          sale_order_date: "2021-09-15",
          sale_order_total: 16,
          shipping_address_street: "105 Natures View Lane",
          shipping_address_street_2: "",
          shipping_address_id: 8363908,
          shipping_address_name: "Stephanie Richardson",
          shipping_address_zip: "35824",
          shipping_address_city: "Huntsville",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355630-0",
          stock_picking_id: 3116109,
          total_price: 26.97,
          shipping_batch_id: -1,
          date: "2021-09-16 06:45:54",
          picking_write_date: "2021-09-16 08:14:49",
          address_grp_combined:
            "hau/stephanie richardson/105 natures view lane//huntsville/alabama/35824/united states",
          stock_move_is_set: false,
          stock_move_id: 78187020,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-P-08",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397981,
          sale_order_line_description:
            "7 - 7.5 mm White Freshwater Cultured and 10 - 10",
          sale_order_line_customer_cost: "26.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "AEWS00847",
          product_product_name:
            "7 - 7.5 mm White Freshwater Cultured and 10 - 10.5 mm White Freshwater Cultured Fashion Pearls Earrings Silver White",
          product_id: 220561,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/AEWS00847.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552466",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:30:04.014Z",
        updatedAt: "2021-09-16T08:30:04.014Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["20", "21", "22"],
    _id: "6195ae14fc40c13f24ca03fe",
    createdAt: "2021-11-18T01:36:20.855Z",
    updatedAt: "2021-11-18T01:36:20.855Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 23,
    orderBins: {
      "614289155182abdc65a6e780": "JCP355652613_272-0",
      "614289215182ab4f9ea6e7ca": "SAMS8775304897-0",
      "614289235182ab425ba6e7d7": "SAMS8775304809-0",
      "61428c995182abb7b6a6e7e4": "JCP355653983_293-0",
      "614297175182abac99a6e80b": "SAMS8775305290-0",
      "614297185182ab5580a6e818": "JCP225998229_274-0",
      "6142a8b65182ab1d24a6e901": "OS355668143-0",
      "6142a8b75182ab8fc5a6e90f": "HAU66353297-0",
      "6142ac2e5182ab6338a6e929": "OS355669800-0",
      "6142c15a5182abce7da6ea42": "SAMS8775306741-0",
      "6142c15b5182abe0e7a6ea69": "BLK100977738-0",
      "6142c4cb5182ab486aa6ead3": "OS355680603-0",
      "6142cf7e5182ab12a2a6eb48": "BLK100980568-0",
      "6142d9e15182ab1a2aa6ebd5": "EVN912988_1_1_1-0",
      "6142d9e25182ab121ea6ec05": "SAMS8775307569-0",
      "6142d9e35182ab15c0a6ec2c": "BLK100982065-0",
      "6142eb775182abd9cca6ec7c": "JCP226005360_272-0",
      "6142ef3a5182abfd63a6ece1": "HAU66355369-0",
      "6142f6015182ab3557a6ed20": "SKS7637461-0",
      "6142fd085182ab84caa6ed85": "HAU66355769-0",
      "6142fd095182aba01ca6edac": "FGHPO000087805933-0",
      "6143008b5182abb63ea6edd4": "OS355696248-0",
      "6143008b5182abcb94a6ede2": "HAU66355630-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "24", section: "A", bin: "16" },
        price: 40,
        notes: [],
        isSet: false,
        _id: "6142bdbe5182ab627da6e9e0",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363663",
            street: "1700 Old Corinth Rd",
            name: "Cherrelle Freeman",
            zip: "30263-7721",
            city: "Newnan",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 40,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142bdbe5182ab627da6e9e0"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142bdbe5182ab0360a6e9db",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121365,
          orderNo: "TAR9948301756-0",
          customerId: 181,
          customerName: "Target",
          orderReference: "TAR9948301756",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363663,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552279",
          carrier: "UPS",
          shippingMethod: " UPS Ground",
          addressGroupCombined: null,
          createdAt: "2021-09-16T03:45:02.399Z",
          updatedAt: "2021-09-16T03:45:02.584Z",
          labelFile: "5121365/labelFile.txt",
          zplFile: "5121365/zplFile.txt",
          pdfFile: "5121365/pdfFile.txt",
          addressString: "1700oldcorinthrdnewnangeorgia302637721unitedstates",
          normalizedCustomerName: "target",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ARS01795",
        productName: "2.54 CT TGW Cubic Zirconia Fashion Rings Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/ARS01795.jpg",
        reference: "TAR9948301756-0",
        sku: "ARS01795",
        binLocation: "24-A-16",
        boxCode: "",
        stockPickingId: "3115987",
        uniqueIdentifier: "5121365-3115987-4397835",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121365,
          sale_order_customer_id: 181,
          sale_order_customer_name: "Target",
          sale_order_order_reference: "TAR9948301756",
          sale_order_date: "2021-09-15",
          sale_order_total: 32,
          shipping_address_street: "1700 Old Corinth Rd",
          shipping_address_street_2: "",
          shipping_address_id: 8363663,
          shipping_address_name: "Cherrelle Freeman",
          shipping_address_zip: "30263-7721",
          shipping_address_city: "Newnan",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "TAR9948301756-0",
          stock_picking_id: 3115987,
          total_price: 40,
          shipping_batch_id: -1,
          date: "2021-09-16 02:31:56",
          picking_write_date: "2021-09-16 02:41:00",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186851,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "24-A-16",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397835,
          sale_order_line_description: "RING ALLURA",
          sale_order_line_customer_cost: "40.0",
          sale_order_line_vendor_sku: "TAR011461-0700",
          sale_order_line_size_id: 7,
          product_product_default_code: "ARS01795",
          product_product_name:
            "2.54 CT TGW Cubic Zirconia Fashion Rings Silver White",
          product_id: 343519,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ARS01795.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " UPS Ground",
          order_tracking_number: "1Z8R9V140318552279",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T03:45:02.570Z",
        updatedAt: "2021-09-16T03:45:02.571Z",
        __v: 0,
      },
      {
        stockBin: { row: "24", section: "E", bin: "15" },
        price: 52.5,
        notes: [],
        isSet: false,
        _id: "6142eb795182ab41baa6ecc3",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363855",
            street: "4018 Tiger Paw Ln",
            name: "Dreama Igo",
            zip: "29588-4634",
            city: "Myrtle Beach",
            country: "United States",
            state: "South Carolina",
          },
          orderValue: 52.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142eb795182ab41baa6ecc3"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb795182abc605a6ecbe",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121469,
          orderNo: "BLK100984718-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100984718",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363855,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694413",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/dreama igo/4018 tiger paw ln//myrtle beach/south carolina/29588-4634/united states",
          createdAt: "2021-09-16T07:00:09.116Z",
          updatedAt: "2021-09-16T07:00:09.283Z",
          labelFile: "5121469/labelFile.txt",
          zplFile: "5121469/zplFile.txt",
          pdfFile: "5121469/pdfFile.txt",
          addressString:
            "4018tigerpawlnmyrtlebeachsouthcarolina295884634unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "ARWS02891",
        productName:
          "1-1/10 CT TGW Cubic Zirconia And 10-10.5 mm Freshwater Cultured Fashion Pearl Ring Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/ARWS02891.jpg",
        reference: "BLK100984718-0",
        sku: "ARWS02891",
        binLocation: "24-E-15",
        boxCode: "",
        stockPickingId: "3116083",
        uniqueIdentifier: "5121469-3116083-4397952",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121469,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100984718",
          sale_order_date: "2021-09-15",
          sale_order_total: 25,
          shipping_address_street: "4018 Tiger Paw Ln",
          shipping_address_street_2: null,
          shipping_address_id: 8363855,
          shipping_address_name: "Dreama Igo",
          shipping_address_zip: "29588-4634",
          shipping_address_city: "Myrtle Beach",
          shipping_address_state: "South Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100984718-0",
          stock_picking_id: 3116083,
          total_price: 52.5,
          shipping_batch_id: -1,
          date: "2021-09-16 05:34:01",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined:
            "blk/dreama igo/4018 tiger paw ln//myrtle beach/south carolina/29588-4634/united states",
          stock_move_is_set: false,
          stock_move_id: 78186986,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "24-E-15",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397952,
          sale_order_line_description: "FWP CZ GEO RG 7 SS DS",
          sale_order_line_customer_cost: "52.5",
          sale_order_line_vendor_sku: "686692364929",
          sale_order_line_size_id: 7,
          product_product_default_code: "ARWS02891",
          product_product_name:
            "1-1/10 CT TGW Cubic Zirconia And 10-10.5 mm Freshwater Cultured Fashion Pearl Ring Silver White",
          product_id: 3097943,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ARWS02891.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694413",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:00:09.266Z",
        updatedAt: "2021-09-16T07:00:09.268Z",
        __v: 0,
      },
      {
        stockBin: { row: "24", section: "N", bin: "18" },
        price: 96.25,
        notes: [],
        isSet: false,
        _id: "6142a1a05182ab546ba6e872",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363536",
            street: "1735 PRESIDIO DR",
            name: "Nadira Ragoo",
            zip: "34711-6533",
            city: "CLERMONT",
            country: "United States",
            state: "Florida",
          },
          orderValue: 96.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a1a05182ab546ba6e872"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a1a05182ab3a42a6e86d",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121298,
          orderNo: "BLK100974685-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974685",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363536,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694218",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/nadira ragoo/1735 presidio dr//clermont/florida/34711-6533/united states",
          createdAt: "2021-09-16T01:45:04.139Z",
          updatedAt: "2021-09-16T01:45:04.461Z",
          labelFile: "5121298/labelFile.txt",
          zplFile: "5121298/zplFile.txt",
          pdfFile: "5121298/pdfFile.txt",
          addressString: "1735presidiodrclermontflorida347116533unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0L5L-XEMV",
        productName: "1/5 CT Diamond TW Anniversary Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
        reference: "BLK100974685-0",
        sku: "FC0L5L-XEMV",
        binLocation: "24-N-18",
        boxCode: "",
        stockPickingId: "3115923",
        uniqueIdentifier: "5121298-3115923-4397758",
        stockMoveSize: "7.5",
        orderLineSize: "7.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121298,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974685",
          sale_order_date: "2021-09-15",
          sale_order_total: 40,
          shipping_address_street: "1735 PRESIDIO DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363536,
          shipping_address_name: "Nadira Ragoo",
          shipping_address_zip: "34711-6533",
          shipping_address_city: "CLERMONT",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974685-0",
          stock_picking_id: 3115923,
          total_price: 96.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:31:18",
          picking_write_date: "2021-09-16 00:40:51",
          address_grp_combined:
            "blk/nadira ragoo/1735 presidio dr//clermont/florida/34711-6533/united states",
          stock_move_is_set: false,
          stock_move_id: 78186755,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "24-N-18",
          stock_move_uom: "PCE",
          stock_move_size: "7.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397758,
          sale_order_line_description: "DIA ANNIV RG SS 7.5 DS",
          sale_order_line_customer_cost: "96.25",
          sale_order_line_vendor_sku: "686692278998",
          sale_order_line_size_id: 8,
          product_product_default_code: "FC0L5L-XEMV",
          product_product_name: "1/5 CT Diamond TW Anniversary Ring Silver I3",
          product_id: 90399,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
          sale_order_line_size: "7.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694218",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:04.423Z",
        updatedAt: "2021-09-16T01:45:04.425Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["24"],
    _id: "6195ae14fc40c13f24ca03ff",
    createdAt: "2021-11-18T01:36:20.866Z",
    updatedAt: "2021-11-18T01:36:20.866Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 3,
    orderBins: {
      "6142a1a05182ab3a42a6e86d": "BLK100974685-0",
      "6142bdbe5182ab0360a6e9db": "TAR9948301756-0",
      "6142eb795182abc605a6ecbe": "BLK100984718-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "03", section: "B", bin: "36" },
        price: 162.75,
        notes: [],
        isSet: false,
        _id: "6142cf765182ab2c8ea6eb0b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363719",
            street: "108 boul dOrleans",
            name: "LILIANA RAFFO",
            zip: "J6Z 4H4",
            city: "LORRAINE",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 162.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf765182ab2c8ea6eb0b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf765182ab1f23a6eb06",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121399,
          orderNo: "HBC1156472-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156472",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363719,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416713321338",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:00:38.492Z",
          updatedAt: "2021-09-16T05:00:38.694Z",
          labelFile: "5121399/labelFile.txt",
          zplFile: "5121399/zplFile.txt",
          pdfFile: "5121399/pdfFile.txt",
          addressString: "108bouldorleanslorrainequebecj6z4h4canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "BGS0104CT",
        productName:
          "14 3/4 CT TGW Citrine Bracelet Silver Length (inches): 7.5",
        productImage: "https://partner.delmarintl.ca/catalog/BGS0104CT.jpg",
        reference: "HBC1156472-0",
        sku: "BGS0104CT",
        binLocation: "03-B-36",
        boxCode: "",
        stockPickingId: "3116014",
        uniqueIdentifier: "5121399-3116014-4397872",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121399,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156472",
          sale_order_date: "2021-09-15",
          sale_order_total: 104.63,
          shipping_address_street: "108 boul dOrleans",
          shipping_address_street_2: null,
          shipping_address_id: 8363719,
          shipping_address_name: "LILIANA RAFFO",
          shipping_address_zip: "J6Z 4H4",
          shipping_address_city: "LORRAINE",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156472-0",
          stock_picking_id: 3116014,
          total_price: 162.75,
          shipping_batch_id: -1,
          date: "2021-09-16 03:18:53",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186894,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "03-B-36",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397872,
          sale_order_line_description: "Sterling Silver and Citrine Bracelet",
          sale_order_line_customer_cost: "162.75",
          sale_order_line_vendor_sku: "HBC000687",
          sale_order_line_size_id: null,
          product_product_default_code: "BGS0104CT",
          product_product_name:
            "14 3/4 CT TGW Citrine Bracelet Silver Length (inches): 7.5",
          product_id: 60783,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/BGS0104CT.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416713321338",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T05:00:38.682Z",
        updatedAt: "2021-09-16T05:00:38.683Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["03"],
    _id: "6195ae14fc40c13f24ca0400",
    createdAt: "2021-11-18T01:36:20.943Z",
    updatedAt: "2021-11-18T01:36:20.943Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 1,
    orderBins: { "6142cf765182ab1f23a6eb06": "HBC1156472-0" },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "06", section: "F", bin: "61" },
        price: 598,
        notes: [],
        isSet: false,
        _id: "6142f6015182abd0caa6ed18",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363879",
            street: "8090 avenue Arlington",
            name: "Caroline Menard",
            zip: "J3Y 0P8",
            city: "St-Hubert",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 598,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6015182abd0caa6ed18"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6015182ab6d6fa6ed13",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121483,
          orderNo: "HBC1157243-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157243",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363879,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416714093333",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:45:05.027Z",
          updatedAt: "2021-09-16T07:45:05.201Z",
          labelFile: "5121483/labelFile.txt",
          zplFile: "5121483/zplFile.txt",
          pdfFile: "5121483/pdfFile.txt",
          addressString: "8090avenuearlingtonsthubertquebecj3y0p8canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC27HG-A08R",
        productName:
          "2 3/4 CT TGW Created White Sapphire Solitaire Ring 10k Yellow Gold",
        productImage: "https://partner.delmarintl.ca/catalog/FC27HG-A08R.jpg",
        reference: "HBC1157243-0",
        sku: "FC27HG-A08R",
        binLocation: "06-F-61",
        boxCode: "",
        stockPickingId: "3116098",
        uniqueIdentifier: "5121483-3116098-4397969",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121483,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157243",
          sale_order_date: "2021-09-15",
          sale_order_total: 306.98,
          shipping_address_street: "8090 avenue Arlington",
          shipping_address_street_2: null,
          shipping_address_id: 8363879,
          shipping_address_name: "Caroline Menard",
          shipping_address_zip: "J3Y 0P8",
          shipping_address_city: "St-Hubert",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157243-0",
          stock_picking_id: 3116098,
          total_price: 598,
          shipping_batch_id: -1,
          date: "2021-09-16 06:08:25",
          picking_write_date: "2021-09-16 06:41:20",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187009,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "06-F-61",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397969,
          sale_order_line_description:
            "10K Yellow Gold &amp; Created White Sapphire Solitaire Ring",
          sale_order_line_customer_cost: "598.0",
          sale_order_line_vendor_sku: "HBC006372",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC27HG-A08R",
          product_product_name:
            "2 3/4 CT TGW Created White Sapphire Solitaire Ring 10k Yellow Gold",
          product_id: 3105762,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC27HG-A08R.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416714093333",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T07:45:05.190Z",
        updatedAt: "2021-09-16T07:45:05.190Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["06"],
    _id: "6195ae14fc40c13f24ca0401",
    createdAt: "2021-11-18T01:36:20.946Z",
    updatedAt: "2021-11-18T01:36:20.946Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 1,
    orderBins: { "6142f6015182ab6d6fa6ed13": "HBC1157243-0" },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "11", section: "H", bin: "41" },
        price: 104.64,
        notes: [],
        isSet: false,
        _id: "6143121f5182ab39eaa6ee46",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363944",
            street: "77 Davisville Ave, APT 2512",
            name: "CLAIRE WILKINSON",
            zip: "M4S 1G4",
            city: "TORONTO",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 104.64,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143121f5182ab39eaa6ee46"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143121f5182ab78bba6ee41",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121518,
          orderNo: "HBC1157362-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157362",
          orderDate: "2021-09-16T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363944,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416714005336",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T09:45:03.252Z",
          updatedAt: "2021-09-16T09:45:03.425Z",
          labelFile: "5121518/labelFile.txt",
          zplFile: "5121518/zplFile.txt",
          pdfFile: "5121518/pdfFile.txt",
          addressString: "77davisvilleaveapt2512torontoontariom4s1g4canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC18WT-M3TZ",
        productName:
          "1/7 CT TGW Emerald Fashion Ring Silver Black Rhodium Plated",
        productImage: "https://partner.delmarintl.ca/catalog/FC18WT-M3TZ.jpg",
        reference: "HBC1157362-0",
        sku: "FC18WT-M3TZ",
        binLocation: "11-H-41",
        boxCode: "",
        stockPickingId: "3116132",
        uniqueIdentifier: "5121518-3116132-4398006",
        stockMoveSize: "5.0",
        orderLineSize: "5.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121518,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157362",
          sale_order_date: "2021-09-16",
          sale_order_total: 54.24,
          shipping_address_street: "77 Davisville Ave",
          shipping_address_street_2: "APT 2512",
          shipping_address_id: 8363944,
          shipping_address_name: "CLAIRE WILKINSON",
          shipping_address_zip: "M4S 1G4",
          shipping_address_city: "TORONTO",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157362-0",
          stock_picking_id: 3116132,
          total_price: 104.64,
          shipping_batch_id: -1,
          date: "2021-09-16 08:34:28",
          picking_write_date: "2021-09-16 09:44:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187050,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "11-H-41",
          stock_move_uom: "PCE",
          stock_move_size: "5.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4398006,
          sale_order_line_description: "Emerald Sterling Silver Infinity Ring",
          sale_order_line_customer_cost: "104.64",
          sale_order_line_vendor_sku: "HBC001862",
          sale_order_line_size_id: 3,
          product_product_default_code: "FC18WT-M3TZ",
          product_product_name:
            "1/7 CT TGW Emerald Fashion Ring Silver Black Rhodium Plated",
          product_id: 204816,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC18WT-M3TZ.jpg",
          sale_order_line_size: "5.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416714005336",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T09:45:03.411Z",
        updatedAt: "2021-09-16T09:45:03.412Z",
        __v: 0,
      },
      {
        stockBin: { row: "12", section: "P", bin: "10" },
        price: 183.75,
        notes: [],
        isSet: false,
        _id: "61421c0a5182ab3a86a6e040",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362909",
            street: "4982 ReneCoty",
            name: "Stavroula Alexandris",
            zip: "H7W 2J4",
            city: "Laval",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 183.75,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61421c0a5182ab3a86a6e040"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61421c0a5182ab31b5a6e03b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120866,
          orderNo: "HBC1156333-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156333",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362909,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416666753330",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T16:15:06.212Z",
          updatedAt: "2021-09-15T16:15:06.405Z",
          labelFile: "5120866/labelFile.txt",
          zplFile: "5120866/zplFile.txt",
          pdfFile: "5120866/pdfFile.txt",
          addressString: "4982renecotylavalquebech7w2j4canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0V63-3TYB",
        productName:
          "1/10 CT Diamond TW And 4 CT TGW GREEN QUARTZ- Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0V63-3TYB.jpg",
        reference: "HBC1156333-0",
        sku: "FC0V63-3TYB",
        binLocation: "12-P-10",
        boxCode: "",
        stockPickingId: "3115503",
        uniqueIdentifier: "5120866-3115503-4397219",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120866,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156333",
          sale_order_date: "2021-09-15",
          sale_order_total: 118.42,
          shipping_address_street: "4982 ReneCoty",
          shipping_address_street_2: null,
          shipping_address_id: 8362909,
          shipping_address_name: "Stavroula Alexandris",
          shipping_address_zip: "H7W 2J4",
          shipping_address_city: "Laval",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156333-0",
          stock_picking_id: 3115503,
          total_price: 183.75,
          shipping_batch_id: -1,
          date: "2021-09-15 15:05:28",
          picking_write_date: "2021-09-15 16:14:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78185878,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "12-P-10",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397219,
          sale_order_line_description:
            "4 CT. T.W. Green Amethyst and Diamond Sterling Silver Halo Ring",
          sale_order_line_customer_cost: "183.75",
          sale_order_line_vendor_sku: "HBC000754",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0V63-3TYB",
          product_product_name:
            "1/10 CT Diamond TW And 4 CT TGW GREEN QUARTZ- Fashion Ring Silver GH I3",
          product_id: 96969,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0V63-3TYB.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416666753330",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T16:15:06.394Z",
        updatedAt: "2021-09-15T16:15:06.395Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["11", "12"],
    _id: "6195ae14fc40c13f24ca0402",
    createdAt: "2021-11-18T01:36:20.949Z",
    updatedAt: "2021-11-18T01:36:20.949Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 2,
    orderBins: {
      "61421c0a5182ab31b5a6e03b": "HBC1156333-0",
      "6143121f5182ab78bba6ee41": "HBC1157362-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "14", section: "F", bin: "26" },
        price: 138.59,
        notes: [],
        isSet: false,
        _id: "6142c15a5182ab0738a6ea54",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363650",
            street: "233-5317 UPPER MIDDLE RD, Buzzer 1233",
            name: "Lesley connelly",
            zip: "L7L 0G8",
            city: "BURLINGTON",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 138.59,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142c15a5182ab0738a6ea54"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142c15a5182ab47cfa6ea4f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121358,
          orderNo: "HBC1156408-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156408",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363650,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416713247331",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T04:00:26.739Z",
          updatedAt: "2021-09-16T04:00:26.899Z",
          labelFile: "5121358/labelFile.txt",
          zplFile: "5121358/zplFile.txt",
          pdfFile: "5121358/pdfFile.txt",
          addressString:
            "2335317uppermiddlerdbuzzer1233burlingtonontariol7l0g8canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1TX3-1M9X",
        productName:
          "2/5 CT TGW Created Blue Sapphire And 9 - 9.5 MM White Freshwater Cultured Pearl Bracelet Silver Length (inches): 7",
        productImage: "https://partner.delmarintl.ca/catalog/FC1TX3-1M9X.jpg",
        reference: "HBC1156408-0",
        sku: "FC1TX3-1M9X",
        binLocation: "14-F-26",
        boxCode: "",
        stockPickingId: "3115982",
        uniqueIdentifier: "5121358-3115982-4397829",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121358,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156408",
          sale_order_date: "2021-09-15",
          sale_order_total: 89.27,
          shipping_address_street: "233-5317 UPPER MIDDLE RD",
          shipping_address_street_2: "Buzzer 1233",
          shipping_address_id: 8363650,
          shipping_address_name: "Lesley connelly",
          shipping_address_zip: "L7L 0G8",
          shipping_address_city: "BURLINGTON",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156408-0",
          stock_picking_id: 3115982,
          total_price: 138.59,
          shipping_batch_id: -1,
          date: "2021-09-16 02:19:42",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186844,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-F-26",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397829,
          sale_order_line_description:
            "Sterling Silver, Created Blue Sapphire and 9-9.5MM Cultured Freshwater Pearl Rondelles Bracelet",
          sale_order_line_customer_cost: "138.59",
          sale_order_line_vendor_sku: "HBC006022",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1TX3-1M9X",
          product_product_name:
            "2/5 CT TGW Created Blue Sapphire And 9 - 9.5 MM White Freshwater Cultured Pearl Bracelet Silver Length (inches): 7",
          product_id: 390230,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1TX3-1M9X.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416713247331",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T04:00:26.884Z",
        updatedAt: "2021-09-16T04:00:26.885Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "G", bin: "24" },
        price: 196.34,
        notes: [],
        isSet: false,
        _id: "6142cf7e5182ab7938a6eb5a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363738",
            street: "3367 RASPBERRY BUSH TRAIL",
            name: "Garrett Holmes",
            zip: "L6L 6V2",
            city: "OAKVILLE",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 196.34,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7e5182ab7938a6eb5a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7e5182ab2186a6eb55",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121409,
          orderNo: "HBC1156493-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156493",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363738,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416713428334",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:00:46.702Z",
          updatedAt: "2021-09-16T05:00:46.857Z",
          labelFile: "5121409/labelFile.txt",
          zplFile: "5121409/zplFile.txt",
          pdfFile: "5121409/pdfFile.txt",
          addressString: "3367raspberrybushtrailoakvilleontariol6l6v2canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC09RG-RXMM",
        productName:
          "0.02 CT Diamond TW And 7 3/8 CT TGW GREEN QUARTZ- Bracelet Silver GH I3 Length (inches): 7.25",
        productImage: "https://partner.delmarintl.ca/catalog/FC09RG-RXMM.jpg",
        reference: "HBC1156493-0",
        sku: "FC09RG-RXMM",
        binLocation: "14-G-24",
        boxCode: "",
        stockPickingId: "3116024",
        uniqueIdentifier: "5121409-3116024-4397883",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121409,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156493",
          sale_order_date: "2021-09-15",
          sale_order_total: 126.56,
          shipping_address_street: "3367 RASPBERRY BUSH TRAIL",
          shipping_address_street_2: null,
          shipping_address_id: 8363738,
          shipping_address_name: "Garrett Holmes",
          shipping_address_zip: "L6L 6V2",
          shipping_address_city: "OAKVILLE",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156493-0",
          stock_picking_id: 3116024,
          total_price: 196.34,
          shipping_batch_id: -1,
          date: "2021-09-16 03:34:49",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186905,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-G-24",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397883,
          sale_order_line_description:
            "Sterling Silver, Green Quartz & 0.02 CT. T.W. Diamond Tennis Bracelet",
          sale_order_line_customer_cost: "196.34",
          sale_order_line_vendor_sku: "HBC006363",
          sale_order_line_size_id: null,
          product_product_default_code: "FC09RG-RXMM",
          product_product_name:
            "0.02 CT Diamond TW And 7 3/8 CT TGW GREEN QUARTZ- Bracelet Silver GH I3 Length (inches): 7.25",
          product_id: 3109210,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC09RG-RXMM.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416713428334",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T05:00:46.845Z",
        updatedAt: "2021-09-16T05:00:46.846Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "I", bin: "56" },
        price: 1325.62,
        notes: [],
        isSet: false,
        _id: "614277745182ab4e54a6e6e8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363343",
            street: "5 Clancy Dr",
            name: "Rong Hao",
            zip: "M2J 2V7",
            city: "North York",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 1325.62,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614277745182ab4e54a6e6e8"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614277745182abd307a6e6e3",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121196,
          orderNo: "HBC1156869-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156869",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363343,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416702692333",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T22:45:08.335Z",
          updatedAt: "2021-09-15T22:45:08.521Z",
          labelFile: "5121196/labelFile.txt",
          zplFile: "5121196/zplFile.txt",
          pdfFile: "5121196/pdfFile.txt",
          addressString: "5clancydrnorthyorkontariom2j2v7canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC08JF-1UMM",
        productName:
          "1/4 CT Diamond TW And 1 3/4 CT TGW Morganite Fashion Pendant With Chain 14k Pink Gold GH I1",
        productImage: "https://partner.delmarintl.ca/catalog/FC08JF-1UMM.jpg",
        reference: "HBC1156869-0",
        sku: "FC08JF-1UMM",
        binLocation: "14-I-56",
        boxCode: "",
        stockPickingId: "3115826",
        uniqueIdentifier: "5121196-3115826-4397633",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121196,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156869",
          sale_order_date: "2021-09-15",
          sale_order_total: 794.39,
          shipping_address_street: "5 Clancy Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363343,
          shipping_address_name: "Rong Hao",
          shipping_address_zip: "M2J 2V7",
          shipping_address_city: "North York",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156869-0",
          stock_picking_id: 3115826,
          total_price: 1325.62,
          shipping_batch_id: -1,
          date: "2021-09-15 21:34:30",
          picking_write_date: "2021-09-15 22:44:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186622,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-I-56",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397633,
          sale_order_line_description:
            "0.25 CT. T.W. Diamond, Morganite and 14K Rose Gold Halo Necklace",
          sale_order_line_customer_cost: "1325.62",
          sale_order_line_vendor_sku: "HBC002947",
          sale_order_line_size_id: null,
          product_product_default_code: "FC08JF-1UMM",
          product_product_name:
            "1/4 CT Diamond TW And 1 3/4 CT TGW Morganite Fashion Pendant With Chain 14k Pink Gold GH I1",
          product_id: 368857,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC08JF-1UMM.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416702692333",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T22:45:08.507Z",
        updatedAt: "2021-09-15T22:45:08.507Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "B", bin: "30" },
        price: 161.17,
        notes: [],
        isSet: false,
        _id: "614249bc5182ab93dea6e3d2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363120",
            street: "118 Theobalds Circle",
            name: "Carol Yewchuck",
            zip: "L4C 9E2",
            city: "Richmond Hill",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 161.17,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614249bc5182ab93dea6e3d2"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614249bc5182ab92cfa6e3cd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121051,
          orderNo: "HBC1156182-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156182",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363120,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416687267335",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T19:30:04.707Z",
          updatedAt: "2021-09-15T19:30:04.910Z",
          labelFile: "5121051/labelFile.txt",
          zplFile: "5121051/zplFile.txt",
          pdfFile: "5121051/pdfFile.txt",
          addressString: "118theobaldscirclerichmondhillontariol4c9e2canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC22ME-X159",
        productName:
          "0.02 CT Diamond TW 11-12 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC22ME-X159.jpg",
        reference: "HBC1156182-0",
        sku: "FC22ME-X159",
        binLocation: "15-B-30",
        boxCode: "",
        stockPickingId: "3115682",
        uniqueIdentifier: "5121051-3115682-4397459",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121051,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156182",
          sale_order_date: "2021-09-15",
          sale_order_total: 103.96,
          shipping_address_street: "118 Theobalds Circle",
          shipping_address_street_2: null,
          shipping_address_id: 8363120,
          shipping_address_name: "Carol Yewchuck",
          shipping_address_zip: "L4C 9E2",
          shipping_address_city: "Richmond Hill",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156182-0",
          stock_picking_id: 3115682,
          total_price: 161.17,
          shipping_batch_id: -1,
          date: "2021-09-15 18:04:12",
          picking_write_date: "2021-09-15 19:15:22",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186319,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-B-30",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397459,
          sale_order_line_description:
            "Sterling Silver, Diamond and 11-12MM Cultured Freshwater Pearl Drop Earrings",
          sale_order_line_customer_cost: "161.17",
          sale_order_line_vendor_sku: "HBC006023",
          sale_order_line_size_id: null,
          product_product_default_code: "FC22ME-X159",
          product_product_name:
            "0.02 CT Diamond TW 11-12 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver GH I2;I3",
          product_id: 3071767,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC22ME-X159.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416687267335",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T19:30:04.896Z",
        updatedAt: "2021-09-15T19:30:04.897Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "D", bin: "56" },
        price: 206,
        notes: [],
        isSet: false,
        _id: "6142f6005182ab776ca6ed0b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363880",
            street: "200 1281-91 Street SW",
            name: "Ed Romanowski",
            zip: "T6X 1H1",
            city: "Edmonton",
            country: "Canada",
            state: "Alberta",
          },
          orderValue: 206,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142f6005182ab776ca6ed0b"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f6005182abbb61a6ed06",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121481,
          orderNo: "HBC1157553-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157553",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363880,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416714094330",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T07:45:04.697Z",
          updatedAt: "2021-09-16T07:45:04.848Z",
          labelFile: "5121481/labelFile.txt",
          zplFile: "5121481/zplFile.txt",
          pdfFile: "5121481/pdfFile.txt",
          addressString: "200128191streetswedmontonalbertat6x1h1canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0C5H-6PLM",
        productName:
          "0.02 CT Diamond TW And 8.46 CT TGW Garnet Bracelet Silver I3 Length (inches): 7",
        productImage: "https://partner.delmarintl.ca/catalog/FC0C5H-6PLM.jpg",
        reference: "HBC1157553-0",
        sku: "FC0C5H-6PLM",
        binLocation: "15-D-56",
        boxCode: "",
        stockPickingId: "3116097",
        uniqueIdentifier: "5121481-3116097-4397968",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121481,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157553",
          sale_order_date: "2021-09-15",
          sale_order_total: 102.9,
          shipping_address_street: "200 1281-91 Street SW",
          shipping_address_street_2: null,
          shipping_address_id: 8363880,
          shipping_address_name: "Ed Romanowski",
          shipping_address_zip: "T6X 1H1",
          shipping_address_city: "Edmonton",
          shipping_address_state: "Alberta",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157553-0",
          stock_picking_id: 3116097,
          total_price: 206,
          shipping_batch_id: -1,
          date: "2021-09-16 06:08:25",
          picking_write_date: "2021-09-16 06:41:16",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187008,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-D-56",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397968,
          sale_order_line_description:
            "Sterling Silver, Garnet &amp; 0.02 CT. T.W. Diamond Tennis Bracelet",
          sale_order_line_customer_cost: "206.0",
          sale_order_line_vendor_sku: "HBC005946",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0C5H-6PLM",
          product_product_name:
            "0.02 CT Diamond TW And 8.46 CT TGW Garnet Bracelet Silver I3 Length (inches): 7",
          product_id: 231237,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0C5H-6PLM.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416714094330",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T07:45:04.835Z",
        updatedAt: "2021-09-16T07:45:04.836Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["14", "15"],
    _id: "6195ae14fc40c13f24ca0403",
    createdAt: "2021-11-18T01:36:20.952Z",
    updatedAt: "2021-11-18T01:36:20.952Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 5,
    orderBins: {
      "614249bc5182ab92cfa6e3cd": "HBC1156182-0",
      "614277745182abd307a6e6e3": "HBC1156869-0",
      "6142c15a5182ab47cfa6ea4f": "HBC1156408-0",
      "6142cf7e5182ab2186a6eb55": "HBC1156493-0",
      "6142f6005182abbb61a6ed06": "HBC1157553-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "18", section: "J", bin: "11" },
        price: 84,
        notes: [],
        isSet: false,
        _id: "61422a1b5182ab65c4a6e1d8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362963",
            street: "17152 ST ANDREWS RD",
            name: "Janet Nelson",
            zip: "L7C 2S1",
            city: "CALEDON EAST",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 84,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61422a1b5182ab65c4a6e1d8"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61422a1b5182abc1e7a6e1d3",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120949,
          orderNo: "HBC1156395-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156395",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362963,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416672853338",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T17:15:07.274Z",
          updatedAt: "2021-09-15T17:15:07.424Z",
          labelFile: "5120949/labelFile.txt",
          zplFile: "5120949/zplFile.txt",
          pdfFile: "5120949/pdfFile.txt",
          addressString: "17152standrewsrdcaledoneastontariol7c2s1canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "EGS1248PRLQ",
        productName:
          "5 7/8 CT TGW Peridot Lemon Quartz LeverBack Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/EGS1248PRLQ.jpg",
        reference: "HBC1156395-0",
        sku: "EGS1248PRLQ",
        binLocation: "18-J-11",
        boxCode: "",
        stockPickingId: "3115586",
        uniqueIdentifier: "5120949-3115586-4397308",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120949,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156395",
          sale_order_date: "2021-09-15",
          sale_order_total: 53.11,
          shipping_address_street: "17152 ST ANDREWS RD",
          shipping_address_street_2: null,
          shipping_address_id: 8362963,
          shipping_address_name: "Janet Nelson",
          shipping_address_zip: "L7C 2S1",
          shipping_address_city: "CALEDON EAST",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156395-0",
          stock_picking_id: 3115586,
          total_price: 84,
          shipping_batch_id: -1,
          date: "2021-09-15 16:04:20",
          picking_write_date: "2021-09-15 17:14:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186095,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-J-11",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397308,
          sale_order_line_description:
            "Sterling  Silver Peridot and Lemon Quartz Drop Earrings",
          sale_order_line_customer_cost: "84.0",
          sale_order_line_vendor_sku: "HBC000704",
          sale_order_line_size_id: null,
          product_product_default_code: "EGS1248PRLQ",
          product_product_name:
            "5 7/8 CT TGW Peridot Lemon Quartz LeverBack Earrings Silver",
          product_id: 60939,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/EGS1248PRLQ.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416672853338",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T17:15:07.411Z",
        updatedAt: "2021-09-15T17:15:07.412Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "K", bin: "01" },
        price: 136.7,
        notes: [],
        isSet: false,
        _id: "614231205182abb204a6e1e5",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362981",
            street: "32 LANSFIELD WAY",
            name: "Chirag Jain",
            zip: "K2G 3V8",
            city: "NEPEAN",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 136.7,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614231205182abb204a6e1e5"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142311f5182ab2620a6e1e0",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120962,
          orderNo: "HBC1155967-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1155967",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362981,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416676102333",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T17:45:03.972Z",
          updatedAt: "2021-09-15T17:45:04.335Z",
          labelFile: "5120962/labelFile.txt",
          zplFile: "5120962/zplFile.txt",
          pdfFile: "5120962/pdfFile.txt",
          addressString: "32lansfieldwaynepeanontariok2g3v8canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0YX5-7488",
        productName:
          "0.05 CT Diamond TW Heart Pendant With Chain Pink Silver GH I3 Pink Plated",
        productImage: "https://partner.delmarintl.ca/catalog/FC0YX5-7488.jpg",
        reference: "HBC1155967-0",
        sku: "FC0YX5-7488",
        binLocation: "18-K-01",
        boxCode: "",
        stockPickingId: "3115598",
        uniqueIdentifier: "5120962-3115598-4397323",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120962,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1155967",
          sale_order_date: "2021-09-15",
          sale_order_total: 72.32,
          shipping_address_street: "32 LANSFIELD WAY",
          shipping_address_street_2: null,
          shipping_address_id: 8362981,
          shipping_address_name: "Chirag Jain",
          shipping_address_zip: "K2G 3V8",
          shipping_address_city: "NEPEAN",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1155967-0",
          stock_picking_id: 3115598,
          total_price: 136.7,
          shipping_batch_id: -1,
          date: "2021-09-15 16:19:17",
          picking_write_date: "2021-09-15 16:41:11",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186131,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-K-01",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397323,
          sale_order_line_description: "Diamond Swirl Heart Necklace",
          sale_order_line_customer_cost: "136.7",
          sale_order_line_vendor_sku: "HBC000331",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0YX5-7488",
          product_product_name:
            "0.05 CT Diamond TW Heart Pendant With Chain Pink Silver GH I3 Pink Plated",
          product_id: 103486,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0YX5-7488.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416676102333",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T17:45:04.318Z",
        updatedAt: "2021-09-15T17:45:04.320Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["18"],
    _id: "6195ae14fc40c13f24ca0404",
    createdAt: "2021-11-18T01:36:20.956Z",
    updatedAt: "2021-11-18T01:36:20.956Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 2,
    orderBins: {
      "61422a1b5182abc1e7a6e1d3": "HBC1156395-0",
      "6142311f5182ab2620a6e1e0": "HBC1155967-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "20", section: "A", bin: "05" },
        price: 178.5,
        notes: [],
        isSet: false,
        _id: "614249bc5182abcdc2a6e3c5",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363107",
            street: "12481 204st",
            name: "Danica Berard",
            zip: "V2X 0T7",
            city: "Maple Ridge",
            country: "Canada",
            state: "British Columbia",
          },
          orderValue: 178.5,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614249bc5182abcdc2a6e3c5"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614249bc5182ab9f88a6e3c0",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121041,
          orderNo: "HBC1156009-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156009",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363107,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416687263337",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T19:30:04.338Z",
          updatedAt: "2021-09-15T19:30:04.513Z",
          labelFile: "5121041/labelFile.txt",
          zplFile: "5121041/zplFile.txt",
          pdfFile: "5121041/pdfFile.txt",
          addressString: "12481204stmapleridgebritishcolumbiav2x0t7canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "SWS01331",
        productName:
          'Set of 6-7mm White Freshwater Cultured Button Pearl Double-Row 17" Necklace & 7 Bracelet w/ Silver Beads & Silver Clasp & 7-8mm Button Earring (grade AAA)',
        productImage: "https://partner.delmarintl.ca/catalog/SWS01331.jpg",
        reference: "HBC1156009-0",
        sku: "SWS01331",
        binLocation: "20-A-05",
        boxCode: "",
        stockPickingId: "3115673",
        uniqueIdentifier: "5121041-3115673-4397451",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121041,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156009",
          sale_order_date: "2021-09-15",
          sale_order_total: 106.05,
          shipping_address_street: "12481 204st",
          shipping_address_street_2: null,
          shipping_address_id: 8363107,
          shipping_address_name: "Danica Berard",
          shipping_address_zip: "V2X 0T7",
          shipping_address_city: "Maple Ridge",
          shipping_address_state: "British Columbia",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156009-0",
          stock_picking_id: 3115673,
          total_price: 178.5,
          shipping_batch_id: -1,
          date: "2021-09-15 17:49:04",
          picking_write_date: "2021-09-15 19:15:22",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186304,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-A-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397451,
          sale_order_line_description:
            "6-8mm White Button Freshwater Pearl Sterling Silver Necklace, Bracelet and Earrings Set",
          sale_order_line_customer_cost: "178.5",
          sale_order_line_vendor_sku: "HBC001994",
          sale_order_line_size_id: null,
          product_product_default_code: "SWS01331",
          product_product_name:
            'Set of 6-7mm White Freshwater Cultured Button Pearl Double-Row 17" Necklace & 7 Bracelet w/ Silver Beads & Silver Clasp & 7-8mm Button Earring (grade AAA)',
          product_id: 61302,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/SWS01331.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416687263337",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T19:30:04.499Z",
        updatedAt: "2021-09-15T19:30:04.500Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "D", bin: "05" },
        price: 175.87,
        notes: [],
        isSet: false,
        _id: "61422a195182ab10aaa6e195",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362944",
            street: "210-1838 AV VICTORIA",
            name: "Diane Vincent",
            zip: "J4V 1M5",
            city: "GREENFIELD PARK",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 175.87,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["61422a195182ab10aaa6e195"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61422a195182ab3a4fa6e190",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120933,
          orderNo: "HBC1156387-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156387",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362944,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416672850337",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T17:15:05.276Z",
          updatedAt: "2021-09-15T17:15:05.438Z",
          labelFile: "5120933/labelFile.txt",
          zplFile: "5120933/zplFile.txt",
          pdfFile: "5120933/pdfFile.txt",
          addressString: "2101838avvictoriagreenfieldparkquebecj4v1m5canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1W6P-MPXG",
        productName: "1/4 CT Diamond TW Bracelet Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1W6P-MPXG.jpg",
        reference: "HBC1156387-0",
        sku: "FC1W6P-MPXG",
        binLocation: "20-D-05",
        boxCode: "",
        stockPickingId: "3115572",
        uniqueIdentifier: "5120933-3115572-4397290",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120933,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156387",
          sale_order_date: "2021-09-15",
          sale_order_total: 106.93,
          shipping_address_street: "210-1838 AV VICTORIA",
          shipping_address_street_2: null,
          shipping_address_id: 8362944,
          shipping_address_name: "Diane Vincent",
          shipping_address_zip: "J4V 1M5",
          shipping_address_city: "GREENFIELD PARK",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156387-0",
          stock_picking_id: 3115572,
          total_price: 175.87,
          shipping_batch_id: -1,
          date: "2021-09-15 15:51:04",
          picking_write_date: "2021-09-15 16:10:50",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186054,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-D-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397290,
          sale_order_line_description:
            "Sterling Silver Bracelet with 0.25 CT. T.W. Diamond",
          sale_order_line_customer_cost: "175.87",
          sale_order_line_vendor_sku: "HBC003602",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1W6P-MPXG",
          product_product_name: "1/4 CT Diamond TW Bracelet Silver I3",
          product_id: 461057,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1W6P-MPXG.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416672850337",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T17:15:05.425Z",
        updatedAt: "2021-09-15T17:15:05.426Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "03" },
        price: 138.32,
        notes: [],
        isSet: false,
        _id: "6142a5235182ab1b73a6e8c5",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363502",
            street: "6500 Montevideo Rd Suite 209",
            name: "Fida Mansour",
            zip: "L5N 3T6",
            city: "Mississauga",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 138.32,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142a5235182ab1b73a6e8c5"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142a5225182abc6d6a6e8c0",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121284,
          orderNo: "HBC1157209-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157209",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363502,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416711230335",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T02:00:02.791Z",
          updatedAt: "2021-09-16T02:00:03.293Z",
          labelFile: "5121284/labelFile.txt",
          zplFile: "5121284/zplFile.txt",
          pdfFile: "5121284/pdfFile.txt",
          addressString:
            "6500montevideordsuite209mississaugaontariol5n3t6canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0LG2-PV62",
        productName: "1/8 CT Diamond TW Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LG2-PV62.jpg",
        reference: "HBC1157209-0",
        sku: "FC0LG2-PV62",
        binLocation: "20-F-03",
        boxCode: "",
        stockPickingId: "3115907",
        uniqueIdentifier: "5121284-3115907-4397735",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121284,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157209",
          sale_order_date: "2021-09-15",
          sale_order_total: 76.84,
          shipping_address_street: "6500 Montevideo Rd Suite 209",
          shipping_address_street_2: null,
          shipping_address_id: 8363502,
          shipping_address_name: "Fida Mansour",
          shipping_address_zip: "L5N 3T6",
          shipping_address_city: "Mississauga",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157209-0",
          stock_picking_id: 3115907,
          total_price: 138.32,
          shipping_batch_id: -1,
          date: "2021-09-16 00:09:00",
          picking_write_date: "2021-09-16 01:44:45",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186726,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-F-03",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397735,
          sale_order_line_description:
            ".08 CT Diamond and Sterling Silver Vintage Ring",
          sale_order_line_customer_cost: "138.32",
          sale_order_line_vendor_sku: "HBC000398",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0LG2-PV62",
          product_product_name: "1/8 CT Diamond TW Fashion Ring Silver I3",
          product_id: 89442,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LG2-PV62.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416711230335",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T02:00:03.198Z",
        updatedAt: "2021-09-16T02:00:03.200Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "06" },
        price: 168,
        notes: [],
        isSet: false,
        _id: "6142bdbe5182ab00eca6e9ed",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363664",
            street: "28 Grandview Cres",
            name: "Susan SUMMERS",
            zip: "L3Z 3L1",
            city: "Bradford",
            country: "Canada",
            state: "Ontario",
          },
          orderValue: 168,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142bdbe5182ab00eca6e9ed"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142bdbe5182abc2c0a6e9e8",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121364,
          orderNo: "HBC1157363-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157363",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363664,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416713081331",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T03:45:02.785Z",
          updatedAt: "2021-09-16T03:45:02.948Z",
          labelFile: "5121364/labelFile.txt",
          zplFile: "5121364/zplFile.txt",
          pdfFile: "5121364/pdfFile.txt",
          addressString: "28grandviewcresbradfordontariol3z3l1canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC18G9-2FF8",
        productName:
          "1/10 CT Diamond TW Locket Pendant With Chain Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC18G9-2FF8.jpg",
        reference: "HBC1157363-0",
        sku: "FC18G9-2FF8",
        binLocation: "20-F-06",
        boxCode: "",
        stockPickingId: "3115988",
        uniqueIdentifier: "5121364-3115988-4397836",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121364,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157363",
          sale_order_date: "2021-09-15",
          sale_order_total: 107.35,
          shipping_address_street: "28 Grandview Cres",
          shipping_address_street_2: null,
          shipping_address_id: 8363664,
          shipping_address_name: "Susan SUMMERS",
          shipping_address_zip: "L3Z 3L1",
          shipping_address_city: "Bradford",
          shipping_address_state: "Ontario",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157363-0",
          stock_picking_id: 3115988,
          total_price: 168,
          shipping_batch_id: -1,
          date: "2021-09-16 02:32:03",
          picking_write_date: "2021-09-16 02:40:46",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186852,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-F-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397836,
          sale_order_line_description:
            ".10 CT Diamond and Sterling Silver Locket Necklace",
          sale_order_line_customer_cost: "168.0",
          sale_order_line_vendor_sku: "HBC000408",
          sale_order_line_size_id: null,
          product_product_default_code: "FC18G9-2FF8",
          product_product_name:
            "1/10 CT Diamond TW Locket Pendant With Chain Silver GH I2;I3",
          product_id: 202711,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC18G9-2FF8.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416713081331",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T03:45:02.931Z",
        updatedAt: "2021-09-16T03:45:02.932Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "07" },
        price: 241.49,
        notes: [],
        isSet: false,
        _id: "6142707a5182abc58ca6e65a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363282",
            street: "103-3766 THURSTON ST",
            name: "Maliha Omar",
            zip: "V5H 1H7",
            city: "BURNABY",
            country: "Canada",
            state: "British Columbia",
          },
          orderValue: 241.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142707a5182abc58ca6e65a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614270795182ab3bb0a6e655",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121162,
          orderNo: "HBC1156835-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156835",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363282,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416701638332",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T22:15:21.903Z",
          updatedAt: "2021-09-15T22:15:22.063Z",
          labelFile: "5121162/labelFile.txt",
          zplFile: "5121162/zplFile.txt",
          pdfFile: "5121162/pdfFile.txt",
          addressString: "1033766thurstonstburnabybritishcolumbiav5h1h7canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "SCDS10909B",
        productName:
          "1/10 CT Diamond TW 9 - 9.5 MM Black Tahitian Cultured Pearl Set With Chain Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/SCDS10909B.jpg",
        reference: "HBC1156835-0",
        sku: "SCDS10909B",
        binLocation: "20-F-07",
        boxCode: "",
        stockPickingId: "3115794",
        uniqueIdentifier: "5121162-3115794-4397593",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121162,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156835",
          sale_order_date: "2021-09-15",
          sale_order_total: 192.15,
          shipping_address_street: "103-3766 THURSTON ST",
          shipping_address_street_2: null,
          shipping_address_id: 8363282,
          shipping_address_name: "Maliha Omar",
          shipping_address_zip: "V5H 1H7",
          shipping_address_city: "BURNABY",
          shipping_address_state: "British Columbia",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156835-0",
          stock_picking_id: 3115794,
          total_price: 241.49,
          shipping_batch_id: -1,
          date: "2021-09-15 20:49:02",
          picking_write_date: "2021-09-15 22:14:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186584,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-F-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397593,
          sale_order_line_description:
            "9-9.5MM Cultured Tahitian Pearl Swirl Necklace and Earrings Set with 0.10 CT. T.W. Diamonds",
          sale_order_line_customer_cost: "241.49",
          sale_order_line_vendor_sku: "HBC001921",
          sale_order_line_size_id: null,
          product_product_default_code: "SCDS10909B",
          product_product_name:
            "1/10 CT Diamond TW 9 - 9.5 MM Black Tahitian Cultured Pearl Set With Chain Silver GH I2;I3",
          product_id: 463988,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/SCDS10909B.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416701638332",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T22:15:22.050Z",
        updatedAt: "2021-09-15T22:15:22.051Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "F", bin: "07" },
        price: 241.49,
        notes: [],
        isSet: false,
        _id: "6142cf7f5182ab7b93a6eb67",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363737",
            street: "7027 rue Chabot",
            name: "Paula Monteiro",
            zip: "H2E 2K6",
            city: "Montral",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 241.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142cf7f5182ab7b93a6eb67"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142cf7f5182ab81aaa6eb62",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121408,
          orderNo: "HBC1156488-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156488",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363737,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416713426330",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T05:00:47.033Z",
          updatedAt: "2021-09-16T05:00:47.196Z",
          labelFile: "5121408/labelFile.txt",
          zplFile: "5121408/zplFile.txt",
          pdfFile: "5121408/pdfFile.txt",
          addressString: "7027ruechabotmontralquebech2e2k6canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "SCDS10909B",
        productName:
          "1/10 CT Diamond TW 9 - 9.5 MM Black Tahitian Cultured Pearl Set With Chain Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/SCDS10909B.jpg",
        reference: "HBC1156488-0",
        sku: "SCDS10909B",
        binLocation: "20-F-07",
        boxCode: "",
        stockPickingId: "3116025",
        uniqueIdentifier: "5121408-3116025-4397882",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121408,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156488",
          sale_order_date: "2021-09-15",
          sale_order_total: 210.4,
          shipping_address_street: "7027 rue Chabot",
          shipping_address_street_2: null,
          shipping_address_id: 8363737,
          shipping_address_name: "Paula Monteiro",
          shipping_address_zip: "H2E 2K6",
          shipping_address_city: "Montral",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156488-0",
          stock_picking_id: 3116025,
          total_price: 241.49,
          shipping_batch_id: -1,
          date: "2021-09-16 03:34:49",
          picking_write_date: "2021-09-16 04:45:45",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186904,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-F-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397882,
          sale_order_line_description:
            "9-9.5MM Cultured Tahitian Pearl Swirl Necklace and Earrings Set with 0.10 CT. T.W. Diamonds",
          sale_order_line_customer_cost: "241.49",
          sale_order_line_vendor_sku: "HBC001921",
          sale_order_line_size_id: null,
          product_product_default_code: "SCDS10909B",
          product_product_name:
            "1/10 CT Diamond TW 9 - 9.5 MM Black Tahitian Cultured Pearl Set With Chain Silver GH I2;I3",
          product_id: 463988,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/SCDS10909B.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416713426330",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T05:00:47.180Z",
        updatedAt: "2021-09-16T05:00:47.180Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "B", bin: "11" },
        price: 380.62,
        notes: [],
        isSet: false,
        _id: "6143121f5182abdc88a6ee53",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363943",
            street: "7448 Kingsley road, 102",
            name: "Ecaterina Maranda",
            zip: "H4W 1P2",
            city: "Cote Saint-Luc",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 380.62,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6143121f5182abdc88a6ee53"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6143121f5182ab6565a6ee4e",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121520,
          orderNo: "HBC1157621-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157621",
          orderDate: "2021-09-16T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363943,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416714006333",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T09:45:03.628Z",
          updatedAt: "2021-09-16T09:45:03.799Z",
          labelFile: "5121520/labelFile.txt",
          zplFile: "5121520/zplFile.txt",
          pdfFile: "5121520/pdfFile.txt",
          addressString: "7448kingsleyroad102cotesaintlucquebech4w1p2canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "EDKTW7518",
        productName: "1/10 CT Diamond TW Cuff Earrings 10k White Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/EDKTW7518.jpg",
        reference: "HBC1157621-0",
        sku: "EDKTW7518",
        binLocation: "21-B-11",
        boxCode: "",
        stockPickingId: "3116134",
        uniqueIdentifier: "5121520-3116134-4398008",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121520,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157621",
          sale_order_date: "2021-09-16",
          sale_order_total: 228.8,
          shipping_address_street: "7448 Kingsley road",
          shipping_address_street_2: "102",
          shipping_address_id: 8363943,
          shipping_address_name: "Ecaterina Maranda",
          shipping_address_zip: "H4W 1P2",
          shipping_address_city: "Cote Saint-Luc",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157621-0",
          stock_picking_id: 3116134,
          total_price: 380.62,
          shipping_batch_id: -1,
          date: "2021-09-16 08:34:44",
          picking_write_date: "2021-09-16 09:44:37",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78187052,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-B-11",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4398008,
          sale_order_line_description:
            "10K White Gold &amp; 0.1 CT. T.W. Diamond Hoop Earrings",
          sale_order_line_customer_cost: "380.62",
          sale_order_line_vendor_sku: "HBC005313",
          sale_order_line_size_id: null,
          product_product_default_code: "EDKTW7518",
          product_product_name:
            "1/10 CT Diamond TW Cuff Earrings 10k White Gold GH I1;I2",
          product_id: 206489,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/EDKTW7518.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416714006333",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T09:45:03.786Z",
        updatedAt: "2021-09-16T09:45:03.786Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "I", bin: "10" },
        price: 123.29,
        notes: [],
        isSet: false,
        _id: "6142b3385182aba157a6e99a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363606",
            street: "559 Merlin Landng NW",
            name: "Graham Harris",
            zip: "T5S 0L9",
            city: "Edmonton",
            country: "Canada",
            state: "Alberta",
          },
          orderValue: 123.29,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["6142b3385182aba157a6e99a"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3385182ab591ea6e995",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121334,
          orderNo: "HBC1157304-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1157304",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363606,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416712643332",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-16T03:00:08.123Z",
          updatedAt: "2021-09-16T03:00:08.287Z",
          labelFile: "5121334/labelFile.txt",
          zplFile: "5121334/zplFile.txt",
          pdfFile: "5121334/pdfFile.txt",
          addressString: "559merlinlandngnwedmontonalbertat5s0l9canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC11RR-JL2K",
        productName:
          "9 - 10 MM Black Tahitian Cultured Pearl Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC11RR-JL2K.jpg",
        reference: "HBC1157304-0",
        sku: "FC11RR-JL2K",
        binLocation: "22-I-10",
        boxCode: "",
        stockPickingId: "3115959",
        uniqueIdentifier: "5121334-3115959-4397796",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121334,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1157304",
          sale_order_date: "2021-09-15",
          sale_order_total: 66.15,
          shipping_address_street: "559 Merlin Landng NW",
          shipping_address_street_2: null,
          shipping_address_id: 8363606,
          shipping_address_name: "Graham Harris",
          shipping_address_zip: "T5S 0L9",
          shipping_address_city: "Edmonton",
          shipping_address_state: "Alberta",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1157304-0",
          stock_picking_id: 3115959,
          total_price: 123.29,
          shipping_batch_id: -1,
          date: "2021-09-16 01:34:44",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186806,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-I-10",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397796,
          sale_order_line_description:
            "9.5-10mm Black Round Tahitian Cultured Pearl and Sterling Silver Arch Necklace",
          sale_order_line_customer_cost: "123.29",
          sale_order_line_vendor_sku: "HBC000958",
          sale_order_line_size_id: null,
          product_product_default_code: "FC11RR-JL2K",
          product_product_name:
            "9 - 10 MM Black Tahitian Cultured Pearl Fashion Pendant With Chain Silver",
          product_id: 181097,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC11RR-JL2K.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416712643332",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T03:00:08.270Z",
        updatedAt: "2021-09-16T03:00:08.271Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "Q", bin: "11" },
        price: 114.99,
        notes: [],
        isSet: false,
        _id: "614289205182ab0f6aa6e7c2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363426",
            street: "343 rue de la Greve",
            name: "Sophie Lamarre",
            zip: "G0R 4J0",
            city: "SAINT-VALLIER",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 114.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614289205182ab0f6aa6e7c2"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614289205182ab4338a6e7bd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121236,
          orderNo: "WMCY26417183.001-0",
          customerId: 231,
          customerName: "Walmart Canada",
          orderReference: "WMCY26417183.001",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363426,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2000280704819335",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined:
            "wmc/sophie lamarre/343 rue de la greve//saint-vallier/quebec/g0r 4j0/canada",
          createdAt: "2021-09-16T00:00:32.717Z",
          updatedAt: "2021-09-16T00:00:32.867Z",
          labelFile: "5121236/labelFile.txt",
          zplFile: "5121236/zplFile.txt",
          pdfFile: "5121236/pdfFile.txt",
          addressString: "343ruedelagrevesaintvallierquebecg0r4j0canada",
          normalizedCustomerName: "walmart canada",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "PCS2291BC",
        productName:
          "9 - 9.5 MM Black Tahitian Cultured Pearl Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/PCS2291BC.jpg",
        reference: "WMCY26417183.001-0",
        sku: "PCS2291BC",
        binLocation: "22-Q-11",
        boxCode: "",
        stockPickingId: "3115864",
        uniqueIdentifier: "5121236-3115864-4397677",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121236,
          sale_order_customer_id: 231,
          sale_order_customer_name: "Walmart Canada",
          sale_order_order_reference: "WMCY26417183.001",
          sale_order_date: "2021-09-15",
          sale_order_total: 80.48,
          shipping_address_street: "343 rue de la Greve",
          shipping_address_street_2: null,
          shipping_address_id: 8363426,
          shipping_address_name: "Sophie Lamarre",
          shipping_address_zip: "G0R 4J0",
          shipping_address_city: "SAINT-VALLIER",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "WMCY26417183.001-0",
          stock_picking_id: 3115864,
          total_price: 114.99,
          shipping_batch_id: -1,
          date: "2021-09-15 22:18:41",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined:
            "wmc/sophie lamarre/343 rue de la greve//saint-vallier/quebec/g0r 4j0/canada",
          stock_move_is_set: false,
          stock_move_id: 78186670,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-Q-11",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397677,
          sale_order_line_description:
            "Asteria 9 9 5mm Black Drop Tahitian Cultured Pearl Sterling Silver Fashion",
          sale_order_line_customer_cost: "114.99",
          sale_order_line_vendor_sku: "WMC002860",
          sale_order_line_size_id: null,
          product_product_default_code: "PCS2291BC",
          product_product_name:
            "9 - 9.5 MM Black Tahitian Cultured Pearl Fashion Pendant With Chain Silver",
          product_id: 69089,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/PCS2291BC.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2000280704819335",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-16T00:00:32.856Z",
        updatedAt: "2021-09-16T00:00:32.857Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["20", "21", "22"],
    _id: "6195ae14fc40c13f24ca0405",
    createdAt: "2021-11-18T01:36:20.959Z",
    updatedAt: "2021-11-18T01:36:20.959Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 9,
    orderBins: {
      "61422a195182ab3a4fa6e190": "HBC1156387-0",
      "614249bc5182ab9f88a6e3c0": "HBC1156009-0",
      "614270795182ab3bb0a6e655": "HBC1156835-0",
      "614289205182ab4338a6e7bd": "WMCY26417183.001-0",
      "6142a5225182abc6d6a6e8c0": "HBC1157209-0",
      "6142b3385182ab591ea6e995": "HBC1157304-0",
      "6142bdbe5182abc2c0a6e9e8": "HBC1157363-0",
      "6142cf7f5182ab81aaa6eb62": "HBC1156488-0",
      "6143121f5182ab6565a6ee4e": "HBC1157621-0",
    },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "24", section: "L", bin: "30" },
        price: 609,
        notes: [],
        isSet: false,
        _id: "614234b45182abf8f7a6e281",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362906",
            street: "354 boul Ste-Rose",
            name: "Isabelle Perrault",
            zip: "H7L 1N1",
            city: "Laval",
            country: "Canada",
            state: "Quebec",
          },
          orderValue: 609,
          orderStatus: "UNPROCESSED",
          orderLineItems: ["614234b45182abf8f7a6e281"],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: true,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614234b45182ab59a8a6e27c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120862,
          orderNo: "HBC1156322-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156322",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362906,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416675794331",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T18:00:20.339Z",
          updatedAt: "2021-09-15T18:00:20.490Z",
          labelFile: "5120862/labelFile.txt",
          zplFile: "5120862/zplFile.txt",
          pdfFile: "5120862/pdfFile.txt",
          addressString: "354boulsteroselavalquebech7l1n1canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 1,
          noOfItems: 1,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2161-MY5W",
        productName:
          "3/8 CT Black and White Emerald and Round Diamonds TW Fashion Ring 10k Pink Gold GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2161-MY5W.jpg",
        reference: "HBC1156322-0",
        sku: "FC2161-MY5W",
        binLocation: "24-L-30",
        boxCode: "",
        stockPickingId: "3115499",
        uniqueIdentifier: "5120862-3115499-4397212",
        stockMoveSize: "6.0",
        orderLineSize: "7.0",
        isNoResize: false,
        rawData: {
          sale_order_id: 5120862,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156322",
          sale_order_date: "2021-09-15",
          sale_order_total: 373.67,
          shipping_address_street: "354 boul Ste-Rose",
          shipping_address_street_2: null,
          shipping_address_id: 8362906,
          shipping_address_name: "Isabelle Perrault",
          shipping_address_zip: "H7L 1N1",
          shipping_address_city: "Laval",
          shipping_address_state: "Quebec",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156322-0",
          stock_picking_id: 3115499,
          total_price: 609,
          shipping_batch_id: -1,
          date: "2021-09-15 15:04:31",
          picking_write_date: "2021-09-15 17:44:38",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78185873,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "24-L-30",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397212,
          sale_order_line_description:
            "10K Rose Gold, 0.38 CT. T.W. Diamond &amp; Black Diamond Modern Victorian-Style Ring",
          sale_order_line_customer_cost: "609.0",
          sale_order_line_vendor_sku: "HBC003397",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC2161-MY5W",
          product_product_name:
            "3/8 CT Black and White Emerald and Round Diamonds TW Fashion Ring 10k Pink Gold GH I2;I3",
          product_id: 1216460,
          ship2store: false,
          is_no_resize: false,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2161-MY5W.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416675794331",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T18:00:20.478Z",
        updatedAt: "2021-09-15T18:00:20.479Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: false,
    assignedTables: [],
    assignedPickers: [],
    rows: ["24"],
    _id: "6195ae14fc40c13f24ca0406",
    createdAt: "2021-11-18T01:36:20.965Z",
    updatedAt: "2021-11-18T01:36:20.965Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 1,
    orderBins: { "614234b45182ab59a8a6e27c": "HBC1156322-0" },
    priority: 7,
    tags: "Single-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "01", section: "E", bin: "47" },
        price: 542.5,
        notes: [],
        isSet: true,
        _id: "61425b575182abbadaa6e4d7",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363192",
            street: "29755 Tiffany Woods Rd",
            name: "Katherine Smith",
            zip: "28137",
            city: "Richfield",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 1085,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61425b575182ab2970a6e4ce",
            "61425b575182abbadaa6e4d7",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61425b575182ab0152a6e4c9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121089,
          orderNo: "BLK100962524-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100962524",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363192,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694104",
          carrier: "Fedex",
          shippingMethod: " 2nd Day",
          addressGroupCombined:
            "blk/katherine smith/29755 tiffany woods rd//richfield/north carolina/28137/united states",
          createdAt: "2021-09-15T20:45:11.319Z",
          updatedAt: "2021-09-15T20:45:11.772Z",
          labelFile: "5121089/labelFile.txt",
          zplFile: "5121089/zplFile.txt",
          pdfFile: "5121089/pdfFile.txt",
          addressString:
            "29755tiffanywoodsrdrichfieldnorthcarolina28137unitedstates",
          normalizedCustomerName: "belk",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC17UB-HE9J",
        productName:
          "1/6 CT Diamond TW And 1 1/3 CT TGW Morganite Fashion Ring 10k Pink Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC17UB-HE9J.jpg",
        reference: "BLK100962524-0",
        sku: "FC17UB-HE9J",
        binLocation: "01-E-47",
        boxCode: "",
        stockPickingId: "3115722",
        uniqueIdentifier: "5121089-3115722-4397508",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121089,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100962524",
          sale_order_date: "2021-09-15",
          sale_order_total: 484,
          shipping_address_street: "29755 Tiffany Woods Rd",
          shipping_address_street_2: null,
          shipping_address_id: 8363192,
          shipping_address_name: "Katherine Smith",
          shipping_address_zip: "28137",
          shipping_address_city: "Richfield",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100962524-0",
          stock_picking_id: 3115722,
          total_price: 1085,
          shipping_batch_id: -1,
          date: "2021-09-15 19:28:18",
          picking_write_date: "2021-09-15 19:40:45",
          address_grp_combined:
            "blk/katherine smith/29755 tiffany woods rd//richfield/north carolina/28137/united states",
          stock_move_is_set: true,
          stock_move_id: 78186429,
          stock_move_set_product_id: 391154,
          stock_move_quantity: 1,
          stock_move_bin: "01-E-47",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397508,
          sale_order_line_description: "MRG RG SET 10KR 10 DS",
          sale_order_line_customer_cost: "542.5",
          sale_order_line_vendor_sku: "686692126404",
          sale_order_line_size_id: 13,
          product_product_default_code: "FC17UB-HE9J",
          product_product_name:
            "1/6 CT Diamond TW And 1 1/3 CT TGW Morganite Fashion Ring 10k Pink Gold GH I1;I2",
          product_id: 199567,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC17UB-HE9J.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " 2nd Day",
          order_tracking_number: "501509694104",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T20:45:11.758Z",
        updatedAt: "2021-09-15T20:45:11.759Z",
        __v: 0,
      },
      {
        stockBin: { row: "02", section: "B", bin: "03" },
        price: 208.49,
        notes: [],
        isSet: false,
        _id: "614297185182ab89e2a6e837",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363468",
            street: "4179 Slice Dr",
            name: "Kenneth Gillus",
            zip: "80922",
            city: "Colorado Springs",
            country: "United States",
            state: "Colorado",
          },
          orderValue: 423.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614297185182ab89e2a6e837",
            "614297195182ab8a0ba6e841",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297185182ab2a6da6e832",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121260,
          orderNo: "OS355662243-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355662243",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363468,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256382",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/kenneth gillus/4179 slice dr//colorado springs/colorado/80922/united states",
          createdAt: "2021-09-16T01:00:08.836Z",
          updatedAt: "2021-09-16T01:00:09.490Z",
          labelFile: "5121260/labelFile.txt",
          zplFile: "5121260/zplFile.txt",
          pdfFile: "5121260/pdfFile.txt",
          addressString: "4179slicedrcoloradospringscolorado80922unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1Z85-R88P",
        productName: "1/5 CT Diamond TW LeverBack Earrings Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1Z85-R88P.jpg",
        reference: "OS355662243-0",
        sku: "FC1Z85-R88P",
        binLocation: "02-B-03",
        boxCode: "",
        stockPickingId: "3115891",
        uniqueIdentifier: "5121260-3115891-4397706",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121260,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355662243",
          sale_order_date: "2021-09-15",
          sale_order_total: 296,
          shipping_address_street: "4179 Slice Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363468,
          shipping_address_name: "Kenneth Gillus",
          shipping_address_zip: "80922",
          shipping_address_city: "Colorado Springs",
          shipping_address_state: "Colorado",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355662243-0",
          stock_picking_id: 3115891,
          total_price: 423.48,
          shipping_batch_id: -1,
          date: "2021-09-15 23:23:05",
          picking_write_date: "2021-09-16 00:44:59",
          address_grp_combined:
            "os/kenneth gillus/4179 slice dr//colorado springs/colorado/80922/united states",
          stock_move_is_set: false,
          stock_move_id: 78186705,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-B-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397706,
          sale_order_line_description:
            "Miadora Sterling Silver 1/5ct TDW Diamond Square Halo Leverback Earrings",
          sale_order_line_customer_cost: "208.49",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC1Z85-R88P",
          product_product_name:
            "1/5 CT Diamond TW LeverBack Earrings Silver GH I2;I3",
          product_id: 468715,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1Z85-R88P.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256382",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T01:00:09.000Z",
        updatedAt: "2021-09-16T01:00:09.001Z",
        __v: 0,
      },
      {
        stockBin: { row: "03", section: "A", bin: "36" },
        price: 410.495,
        notes: [],
        isSet: true,
        _id: "6142c15a5182ab65e3a6ea3a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363647",
            street: "202 Haddon Rd",
            name: "Joshua Marini",
            zip: "08015-6428",
            city: "Browns Mills",
            country: "United States",
            state: "New Jersey",
          },
          orderValue: 820.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142c1595182abbacaa6ea31",
            "6142c15a5182ab65e3a6ea3a",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142c1595182ab776aa6ea2c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121355,
          orderNo: "GVX2784211-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2784211-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363647,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9402116902453316256298",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T04:00:25.787Z",
          updatedAt: "2021-09-16T04:00:26.241Z",
          labelFile: "5121355/labelFile.txt",
          zplFile: "5121355/zplFile.txt",
          pdfFile: "5121355/pdfFile.txt",
          addressString: "202haddonrdbrownsmillsnewjersey080156428unitedstates",
          normalizedCustomerName: "govx",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC225Z-BJZ7",
        productName: "1/8 CT Diamond TW Fashion Ring 14k White Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC225Z-BJZ7.jpg",
        reference: "GVX2784211-DEL01-0",
        sku: "FC225Z-BJZ7",
        binLocation: "03-A-36",
        boxCode: "",
        stockPickingId: "3115978",
        uniqueIdentifier: "5121355-3115978-4397825",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121355,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2784211-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 558,
          shipping_address_street: "202 Haddon Rd",
          shipping_address_street_2: "",
          shipping_address_id: 8363647,
          shipping_address_name: "Joshua Marini",
          shipping_address_zip: "08015-6428",
          shipping_address_city: "Browns Mills",
          shipping_address_state: "New Jersey",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2784211-DEL01-0",
          stock_picking_id: 3115978,
          total_price: 820.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:18:49",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186837,
          stock_move_set_product_id: 2762393,
          stock_move_quantity: 1,
          stock_move_bin: "03-A-36",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397825,
          sale_order_line_description:
            "1/4 CT Round Cut Diamond TW and 2 CT TGW Created Moissanite - White Bridal Ring Set - 14K White Gold - 7",
          sale_order_line_customer_cost: "410.495",
          sale_order_line_vendor_sku: "GVX005391-7",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC225Z-BJZ7",
          product_product_name:
            "1/8 CT Diamond TW Fashion Ring 14k White Gold GH I1;I2",
          product_id: 2578317,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC225Z-BJZ7.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9402116902453316256298",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:26.231Z",
        updatedAt: "2021-09-16T04:00:26.231Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "A", bin: "49" },
        price: 542.5,
        notes: [],
        isSet: true,
        _id: "61425b575182ab2970a6e4ce",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363192",
            street: "29755 Tiffany Woods Rd",
            name: "Katherine Smith",
            zip: "28137",
            city: "Richfield",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 1085,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61425b575182ab2970a6e4ce",
            "61425b575182abbadaa6e4d7",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61425b575182ab0152a6e4c9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121089,
          orderNo: "BLK100962524-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100962524",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363192,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694104",
          carrier: "Fedex",
          shippingMethod: " 2nd Day",
          addressGroupCombined:
            "blk/katherine smith/29755 tiffany woods rd//richfield/north carolina/28137/united states",
          createdAt: "2021-09-15T20:45:11.319Z",
          updatedAt: "2021-09-15T20:45:11.772Z",
          labelFile: "5121089/labelFile.txt",
          zplFile: "5121089/zplFile.txt",
          pdfFile: "5121089/pdfFile.txt",
          addressString:
            "29755tiffanywoodsrdrichfieldnorthcarolina28137unitedstates",
          normalizedCustomerName: "belk",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC17UB-WB2",
        productName: "0.06 CT Diamond TW Fashion Ring 10k Pink Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC17UB-WB2.jpg",
        reference: "BLK100962524-0",
        sku: "FC17UB-WB2",
        binLocation: "04-A-49",
        boxCode: "",
        stockPickingId: "3115722",
        uniqueIdentifier: "5121089-3115722-4397509",
        stockMoveSize: "10.0",
        orderLineSize: "10.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121089,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100962524",
          sale_order_date: "2021-09-15",
          sale_order_total: 484,
          shipping_address_street: "29755 Tiffany Woods Rd",
          shipping_address_street_2: null,
          shipping_address_id: 8363192,
          shipping_address_name: "Katherine Smith",
          shipping_address_zip: "28137",
          shipping_address_city: "Richfield",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100962524-0",
          stock_picking_id: 3115722,
          total_price: 1085,
          shipping_batch_id: -1,
          date: "2021-09-15 19:28:18",
          picking_write_date: "2021-09-15 19:40:45",
          address_grp_combined:
            "blk/katherine smith/29755 tiffany woods rd//richfield/north carolina/28137/united states",
          stock_move_is_set: true,
          stock_move_id: 78186430,
          stock_move_set_product_id: 391154,
          stock_move_quantity: 1,
          stock_move_bin: "04-A-49",
          stock_move_uom: "PCE",
          stock_move_size: "10.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397509,
          sale_order_line_description: "MRG RG SET 10KR 10 DS",
          sale_order_line_customer_cost: "542.5",
          sale_order_line_vendor_sku: "686692126404",
          sale_order_line_size_id: 13,
          product_product_default_code: "FC17UB-WB2",
          product_product_name:
            "0.06 CT Diamond TW Fashion Ring 10k Pink Gold GH I1;I2",
          product_id: 376959,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC17UB-WB2.jpg",
          sale_order_line_size: "10.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " 2nd Day",
          order_tracking_number: "501509694104",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T20:45:11.465Z",
        updatedAt: "2021-09-15T20:45:11.466Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "D", bin: "65" },
        price: 87.5,
        notes: [],
        isSet: false,
        _id: "6142eb795182ab101fa6ecd9",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363854",
            street: "200 Fernway Dr",
            name: "Sheila Ziegler",
            zip: "35501-3324",
            city: "Jasper",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 175,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142eb795182ab0b9ca6ecd0",
            "6142eb795182ab101fa6ecd9",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb795182abc8fea6eccb",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121468,
          orderNo: "BLK100984139-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100984139",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363854,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694402",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/sheila ziegler/200 fernway dr//jasper/alabama/35501-3324/united states",
          createdAt: "2021-09-16T07:00:09.465Z",
          updatedAt: "2021-09-16T07:00:09.971Z",
          labelFile: "5121468/labelFile.txt",
          zplFile: "5121468/zplFile.txt",
          pdfFile: "5121468/pdfFile.txt",
          addressString: "200fernwaydrjasperalabama355013324unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2V-6Z87",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-6Z87.jpg",
        reference: "BLK100984139-0",
        sku: "FC0T2V-6Z87",
        binLocation: "04-D-65",
        boxCode: "",
        stockPickingId: "3116084",
        uniqueIdentifier: "5121468-3116084-4397951",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121468,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100984139",
          sale_order_date: "2021-09-15",
          sale_order_total: 84,
          shipping_address_street: "200 Fernway Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363854,
          shipping_address_name: "Sheila Ziegler",
          shipping_address_zip: "35501-3324",
          shipping_address_city: "Jasper",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100984139-0",
          stock_picking_id: 3116084,
          total_price: 175,
          shipping_batch_id: -1,
          date: "2021-09-16 05:34:03",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined:
            "blk/sheila ziegler/200 fernway dr//jasper/alabama/35501-3324/united states",
          stock_move_is_set: false,
          stock_move_id: 78186987,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-D-65",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397951,
          sale_order_line_description: "CWS DIA FLW RG SS 9 DS",
          sale_order_line_customer_cost: "87.5",
          sale_order_line_vendor_sku: "686692415867",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC0T2V-6Z87",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 3101737,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-6Z87.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694402",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:00:09.955Z",
        updatedAt: "2021-09-16T07:00:09.955Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "I", bin: "43" },
        price: 410.495,
        notes: [],
        isSet: true,
        _id: "6142c1595182abbacaa6ea31",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363647",
            street: "202 Haddon Rd",
            name: "Joshua Marini",
            zip: "08015-6428",
            city: "Browns Mills",
            country: "United States",
            state: "New Jersey",
          },
          orderValue: 820.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142c1595182abbacaa6ea31",
            "6142c15a5182ab65e3a6ea3a",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142c1595182ab776aa6ea2c",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121355,
          orderNo: "GVX2784211-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2784211-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363647,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9402116902453316256298",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T04:00:25.787Z",
          updatedAt: "2021-09-16T04:00:26.241Z",
          labelFile: "5121355/labelFile.txt",
          zplFile: "5121355/zplFile.txt",
          pdfFile: "5121355/pdfFile.txt",
          addressString: "202haddonrdbrownsmillsnewjersey080156428unitedstates",
          normalizedCustomerName: "govx",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2267-WG58",
        productName:
          "1/10 CT Diamond TW And 2 CT DEW Created Moissanite-White Fashion Ring 14k White Gold GH I1;I2",
        productImage: "https://partner.delmarintl.ca/catalog/FC2267-WG58.jpg",
        reference: "GVX2784211-DEL01-0",
        sku: "FC2267-WG58",
        binLocation: "04-I-43",
        boxCode: "",
        stockPickingId: "3115978",
        uniqueIdentifier: "5121355-3115978-4397826",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121355,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2784211-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 558,
          shipping_address_street: "202 Haddon Rd",
          shipping_address_street_2: "",
          shipping_address_id: 8363647,
          shipping_address_name: "Joshua Marini",
          shipping_address_zip: "08015-6428",
          shipping_address_city: "Browns Mills",
          shipping_address_state: "New Jersey",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2784211-DEL01-0",
          stock_picking_id: 3115978,
          total_price: 820.99,
          shipping_batch_id: -1,
          date: "2021-09-16 02:18:49",
          picking_write_date: "2021-09-16 03:44:55",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186838,
          stock_move_set_product_id: 2762393,
          stock_move_quantity: 1,
          stock_move_bin: "04-I-43",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397826,
          sale_order_line_description:
            "1/4 CT Round Cut Diamond TW and 2 CT TGW Created Moissanite - White Bridal Ring Set - 14K White Gold - 7",
          sale_order_line_customer_cost: "410.495",
          sale_order_line_vendor_sku: "GVX005391-7",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC2267-WG58",
          product_product_name:
            "1/10 CT Diamond TW And 2 CT DEW Created Moissanite-White Fashion Ring 14k White Gold GH I1;I2",
          product_id: 2578321,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2267-WG58.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9402116902453316256298",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:00:25.931Z",
        updatedAt: "2021-09-16T04:00:25.932Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "I", bin: "59" },
        price: 133.32,
        notes: [],
        isSet: false,
        _id: "61429a965182ab3b83a6e84f",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363492",
            street: "230 ROBBINS AVE",
            name: "YOLANDA GILL",
            zip: "49022",
            city: "BENTON HARBOR",
            country: "United States",
            state: "Michigan",
          },
          orderValue: 266.64,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61429a965182ab3b83a6e84f",
            "61429a975182ab64a7a6e858",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61429a965182ab1259a6e84a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121275,
          orderNo: "JCP355655698_274-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355655698_274",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363492,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552206",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/yolanda gill/230 robbins ave//benton harbor/michigan/49022/united states",
          createdAt: "2021-09-16T01:15:02.652Z",
          updatedAt: "2021-09-16T01:15:03.251Z",
          labelFile: "5121275/labelFile.txt",
          zplFile: "5121275/zplFile.txt",
          pdfFile: "5121275/pdfFile.txt",
          addressString: "230robbinsavebentonharbormichigan49022unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0L5L-XEMV",
        productName: "1/5 CT Diamond TW Anniversary Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
        reference: "JCP355655698_274-0",
        sku: "FC0L5L-XEMV",
        binLocation: "05-I-59",
        boxCode: "",
        stockPickingId: "3115900",
        uniqueIdentifier: "5121275-3115900-4397720",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121275,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355655698_274",
          sale_order_date: "2021-09-15",
          sale_order_total: 76,
          shipping_address_street: "230 ROBBINS AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363492,
          shipping_address_name: "YOLANDA GILL",
          shipping_address_zip: "49022",
          shipping_address_city: "BENTON HARBOR",
          shipping_address_state: "Michigan",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355655698_274-0",
          stock_picking_id: 3115900,
          total_price: 266.64,
          shipping_batch_id: -1,
          date: "2021-09-15 23:55:25",
          picking_write_date: "2021-09-16 01:14:37",
          address_grp_combined:
            "jcp/yolanda gill/230 robbins ave//benton harbor/michigan/49022/united states",
          stock_move_is_set: false,
          stock_move_id: 78186718,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "05-I-59",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397720,
          sale_order_line_description: "SS 1/5CTTW RNG",
          sale_order_line_customer_cost: "133.32",
          sale_order_line_vendor_sku: "JCP002584",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0L5L-XEMV",
          product_product_name: "1/5 CT Diamond TW Anniversary Ring Silver I3",
          product_id: 90399,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552206",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T01:15:02.928Z",
        updatedAt: "2021-09-16T01:15:02.929Z",
        __v: 0,
      },
      {
        stockBin: { row: "05", section: "L", bin: "16" },
        price: 171.245,
        notes: [],
        isSet: true,
        _id: "6142c4cc5182ab0bd5a6eaf0",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363690",
            street: "530 Raven Ave",
            name: "Jackie Padron",
            zip: "33166",
            city: "Miami Springs",
            country: "United States",
            state: "Florida",
          },
          orderValue: 342.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142c4cb5182abb7b6a6eae6",
            "6142c4cc5182ab0bd5a6eaf0",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142c4cb5182abe8e1a6eae1",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121381,
          orderNo: "OS355683301-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355683301",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363690,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256511",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/jackie padron/530 raven ave//miami springs/florida/33166/united states",
          createdAt: "2021-09-16T04:15:07.823Z",
          updatedAt: "2021-09-16T04:15:08.406Z",
          labelFile: "5121381/labelFile.txt",
          zplFile: "5121381/zplFile.txt",
          pdfFile: "5121381/pdfFile.txt",
          addressString: "530ravenavemiamispringsflorida33166unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RYKTW100208WS",
        productName:
          "2 3/8 CT TGW Created White Sapphire Solitaire Ring 10k White Gold",
        productImage: "https://partner.delmarintl.ca/catalog/RYKTW100208WS.jpg",
        reference: "OS355683301-0",
        sku: "RYKTW100208WS",
        binLocation: "05-L-16",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116000",
        uniqueIdentifier: "5121381-3116000-4397849",
        stockMoveSize: "5.5",
        orderLineSize: "5.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121381,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355683301",
          sale_order_date: "2021-09-15",
          sale_order_total: 271,
          shipping_address_street: "530 Raven Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363690,
          shipping_address_name: "Jackie Padron",
          shipping_address_zip: "33166",
          shipping_address_city: "Miami Springs",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355683301-0",
          stock_picking_id: 3116000,
          total_price: 342.49,
          shipping_batch_id: -1,
          date: "2021-09-16 02:46:24",
          picking_write_date: "2021-09-16 03:10:57",
          address_grp_combined:
            "os/jackie padron/530 raven ave//miami springs/florida/33166/united states",
          stock_move_is_set: true,
          stock_move_id: 78186869,
          stock_move_set_product_id: 226609,
          stock_move_quantity: 1,
          stock_move_bin: "05-L-16",
          stock_move_uom: "PCE",
          stock_move_size: "5.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397849,
          sale_order_line_description: "5.5",
          sale_order_line_customer_cost: "171.245",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 4,
          product_product_default_code: "RYKTW100208WS",
          product_product_name:
            "2 3/8 CT TGW Created White Sapphire Solitaire Ring 10k White Gold",
          product_id: 73902,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RYKTW100208WS.jpg",
          sale_order_line_size: "5.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256511",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:15:08.395Z",
        updatedAt: "2021-09-16T04:15:08.396Z",
        __v: 0,
      },
      {
        stockBin: { row: "07", section: "D", bin: "10" },
        price: 85.73,
        notes: [],
        isSet: false,
        _id: "61425b545182abbf12a6e460",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363168",
            street: "1402 NORTH HARVARD AVENUE, SUITE E",
            name: "GISELLE BUSTOS",
            zip: "74115",
            city: "TULSA",
            country: "United States",
            state: "Oklahoma",
          },
          orderValue: 376.55,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61425b545182abbf12a6e460",
            "61425b545182ab1858a6e469",
            "61425b545182abd7e8a6e472",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61425b545182ab9400a6e45b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121078,
          orderNo: "SAMS8775303516-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775303516",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363168,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594107",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-15T20:45:08.103Z",
          updatedAt: "2021-09-15T20:45:08.880Z",
          labelFile: "5121078/labelFile.txt",
          zplFile: "5121078/zplFile.txt",
          pdfFile: "5121078/pdfFile.txt",
          addressString:
            "1402northharvardavenuesuiteetulsaoklahoma74115unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1Y1P-WXK7",
        productName:
          "1 7/8 CT TGW Blue Topaz - Sky White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1Y1P-WXK7.jpg",
        reference: "SAMS8775303516-0",
        sku: "FC1Y1P-WXK7",
        binLocation: "07-D-10",
        boxCode: "SCE",
        stockPickingId: "3115711",
        uniqueIdentifier: "5121078-3115711-4397496",
        stockMoveSize: "6.5",
        orderLineSize: "6.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121078,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775303516",
          sale_order_date: "2021-09-15",
          sale_order_total: 248,
          shipping_address_street: "1402 NORTH HARVARD AVENUE",
          shipping_address_street_2: "SUITE E",
          shipping_address_id: 8363168,
          shipping_address_name: "GISELLE BUSTOS",
          shipping_address_zip: "74115",
          shipping_address_city: "TULSA",
          shipping_address_state: "Oklahoma",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775303516-0",
          stock_picking_id: 3115711,
          total_price: 376.55,
          shipping_batch_id: -1,
          date: "2021-09-15 19:18:36",
          picking_write_date: "2021-09-15 19:41:05",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186410,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "07-D-10",
          stock_move_uom: "PCE",
          stock_move_size: "6.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397496,
          sale_order_line_description: "1.89 CCTW GEM RNG65",
          sale_order_line_customer_cost: "85.73",
          sale_order_line_vendor_sku: "SAMS01754/6.5",
          sale_order_line_size_id: 6,
          product_product_default_code: "FC1Y1P-WXK7",
          product_product_name:
            "1 7/8 CT TGW Blue Topaz - Sky White Sapphire Fashion Ring Silver",
          product_id: 466351,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1Y1P-WXK7.jpg",
          sale_order_line_size: "6.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594107",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T20:45:08.254Z",
        updatedAt: "2021-09-15T20:45:08.255Z",
        __v: 0,
      },
      {
        stockBin: { row: "09", section: "D", bin: "24" },
        price: 61.25,
        notes: [],
        isSet: false,
        _id: "6142b3395182ab9226a6e9bd",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363614",
            street: "246 EMERALD CIR",
            name: "Leonor Figueroa",
            zip: "30628-1735",
            city: "COLBERT",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 140,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142b3385182ab01b8a6e9b4",
            "6142b3395182ab9226a6e9bd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3385182ab2c9ba6e9af",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121338,
          orderNo: "BLK100977483-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100977483",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363614,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694295",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/leonor figueroa/246 emerald cir//colbert/georgia/30628-1735/united states",
          createdAt: "2021-09-16T03:00:08.813Z",
          updatedAt: "2021-09-16T03:00:09.297Z",
          labelFile: "5121338/labelFile.txt",
          zplFile: "5121338/zplFile.txt",
          pdfFile: "5121338/pdfFile.txt",
          addressString: "246emeraldcircolbertgeorgia306281735unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC11BC-PYGB",
        productName: "1 1/2 CT TGW CREATED WHITE SAPPHIRE FASHION RING SILVER",
        productImage: "https://partner.delmarintl.ca/catalog/FC11BC-PYGB.jpg",
        reference: "BLK100977483-0",
        sku: "FC11BC-PYGB",
        binLocation: "09-D-24",
        boxCode: "",
        stockPickingId: "3115961",
        uniqueIdentifier: "5121338-3115961-4397800",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121338,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100977483",
          sale_order_date: "2021-09-15",
          sale_order_total: 62,
          shipping_address_street: "246 EMERALD CIR",
          shipping_address_street_2: null,
          shipping_address_id: 8363614,
          shipping_address_name: "Leonor Figueroa",
          shipping_address_zip: "30628-1735",
          shipping_address_city: "COLBERT",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100977483-0",
          stock_picking_id: 3115961,
          total_price: 140,
          shipping_batch_id: -1,
          date: "2021-09-16 01:35:04",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "blk/leonor figueroa/246 emerald cir//colbert/georgia/30628-1735/united states",
          stock_move_is_set: false,
          stock_move_id: 78186808,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "09-D-24",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397800,
          sale_order_line_description: "CWS EG RG SS 6 DS",
          sale_order_line_customer_cost: "61.25",
          sale_order_line_vendor_sku: "686692375963",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC11BC-PYGB",
          product_product_name:
            "1 1/2 CT TGW CREATED WHITE SAPPHIRE FASHION RING SILVER",
          product_id: 107097,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC11BC-PYGB.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694295",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:09.272Z",
        updatedAt: "2021-09-16T03:00:09.272Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "C", bin: "18" },
        price: 157.89,
        notes: [],
        isSet: true,
        _id: "6142544a5182abe880a6e430",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363158",
            street: "4874 PENNYROYAL RD",
            name: "RICHARD THOMAS",
            zip: "45005",
            city: "FRANKLIN",
            country: "United States",
            state: "Ohio",
          },
          orderValue: 315.78,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142544a5182abe880a6e430",
            "6142544b5182ab38fca6e439",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142544a5182ab5da6a6e42b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121074,
          orderNo: "FGHPO000087798164-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087798164",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363158,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484678",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-15T20:15:06.784Z",
          updatedAt: "2021-09-15T20:15:07.247Z",
          labelFile: "5121074/labelFile.txt",
          zplFile: "5121074/zplFile.txt",
          pdfFile: "5121074/pdfFile.txt",
          addressString: "4874pennyroyalrdfranklinohio45005unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0U7J-WB",
        productName: "0.06 CT Diamond TW Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0U7J-WB.jpg",
        reference: "FGHPO000087798164-0",
        sku: "FC0U7J-WB",
        binLocation: "10-C-18",
        boxCode: "",
        stockPickingId: "3115706",
        uniqueIdentifier: "5121074-3115706-4397488",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121074,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087798164",
          sale_order_date: "2021-09-15",
          sale_order_total: 82,
          shipping_address_street: "4874 PENNYROYAL RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363158,
          shipping_address_name: "RICHARD THOMAS",
          shipping_address_zip: "45005",
          shipping_address_city: "FRANKLIN",
          shipping_address_state: "Ohio",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087798164-0",
          stock_picking_id: 3115706,
          total_price: 315.78,
          shipping_batch_id: -1,
          date: "2021-09-15 19:07:15",
          picking_write_date: "2021-09-15 20:14:37",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186398,
          stock_move_set_product_id: 3106198,
          stock_move_quantity: 1,
          stock_move_bin: "10-C-18",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397488,
          sale_order_line_description: "SS 1/3 CT TGW Created White Sa 7",
          sale_order_line_customer_cost: "157.89",
          sale_order_line_vendor_sku: "FGH004503",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0U7J-WB",
          product_product_name:
            "0.06 CT Diamond TW Fashion Ring Silver GH I2;I3",
          product_id: 226587,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0U7J-WB.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484678",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T20:15:06.937Z",
        updatedAt: "2021-09-15T20:15:06.938Z",
        __v: 0,
      },
      {
        stockBin: { row: "10", section: "E", bin: "19" },
        price: 171.245,
        notes: [],
        isSet: true,
        _id: "6142c4cb5182abb7b6a6eae6",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363690",
            street: "530 Raven Ave",
            name: "Jackie Padron",
            zip: "33166",
            city: "Miami Springs",
            country: "United States",
            state: "Florida",
          },
          orderValue: 342.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142c4cb5182abb7b6a6eae6",
            "6142c4cc5182ab0bd5a6eaf0",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142c4cb5182abe8e1a6eae1",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121381,
          orderNo: "OS355683301-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355683301",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363690,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256511",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/jackie padron/530 raven ave//miami springs/florida/33166/united states",
          createdAt: "2021-09-16T04:15:07.823Z",
          updatedAt: "2021-09-16T04:15:08.406Z",
          labelFile: "5121381/labelFile.txt",
          zplFile: "5121381/zplFile.txt",
          pdfFile: "5121381/pdfFile.txt",
          addressString: "530ravenavemiamispringsflorida33166unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RYKTW100208WS-WB",
        productName:
          "2/5 CT TGW Created White Sapphire Fashion Ring 10k White Gold",
        productImage:
          "https://partner.delmarintl.ca/catalog/RYKTW100208WS-WB.jpg",
        reference: "OS355683301-0",
        sku: "RYKTW100208WS-WB",
        binLocation: "10-E-19",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116000",
        uniqueIdentifier: "5121381-3116000-4397850",
        stockMoveSize: "5.5",
        orderLineSize: "5.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121381,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355683301",
          sale_order_date: "2021-09-15",
          sale_order_total: 271,
          shipping_address_street: "530 Raven Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363690,
          shipping_address_name: "Jackie Padron",
          shipping_address_zip: "33166",
          shipping_address_city: "Miami Springs",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355683301-0",
          stock_picking_id: 3116000,
          total_price: 342.49,
          shipping_batch_id: -1,
          date: "2021-09-16 02:46:24",
          picking_write_date: "2021-09-16 03:10:57",
          address_grp_combined:
            "os/jackie padron/530 raven ave//miami springs/florida/33166/united states",
          stock_move_is_set: true,
          stock_move_id: 78186870,
          stock_move_set_product_id: 226609,
          stock_move_quantity: 1,
          stock_move_bin: "10-E-19",
          stock_move_uom: "PCE",
          stock_move_size: "5.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397850,
          sale_order_line_description: "5.5",
          sale_order_line_customer_cost: "171.245",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 4,
          product_product_default_code: "RYKTW100208WS-WB",
          product_product_name:
            "2/5 CT TGW Created White Sapphire Fashion Ring 10k White Gold",
          product_id: 226610,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RYKTW100208WS-WB.jpg",
          sale_order_line_size: "5.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256511",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T04:15:07.962Z",
        updatedAt: "2021-09-16T04:15:07.962Z",
        __v: 0,
      },
      {
        stockBin: { row: "11", section: "F", bin: "15" },
        price: 199.98,
        notes: [],
        isSet: false,
        _id: "61426cf95182ab7b93a6e586",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363248",
            street: "200 ROBINSON DR",
            name: "SHARON ROBBINS",
            zip: "27534",
            city: "GOLDSBORO",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 383.29999999999995,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cf85182ab4b4da6e57d",
            "61426cf95182ab7b93a6e586",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61426cf85182ab28d4a6e578",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121141,
          orderNo: "JCP225994129_272-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225994129_272",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363248,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552028",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/sharon robbins/200 robinson dr//goldsboro/north carolina/27534/united states",
          createdAt: "2021-09-15T22:00:24.786Z",
          updatedAt: "2021-09-15T22:00:25.235Z",
          labelFile: "5121141/labelFile.txt",
          zplFile: "5121141/zplFile.txt",
          pdfFile: "5121141/pdfFile.txt",
          addressString: "200robinsondrgoldsboronorthcarolina27534unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC1FUL-PFWG",
        productName:
          "2 1/10 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1FUL-PFWG.jpg",
        reference: "JCP225994129_272-0",
        sku: "FC1FUL-PFWG",
        binLocation: "11-F-15",
        boxCode: "JC10",
        stockPickingId: "3115774",
        uniqueIdentifier: "5121141-3115774-4397568",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121141,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225994129_272",
          sale_order_date: "2021-09-15",
          sale_order_total: 66.5,
          shipping_address_street: "200 ROBINSON DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363248,
          shipping_address_name: "SHARON ROBBINS",
          shipping_address_zip: "27534",
          shipping_address_city: "GOLDSBORO",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225994129_272-0",
          stock_picking_id: 3115774,
          total_price: 383.3,
          shipping_batch_id: -1,
          date: "2021-09-15 20:24:53",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined:
            "jcp/sharon robbins/200 robinson dr//goldsboro/north carolina/27534/united states",
          stock_move_is_set: false,
          stock_move_id: 78186550,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "11-F-15",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397568,
          sale_order_line_description: "SS SAPH RNG",
          sale_order_line_customer_cost: "199.98",
          sale_order_line_vendor_sku: "JCP004299",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC1FUL-PFWG",
          product_product_name:
            "2 1/10 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 222008,
          ship2store: false,
          is_no_resize: null,
          box_code: "JC10",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1FUL-PFWG.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552028",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T22:00:25.222Z",
        updatedAt: "2021-09-15T22:00:25.223Z",
        __v: 0,
      },
      {
        stockBin: { row: "12", section: "G", bin: "64" },
        price: 124.99,
        notes: [],
        isSet: false,
        _id: "6142f5ff5182abbdcfa6ecf3",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363867",
            street: "4913 Sage Ln",
            name: "Mehmood Syed",
            zip: "60047",
            city: "Long Grove",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 204.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142f5ff5182abbdcfa6ecf3",
            "6142f6005182ab1815a6ecfd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f5ff5182abad30a6ecee",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121477,
          orderNo: "OS355694491-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355694491",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363867,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256504",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/mehmood syed/4913 sage ln//long grove/illinois/60047/united states",
          createdAt: "2021-09-16T07:45:03.541Z",
          updatedAt: "2021-09-16T07:45:04.371Z",
          labelFile: "5121477/labelFile.txt",
          zplFile: "5121477/zplFile.txt",
          pdfFile: "5121477/pdfFile.txt",
          addressString: "4913sagelnlonggroveillinois60047unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1KWX-1Y51",
        productName:
          "0.02 CT Diamond TW And 4 1/3 CT TGW Tanzanite Rose de France Peridot Amethyst Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1KWX-1Y51.jpg",
        reference: "OS355694491-0",
        sku: "FC1KWX-1Y51",
        binLocation: "12-G-64",
        boxCode: "PC-MIADORA:CRD-MIADORA",
        stockPickingId: "3116091",
        uniqueIdentifier: "5121477-3116091-4397960",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121477,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355694491",
          sale_order_date: "2021-09-15",
          sale_order_total: 154,
          shipping_address_street: "4913 Sage Ln",
          shipping_address_street_2: null,
          shipping_address_id: 8363867,
          shipping_address_name: "Mehmood Syed",
          shipping_address_zip: "60047",
          shipping_address_city: "Long Grove",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355694491-0",
          stock_picking_id: 3116091,
          total_price: 204.98,
          shipping_batch_id: -1,
          date: "2021-09-16 06:05:29",
          picking_write_date: "2021-09-16 06:41:22",
          address_grp_combined:
            "os/mehmood syed/4913 sage ln//long grove/illinois/60047/united states",
          stock_move_is_set: false,
          stock_move_id: 78187002,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "12-G-64",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397960,
          sale_order_line_description: "6",
          sale_order_line_customer_cost: "124.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 5,
          product_product_default_code: "FC1KWX-1Y51",
          product_product_name:
            "0.02 CT Diamond TW And 4 1/3 CT TGW Tanzanite Rose de France Peridot Amethyst Fashion Ring Silver GH I2;I3",
          product_id: 235044,
          ship2store: false,
          is_no_resize: null,
          box_code: "PC-MIADORA:CRD-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1KWX-1Y51.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256504",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T07:45:03.799Z",
        updatedAt: "2021-09-16T07:45:03.800Z",
        __v: 0,
      },
      {
        stockBin: { row: "12", section: "P", bin: "34" },
        price: 87.5,
        notes: [],
        isSet: false,
        _id: "6142eb795182ab0b9ca6ecd0",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363854",
            street: "200 Fernway Dr",
            name: "Sheila Ziegler",
            zip: "35501-3324",
            city: "Jasper",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 175,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142eb795182ab0b9ca6ecd0",
            "6142eb795182ab101fa6ecd9",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142eb795182abc8fea6eccb",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121468,
          orderNo: "BLK100984139-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100984139",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363854,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694402",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/sheila ziegler/200 fernway dr//jasper/alabama/35501-3324/united states",
          createdAt: "2021-09-16T07:00:09.465Z",
          updatedAt: "2021-09-16T07:00:09.971Z",
          labelFile: "5121468/labelFile.txt",
          zplFile: "5121468/zplFile.txt",
          pdfFile: "5121468/pdfFile.txt",
          addressString: "200fernwaydrjasperalabama355013324unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS5258ATN",
        productName: "4 3/5 CT TGW Amethyst Tanzanite Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS5258ATN.jpg",
        reference: "BLK100984139-0",
        sku: "RGS5258ATN",
        binLocation: "12-P-34",
        boxCode: "",
        stockPickingId: "3116084",
        uniqueIdentifier: "5121468-3116084-4397953",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121468,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100984139",
          sale_order_date: "2021-09-15",
          sale_order_total: 84,
          shipping_address_street: "200 Fernway Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363854,
          shipping_address_name: "Sheila Ziegler",
          shipping_address_zip: "35501-3324",
          shipping_address_city: "Jasper",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100984139-0",
          stock_picking_id: 3116084,
          total_price: 175,
          shipping_batch_id: -1,
          date: "2021-09-16 05:34:03",
          picking_write_date: "2021-09-16 06:44:59",
          address_grp_combined:
            "blk/sheila ziegler/200 fernway dr//jasper/alabama/35501-3324/united states",
          stock_move_is_set: false,
          stock_move_id: 78186988,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "12-P-34",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397953,
          sale_order_line_description: "X-SS RG AMY OV W/TZ",
          sale_order_line_customer_cost: "87.5",
          sale_order_line_vendor_sku: "075000453057",
          sale_order_line_size_id: 11,
          product_product_default_code: "RGS5258ATN",
          product_product_name:
            "4 3/5 CT TGW Amethyst Tanzanite Fashion Ring Silver",
          product_id: 60814,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS5258ATN.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694402",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T07:00:09.657Z",
        updatedAt: "2021-09-16T07:00:09.657Z",
        __v: 0,
      },
      {
        stockBin: { row: "13", section: "D", bin: "51" },
        price: 24.97,
        notes: [],
        isSet: false,
        _id: "6142d2d85182abfcada6eb97",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363744",
            street: "8 Village Way Apt 4",
            name: "Amanda Cruz",
            zip: "01760",
            city: "Natick",
            country: "United States",
            state: "Massachusetts",
          },
          orderValue: 50.47,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142d2d85182ab271ba6eb8e",
            "6142d2d85182abfcada6eb97",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d85182ab1a3ca6eb89",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121412,
          orderNo: "HAU66354452-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66354452",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363744,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552331",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/amanda cruz/8 village way apt 4//natick/massachusetts/01760/united states",
          createdAt: "2021-09-16T05:15:04.437Z",
          updatedAt: "2021-09-16T05:15:04.916Z",
          labelFile: "5121412/labelFile.txt",
          zplFile: "5121412/zplFile.txt",
          pdfFile: "5121412/pdfFile.txt",
          addressString: "8villagewayapt4natickmassachusetts01760unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC2A1M-RF4F",
        productName:
          "1/10 CT TGW White Cubic Zirconia And 7.5 - 8 MM White Freshwater Cultured Pearl Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A1M-RF4F.jpg",
        reference: "HAU66354452-0",
        sku: "FC2A1M-RF4F",
        binLocation: "13-D-51",
        boxCode: "",
        stockPickingId: "3116028",
        uniqueIdentifier: "5121412-3116028-4397886",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121412,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66354452",
          sale_order_date: "2021-09-15",
          sale_order_total: 39,
          shipping_address_street: "8 Village Way Apt 4",
          shipping_address_street_2: "",
          shipping_address_id: 8363744,
          shipping_address_name: "Amanda Cruz",
          shipping_address_zip: "01760",
          shipping_address_city: "Natick",
          shipping_address_state: "Massachusetts",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66354452-0",
          stock_picking_id: 3116028,
          total_price: 50.47,
          shipping_batch_id: -1,
          date: "2021-09-16 03:46:19",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "hau/amanda cruz/8 village way apt 4//natick/massachusetts/01760/united states",
          stock_move_is_set: false,
          stock_move_id: 78186908,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "13-D-51",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397886,
          sale_order_line_description:
            "1/10 CT TGW White Cubic Zirconia And 7.5 - 8 MM ",
          sale_order_line_customer_cost: "24.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC2A1M-RF4F",
          product_product_name:
            "1/10 CT TGW White Cubic Zirconia And 7.5 - 8 MM White Freshwater Cultured Pearl Fashion Ring Silver",
          product_id: 3110443,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A1M-RF4F.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552331",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:15:04.904Z",
        updatedAt: "2021-09-16T05:15:04.905Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "D", bin: "47" },
        price: 49.97,
        notes: [],
        isSet: false,
        _id: "614238295182ab1df5a6e2de",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363025",
            street: "68 Clinton Avenue",
            name: "Stella Anyan",
            zip: "10301",
            city: "Staten Island",
            country: "United States",
            state: "New York",
          },
          orderValue: 92.94,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614238295182ab1df5a6e2de",
            "6142382a5182ab6e99a6e2e7",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614238295182ab1cbda6e2d9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120990,
          orderNo: "HAU66350362-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66350362",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363025,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318551869",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/stella anyan/68 clinton avenue//staten island/new york/10301/united states",
          createdAt: "2021-09-15T18:15:05.593Z",
          updatedAt: "2021-09-15T18:15:06.049Z",
          labelFile: "5120990/labelFile.txt",
          zplFile: "5120990/zplFile.txt",
          pdfFile: "5120990/pdfFile.txt",
          addressString: "68clintonavenuestatenislandnewyork10301unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0KWX-7VJE",
        productName: "0.06 CT Diamond TW Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0KWX-7VJE.jpg",
        reference: "HAU66350362-0",
        sku: "FC0KWX-7VJE",
        binLocation: "14-D-47",
        boxCode: "",
        stockPickingId: "3115626",
        uniqueIdentifier: "5120990-3115626-4397350",
        stockMoveSize: "6.5",
        orderLineSize: "6.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120990,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66350362",
          sale_order_date: "2021-09-15",
          sale_order_total: 44,
          shipping_address_street: "68 Clinton Avenue",
          shipping_address_street_2: "",
          shipping_address_id: 8363025,
          shipping_address_name: "Stella Anyan",
          shipping_address_zip: "10301",
          shipping_address_city: "Staten Island",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66350362-0",
          stock_picking_id: 3115626,
          total_price: 92.94,
          shipping_batch_id: -1,
          date: "2021-09-15 16:45:58",
          picking_write_date: "2021-09-15 18:14:37",
          address_grp_combined:
            "hau/stella anyan/68 clinton avenue//staten island/new york/10301/united states",
          stock_move_is_set: false,
          stock_move_id: 78186168,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-D-47",
          stock_move_uom: "PCE",
          stock_move_size: "6.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397350,
          sale_order_line_description:
            "0.06 CT Diamond TW Fashion Ring Silver I3",
          sale_order_line_customer_cost: "49.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 6,
          product_product_default_code: "FC0KWX-7VJE",
          product_product_name: "0.06 CT Diamond TW Fashion Ring Silver I3",
          product_id: 87814,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0KWX-7VJE.jpg",
          sale_order_line_size: "6.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318551869",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T18:15:05.734Z",
        updatedAt: "2021-09-15T18:15:05.735Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "E", bin: "71" },
        price: null,
        notes: [],
        isSet: false,
        _id: "6142fd075182ab6b15a6ed74",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363909",
            street: "8530 NW 72nd Street, D#0021",
            name: "Delia Drohan",
            zip: "33166-2300",
            city: "Miami",
            country: "United States",
            state: "Florida",
          },
          orderValue: 0,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142fd075182ab6b15a6ed74",
            "6142fd075182ab1f12a6ed7d",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd075182abade6a6ed6f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121496,
          orderNo: "HAU66355612-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355612",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363909,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552457",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/delia drohan/8530 nw 72nd street/d#0021/miami/florida/33166-2300/united states",
          createdAt: "2021-09-16T08:15:03.414Z",
          updatedAt: "2021-09-16T08:15:03.952Z",
          labelFile: "5121496/labelFile.txt",
          zplFile: "5121496/zplFile.txt",
          pdfFile: "5121496/pdfFile.txt",
          addressString:
            "8530nw72ndstreetd0021miamiflorida331662300unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC2A6G-M4HX",
        productName:
          "0.02 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A6G-M4HX.jpg",
        reference: "HAU66355612-0",
        sku: "FC2A6G-M4HX",
        binLocation: "14-E-71",
        boxCode: "",
        stockPickingId: "3116111",
        uniqueIdentifier: "5121496-3116111-4397982",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121496,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355612",
          sale_order_date: "2021-09-15",
          sale_order_total: 39,
          shipping_address_street: "8530 NW 72nd Street",
          shipping_address_street_2: "D#0021",
          shipping_address_id: 8363909,
          shipping_address_name: "Delia Drohan",
          shipping_address_zip: "33166-2300",
          shipping_address_city: "Miami",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355612-0",
          stock_picking_id: 3116111,
          total_price: 0,
          shipping_batch_id: -1,
          date: "2021-09-16 06:45:54",
          picking_write_date: "2021-09-16 07:11:00",
          address_grp_combined:
            "hau/delia drohan/8530 nw 72nd street/d#0021/miami/florida/33166-2300/united states",
          stock_move_is_set: false,
          stock_move_id: 78187023,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-E-71",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397982,
          sale_order_line_description:
            "0.02 CT Diamond TW 8 - 8.5 MM White Freshwater C",
          sale_order_line_customer_cost: null,
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "FC2A6G-M4HX",
          product_product_name:
            "0.02 CT Diamond TW 8 - 8.5 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver I3",
          product_id: 3110506,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A6G-M4HX.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552457",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:03.618Z",
        updatedAt: "2021-09-16T08:15:03.619Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "G", bin: "17" },
        price: 35.745,
        notes: [],
        isSet: true,
        _id: "614270785182ab7274a6e625",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363272",
            street: "1412 W 13th Ave",
            name: "Claretha Grimes",
            zip: "71603",
            city: "Pine Bluff",
            country: "United States",
            state: "Arkansas",
          },
          orderValue: 71.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614270785182ab13baa6e61b",
            "614270785182ab7274a6e625",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "614270775182ab6ef7a6e616",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121156,
          orderNo: "OS355650296-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355650296",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363272,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256337",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/claretha grimes/1412 w 13th ave//pine bluff/arkansas/71603/united states",
          createdAt: "2021-09-15T22:15:19.996Z",
          updatedAt: "2021-09-15T22:15:20.619Z",
          labelFile: "5121156/labelFile.txt",
          zplFile: "5121156/zplFile.txt",
          pdfFile: "5121156/pdfFile.txt",
          addressString: "1412w13thavepinebluffarkansas71603unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2A1U-KH8X",
        productName:
          "0.02 CT Diamond TW 6.5 - 7 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A1U-KH8X.jpg",
        reference: "OS355650296-0",
        sku: "FC2A1U-KH8X",
        binLocation: "14-G-17",
        boxCode: "",
        stockPickingId: "3115788",
        uniqueIdentifier: "5121156-3115788-4397586",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121156,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355650296",
          sale_order_date: "2021-09-15",
          sale_order_total: 56,
          shipping_address_street: "1412 W 13th Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363272,
          shipping_address_name: "Claretha Grimes",
          shipping_address_zip: "71603",
          shipping_address_city: "Pine Bluff",
          shipping_address_state: "Arkansas",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355650296-0",
          stock_picking_id: 3115788,
          total_price: 71.49,
          shipping_batch_id: -1,
          date: "2021-09-15 20:43:14",
          picking_write_date: "2021-09-15 22:14:37",
          address_grp_combined:
            "os/claretha grimes/1412 w 13th ave//pine bluff/arkansas/71603/united states",
          stock_move_is_set: true,
          stock_move_id: 78186570,
          stock_move_set_product_id: 3124472,
          stock_move_quantity: 1,
          stock_move_bin: "14-G-17",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397586,
          sale_order_line_description:
            "Miadora Cultured FW Pearl & Diamond Accent Drop Earrings & Necklace Set in Sterling Silver (6.5-7mm)",
          sale_order_line_customer_cost: "35.745",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC2A1U-KH8X",
          product_product_name:
            "0.02 CT Diamond TW 6.5 - 7 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
          product_id: 3110492,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A1U-KH8X.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256337",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-15T22:15:20.606Z",
        updatedAt: "2021-09-15T22:15:20.607Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "K", bin: "29" },
        price: 133.32,
        notes: [],
        isSet: false,
        _id: "61429a975182ab64a7a6e858",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363492",
            street: "230 ROBBINS AVE",
            name: "YOLANDA GILL",
            zip: "49022",
            city: "BENTON HARBOR",
            country: "United States",
            state: "Michigan",
          },
          orderValue: 266.64,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61429a965182ab3b83a6e84f",
            "61429a975182ab64a7a6e858",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61429a965182ab1259a6e84a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121275,
          orderNo: "JCP355655698_274-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP355655698_274",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363492,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552206",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/yolanda gill/230 robbins ave//benton harbor/michigan/49022/united states",
          createdAt: "2021-09-16T01:15:02.652Z",
          updatedAt: "2021-09-16T01:15:03.251Z",
          labelFile: "5121275/labelFile.txt",
          zplFile: "5121275/zplFile.txt",
          pdfFile: "5121275/pdfFile.txt",
          addressString: "230robbinsavebentonharbormichigan49022unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0L9M-9AF4",
        productName: "1/10 CT Diamond TW Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L9M-9AF4.jpg",
        reference: "JCP355655698_274-0",
        sku: "FC0L9M-9AF4",
        binLocation: "14-K-29",
        boxCode: "",
        stockPickingId: "3115900",
        uniqueIdentifier: "5121275-3115900-4397719",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121275,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP355655698_274",
          sale_order_date: "2021-09-15",
          sale_order_total: 76,
          shipping_address_street: "230 ROBBINS AVE",
          shipping_address_street_2: null,
          shipping_address_id: 8363492,
          shipping_address_name: "YOLANDA GILL",
          shipping_address_zip: "49022",
          shipping_address_city: "BENTON HARBOR",
          shipping_address_state: "Michigan",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP355655698_274-0",
          stock_picking_id: 3115900,
          total_price: 266.64,
          shipping_batch_id: -1,
          date: "2021-09-15 23:55:25",
          picking_write_date: "2021-09-16 01:14:37",
          address_grp_combined:
            "jcp/yolanda gill/230 robbins ave//benton harbor/michigan/49022/united states",
          stock_move_is_set: false,
          stock_move_id: 78186717,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-K-29",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397719,
          sale_order_line_description: "SS DA RNG",
          sale_order_line_customer_cost: "133.32",
          sale_order_line_vendor_sku: "JCP002579",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0L9M-9AF4",
          product_product_name: "1/10 CT Diamond TW Fashion Ring Silver I3",
          product_id: 89379,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L9M-9AF4.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552206",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T01:15:03.240Z",
        updatedAt: "2021-09-16T01:15:03.241Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "L", bin: "06" },
        price: 183.39,
        notes: [],
        isSet: false,
        _id: "61425b545182ab1858a6e469",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363168",
            street: "1402 NORTH HARVARD AVENUE, SUITE E",
            name: "GISELLE BUSTOS",
            zip: "74115",
            city: "TULSA",
            country: "United States",
            state: "Oklahoma",
          },
          orderValue: 376.55,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61425b545182abbf12a6e460",
            "61425b545182ab1858a6e469",
            "61425b545182abd7e8a6e472",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61425b545182ab9400a6e45b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121078,
          orderNo: "SAMS8775303516-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775303516",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363168,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594107",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-15T20:45:08.103Z",
          updatedAt: "2021-09-15T20:45:08.880Z",
          labelFile: "5121078/labelFile.txt",
          zplFile: "5121078/zplFile.txt",
          pdfFile: "5121078/pdfFile.txt",
          addressString:
            "1402northharvardavenuesuiteetulsaoklahoma74115unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1Y3J-1JXG",
        productName:
          "6 1/2 CT TGW Blue Topaz - Sky White Sapphire LeverBack Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1Y3J-1JXG.jpg",
        reference: "SAMS8775303516-0",
        sku: "FC1Y3J-1JXG",
        binLocation: "14-L-06",
        boxCode: "SCU",
        stockPickingId: "3115711",
        uniqueIdentifier: "5121078-3115711-4397495",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121078,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775303516",
          sale_order_date: "2021-09-15",
          sale_order_total: 248,
          shipping_address_street: "1402 NORTH HARVARD AVENUE",
          shipping_address_street_2: "SUITE E",
          shipping_address_id: 8363168,
          shipping_address_name: "GISELLE BUSTOS",
          shipping_address_zip: "74115",
          shipping_address_city: "TULSA",
          shipping_address_state: "Oklahoma",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775303516-0",
          stock_picking_id: 3115711,
          total_price: 376.55,
          shipping_batch_id: -1,
          date: "2021-09-15 19:18:36",
          picking_write_date: "2021-09-15 19:41:05",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186409,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-L-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397495,
          sale_order_line_description: "6.5 CTTW GEM EAR",
          sale_order_line_customer_cost: "183.39",
          sale_order_line_vendor_sku: "SAMS01756",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1Y3J-1JXG",
          product_product_name:
            "6 1/2 CT TGW Blue Topaz - Sky White Sapphire LeverBack Earrings Silver",
          product_id: 466639,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1Y3J-1JXG.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594107",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T20:45:08.569Z",
        updatedAt: "2021-09-15T20:45:08.570Z",
        __v: 0,
      },
      {
        stockBin: { row: "14", section: "M", bin: "48" },
        price: 79.99,
        notes: [],
        isSet: false,
        _id: "6142f6005182ab1815a6ecfd",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363867",
            street: "4913 Sage Ln",
            name: "Mehmood Syed",
            zip: "60047",
            city: "Long Grove",
            country: "United States",
            state: "Illinois",
          },
          orderValue: 204.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142f5ff5182abbdcfa6ecf3",
            "6142f6005182ab1815a6ecfd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142f5ff5182abad30a6ecee",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121477,
          orderNo: "OS355694491-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355694491",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363867,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256504",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/mehmood syed/4913 sage ln//long grove/illinois/60047/united states",
          createdAt: "2021-09-16T07:45:03.541Z",
          updatedAt: "2021-09-16T07:45:04.371Z",
          labelFile: "5121477/labelFile.txt",
          zplFile: "5121477/zplFile.txt",
          pdfFile: "5121477/pdfFile.txt",
          addressString: "4913sagelnlonggroveillinois60047unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1G61-L5H9",
        productName:
          "1 5/8 CT TGW Blue Topaz - Sky Blue Topaz - London Created White Sapphire Blue Topaz - Swiss And White Freshwater Cultured Pearl Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1G61-L5H9.jpg",
        reference: "OS355694491-0",
        sku: "FC1G61-L5H9",
        binLocation: "14-M-48",
        boxCode: "CRD-MIADORA:PC-MIADORA",
        stockPickingId: "3116091",
        uniqueIdentifier: "5121477-3116091-4397959",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121477,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355694491",
          sale_order_date: "2021-09-15",
          sale_order_total: 154,
          shipping_address_street: "4913 Sage Ln",
          shipping_address_street_2: null,
          shipping_address_id: 8363867,
          shipping_address_name: "Mehmood Syed",
          shipping_address_zip: "60047",
          shipping_address_city: "Long Grove",
          shipping_address_state: "Illinois",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355694491-0",
          stock_picking_id: 3116091,
          total_price: 204.98,
          shipping_batch_id: -1,
          date: "2021-09-16 06:05:29",
          picking_write_date: "2021-09-16 06:41:22",
          address_grp_combined:
            "os/mehmood syed/4913 sage ln//long grove/illinois/60047/united states",
          stock_move_is_set: false,
          stock_move_id: 78187001,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "14-M-48",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397959,
          sale_order_line_description: "7",
          sale_order_line_customer_cost: "79.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: 7,
          product_product_default_code: "FC1G61-L5H9",
          product_product_name:
            "1 5/8 CT TGW Blue Topaz - Sky Blue Topaz - London Created White Sapphire Blue Topaz - Swiss And White Freshwater Cultured Pearl Fashion Ring Silver",
          product_id: 226027,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1G61-L5H9.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256504",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T07:45:04.358Z",
        updatedAt: "2021-09-16T07:45:04.359Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "C", bin: "54" },
        price: 183.32,
        notes: [],
        isSet: false,
        _id: "61426cf85182ab4b4da6e57d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363248",
            street: "200 ROBINSON DR",
            name: "SHARON ROBBINS",
            zip: "27534",
            city: "GOLDSBORO",
            country: "United States",
            state: "North Carolina",
          },
          orderValue: 383.29999999999995,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cf85182ab4b4da6e57d",
            "61426cf95182ab7b93a6e586",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61426cf85182ab28d4a6e578",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121141,
          orderNo: "JCP225994129_272-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225994129_272",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363248,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552028",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/sharon robbins/200 robinson dr//goldsboro/north carolina/27534/united states",
          createdAt: "2021-09-15T22:00:24.786Z",
          updatedAt: "2021-09-15T22:00:25.235Z",
          labelFile: "5121141/labelFile.txt",
          zplFile: "5121141/zplFile.txt",
          pdfFile: "5121141/pdfFile.txt",
          addressString: "200robinsondrgoldsboronorthcarolina27534unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0W0X-PJRR",
        productName:
          "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
        reference: "JCP225994129_272-0",
        sku: "FC0W0X-PJRR",
        binLocation: "15-C-54",
        boxCode: "",
        stockPickingId: "3115774",
        uniqueIdentifier: "5121141-3115774-4397569",
        stockMoveSize: "8.0",
        orderLineSize: "8.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121141,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225994129_272",
          sale_order_date: "2021-09-15",
          sale_order_total: 66.5,
          shipping_address_street: "200 ROBINSON DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363248,
          shipping_address_name: "SHARON ROBBINS",
          shipping_address_zip: "27534",
          shipping_address_city: "GOLDSBORO",
          shipping_address_state: "North Carolina",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225994129_272-0",
          stock_picking_id: 3115774,
          total_price: 383.3,
          shipping_batch_id: -1,
          date: "2021-09-15 20:24:53",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined:
            "jcp/sharon robbins/200 robinson dr//goldsboro/north carolina/27534/united states",
          stock_move_is_set: false,
          stock_move_id: 78186551,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-C-54",
          stock_move_uom: "PCE",
          stock_move_size: "8.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397569,
          sale_order_line_description: "SS  SAPH RNG",
          sale_order_line_customer_cost: "183.32",
          sale_order_line_vendor_sku: "JCP004289",
          sale_order_line_size_id: 9,
          product_product_default_code: "FC0W0X-PJRR",
          product_product_name:
            "4/5 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver",
          product_id: 99326,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-PJRR.jpg",
          sale_order_line_size: "8.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552028",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T22:00:24.924Z",
        updatedAt: "2021-09-15T22:00:24.925Z",
        __v: 0,
      },
      {
        stockBin: { row: "15", section: "D", bin: "55" },
        price: 59.99,
        notes: [],
        isSet: false,
        _id: "614289205182ab55f9a6e7a8",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363418",
            street: "4887 E Comanche Dr",
            name: "Jesse Leedham",
            zip: "86326-5714",
            city: "Cottonwood",
            country: "United States",
            state: "Arizona",
          },
          orderValue: 224.98000000000002,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142891f5182ab6d65a6e79f",
            "614289205182ab55f9a6e7a8",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142891f5182ab9213a6e79a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121233,
          orderNo: "GVX2783820-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2783820-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363418,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256320",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:31.652Z",
          updatedAt: "2021-09-16T00:00:32.195Z",
          labelFile: "5121233/labelFile.txt",
          zplFile: "5121233/zplFile.txt",
          pdfFile: "5121233/pdfFile.txt",
          addressString:
            "4887ecomanchedrcottonwoodarizona863265714unitedstates",
          normalizedCustomerName: "govx",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0W0X-FA6A",
        productName: "4/5 CT TGW Created White Sapphire Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0W0X-FA6A.jpg",
        reference: "GVX2783820-DEL01-0",
        sku: "FC0W0X-FA6A",
        binLocation: "15-D-55",
        boxCode: "",
        stockPickingId: "3115862",
        uniqueIdentifier: "5121233-3115862-4397674",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121233,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2783820-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 146,
          shipping_address_street: "4887 E Comanche Dr",
          shipping_address_street_2: "",
          shipping_address_id: 8363418,
          shipping_address_name: "Jesse Leedham",
          shipping_address_zip: "86326-5714",
          shipping_address_city: "Cottonwood",
          shipping_address_state: "Arizona",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2783820-DEL01-0",
          stock_picking_id: 3115862,
          total_price: 224.98,
          shipping_batch_id: -1,
          date: "2021-09-15 22:16:35",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186667,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "15-D-55",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397674,
          sale_order_line_description:
            "4/5 CT Created White Sapphire Silver Ring - 6",
          sale_order_line_customer_cost: "59.99",
          sale_order_line_vendor_sku: "GVX002034-6",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0W0X-FA6A",
          product_product_name:
            "4/5 CT TGW Created White Sapphire Fashion Ring Silver",
          product_id: 99324,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0W0X-FA6A.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256320",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T00:00:32.185Z",
        updatedAt: "2021-09-16T00:00:32.186Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "A", bin: "04" },
        price: 121,
        notes: [],
        isSet: false,
        _id: "61426cfb5182ab5090a6e5ea",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363256",
            street: "326 Molino Ave",
            name: "Kelly Bui",
            zip: "90814",
            city: "Long Beach",
            country: "United States",
            state: "California",
          },
          orderValue: 302.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cfb5182ab9ee6a6e5e1",
            "61426cfb5182ab5090a6e5ea",
            "61426cfc5182ab6977a6e5f3",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61426cfb5182ab433ba6e5dc",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121146,
          orderNo: "JMSM2HC1465-0",
          customerId: 177,
          customerName: "Jomashop",
          orderReference: "JMSM2HC1465",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363256,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552046",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          createdAt: "2021-09-15T22:00:27.435Z",
          updatedAt: "2021-09-15T22:00:28.225Z",
          labelFile: "5121146/labelFile.txt",
          zplFile: "5121146/zplFile.txt",
          pdfFile: "5121146/pdfFile.txt",
          addressString: "326molinoavelongbeachcalifornia90814unitedstates",
          normalizedCustomerName: "jomashop",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "NYS0298CWS",
        productName:
          "33 CT TGW Created White Sapphire Necklace Silver White Tongue and Groove Clasp Length (inches): 17",
        productImage: "https://partner.delmarintl.ca/catalog/NYS0298CWS.jpg",
        reference: "JMSM2HC1465-0",
        sku: "NYS0298CWS",
        binLocation: "17-A-04",
        boxCode: "",
        stockPickingId: "3115782",
        uniqueIdentifier: "5121146-3115782-4397578",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121146,
          sale_order_customer_id: 177,
          sale_order_customer_name: "Jomashop",
          sale_order_order_reference: "JMSM2HC1465",
          sale_order_date: "2021-09-15",
          sale_order_total: 170.5,
          shipping_address_street: "326 Molino Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363256,
          shipping_address_name: "Kelly Bui",
          shipping_address_zip: "90814",
          shipping_address_city: "Long Beach",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "JMSM2HC1465-0",
          stock_picking_id: 3115782,
          total_price: 302.98,
          shipping_batch_id: -1,
          date: "2021-09-15 20:31:26",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          stock_move_is_set: false,
          stock_move_id: 78186560,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-A-04",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397578,
          sale_order_line_description:
            "Amour Sterling Silver 33 CT TGW Created White Sapphire Tennis Necklace",
          sale_order_line_customer_cost: "121.0",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "NYS0298CWS",
          product_product_name:
            "33 CT TGW Created White Sapphire Necklace Silver White Tongue and Groove Clasp Length (inches): 17",
          product_id: 3105250,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/NYS0298CWS.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552046",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T22:00:27.918Z",
        updatedAt: "2021-09-15T22:00:27.919Z",
        __v: 0,
      },
      {
        stockBin: { row: "17", section: "I", bin: "15" },
        price: 164.99,
        notes: [],
        isSet: false,
        _id: "6142891f5182ab6d65a6e79f",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363418",
            street: "4887 E Comanche Dr",
            name: "Jesse Leedham",
            zip: "86326-5714",
            city: "Cottonwood",
            country: "United States",
            state: "Arizona",
          },
          orderValue: 224.98000000000002,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142891f5182ab6d65a6e79f",
            "614289205182ab55f9a6e7a8",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142891f5182ab9213a6e79a",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121233,
          orderNo: "GVX2783820-DEL01-0",
          customerId: 860,
          customerName: "GovX",
          orderReference: "GVX2783820-DEL01",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363418,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256320",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined: null,
          createdAt: "2021-09-16T00:00:31.652Z",
          updatedAt: "2021-09-16T00:00:32.195Z",
          labelFile: "5121233/labelFile.txt",
          zplFile: "5121233/zplFile.txt",
          pdfFile: "5121233/pdfFile.txt",
          addressString:
            "4887ecomanchedrcottonwoodarizona863265714unitedstates",
          normalizedCustomerName: "govx",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2A41-8973",
        productName: "1 CT DEW Created Moissanite-White Fashion Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A41-8973.jpg",
        reference: "GVX2783820-DEL01-0",
        sku: "FC2A41-8973",
        binLocation: "17-I-15",
        boxCode: "",
        stockPickingId: "3115862",
        uniqueIdentifier: "5121233-3115862-4397675",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121233,
          sale_order_customer_id: 860,
          sale_order_customer_name: "GovX",
          sale_order_order_reference: "GVX2783820-DEL01",
          sale_order_date: "2021-09-15",
          sale_order_total: 146,
          shipping_address_street: "4887 E Comanche Dr",
          shipping_address_street_2: "",
          shipping_address_id: 8363418,
          shipping_address_name: "Jesse Leedham",
          shipping_address_zip: "86326-5714",
          shipping_address_city: "Cottonwood",
          shipping_address_state: "Arizona",
          shipping_address_country: "United States",
          stock_picking_reference: "GVX2783820-DEL01-0",
          stock_picking_id: 3115862,
          total_price: 224.98,
          shipping_batch_id: -1,
          date: "2021-09-15 22:16:35",
          picking_write_date: "2021-09-15 23:44:56",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186668,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "17-I-15",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397675,
          sale_order_line_description:
            "1 CT TGW Created Moissanite 3-stone Ring In Sterling Silver - 6",
          sale_order_line_customer_cost: "164.99",
          sale_order_line_vendor_sku: "GVX005976-6",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC2A41-8973",
          product_product_name:
            "1 CT DEW Created Moissanite-White Fashion Ring Silver",
          product_id: 3109853,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A41-8973.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256320",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T00:00:31.847Z",
        updatedAt: "2021-09-16T00:00:31.848Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "B", bin: "10" },
        price: 133.215,
        notes: [],
        isSet: true,
        _id: "61426cf85182ab009ca6e563",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363245",
            street: "10 ELM RD",
            name: "ROBBIN HINDS",
            zip: "34472",
            city: "OCALA",
            country: "United States",
            state: "Florida",
          },
          orderValue: 266.43,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cf75182abf769a6e55a",
            "61426cf85182ab009ca6e563",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61426cf75182ab0f77a6e555",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121139,
          orderNo: "SAMS8775303732-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775303732",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363245,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594130",
          carrier: "Fedex",
          shippingMethod: "  2nd Day",
          addressGroupCombined: null,
          createdAt: "2021-09-15T22:00:23.605Z",
          updatedAt: "2021-09-15T22:00:24.156Z",
          labelFile: "5121139/labelFile.txt",
          zplFile: "5121139/zplFile.txt",
          pdfFile: "5121139/pdfFile.txt",
          addressString: "10elmrdocalaflorida34472unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC156Z-MLTW",
        productName:
          "2 3/4 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC156Z-MLTW.jpg",
        reference: "SAMS8775303732-0",
        sku: "FC156Z-MLTW",
        binLocation: "18-B-10",
        boxCode: "SCU",
        stockPickingId: "3115771",
        uniqueIdentifier: "5121139-3115771-4397564",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121139,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775303732",
          sale_order_date: "2021-09-15",
          sale_order_total: 185,
          shipping_address_street: "10 ELM RD",
          shipping_address_street_2: "",
          shipping_address_id: 8363245,
          shipping_address_name: "ROBBIN HINDS",
          shipping_address_zip: "34472",
          shipping_address_city: "OCALA",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775303732-0",
          stock_picking_id: 3115771,
          total_price: 266.43,
          shipping_batch_id: -1,
          date: "2021-09-15 20:19:26",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186543,
          stock_move_set_product_id: 351612,
          stock_move_quantity: 1,
          stock_move_bin: "18-B-10",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397564,
          sale_order_line_description: "5.75 GEM PENDEAR SET",
          sale_order_line_customer_cost: "133.215",
          sale_order_line_vendor_sku: "SAMS01946",
          sale_order_line_size_id: null,
          product_product_default_code: "FC156Z-MLTW",
          product_product_name:
            "2 3/4 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Pendant With Chain Silver",
          product_id: 184494,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC156Z-MLTW.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: "  2nd Day",
          order_tracking_number: "501515594130",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T22:00:24.137Z",
        updatedAt: "2021-09-15T22:00:24.138Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "G", bin: "09" },
        price: 157.89,
        notes: [],
        isSet: true,
        _id: "6142544b5182ab38fca6e439",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363158",
            street: "4874 PENNYROYAL RD",
            name: "RICHARD THOMAS",
            zip: "45005",
            city: "FRANKLIN",
            country: "United States",
            state: "Ohio",
          },
          orderValue: 315.78,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142544a5182abe880a6e430",
            "6142544b5182ab38fca6e439",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142544a5182ab5da6a6e42b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121074,
          orderNo: "FGHPO000087798164-0",
          customerId: 152,
          customerName: "Bluestem Fingerhut",
          orderReference: "FGHPO000087798164",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363158,
          printPslip: 1,
          printExtra: false,
          paperFormat: 8,
          trackingNumber: "1Z306EF2YW11484678",
          carrier: "UPS",
          shippingMethod: " SurePost",
          addressGroupCombined: null,
          createdAt: "2021-09-15T20:15:06.784Z",
          updatedAt: "2021-09-15T20:15:07.247Z",
          labelFile: "5121074/labelFile.txt",
          zplFile: "5121074/zplFile.txt",
          pdfFile: "5121074/pdfFile.txt",
          addressString: "4874pennyroyalrdfranklinohio45005unitedstates",
          normalizedCustomerName: "bluestem fingerhut",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0U7J-BV5G",
        productName:
          "0.05 CT Diamond TW And 1/3 CT TGW Created White Sapphire Fashion Ring Silver GH I2;I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0U7J-BV5G.jpg",
        reference: "FGHPO000087798164-0",
        sku: "FC0U7J-BV5G",
        binLocation: "18-G-09",
        boxCode: "CRD-SB",
        stockPickingId: "3115706",
        uniqueIdentifier: "5121074-3115706-4397487",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121074,
          sale_order_customer_id: 152,
          sale_order_customer_name: "Bluestem Fingerhut",
          sale_order_order_reference: "FGHPO000087798164",
          sale_order_date: "2021-09-15",
          sale_order_total: 82,
          shipping_address_street: "4874 PENNYROYAL RD",
          shipping_address_street_2: null,
          shipping_address_id: 8363158,
          shipping_address_name: "RICHARD THOMAS",
          shipping_address_zip: "45005",
          shipping_address_city: "FRANKLIN",
          shipping_address_state: "Ohio",
          shipping_address_country: "United States",
          stock_picking_reference: "FGHPO000087798164-0",
          stock_picking_id: 3115706,
          total_price: 315.78,
          shipping_batch_id: -1,
          date: "2021-09-15 19:07:15",
          picking_write_date: "2021-09-15 20:14:37",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186397,
          stock_move_set_product_id: 3106198,
          stock_move_quantity: 1,
          stock_move_bin: "18-G-09",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397487,
          sale_order_line_description: "SS 1/3 CT TGW Created White Sa 7",
          sale_order_line_customer_cost: "157.89",
          sale_order_line_vendor_sku: "FGH004503",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0U7J-BV5G",
          product_product_name:
            "0.05 CT Diamond TW And 1/3 CT TGW Created White Sapphire Fashion Ring Silver GH I2;I3",
          product_id: 96448,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-SB",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0U7J-BV5G.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " SurePost",
          order_tracking_number: "1Z306EF2YW11484678",
          paper_format: 8,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T20:15:07.234Z",
        updatedAt: "2021-09-15T20:15:07.235Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "G", bin: "15" },
        price: 131.99,
        notes: [],
        isSet: false,
        _id: "61426cfb5182ab9ee6a6e5e1",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363256",
            street: "326 Molino Ave",
            name: "Kelly Bui",
            zip: "90814",
            city: "Long Beach",
            country: "United States",
            state: "California",
          },
          orderValue: 302.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cfb5182ab9ee6a6e5e1",
            "61426cfb5182ab5090a6e5ea",
            "61426cfc5182ab6977a6e5f3",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61426cfb5182ab433ba6e5dc",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121146,
          orderNo: "JMSM2HC1465-0",
          customerId: 177,
          customerName: "Jomashop",
          orderReference: "JMSM2HC1465",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363256,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552046",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          createdAt: "2021-09-15T22:00:27.435Z",
          updatedAt: "2021-09-15T22:00:28.225Z",
          labelFile: "5121146/labelFile.txt",
          zplFile: "5121146/zplFile.txt",
          pdfFile: "5121146/pdfFile.txt",
          addressString: "326molinoavelongbeachcalifornia90814unitedstates",
          normalizedCustomerName: "jomashop",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "ABS03043",
        productName:
          "56.7 CT TGW Cubic Zirconia Bracelet Silver White Length (inches): 7",
        productImage: "https://partner.delmarintl.ca/catalog/ABS03043.jpg",
        reference: "JMSM2HC1465-0",
        sku: "ABS03043",
        binLocation: "18-G-15",
        boxCode: "",
        stockPickingId: "3115782",
        uniqueIdentifier: "5121146-3115782-4397579",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121146,
          sale_order_customer_id: 177,
          sale_order_customer_name: "Jomashop",
          sale_order_order_reference: "JMSM2HC1465",
          sale_order_date: "2021-09-15",
          sale_order_total: 170.5,
          shipping_address_street: "326 Molino Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363256,
          shipping_address_name: "Kelly Bui",
          shipping_address_zip: "90814",
          shipping_address_city: "Long Beach",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "JMSM2HC1465-0",
          stock_picking_id: 3115782,
          total_price: 302.98,
          shipping_batch_id: -1,
          date: "2021-09-15 20:31:26",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          stock_move_is_set: false,
          stock_move_id: 78186561,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-G-15",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397579,
          sale_order_line_description:
            "Amour Sterling Silver 56.7 CT TGW Cubic Zirconia Tennis Bracelet",
          sale_order_line_customer_cost: "131.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "ABS03043",
          product_product_name:
            "56.7 CT TGW Cubic Zirconia Bracelet Silver White Length (inches): 7",
          product_id: 3100433,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/ABS03043.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552046",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T22:00:27.601Z",
        updatedAt: "2021-09-15T22:00:27.602Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "G", bin: "16" },
        price: 78.75,
        notes: [],
        isSet: true,
        _id: "6142a1a25182ab8255a6e8ab",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363537",
            street: "922 Sparkman St NW",
            name: "Terry Phillips",
            zip: "35640-4528",
            city: "Hartselle",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 236.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142a1a15182ab137da6e899",
            "6142a1a15182ab40fca6e8a2",
            "6142a1a25182ab8255a6e8ab",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142a1a15182ab6baca6e894",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121299,
          orderNo: "BLK100974129-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974129",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363537,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694207",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          createdAt: "2021-09-16T01:45:05.349Z",
          updatedAt: "2021-09-16T01:45:06.095Z",
          labelFile: "5121299/labelFile.txt",
          zplFile: "5121299/zplFile.txt",
          pdfFile: "5121299/pdfFile.txt",
          addressString: "922sparkmanstnwhartsellealabama356404528unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "BLK100974129-0",
        sku: "FC0T2V-GXKV",
        binLocation: "18-G-16",
        boxCode: "",
        stockPickingId: "3115927",
        uniqueIdentifier: "5121299-3115927-4397760",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121299,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974129",
          sale_order_date: "2021-09-15",
          sale_order_total: 105,
          shipping_address_street: "922 Sparkman St NW",
          shipping_address_street_2: null,
          shipping_address_id: 8363537,
          shipping_address_name: "Terry Phillips",
          shipping_address_zip: "35640-4528",
          shipping_address_city: "Hartselle",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974129-0",
          stock_picking_id: 3115927,
          total_price: 236.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:32:14",
          picking_write_date: "2021-09-16 00:40:44",
          address_grp_combined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          stock_move_is_set: true,
          stock_move_id: 78186759,
          stock_move_set_product_id: 228639,
          stock_move_quantity: 1,
          stock_move_bin: "18-G-16",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397760,
          sale_order_line_description: "CR SSPH SET SS RG 7 DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692415751",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694207",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:06.082Z",
        updatedAt: "2021-09-16T01:45:06.083Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "H", bin: "16" },
        price: null,
        notes: [],
        isSet: false,
        _id: "6142fd075182ab1f12a6ed7d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363909",
            street: "8530 NW 72nd Street, D#0021",
            name: "Delia Drohan",
            zip: "33166-2300",
            city: "Miami",
            country: "United States",
            state: "Florida",
          },
          orderValue: 0,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142fd075182ab6b15a6ed74",
            "6142fd075182ab1f12a6ed7d",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142fd075182abade6a6ed6f",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121496,
          orderNo: "HAU66355612-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66355612",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363909,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552457",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/delia drohan/8530 nw 72nd street/d#0021/miami/florida/33166-2300/united states",
          createdAt: "2021-09-16T08:15:03.414Z",
          updatedAt: "2021-09-16T08:15:03.952Z",
          labelFile: "5121496/labelFile.txt",
          zplFile: "5121496/zplFile.txt",
          pdfFile: "5121496/pdfFile.txt",
          addressString:
            "8530nw72ndstreetd0021miamiflorida331662300unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC2A1R-KCUT",
        productName:
          "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A1R-KCUT.jpg",
        reference: "HAU66355612-0",
        sku: "FC2A1R-KCUT",
        binLocation: "18-H-16",
        boxCode: "",
        stockPickingId: "3116111",
        uniqueIdentifier: "5121496-3116111-4397980",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121496,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66355612",
          sale_order_date: "2021-09-15",
          sale_order_total: 39,
          shipping_address_street: "8530 NW 72nd Street",
          shipping_address_street_2: "D#0021",
          shipping_address_id: 8363909,
          shipping_address_name: "Delia Drohan",
          shipping_address_zip: "33166-2300",
          shipping_address_city: "Miami",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66355612-0",
          stock_picking_id: 3116111,
          total_price: 0,
          shipping_batch_id: -1,
          date: "2021-09-16 06:45:54",
          picking_write_date: "2021-09-16 07:11:00",
          address_grp_combined:
            "hau/delia drohan/8530 nw 72nd street/d#0021/miami/florida/33166-2300/united states",
          stock_move_is_set: false,
          stock_move_id: 78187022,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "18-H-16",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397980,
          sale_order_line_description:
            "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater C",
          sale_order_line_customer_cost: null,
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "FC2A1R-KCUT",
          product_product_name:
            "0.02 CT Diamond TW 8.5 - 9 MM White Freshwater Cultured Pearl Fashion Pendant With Chain Silver I3",
          product_id: 3110490,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A1R-KCUT.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552457",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T08:15:03.941Z",
        updatedAt: "2021-09-16T08:15:03.942Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "L", bin: "18" },
        price: 35.745,
        notes: [],
        isSet: true,
        _id: "614270785182ab13baa6e61b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363272",
            street: "1412 W 13th Ave",
            name: "Claretha Grimes",
            zip: "71603",
            city: "Pine Bluff",
            country: "United States",
            state: "Arkansas",
          },
          orderValue: 71.49,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614270785182ab13baa6e61b",
            "614270785182ab7274a6e625",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "614270775182ab6ef7a6e616",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121156,
          orderNo: "OS355650296-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355650296",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363272,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256337",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/claretha grimes/1412 w 13th ave//pine bluff/arkansas/71603/united states",
          createdAt: "2021-09-15T22:15:19.996Z",
          updatedAt: "2021-09-15T22:15:20.619Z",
          labelFile: "5121156/labelFile.txt",
          zplFile: "5121156/zplFile.txt",
          pdfFile: "5121156/pdfFile.txt",
          addressString: "1412w13thavepinebluffarkansas71603unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2A1Y-A7CB",
        productName:
          "0.02 CT Diamond TW 6.5 - 7 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC2A1Y-A7CB.jpg",
        reference: "OS355650296-0",
        sku: "FC2A1Y-A7CB",
        binLocation: "18-L-18",
        boxCode: "",
        stockPickingId: "3115788",
        uniqueIdentifier: "5121156-3115788-4397587",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121156,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355650296",
          sale_order_date: "2021-09-15",
          sale_order_total: 56,
          shipping_address_street: "1412 W 13th Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363272,
          shipping_address_name: "Claretha Grimes",
          shipping_address_zip: "71603",
          shipping_address_city: "Pine Bluff",
          shipping_address_state: "Arkansas",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355650296-0",
          stock_picking_id: 3115788,
          total_price: 71.49,
          shipping_batch_id: -1,
          date: "2021-09-15 20:43:14",
          picking_write_date: "2021-09-15 22:14:37",
          address_grp_combined:
            "os/claretha grimes/1412 w 13th ave//pine bluff/arkansas/71603/united states",
          stock_move_is_set: true,
          stock_move_id: 78186571,
          stock_move_set_product_id: 3124472,
          stock_move_quantity: 1,
          stock_move_bin: "18-L-18",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397587,
          sale_order_line_description:
            "Miadora Cultured FW Pearl & Diamond Accent Drop Earrings & Necklace Set in Sterling Silver (6.5-7mm)",
          sale_order_line_customer_cost: "35.745",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC2A1Y-A7CB",
          product_product_name:
            "0.02 CT Diamond TW 6.5 - 7 MM White Freshwater Cultured Pearl Fashion Post Earrings Silver I3",
          product_id: 3110497,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2A1Y-A7CB.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256337",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-15T22:15:20.167Z",
        updatedAt: "2021-09-15T22:15:20.168Z",
        __v: 0,
      },
      {
        stockBin: { row: "18", section: "M", bin: "06" },
        price: 93.74,
        notes: [],
        isSet: true,
        _id: "61421c085182abf5dca6e00b",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362893",
            street: "8437 DEVONSHIRE CT",
            name: "BEATRICE ONDRIS",
            zip: "46260",
            city: "INDIANAPOLIS",
            country: "United States",
            state: "Indiana",
          },
          orderValue: 187.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61421c085182ab6511a6e002",
            "61421c085182abf5dca6e00b",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61421c085182ab613aa6dffd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120841,
          orderNo: "JCP225985402_266-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225985402_266",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362893,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318551690",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/beatrice ondris/8437 devonshire ct//indianapolis/indiana/46260/united states",
          createdAt: "2021-09-15T16:15:04.165Z",
          updatedAt: "2021-09-15T16:15:04.819Z",
          labelFile: "5120841/labelFile.txt",
          zplFile: "5120841/zplFile.txt",
          pdfFile: "5120841/pdfFile.txt",
          addressString: "8437devonshirectindianapolisindiana46260unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC06W1-6843",
        productName: "2 1/3 CT TGW Created Ruby Fashion Post Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC06W1-6843.jpg",
        reference: "JCP225985402_266-0",
        sku: "FC06W1-6843",
        binLocation: "18-M-06",
        boxCode: "",
        stockPickingId: "3115480",
        uniqueIdentifier: "5120841-3115480-4397193",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120841,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225985402_266",
          sale_order_date: "2021-09-15",
          sale_order_total: 31.76,
          shipping_address_street: "8437 DEVONSHIRE CT",
          shipping_address_street_2: null,
          shipping_address_id: 8362893,
          shipping_address_name: "BEATRICE ONDRIS",
          shipping_address_zip: "46260",
          shipping_address_city: "INDIANAPOLIS",
          shipping_address_state: "Indiana",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225985402_266-0",
          stock_picking_id: 3115480,
          total_price: 187.48,
          shipping_batch_id: -1,
          date: "2021-09-15 14:42:27",
          picking_write_date: "2021-09-15 16:14:37",
          address_grp_combined:
            "jcp/beatrice ondris/8437 devonshire ct//indianapolis/indiana/46260/united states",
          stock_move_is_set: true,
          stock_move_id: 78185813,
          stock_move_set_product_id: 227685,
          stock_move_quantity: 1,
          stock_move_bin: "18-M-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397193,
          sale_order_line_description: "SS CR RUBY SET",
          sale_order_line_customer_cost: "93.74",
          sale_order_line_vendor_sku: "JCP002494",
          sale_order_line_size_id: null,
          product_product_default_code: "FC06W1-6843",
          product_product_name:
            "2 1/3 CT TGW Created Ruby Fashion Post Earrings Silver",
          product_id: 79544,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC06W1-6843.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318551690",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T16:15:04.798Z",
        updatedAt: "2021-09-15T16:15:04.799Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "B", bin: "08" },
        price: 93.74,
        notes: [],
        isSet: true,
        _id: "61421c085182ab6511a6e002",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8362893",
            street: "8437 DEVONSHIRE CT",
            name: "BEATRICE ONDRIS",
            zip: "46260",
            city: "INDIANAPOLIS",
            country: "United States",
            state: "Indiana",
          },
          orderValue: 187.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61421c085182ab6511a6e002",
            "61421c085182abf5dca6e00b",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61421c085182ab613aa6dffd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120841,
          orderNo: "JCP225985402_266-0",
          customerId: 28,
          customerName: "JCPenney",
          orderReference: "JCP225985402_266",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8362893,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318551690",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jcp/beatrice ondris/8437 devonshire ct//indianapolis/indiana/46260/united states",
          createdAt: "2021-09-15T16:15:04.165Z",
          updatedAt: "2021-09-15T16:15:04.819Z",
          labelFile: "5120841/labelFile.txt",
          zplFile: "5120841/zplFile.txt",
          pdfFile: "5120841/pdfFile.txt",
          addressString: "8437devonshirectindianapolisindiana46260unitedstates",
          normalizedCustomerName: "jcpenney",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0L92-TV59",
        productName:
          "1 1/2 CT TGW Created Ruby Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L92-TV59.jpg",
        reference: "JCP225985402_266-0",
        sku: "FC0L92-TV59",
        binLocation: "20-B-08",
        boxCode: "",
        stockPickingId: "3115480",
        uniqueIdentifier: "5120841-3115480-4397194",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120841,
          sale_order_customer_id: 28,
          sale_order_customer_name: "JCPenney",
          sale_order_order_reference: "JCP225985402_266",
          sale_order_date: "2021-09-15",
          sale_order_total: 31.76,
          shipping_address_street: "8437 DEVONSHIRE CT",
          shipping_address_street_2: null,
          shipping_address_id: 8362893,
          shipping_address_name: "BEATRICE ONDRIS",
          shipping_address_zip: "46260",
          shipping_address_city: "INDIANAPOLIS",
          shipping_address_state: "Indiana",
          shipping_address_country: "United States",
          stock_picking_reference: "JCP225985402_266-0",
          stock_picking_id: 3115480,
          total_price: 187.48,
          shipping_batch_id: -1,
          date: "2021-09-15 14:42:27",
          picking_write_date: "2021-09-15 16:14:37",
          address_grp_combined:
            "jcp/beatrice ondris/8437 devonshire ct//indianapolis/indiana/46260/united states",
          stock_move_is_set: true,
          stock_move_id: 78185814,
          stock_move_set_product_id: 227685,
          stock_move_quantity: 1,
          stock_move_bin: "20-B-08",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397194,
          sale_order_line_description: "SS CR RUBY SET",
          sale_order_line_customer_cost: "93.74",
          sale_order_line_vendor_sku: "JCP002494",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0L92-TV59",
          product_product_name:
            "1 1/2 CT TGW Created Ruby Fashion Pendant With Chain Silver",
          product_id: 88877,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L92-TV59.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318551690",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T16:15:04.378Z",
        updatedAt: "2021-09-15T16:15:04.379Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "C", bin: "06" },
        price: 133.215,
        notes: [],
        isSet: true,
        _id: "61426cf75182abf769a6e55a",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363245",
            street: "10 ELM RD",
            name: "ROBBIN HINDS",
            zip: "34472",
            city: "OCALA",
            country: "United States",
            state: "Florida",
          },
          orderValue: 266.43,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cf75182abf769a6e55a",
            "61426cf85182ab009ca6e563",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "61426cf75182ab0f77a6e555",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121139,
          orderNo: "SAMS8775303732-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775303732",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363245,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594130",
          carrier: "Fedex",
          shippingMethod: "  2nd Day",
          addressGroupCombined: null,
          createdAt: "2021-09-15T22:00:23.605Z",
          updatedAt: "2021-09-15T22:00:24.156Z",
          labelFile: "5121139/labelFile.txt",
          zplFile: "5121139/zplFile.txt",
          pdfFile: "5121139/pdfFile.txt",
          addressString: "10elmrdocalaflorida34472unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1570-4V3H",
        productName:
          "2 7/8 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Post Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC1570-4V3H.jpg",
        reference: "SAMS8775303732-0",
        sku: "FC1570-4V3H",
        binLocation: "20-C-06",
        boxCode: "SCU",
        stockPickingId: "3115771",
        uniqueIdentifier: "5121139-3115771-4397565",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121139,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775303732",
          sale_order_date: "2021-09-15",
          sale_order_total: 185,
          shipping_address_street: "10 ELM RD",
          shipping_address_street_2: "",
          shipping_address_id: 8363245,
          shipping_address_name: "ROBBIN HINDS",
          shipping_address_zip: "34472",
          shipping_address_city: "OCALA",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775303732-0",
          stock_picking_id: 3115771,
          total_price: 266.43,
          shipping_batch_id: -1,
          date: "2021-09-15 20:19:26",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined: null,
          stock_move_is_set: true,
          stock_move_id: 78186544,
          stock_move_set_product_id: 351612,
          stock_move_quantity: 1,
          stock_move_bin: "20-C-06",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397565,
          sale_order_line_description: "5.75 GEM PENDEAR SET",
          sale_order_line_customer_cost: "133.215",
          sale_order_line_vendor_sku: "SAMS01946",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1570-4V3H",
          product_product_name:
            "2 7/8 CT TGW Blue Topaz - London Blue Topaz - Sky Sapphire And White Freshwater Cultured Pearl Fashion Post Earrings Silver",
          product_id: 184493,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCU",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1570-4V3H.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: "  2nd Day",
          order_tracking_number: "501515594130",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T22:00:23.793Z",
        updatedAt: "2021-09-15T22:00:23.793Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "D", bin: "07" },
        price: 78.75,
        notes: [],
        isSet: true,
        _id: "6142a1a15182ab137da6e899",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363537",
            street: "922 Sparkman St NW",
            name: "Terry Phillips",
            zip: "35640-4528",
            city: "Hartselle",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 236.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142a1a15182ab137da6e899",
            "6142a1a15182ab40fca6e8a2",
            "6142a1a25182ab8255a6e8ab",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142a1a15182ab6baca6e894",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121299,
          orderNo: "BLK100974129-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974129",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363537,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694207",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          createdAt: "2021-09-16T01:45:05.349Z",
          updatedAt: "2021-09-16T01:45:06.095Z",
          labelFile: "5121299/labelFile.txt",
          zplFile: "5121299/zplFile.txt",
          pdfFile: "5121299/pdfFile.txt",
          addressString: "922sparkmanstnwhartsellealabama356404528unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T30-YUE1",
        productName:
          "0.04 CT Diamond TW And 8.06 CT TGW Created Blue Sapphire Created White Sapphire LeverBack Earrings Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T30-YUE1.jpg",
        reference: "BLK100974129-0",
        sku: "FC0T30-YUE1",
        binLocation: "20-D-07",
        boxCode: "",
        stockPickingId: "3115927",
        uniqueIdentifier: "5121299-3115927-4397763",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121299,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974129",
          sale_order_date: "2021-09-15",
          sale_order_total: 105,
          shipping_address_street: "922 Sparkman St NW",
          shipping_address_street_2: null,
          shipping_address_id: 8363537,
          shipping_address_name: "Terry Phillips",
          shipping_address_zip: "35640-4528",
          shipping_address_city: "Hartselle",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974129-0",
          stock_picking_id: 3115927,
          total_price: 236.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:32:14",
          picking_write_date: "2021-09-16 00:40:44",
          address_grp_combined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          stock_move_is_set: true,
          stock_move_id: 78186761,
          stock_move_set_product_id: 228639,
          stock_move_quantity: 1,
          stock_move_bin: "20-D-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397763,
          sale_order_line_description: "CR SSPH SET SS RG 7 DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692415751",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T30-YUE1",
          product_product_name:
            "0.04 CT Diamond TW And 8.06 CT TGW Created Blue Sapphire Created White Sapphire LeverBack Earrings Silver GH I3",
          product_id: 101100,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T30-YUE1.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694207",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:05.486Z",
        updatedAt: "2021-09-16T01:45:05.487Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "H", bin: "07" },
        price: 78.75,
        notes: [],
        isSet: true,
        _id: "6142a1a15182ab40fca6e8a2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363537",
            street: "922 Sparkman St NW",
            name: "Terry Phillips",
            zip: "35640-4528",
            city: "Hartselle",
            country: "United States",
            state: "Alabama",
          },
          orderValue: 236.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142a1a15182ab137da6e899",
            "6142a1a15182ab40fca6e8a2",
            "6142a1a25182ab8255a6e8ab",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142a1a15182ab6baca6e894",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121299,
          orderNo: "BLK100974129-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100974129",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363537,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694207",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          createdAt: "2021-09-16T01:45:05.349Z",
          updatedAt: "2021-09-16T01:45:06.095Z",
          labelFile: "5121299/labelFile.txt",
          zplFile: "5121299/zplFile.txt",
          pdfFile: "5121299/pdfFile.txt",
          addressString: "922sparkmanstnwhartsellealabama356404528unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2Y-3WR5",
        productName:
          "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
        reference: "BLK100974129-0",
        sku: "FC0T2Y-3WR5",
        binLocation: "20-H-07",
        boxCode: "",
        stockPickingId: "3115927",
        uniqueIdentifier: "5121299-3115927-4397761",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121299,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100974129",
          sale_order_date: "2021-09-15",
          sale_order_total: 105,
          shipping_address_street: "922 Sparkman St NW",
          shipping_address_street_2: null,
          shipping_address_id: 8363537,
          shipping_address_name: "Terry Phillips",
          shipping_address_zip: "35640-4528",
          shipping_address_city: "Hartselle",
          shipping_address_state: "Alabama",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100974129-0",
          stock_picking_id: 3115927,
          total_price: 236.25,
          shipping_batch_id: -1,
          date: "2021-09-16 00:32:14",
          picking_write_date: "2021-09-16 00:40:44",
          address_grp_combined:
            "blk/terry phillips/922 sparkman st nw//hartselle/alabama/35640-4528/united states",
          stock_move_is_set: true,
          stock_move_id: 78186760,
          stock_move_set_product_id: 228639,
          stock_move_quantity: 1,
          stock_move_bin: "20-H-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397761,
          sale_order_line_description: "CR SSPH SET SS RG 7 DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692415751",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T2Y-3WR5",
          product_product_name:
            "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
          product_id: 107203,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694207",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T01:45:05.780Z",
        updatedAt: "2021-09-16T01:45:05.780Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "H", bin: "07" },
        price: 39.97,
        notes: [],
        isSet: false,
        _id: "6142b6ba5182ab4d24a6e9ca",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363622",
            street: "200 SE 15 Road, Apt 17F",
            name: "Paloma Gutierrez Velazquez",
            zip: "33129",
            city: "Miami",
            country: "United States",
            state: "Florida",
          },
          orderValue: 82.94,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142b6ba5182ab4d24a6e9ca",
            "6142b6ba5182ab78e1a6e9d3",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b6ba5182ab6cf5a6e9c5",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121342,
          orderNo: "HAU66353667-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66353667",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363622,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552251",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/paloma gutierrez velazquez/200 se 15 road/apt 17f/miami/florida/33129/united states",
          createdAt: "2021-09-16T03:15:06.420Z",
          updatedAt: "2021-09-16T03:15:06.908Z",
          labelFile: "5121342/labelFile.txt",
          zplFile: "5121342/zplFile.txt",
          pdfFile: "5121342/pdfFile.txt",
          addressString: "200se15roadapt17fmiamiflorida33129unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0T2Y-3WR5",
        productName:
          "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
        reference: "HAU66353667-0",
        sku: "FC0T2Y-3WR5",
        binLocation: "20-H-07",
        boxCode: "",
        stockPickingId: "3115965",
        uniqueIdentifier: "5121342-3115965-4397806",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121342,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66353667",
          sale_order_date: "2021-09-15",
          sale_order_total: 45,
          shipping_address_street: "200 SE 15 Road",
          shipping_address_street_2: "Apt 17F",
          shipping_address_id: 8363622,
          shipping_address_name: "Paloma Gutierrez Velazquez",
          shipping_address_zip: "33129",
          shipping_address_city: "Miami",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66353667-0",
          stock_picking_id: 3115965,
          total_price: 82.94,
          shipping_batch_id: -1,
          date: "2021-09-16 01:46:44",
          picking_write_date: "2021-09-16 03:14:39",
          address_grp_combined:
            "hau/paloma gutierrez velazquez/200 se 15 road/apt 17f/miami/florida/33129/united states",
          stock_move_is_set: false,
          stock_move_id: 78186815,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-H-07",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397806,
          sale_order_line_description:
            "0.02 CT Diamond TW And 4 CT TGW Created Blue Sap",
          sale_order_line_customer_cost: "39.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T2Y-3WR5",
          product_product_name:
            "0.02 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Pendant With Chain Silver GH I3",
          product_id: 107203,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2Y-3WR5.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552251",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T03:15:06.565Z",
        updatedAt: "2021-09-16T03:15:06.566Z",
        __v: 0,
      },
      {
        stockBin: { row: "20", section: "P", bin: "13" },
        price: 42.97,
        notes: [],
        isSet: false,
        _id: "6142b6ba5182ab78e1a6e9d3",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363622",
            street: "200 SE 15 Road, Apt 17F",
            name: "Paloma Gutierrez Velazquez",
            zip: "33129",
            city: "Miami",
            country: "United States",
            state: "Florida",
          },
          orderValue: 82.94,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142b6ba5182ab4d24a6e9ca",
            "6142b6ba5182ab78e1a6e9d3",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b6ba5182ab6cf5a6e9c5",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121342,
          orderNo: "HAU66353667-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66353667",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363622,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552251",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/paloma gutierrez velazquez/200 se 15 road/apt 17f/miami/florida/33129/united states",
          createdAt: "2021-09-16T03:15:06.420Z",
          updatedAt: "2021-09-16T03:15:06.908Z",
          labelFile: "5121342/labelFile.txt",
          zplFile: "5121342/zplFile.txt",
          pdfFile: "5121342/pdfFile.txt",
          addressString: "200se15roadapt17fmiamiflorida33129unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "HAU66353667-0",
        sku: "FC0T2V-GXKV",
        binLocation: "20-P-13",
        boxCode: "",
        stockPickingId: "3115965",
        uniqueIdentifier: "5121342-3115965-4397805",
        stockMoveSize: "7.5",
        orderLineSize: "7.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121342,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66353667",
          sale_order_date: "2021-09-15",
          sale_order_total: 45,
          shipping_address_street: "200 SE 15 Road",
          shipping_address_street_2: "Apt 17F",
          shipping_address_id: 8363622,
          shipping_address_name: "Paloma Gutierrez Velazquez",
          shipping_address_zip: "33129",
          shipping_address_city: "Miami",
          shipping_address_state: "Florida",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66353667-0",
          stock_picking_id: 3115965,
          total_price: 82.94,
          shipping_batch_id: -1,
          date: "2021-09-16 01:46:44",
          picking_write_date: "2021-09-16 03:14:39",
          address_grp_combined:
            "hau/paloma gutierrez velazquez/200 se 15 road/apt 17f/miami/florida/33129/united states",
          stock_move_is_set: false,
          stock_move_id: 78186814,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "20-P-13",
          stock_move_uom: "PCE",
          stock_move_size: "7.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397805,
          sale_order_line_description:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sap",
          sale_order_line_customer_cost: "42.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 8,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "7.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552251",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T03:15:06.896Z",
        updatedAt: "2021-09-16T03:15:06.897Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "A", bin: "03" },
        price: 25.5,
        notes: [],
        isSet: false,
        _id: "6142d2d85182ab271ba6eb8e",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363744",
            street: "8 Village Way Apt 4",
            name: "Amanda Cruz",
            zip: "01760",
            city: "Natick",
            country: "United States",
            state: "Massachusetts",
          },
          orderValue: 50.47,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142d2d85182ab271ba6eb8e",
            "6142d2d85182abfcada6eb97",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142d2d85182ab1a3ca6eb89",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121412,
          orderNo: "HAU66354452-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66354452",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363744,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552331",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/amanda cruz/8 village way apt 4//natick/massachusetts/01760/united states",
          createdAt: "2021-09-16T05:15:04.437Z",
          updatedAt: "2021-09-16T05:15:04.916Z",
          labelFile: "5121412/labelFile.txt",
          zplFile: "5121412/zplFile.txt",
          pdfFile: "5121412/pdfFile.txt",
          addressString: "8villagewayapt4natickmassachusetts01760unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0L5L-XEMV",
        productName: "1/5 CT Diamond TW Anniversary Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
        reference: "HAU66354452-0",
        sku: "FC0L5L-XEMV",
        binLocation: "21-A-03",
        boxCode: "",
        stockPickingId: "3116028",
        uniqueIdentifier: "5121412-3116028-4397887",
        stockMoveSize: "6.0",
        orderLineSize: "6.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121412,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66354452",
          sale_order_date: "2021-09-15",
          sale_order_total: 39,
          shipping_address_street: "8 Village Way Apt 4",
          shipping_address_street_2: "",
          shipping_address_id: 8363744,
          shipping_address_name: "Amanda Cruz",
          shipping_address_zip: "01760",
          shipping_address_city: "Natick",
          shipping_address_state: "Massachusetts",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66354452-0",
          stock_picking_id: 3116028,
          total_price: 50.47,
          shipping_batch_id: -1,
          date: "2021-09-16 03:46:19",
          picking_write_date: "2021-09-16 05:14:38",
          address_grp_combined:
            "hau/amanda cruz/8 village way apt 4//natick/massachusetts/01760/united states",
          stock_move_is_set: false,
          stock_move_id: 78186909,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-A-03",
          stock_move_uom: "PCE",
          stock_move_size: "6.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397887,
          sale_order_line_description:
            "1/5 CT Diamond TW Anniversary Ring Silver I3",
          sale_order_line_customer_cost: "25.5",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 5,
          product_product_default_code: "FC0L5L-XEMV",
          product_product_name: "1/5 CT Diamond TW Anniversary Ring Silver I3",
          product_id: 90399,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0L5L-XEMV.jpg",
          sale_order_line_size: "6.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552331",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:15:04.596Z",
        updatedAt: "2021-09-16T05:15:04.597Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "C", bin: "12" },
        price: 52.5,
        notes: [],
        isSet: false,
        _id: "614277735182ab6161a6e6d2",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363346",
            street: "195 Gwen Dr",
            name: "Sharon King",
            zip: "30736-6121",
            city: "Ringgold",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 131.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614277735182ab6161a6e6d2",
            "614277745182ab7023a6e6db",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614277735182abdd02a6e6cd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121192,
          orderNo: "BLK100966319-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100966319",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363346,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694160",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/sharon king/195 gwen dr//ringgold/georgia/30736-6121/united states",
          createdAt: "2021-09-15T22:45:07.708Z",
          updatedAt: "2021-09-15T22:45:08.160Z",
          labelFile: "5121192/labelFile.txt",
          zplFile: "5121192/zplFile.txt",
          pdfFile: "5121192/pdfFile.txt",
          addressString: "195gwendrringgoldgeorgia307366121unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "APWS02883PC",
        productName:
          "1 CT TGW Cubic Zirconia & 11.5-12mm Pink Freshwater Cultured Fashion Pearl Pendant With Chain Silver White",
        productImage: "https://partner.delmarintl.ca/catalog/APWS02883PC.jpg",
        reference: "BLK100966319-0",
        sku: "APWS02883PC",
        binLocation: "21-C-12",
        boxCode: "",
        stockPickingId: "3115823",
        uniqueIdentifier: "5121192-3115823-4397630",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121192,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100966319",
          sale_order_date: "2021-09-15",
          sale_order_total: 59,
          shipping_address_street: "195 Gwen Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363346,
          shipping_address_name: "Sharon King",
          shipping_address_zip: "30736-6121",
          shipping_address_city: "Ringgold",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100966319-0",
          stock_picking_id: 3115823,
          total_price: 131.25,
          shipping_batch_id: -1,
          date: "2021-09-15 21:34:10",
          picking_write_date: "2021-09-15 22:44:37",
          address_grp_combined:
            "blk/sharon king/195 gwen dr//ringgold/georgia/30736-6121/united states",
          stock_move_is_set: false,
          stock_move_id: 78186619,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-C-12",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397630,
          sale_order_line_description: "PK FWP CZ HLO NK SS DS",
          sale_order_line_customer_cost: "52.5",
          sale_order_line_vendor_sku: "686692364974",
          sale_order_line_size_id: null,
          product_product_default_code: "APWS02883PC",
          product_product_name:
            "1 CT TGW Cubic Zirconia & 11.5-12mm Pink Freshwater Cultured Fashion Pearl Pendant With Chain Silver White",
          product_id: 3097922,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/APWS02883PC.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694160",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T22:45:07.848Z",
        updatedAt: "2021-09-15T22:45:07.848Z",
        __v: 0,
      },
      {
        stockBin: { row: "21", section: "P", bin: "10" },
        price: 42.97,
        notes: [],
        isSet: false,
        _id: "6142382a5182ab6e99a6e2e7",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363025",
            street: "68 Clinton Avenue",
            name: "Stella Anyan",
            zip: "10301",
            city: "Staten Island",
            country: "United States",
            state: "New York",
          },
          orderValue: 92.94,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614238295182ab1df5a6e2de",
            "6142382a5182ab6e99a6e2e7",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614238295182ab1cbda6e2d9",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5120990,
          orderNo: "HAU66350362-0",
          customerId: 116,
          customerName: "Hautelook",
          orderReference: "HAU66350362",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363025,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318551869",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "hau/stella anyan/68 clinton avenue//staten island/new york/10301/united states",
          createdAt: "2021-09-15T18:15:05.593Z",
          updatedAt: "2021-09-15T18:15:06.049Z",
          labelFile: "5120990/labelFile.txt",
          zplFile: "5120990/zplFile.txt",
          pdfFile: "5120990/pdfFile.txt",
          addressString: "68clintonavenuestatenislandnewyork10301unitedstates",
          normalizedCustomerName: "hautelook",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "HAU66350362-0",
        sku: "FC0T2V-GXKV",
        binLocation: "21-P-10",
        boxCode: "",
        stockPickingId: "3115626",
        uniqueIdentifier: "5120990-3115626-4397348",
        stockMoveSize: "9.0",
        orderLineSize: "9.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5120990,
          sale_order_customer_id: 116,
          sale_order_customer_name: "Hautelook",
          sale_order_order_reference: "HAU66350362",
          sale_order_date: "2021-09-15",
          sale_order_total: 44,
          shipping_address_street: "68 Clinton Avenue",
          shipping_address_street_2: "",
          shipping_address_id: 8363025,
          shipping_address_name: "Stella Anyan",
          shipping_address_zip: "10301",
          shipping_address_city: "Staten Island",
          shipping_address_state: "New York",
          shipping_address_country: "United States",
          stock_picking_reference: "HAU66350362-0",
          stock_picking_id: 3115626,
          total_price: 92.94,
          shipping_batch_id: -1,
          date: "2021-09-15 16:45:58",
          picking_write_date: "2021-09-15 18:14:37",
          address_grp_combined:
            "hau/stella anyan/68 clinton avenue//staten island/new york/10301/united states",
          stock_move_is_set: false,
          stock_move_id: 78186167,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "21-P-10",
          stock_move_uom: "PCE",
          stock_move_size: "9.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397348,
          sale_order_line_description:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sap",
          sale_order_line_customer_cost: "42.97",
          sale_order_line_vendor_sku: "",
          sale_order_line_size_id: 11,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "9.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318551869",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T18:15:06.038Z",
        updatedAt: "2021-09-15T18:15:06.039Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "K", bin: "04" },
        price: 214.99,
        notes: [],
        isSet: false,
        _id: "614297195182ab8a0ba6e841",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363468",
            street: "4179 Slice Dr",
            name: "Kenneth Gillus",
            zip: "80922",
            city: "Colorado Springs",
            country: "United States",
            state: "Colorado",
          },
          orderValue: 423.48,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614297185182ab89e2a6e837",
            "614297195182ab8a0ba6e841",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614297185182ab2a6da6e832",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121260,
          orderNo: "OS355662243-0",
          customerId: 5,
          customerName: "Overstock.com",
          orderReference: "OS355662243",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363468,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "9400116902453316256382",
          carrier: "USPS",
          shippingMethod: " First Class",
          addressGroupCombined:
            "os/kenneth gillus/4179 slice dr//colorado springs/colorado/80922/united states",
          createdAt: "2021-09-16T01:00:08.836Z",
          updatedAt: "2021-09-16T01:00:09.490Z",
          labelFile: "5121260/labelFile.txt",
          zplFile: "5121260/zplFile.txt",
          pdfFile: "5121260/pdfFile.txt",
          addressString: "4179slicedrcoloradospringscolorado80922unitedstates",
          normalizedCustomerName: "overstock.com",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1L23-WM",
        productName: "1/2 CT Diamond TW LeverBack Earrings Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC1L23-WM.jpg",
        reference: "OS355662243-0",
        sku: "FC1L23-WM",
        binLocation: "22-K-04",
        boxCode: "CRD-MIADORA:PC-MIADORA:GM5B",
        stockPickingId: "3115891",
        uniqueIdentifier: "5121260-3115891-4397703",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121260,
          sale_order_customer_id: 5,
          sale_order_customer_name: "Overstock.com",
          sale_order_order_reference: "OS355662243",
          sale_order_date: "2021-09-15",
          sale_order_total: 296,
          shipping_address_street: "4179 Slice Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363468,
          shipping_address_name: "Kenneth Gillus",
          shipping_address_zip: "80922",
          shipping_address_city: "Colorado Springs",
          shipping_address_state: "Colorado",
          shipping_address_country: "United States",
          stock_picking_reference: "OS355662243-0",
          stock_picking_id: 3115891,
          total_price: 423.48,
          shipping_batch_id: -1,
          date: "2021-09-15 23:23:05",
          picking_write_date: "2021-09-16 00:44:59",
          address_grp_combined:
            "os/kenneth gillus/4179 slice dr//colorado springs/colorado/80922/united states",
          stock_move_is_set: false,
          stock_move_id: 78186704,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-K-04",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397703,
          sale_order_line_description:
            "Composite 1/2ct TDW Diamond Dangle Leverback Earrings in Sterling Silver by Miadora - 25.2 mm x 10.3 mm x 12.9 mm",
          sale_order_line_customer_cost: "214.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "FC1L23-WM",
          product_product_name:
            "1/2 CT Diamond TW LeverBack Earrings Silver I3",
          product_id: 233409,
          ship2store: false,
          is_no_resize: null,
          box_code: "CRD-MIADORA:PC-MIADORA:GM5B",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1L23-WM.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " First Class",
          order_tracking_number: "9400116902453316256382",
          paper_format: null,
          print_pslip: 1,
          carrier: "USPS",
        },
        createdAt: "2021-09-16T01:00:09.476Z",
        updatedAt: "2021-09-16T01:00:09.477Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "N", bin: "05" },
        price: 49.99,
        notes: [],
        isSet: false,
        _id: "61426cfc5182ab6977a6e5f3",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363256",
            street: "326 Molino Ave",
            name: "Kelly Bui",
            zip: "90814",
            city: "Long Beach",
            country: "United States",
            state: "California",
          },
          orderValue: 302.98,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61426cfb5182ab9ee6a6e5e1",
            "61426cfb5182ab5090a6e5ea",
            "61426cfc5182ab6977a6e5f3",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61426cfb5182ab433ba6e5dc",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121146,
          orderNo: "JMSM2HC1465-0",
          customerId: 177,
          customerName: "Jomashop",
          orderReference: "JMSM2HC1465",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363256,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552046",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          createdAt: "2021-09-15T22:00:27.435Z",
          updatedAt: "2021-09-15T22:00:28.225Z",
          labelFile: "5121146/labelFile.txt",
          zplFile: "5121146/zplFile.txt",
          pdfFile: "5121146/pdfFile.txt",
          addressString: "326molinoavelongbeachcalifornia90814unitedstates",
          normalizedCustomerName: "jomashop",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Shopify",
        },
        quantity: 1,
        productCode: "BWS1652",
        productName:
          '7.5" 2-Strand 6-7mm Freshwater Cultured White Pearl Bracelet w/ Silver Tube Bars & Clasp',
        productImage: "https://partner.delmarintl.ca/catalog/BWS1652.jpg",
        reference: "JMSM2HC1465-0",
        sku: "BWS1652",
        binLocation: "22-N-05",
        boxCode: "",
        stockPickingId: "3115782",
        uniqueIdentifier: "5121146-3115782-4397573",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121146,
          sale_order_customer_id: 177,
          sale_order_customer_name: "Jomashop",
          sale_order_order_reference: "JMSM2HC1465",
          sale_order_date: "2021-09-15",
          sale_order_total: 170.5,
          shipping_address_street: "326 Molino Ave",
          shipping_address_street_2: null,
          shipping_address_id: 8363256,
          shipping_address_name: "Kelly Bui",
          shipping_address_zip: "90814",
          shipping_address_city: "Long Beach",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "JMSM2HC1465-0",
          stock_picking_id: 3115782,
          total_price: 302.98,
          shipping_batch_id: -1,
          date: "2021-09-15 20:31:26",
          picking_write_date: "2021-09-15 21:44:54",
          address_grp_combined:
            "jms/kelly bui/326 molino ave//long beach/california/90814/united states",
          stock_move_is_set: false,
          stock_move_id: 78186559,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-N-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397573,
          sale_order_line_description:
            'Amour 7.5" 2-Strand 6-7mm Freshwater Cultured White Pearl Bracelet w/ Silver Tube Bars & Clasp',
          sale_order_line_customer_cost: "49.99",
          sale_order_line_vendor_sku: null,
          sale_order_line_size_id: null,
          product_product_default_code: "BWS1652",
          product_product_name:
            '7.5" 2-Strand 6-7mm Freshwater Cultured White Pearl Bracelet w/ Silver Tube Bars & Clasp',
          product_id: 70200,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/BWS1652.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552046",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-15T22:00:28.215Z",
        updatedAt: "2021-09-15T22:00:28.216Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "Q", bin: "03" },
        price: 107.43,
        notes: [],
        isSet: false,
        _id: "61425b545182abd7e8a6e472",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363168",
            street: "1402 NORTH HARVARD AVENUE, SUITE E",
            name: "GISELLE BUSTOS",
            zip: "74115",
            city: "TULSA",
            country: "United States",
            state: "Oklahoma",
          },
          orderValue: 376.55,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61425b545182abbf12a6e460",
            "61425b545182ab1858a6e469",
            "61425b545182abd7e8a6e472",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61425b545182ab9400a6e45b",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121078,
          orderNo: "SAMS8775303516-0",
          customerId: 606,
          customerName: "Sams Club",
          orderReference: "SAMS8775303516",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363168,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501515594107",
          carrier: "Fedex",
          shippingMethod: " Next Day Air",
          addressGroupCombined: null,
          createdAt: "2021-09-15T20:45:08.103Z",
          updatedAt: "2021-09-15T20:45:08.880Z",
          labelFile: "5121078/labelFile.txt",
          zplFile: "5121078/zplFile.txt",
          pdfFile: "5121078/pdfFile.txt",
          addressString:
            "1402northharvardavenuesuiteetulsaoklahoma74115unitedstates",
          normalizedCustomerName: "sams club",
          classification: "321",
          __v: 3,
          noOfItems: 3,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC1XYG-L9XB",
        productName:
          "3.07 CT TGW Blue Topaz - Sky White Sapphire Necklace With Chain Silver Length (inches): 17",
        productImage: "https://partner.delmarintl.ca/catalog/FC1XYG-L9XB.jpg",
        reference: "SAMS8775303516-0",
        sku: "FC1XYG-L9XB",
        binLocation: "22-Q-03",
        boxCode: "SCE",
        stockPickingId: "3115711",
        uniqueIdentifier: "5121078-3115711-4397492",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121078,
          sale_order_customer_id: 606,
          sale_order_customer_name: "Sams Club",
          sale_order_order_reference: "SAMS8775303516",
          sale_order_date: "2021-09-15",
          sale_order_total: 248,
          shipping_address_street: "1402 NORTH HARVARD AVENUE",
          shipping_address_street_2: "SUITE E",
          shipping_address_id: 8363168,
          shipping_address_name: "GISELLE BUSTOS",
          shipping_address_zip: "74115",
          shipping_address_city: "TULSA",
          shipping_address_state: "Oklahoma",
          shipping_address_country: "United States",
          stock_picking_reference: "SAMS8775303516-0",
          stock_picking_id: 3115711,
          total_price: 376.55,
          shipping_batch_id: -1,
          date: "2021-09-15 19:18:36",
          picking_write_date: "2021-09-15 19:41:05",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186408,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-Q-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397492,
          sale_order_line_description: "3.06 CTTW GEM NECK",
          sale_order_line_customer_cost: "107.43",
          sale_order_line_vendor_sku: "SAMS01883",
          sale_order_line_size_id: null,
          product_product_default_code: "FC1XYG-L9XB",
          product_product_name:
            "3.07 CT TGW Blue Topaz - Sky White Sapphire Necklace With Chain Silver Length (inches): 17",
          product_id: 468312,
          ship2store: false,
          is_no_resize: null,
          box_code: "SCE",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC1XYG-L9XB.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Next Day Air",
          order_tracking_number: "501515594107",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T20:45:08.868Z",
        updatedAt: "2021-09-15T20:45:08.869Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "Q", bin: "05" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "614277745182ab7023a6e6db",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363346",
            street: "195 Gwen Dr",
            name: "Sharon King",
            zip: "30736-6121",
            city: "Ringgold",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 131.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "614277735182ab6161a6e6d2",
            "614277745182ab7023a6e6db",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "614277735182abdd02a6e6cd",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121192,
          orderNo: "BLK100966319-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100966319",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363346,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694160",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/sharon king/195 gwen dr//ringgold/georgia/30736-6121/united states",
          createdAt: "2021-09-15T22:45:07.708Z",
          updatedAt: "2021-09-15T22:45:08.160Z",
          labelFile: "5121192/labelFile.txt",
          zplFile: "5121192/zplFile.txt",
          pdfFile: "5121192/pdfFile.txt",
          addressString: "195gwendrringgoldgeorgia307366121unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2Y-ZF19",
        productName:
          "0.02 CT Diamond TW And 4 CT TGW Created White Sapphire Fashion Pendant With Chain Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2Y-ZF19.jpg",
        reference: "BLK100966319-0",
        sku: "FC0T2Y-ZF19",
        binLocation: "22-Q-05",
        boxCode: "",
        stockPickingId: "3115823",
        uniqueIdentifier: "5121192-3115823-4397629",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121192,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100966319",
          sale_order_date: "2021-09-15",
          sale_order_total: 59,
          shipping_address_street: "195 Gwen Dr",
          shipping_address_street_2: null,
          shipping_address_id: 8363346,
          shipping_address_name: "Sharon King",
          shipping_address_zip: "30736-6121",
          shipping_address_city: "Ringgold",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100966319-0",
          stock_picking_id: 3115823,
          total_price: 131.25,
          shipping_batch_id: -1,
          date: "2021-09-15 21:34:10",
          picking_write_date: "2021-09-15 22:44:37",
          address_grp_combined:
            "blk/sharon king/195 gwen dr//ringgold/georgia/30736-6121/united states",
          stock_move_is_set: false,
          stock_move_id: 78186618,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-Q-05",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397629,
          sale_order_line_description: "CWS DIA OV NK SS DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692415881",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0T2Y-ZF19",
          product_product_name:
            "0.02 CT Diamond TW And 4 CT TGW Created White Sapphire Fashion Pendant With Chain Silver GH I3",
          product_id: 3101739,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2Y-ZF19.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694160",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-15T22:45:08.149Z",
        updatedAt: "2021-09-15T22:45:08.149Z",
        __v: 0,
      },
      {
        stockBin: { row: "22", section: "Q", bin: "13" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "6142b3385182ab01b8a6e9b4",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363614",
            street: "246 EMERALD CIR",
            name: "Leonor Figueroa",
            zip: "30628-1735",
            city: "COLBERT",
            country: "United States",
            state: "Georgia",
          },
          orderValue: 140,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142b3385182ab01b8a6e9b4",
            "6142b3395182ab9226a6e9bd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "6142b3385182ab2c9ba6e9af",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121338,
          orderNo: "BLK100977483-0",
          customerId: 138,
          customerName: "BELK",
          orderReference: "BLK100977483",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363614,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "501509694295",
          carrier: "Fedex",
          shippingMethod: " Home Delivery",
          addressGroupCombined:
            "blk/leonor figueroa/246 emerald cir//colbert/georgia/30628-1735/united states",
          createdAt: "2021-09-16T03:00:08.813Z",
          updatedAt: "2021-09-16T03:00:09.297Z",
          labelFile: "5121338/labelFile.txt",
          zplFile: "5121338/zplFile.txt",
          pdfFile: "5121338/pdfFile.txt",
          addressString: "246emeraldcircolbertgeorgia306281735unitedstates",
          normalizedCustomerName: "belk",
          classification: "321",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0T2V-GXKV",
        productName:
          "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
        reference: "BLK100977483-0",
        sku: "FC0T2V-GXKV",
        binLocation: "22-Q-13",
        boxCode: "",
        stockPickingId: "3115961",
        uniqueIdentifier: "5121338-3115961-4397801",
        stockMoveSize: "6.5",
        orderLineSize: "6.5",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121338,
          sale_order_customer_id: 138,
          sale_order_customer_name: "BELK",
          sale_order_order_reference: "BLK100977483",
          sale_order_date: "2021-09-15",
          sale_order_total: 62,
          shipping_address_street: "246 EMERALD CIR",
          shipping_address_street_2: null,
          shipping_address_id: 8363614,
          shipping_address_name: "Leonor Figueroa",
          shipping_address_zip: "30628-1735",
          shipping_address_city: "COLBERT",
          shipping_address_state: "Georgia",
          shipping_address_country: "United States",
          stock_picking_reference: "BLK100977483-0",
          stock_picking_id: 3115961,
          total_price: 140,
          shipping_batch_id: -1,
          date: "2021-09-16 01:35:04",
          picking_write_date: "2021-09-16 02:45:16",
          address_grp_combined:
            "blk/leonor figueroa/246 emerald cir//colbert/georgia/30628-1735/united states",
          stock_move_is_set: false,
          stock_move_id: 78186809,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "22-Q-13",
          stock_move_uom: "PCE",
          stock_move_size: "6.5",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397801,
          sale_order_line_description: "CR SPPH DIA RG SS 6.5 DS",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "686692416543",
          sale_order_line_size_id: 6,
          product_product_default_code: "FC0T2V-GXKV",
          product_product_name:
            "0.05 CT Diamond TW And 4 CT TGW Created Blue Sapphire Created White Sapphire Fashion Ring Silver GH I3",
          product_id: 101104,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0T2V-GXKV.jpg",
          sale_order_line_size: "6.5",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Home Delivery",
          order_tracking_number: "501509694295",
          paper_format: null,
          print_pslip: 1,
          carrier: "Fedex",
        },
        createdAt: "2021-09-16T03:00:08.955Z",
        updatedAt: "2021-09-16T03:00:08.956Z",
        __v: 0,
      },
      {
        stockBin: { row: "24", section: "G", bin: "14" },
        price: 29.995,
        notes: [],
        isSet: true,
        _id: "6142d9e25182ab0a1ea6ebfd",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363780",
            street: "179 CLEARVIEW DR",
            name: "VICTORIO VIDAL",
            zip: "94591-7138",
            city: "VALLEJO",
            country: "United States",
            state: "California",
          },
          orderValue: 59.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142d9e25182ab1968a6ebf4",
            "6142d9e25182ab0a1ea6ebfd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142d9e15182ab7959a6ebef",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121433,
          orderNo: "EVN912988_1_4_4-0",
          customerId: 948,
          customerName: "Evine.com",
          orderReference: "EVN912988_1_4_4",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363780,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552377",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "evn/victorio vidal/179 clearview dr//vallejo/california/94591-7138/united states",
          createdAt: "2021-09-16T05:45:05.953Z",
          updatedAt: "2021-09-16T05:45:06.432Z",
          labelFile: "5121433/labelFile.txt",
          zplFile: "5121433/zplFile.txt",
          pdfFile: "5121433/pdfFile.txt",
          addressString: "179clearviewdrvallejocalifornia945917138unitedstates",
          normalizedCustomerName: "evine.com",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0RAA-GE3Y",
        productName:
          "3 1/4 CT TGW Created Blue Sapphire Fashion Post Earrings Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC0RAA-GE3Y.jpg",
        reference: "EVN912988_1_4_4-0",
        sku: "FC0RAA-GE3Y",
        binLocation: "24-G-14",
        boxCode: "",
        stockPickingId: "3116048",
        uniqueIdentifier: "5121433-3116048-4397911",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121433,
          sale_order_customer_id: 948,
          sale_order_customer_name: "Evine.com",
          sale_order_order_reference: "EVN912988_1_4_4",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "179 CLEARVIEW DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363780,
          shipping_address_name: "VICTORIO VIDAL",
          shipping_address_zip: "94591-7138",
          shipping_address_city: "VALLEJO",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "EVN912988_1_4_4-0",
          stock_picking_id: 3116048,
          total_price: 59.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:17:04",
          picking_write_date: "2021-09-16 04:41:13",
          address_grp_combined:
            "evn/victorio vidal/179 clearview dr//vallejo/california/94591-7138/united states",
          stock_move_is_set: true,
          stock_move_id: 78186941,
          stock_move_set_product_id: 3126108,
          stock_move_quantity: 1,
          stock_move_bin: "24-G-14",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397911,
          sale_order_line_description:
            "CREATED JULES STERLING SILVER 4.83CTW CREATED SAPPHIRE NECKLACE & EARRING SET",
          sale_order_line_customer_cost: "29.995",
          sale_order_line_vendor_sku: "EVN004131",
          sale_order_line_size_id: null,
          product_product_default_code: "FC0RAA-GE3Y",
          product_product_name:
            "3 1/4 CT TGW Created Blue Sapphire Fashion Post Earrings Silver",
          product_id: 92767,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0RAA-GE3Y.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552377",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:45:06.421Z",
        updatedAt: "2021-09-16T05:45:06.422Z",
        __v: 0,
      },
      {
        stockBin: { row: "24", section: "H", bin: "03" },
        price: 29.995,
        notes: [],
        isSet: true,
        _id: "6142d9e25182ab1968a6ebf4",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363780",
            street: "179 CLEARVIEW DR",
            name: "VICTORIO VIDAL",
            zip: "94591-7138",
            city: "VALLEJO",
            country: "United States",
            state: "California",
          },
          orderValue: 59.99,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "6142d9e25182ab1968a6ebf4",
            "6142d9e25182ab0a1ea6ebfd",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: true,
          _id: "6142d9e15182ab7959a6ebef",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121433,
          orderNo: "EVN912988_1_4_4-0",
          customerId: 948,
          customerName: "Evine.com",
          orderReference: "EVN912988_1_4_4",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363780,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "1Z8R9V140318552377",
          carrier: "UPS",
          shippingMethod: " Ground",
          addressGroupCombined:
            "evn/victorio vidal/179 clearview dr//vallejo/california/94591-7138/united states",
          createdAt: "2021-09-16T05:45:05.953Z",
          updatedAt: "2021-09-16T05:45:06.432Z",
          labelFile: "5121433/labelFile.txt",
          zplFile: "5121433/zplFile.txt",
          pdfFile: "5121433/pdfFile.txt",
          addressString: "179clearviewdrvallejocalifornia945917138unitedstates",
          normalizedCustomerName: "evine.com",
          classification: "PAPS",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC2B6W-PK8F",
        productName:
          "1 5/8 CT TGW Created Blue Sapphire Fashion Pendant With Chain Silver",
        productImage: "https://partner.delmarintl.ca/catalog/FC2B6W-PK8F.jpg",
        reference: "EVN912988_1_4_4-0",
        sku: "FC2B6W-PK8F",
        binLocation: "24-H-03",
        boxCode: "",
        stockPickingId: "3116048",
        uniqueIdentifier: "5121433-3116048-4397912",
        stockMoveSize: "",
        orderLineSize: "",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121433,
          sale_order_customer_id: 948,
          sale_order_customer_name: "Evine.com",
          sale_order_order_reference: "EVN912988_1_4_4",
          sale_order_date: "2021-09-15",
          sale_order_total: 36,
          shipping_address_street: "179 CLEARVIEW DR",
          shipping_address_street_2: null,
          shipping_address_id: 8363780,
          shipping_address_name: "VICTORIO VIDAL",
          shipping_address_zip: "94591-7138",
          shipping_address_city: "VALLEJO",
          shipping_address_state: "California",
          shipping_address_country: "United States",
          stock_picking_reference: "EVN912988_1_4_4-0",
          stock_picking_id: 3116048,
          total_price: 59.99,
          shipping_batch_id: -1,
          date: "2021-09-16 04:17:04",
          picking_write_date: "2021-09-16 04:41:13",
          address_grp_combined:
            "evn/victorio vidal/179 clearview dr//vallejo/california/94591-7138/united states",
          stock_move_is_set: true,
          stock_move_id: 78186942,
          stock_move_set_product_id: 3126108,
          stock_move_quantity: 1,
          stock_move_bin: "24-H-03",
          stock_move_uom: "PCE",
          stock_move_size: "",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397912,
          sale_order_line_description:
            "CREATED JULES STERLING SILVER 4.83CTW CREATED SAPPHIRE NECKLACE & EARRING SET",
          sale_order_line_customer_cost: "29.995",
          sale_order_line_vendor_sku: "EVN004131",
          sale_order_line_size_id: null,
          product_product_default_code: "FC2B6W-PK8F",
          product_product_name:
            "1 5/8 CT TGW Created Blue Sapphire Fashion Pendant With Chain Silver",
          product_id: 3125005,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC2B6W-PK8F.jpg",
          sale_order_line_size: "",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Ground",
          order_tracking_number: "1Z8R9V140318552377",
          paper_format: null,
          print_pslip: 1,
          carrier: "UPS",
        },
        createdAt: "2021-09-16T05:45:06.122Z",
        updatedAt: "2021-09-16T05:45:06.123Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: true,
    assignedTables: [],
    assignedPickers: [],
    rows: [
      "20",
      "18",
      "14",
      "21",
      "10",
      "07",
      "22",
      "04",
      "01",
      "15",
      "11",
      "17",
      "02",
      "05",
      "09",
      "03",
      "13",
      "24",
      "12",
    ],
    _id: "6195ae15fc40c13f24ca0407",
    createdAt: "2021-11-18T01:36:21.010Z",
    updatedAt: "2021-11-18T01:36:21.010Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "607c73f19c92af7722dc27dd",
      name: "US orders",
      conditions: [
        {
          _id: "61845e3679976346a23e28ba",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "United States",
          _id: "61845e3679976346a23e28ba",
        },
      ],
      createdAt: "2021-04-18T18:01:21.136Z",
      updatedAt: "2021-11-18T01:28:34.267Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"United States"}}]}',
      __v: 13,
    },
    groupName: "US orders",
    numberOfBins: 23,
    orderBins: {
      "61421c085182ab613aa6dffd": 1,
      "614238295182ab1cbda6e2d9": 2,
      "6142544a5182ab5da6a6e42b": 3,
      "61425b545182ab9400a6e45b": 4,
      "61425b575182ab0152a6e4c9": 5,
      "61426cf75182ab0f77a6e555": 6,
      "61426cf85182ab28d4a6e578": 7,
      "61426cfb5182ab433ba6e5dc": 8,
      "614270775182ab6ef7a6e616": 9,
      "614277735182abdd02a6e6cd": 10,
      "6142891f5182ab9213a6e79a": 11,
      "614297185182ab2a6da6e832": 12,
      "61429a965182ab1259a6e84a": 13,
      "6142a1a15182ab6baca6e894": 14,
      "6142b3385182ab2c9ba6e9af": 15,
      "6142b6ba5182ab6cf5a6e9c5": 16,
      "6142c1595182ab776aa6ea2c": 17,
      "6142c4cb5182abe8e1a6eae1": 18,
      "6142d2d85182ab1a3ca6eb89": 19,
      "6142d9e15182ab7959a6ebef": 20,
      "6142eb795182abc8fea6eccb": 21,
      "6142f5ff5182abad30a6ecee": 22,
      "6142fd075182abade6a6ed6f": 23,
    },
    priority: 10,
    tags: "Multi-Line",
  },
  {
    orderLineItems: [
      {
        stockBin: { row: "02", section: "F", bin: "16" },
        price: 94.5,
        notes: [],
        isSet: false,
        _id: "61423f345182aba537a6e36d",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363081",
            street: "1755 WEST BROADWAY, 403",
            name: "Medinet Medinet",
            zip: "V6J 4S5",
            city: "VANCOUVER",
            country: "Canada",
            state: "British Columbia",
          },
          orderValue: 173.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61423f345182aba537a6e36d",
            "61423f345182ab3f9ca6e376",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61423f345182ab4ff6a6e368",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121028,
          orderNo: "HBC1156507-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156507",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363081,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416684180330",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T18:45:08.430Z",
          updatedAt: "2021-09-15T18:45:08.891Z",
          labelFile: "5121028/labelFile.txt",
          zplFile: "5121028/zplFile.txt",
          pdfFile: "5121028/pdfFile.txt",
          addressString:
            "1755westbroadway403vancouverbritishcolumbiav6j4s5canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "FC0LX3-W2C1",
        productName:
          "0.06 CT Diamond TW And 1.59 CT TGW Created Blue Sapphire Fashion Ring Silver I3",
        productImage: "https://partner.delmarintl.ca/catalog/FC0LX3-W2C1.jpg",
        reference: "HBC1156507-0",
        sku: "FC0LX3-W2C1",
        binLocation: "02-F-16",
        boxCode: "",
        stockPickingId: "3115662",
        uniqueIdentifier: "5121028-3115662-4397436",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121028,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156507",
          sale_order_date: "2021-09-15",
          sale_order_total: 102.9,
          shipping_address_street: "1755 WEST BROADWAY",
          shipping_address_street_2: "403",
          shipping_address_id: 8363081,
          shipping_address_name: "Medinet Medinet",
          shipping_address_zip: "V6J 4S5",
          shipping_address_city: "VANCOUVER",
          shipping_address_state: "British Columbia",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156507-0",
          stock_picking_id: 3115662,
          total_price: 173.25,
          shipping_batch_id: -1,
          date: "2021-09-15 17:34:16",
          picking_write_date: "2021-09-15 17:40:50",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186280,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "02-F-16",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397436,
          sale_order_line_description:
            "Halo Sterling Silver &amp; 0.06 CT. T.W. Diamond Ring",
          sale_order_line_customer_cost: "94.5",
          sale_order_line_vendor_sku: "HBC005233",
          sale_order_line_size_id: 7,
          product_product_default_code: "FC0LX3-W2C1",
          product_product_name:
            "0.06 CT Diamond TW And 1.59 CT TGW Created Blue Sapphire Fashion Ring Silver I3",
          product_id: 90184,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/FC0LX3-W2C1.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416684180330",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T18:45:08.567Z",
        updatedAt: "2021-09-15T18:45:08.569Z",
        __v: 0,
      },
      {
        stockBin: { row: "04", section: "E", bin: "61" },
        price: 78.75,
        notes: [],
        isSet: false,
        _id: "61423f345182ab3f9ca6e376",
        backendDeleted: false,
        order: {
          shippingAddress: {
            id: "8363081",
            street: "1755 WEST BROADWAY, 403",
            name: "Medinet Medinet",
            zip: "V6J 4S5",
            city: "VANCOUVER",
            country: "Canada",
            state: "British Columbia",
          },
          orderValue: 173.25,
          orderStatus: "UNPROCESSED",
          orderLineItems: [
            "61423f345182aba537a6e36d",
            "61423f345182ab3f9ca6e376",
          ],
          shippingBatchId: -1,
          historicallyIncomplete: false,
          notes: [],
          resizingOrder: false,
          combinedOrder: false,
          station: "DEFAULT",
          processAudit: [],
          isSet: false,
          _id: "61423f345182ab4ff6a6e368",
          backendCombined: false,
          nestedOrders: [],
          orderId: 5121028,
          orderNo: "HBC1156507-0",
          customerId: 583,
          customerName: "The Hudson's Bay",
          orderReference: "HBC1156507",
          orderDate: "2021-09-15T00:00:00.000Z",
          warehouseName: "CAFER",
          shippingAddressId: 8363081,
          printPslip: 1,
          printExtra: false,
          paperFormat: null,
          trackingNumber: "2006416684180330",
          carrier: "Canada Post",
          shippingMethod: " Expedited Parcel",
          addressGroupCombined: null,
          createdAt: "2021-09-15T18:45:08.430Z",
          updatedAt: "2021-09-15T18:45:08.891Z",
          labelFile: "5121028/labelFile.txt",
          zplFile: "5121028/zplFile.txt",
          pdfFile: "5121028/pdfFile.txt",
          addressString:
            "1755westbroadway403vancouverbritishcolumbiav6j4s5canada",
          normalizedCustomerName: "the hudson's bay",
          __v: 2,
          noOfItems: 2,
          source: "Amazon",
        },
        quantity: 1,
        productCode: "RGS0212BT",
        productName: "4 3/8 CT TGW Blue Topaz - Sky 3 Stone Ring Silver",
        productImage: "https://partner.delmarintl.ca/catalog/RGS0212BT.jpg",
        reference: "HBC1156507-0",
        sku: "RGS0212BT",
        binLocation: "04-E-61",
        boxCode: "",
        stockPickingId: "3115662",
        uniqueIdentifier: "5121028-3115662-4397435",
        stockMoveSize: "7.0",
        orderLineSize: "7.0",
        isNoResize: null,
        rawData: {
          sale_order_id: 5121028,
          sale_order_customer_id: 583,
          sale_order_customer_name: "The Hudson's Bay",
          sale_order_order_reference: "HBC1156507",
          sale_order_date: "2021-09-15",
          sale_order_total: 102.9,
          shipping_address_street: "1755 WEST BROADWAY",
          shipping_address_street_2: "403",
          shipping_address_id: 8363081,
          shipping_address_name: "Medinet Medinet",
          shipping_address_zip: "V6J 4S5",
          shipping_address_city: "VANCOUVER",
          shipping_address_state: "British Columbia",
          shipping_address_country: "Canada",
          stock_picking_reference: "HBC1156507-0",
          stock_picking_id: 3115662,
          total_price: 173.25,
          shipping_batch_id: -1,
          date: "2021-09-15 17:34:16",
          picking_write_date: "2021-09-15 17:40:50",
          address_grp_combined: null,
          stock_move_is_set: false,
          stock_move_id: 78186279,
          stock_move_set_product_id: -1,
          stock_move_quantity: 1,
          stock_move_bin: "04-E-61",
          stock_move_uom: "PCE",
          stock_move_size: "7.0",
          stock_move_location: "Physical Locations / CAFER / Stock / FEOSPP",
          sale_order_line_id: 4397435,
          sale_order_line_description:
            "Sterling Silver Blue Topaz Three-Stone Ring",
          sale_order_line_customer_cost: "78.75",
          sale_order_line_vendor_sku: "HBC001785",
          sale_order_line_size_id: 7,
          product_product_default_code: "RGS0212BT",
          product_product_name:
            "4 3/8 CT TGW Blue Topaz - Sky 3 Stone Ring Silver",
          product_id: 74666,
          ship2store: false,
          is_no_resize: null,
          box_code: "",
          product_product_image:
            "https://partner.delmarintl.ca/catalog/RGS0212BT.jpg",
          sale_order_line_size: "7.0",
          warehouses_name: "CAFER",
          print_extra: false,
          shipping_method: " Expedited Parcel",
          order_tracking_number: "2006416684180330",
          paper_format: null,
          print_pslip: 1,
          carrier: "Canada Post",
        },
        createdAt: "2021-09-15T18:45:08.880Z",
        updatedAt: "2021-09-15T18:45:08.880Z",
        __v: 0,
      },
    ],
    currentWorkflow: "DEFAULT",
    multipleLineItems: true,
    assignedTables: [],
    assignedPickers: [],
    rows: ["02", "04"],
    _id: "6195ae15fc40c13f24ca0408",
    createdAt: "2021-11-18T01:36:21.040Z",
    updatedAt: "2021-11-18T01:36:21.040Z",
    group: {
      subGroup: false,
      aggregateOrders: false,
      preGroup: false,
      numQueries: 1,
      _id: "6144cbb064753b0a8645625a",
      name: "Canadian Orders",
      conditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      mainCriteria: "$and",
      calcConditions: [
        {
          _id: "6144cbb064753b0a8645625b",
          masterField: "shippingAddress.country",
          comparisonOperator: "$eq",
          value: "Canada",
        },
      ],
      createdAt: "2021-09-17T17:09:04.294Z",
      updatedAt: "2021-09-17T17:09:04.301Z",
      filter: '{"$and":[{"shippingAddress.country":{"$eq":"Canada"}}]}',
      __v: 0,
    },
    groupName: "Canadian Orders",
    numberOfBins: 1,
    orderBins: { "61423f345182ab4ff6a6e368": 1 },
    priority: 10,
    tags: "Multi-Line",
  },
];
