import RowsPerPageDropdown from "./RowsPerPageDropdown";
import PaginationNavigationButtons from "./PaginationNavigationButtons";

const Pagination = ({
  showingLhs,
  showingRhs,
  showingTotal,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
}) => (
  <div className="fixed bottom-0 h-20 bg-transparent md:pl-72 w-screen left-0">
    <div className="flex bg-E5E5E5 w-full h-full items-center text-224E73">
      <div className="flex-1 px-5 font-bold">
        <p className="font-medium text-gray-700">
          Showing <span className="font-medium">{showingLhs}</span> to
          <span className="font-medium"> {showingRhs}</span> of{" "}
          <span className="font-medium">{showingTotal}</span> results
        </p>
      </div>
      <div className="flex-none items-end justify-end text-right mr-10">
        <div className="flex items-center space-x-4">
          <div>Results per page</div>
          <RowsPerPageDropdown perPage={perPage} setPerPage={setPerPage} />
          <PaginationNavigationButtons
            pageNumber={pageNumber}
            total={showingTotal}
            perPage={perPage}
            prevPage={() => checkPagination("backward")}
            nextPage={() => checkPagination("forward")}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Pagination;
