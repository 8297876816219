import AuthedLayout from "#components/layout/AuthedLayout";
import withUsersLogic from "#components/HOC/withUsersLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import UsersList from "#components/users/UsersList";
import UserForm from "#components/users/UserForm";
import { UserAddIcon } from "@heroicons/react/outline";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import AdminLayout from "#components/layout/AdminLayout";
import RoundedDropdown from "#components/utils/RoundedDropdown";

const Users = ({
  masterData,
  users,
  selectedUser,
  setSelectedUser,
  fetchUser,
  saveUser,
  stations,
  roleWise,
  onChangeMultiSelect,
  deleteButtonClicked,
  onChange,
  customers,
  selectedCustomer,
  setSelectedCustomer,
}) => (
  <div className="p-5">
    <PageTitle>User Management</PageTitle>
    <div className="w-96 flex space-x-4 mb-5">
      <RoundedDropdown
        placeholder={"Customer"}
        list={customers}
        labelKey="name"
        valueKey="id"
        name="customer"
        selectedValue={selectedCustomer}
        setSelected={setSelectedCustomer}
      />
    </div>
    <Tabs
      tabs={{
        Admin: (
          <UsersList
            users={roleWise(users, "Admin")}
            editButtonClicked={(e) => {
              fetchUser(e.id);
            }}
            deleteButtonClicked={(e) => deleteButtonClicked(e)}
            headers={["Name", "Warehouse", "Customers", "Username", "Action"]}
            noValuesText="No Users"
            customers={customers}
          />
        ),
        ADMIN: (
          <UsersList
            users={roleWise(users, "ADMIN")}
            editButtonClicked={(e) => {
              fetchUser(e.id);
            }}
            deleteButtonClicked={(e) => deleteButtonClicked(e)}
            headers={["Name", "Warehouse", "Customers", "Username", "Action"]}
            noValuesText="No Users"
            customers={customers}
          />
        ),
        "Warehouse Manager": (
          <UsersList
            users={roleWise(users, "Warehouse Manager")}
            editButtonClicked={(e) => {
              fetchUser(e.id);
            }}
            deleteButtonClicked={(e) => deleteButtonClicked(e)}
            headers={["Name", "Warehouse", "Customers", "Username", "Action"]}
            noValuesText="No Users"
            customers={customers}
          />
        ),
        Associate: (
          <UsersList
            users={roleWise(users, "Associate")}
            editButtonClicked={(e) => {
              fetchUser(e.id);
            }}
            deleteButtonClicked={(e) => deleteButtonClicked(e)}
            showStations={true}
            headers={["Name", "Station", "Customers", "Username", "Action"]}
            noValuesText="No Users"
            customers={customers}
          />
        ),
      }}
      addButtonClicked={() => {
        setSelectedUser({});
      }}
      extra={
        <>
          <AddButton
            text="Add User"
            onClick={() => setSelectedUser({})}
            icon={UserAddIcon}
          />
        </>
      }
    />
    {selectedUser && (
      <UserForm
        title={selectedUser.id ? "Edit User" : "Add User"}
        selectedUser={selectedUser}
        onChange={onChange}
        onChangeDropdown={() => setUserDropdownValue()}
        onClose={() => setSelectedUser()}
        onSubmit={() => saveUser(selectedUser)}
        masterData={masterData}
        stations={stations}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={customers}
      />
    )}
  </div>
);

Users.layout = AuthedLayout;

export default withUsersLogic(Users);
