import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_MASTER_DATA,
  GET_USERS,
  GET_STATIONS,
  GET_USER,
  GET_CUSTOMERS,
} from "#queries";
import { SAVE_USER, DELETE_USER } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";

const withUsersLogic = (WrappedComponent) => {
  return (props) => {
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const appState = useContext(AppStateContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const masterData = useContext(MasterDataContext);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const stationsQuery = useQuery(GET_STATIONS);
    const usersQuery = useQuery(GET_USERS);
    const getUserQuery = useQuery(GET_USER);
    const saveUserQuery = useQuery(SAVE_USER);
    const deleteUserQuery = useQuery(DELETE_USER);

    useEffect(() => {
      stationsQuery.fetchData();
      customersQuery.fetchData();
      usersQuery.fetchData({ filters });
    }, []);

    useEffect(() => {
      if (customersQuery.data && customersQuery.data.customers.length > 0) {
        setSelectedCustomer(customersQuery.data.customers[0].id);
      }
    }, [customersQuery.loading, customersQuery.data, customersQuery.error]);

    useEffect(() => {
      if (getUserQuery.data) {
        setSelectedUser(getUserQuery.data.user);
      }
      if (getUserQuery.error) {
        setSelectedUser(null);
      }
    }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

    useEffect(() => {
      if (saveUserQuery.data) {
        usersQuery.fetchData({ filters });
        setSelectedUser(null);
      }
      if (saveUserQuery.error) {
        console.log(saveUserQuery.error);
        appState.setAlert(saveUserQuery.error.message, "error", 5000);
      }
    }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

    useEffect(() => {
      if (deleteUserQuery.data) {
        usersQuery.fetchData({ filters });
      }
    }, [deleteUserQuery.loading, deleteUserQuery.data, deleteUserQuery.error]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this user?",
        () => {
          deleteUserQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation
      );
    };
    const roleWise = (users, role) => _.filter(users, (e) => e.role === role);

    const onChange = (e) => {
      const user = {
        ...selectedUser,
      };

      user[e.target.name] = e.target.value;
      setSelectedUser(user);
    };

    const onChangeMultiSelect = (field, value) => {
      const user = {
        ...selectedUser,
      };
      console.log("rerrerre");
      user[field] = value;
      setSelectedUser(user);
      console.log(user);
    };
    return (
      <WrappedComponent
        users={usersQuery.data ? usersQuery.data.users : []}
        masterData={masterData}
        stations={stationsQuery.data ? stationsQuery.data.stations : []}
        fetchUser={(id) => getUserQuery.fetchData({ id })}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        saveUser={(user) => {
          saveUserQuery.fetchData({ ...user });
        }}
        roleWise={roleWise}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        deleteButtonClicked={deleteButtonClicked}
        customers={customersQuery.data ? customersQuery.data.customers : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          usersQuery.fetchData({
            filters: {
              customers: [e],
            },
          });
        }}
      />
    );
  };
};

export default withUsersLogic;
