import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import HeaderWithoutSidebar from "#components/common/HeaderWithoutSidebar";
import LoginBox from "#components/login/LoginBox";
import useLoginLogic from "#hooks/login/useLoginLogic";
import { GET_LOGGED_IN_USER } from "#queries";
import { LOGOUT_USER, SET_ACTIVITY } from "#mutations";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import MultipleSelectActivityForm from "#components/common/MultipleSelectActivityForm";

const Login = () => {
  const logoutQuery = useQuery(LOGOUT_USER);
  const setActivityQuery = useQuery(SET_ACTIVITY);
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const { values, onSubmit, onChange } = useLoginLogic();
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    if (setActivityQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (setActivityQuery.error) {
      appState.setAlert(setActivityQuery.error.message, "error", 5000);
    }

    if (setActivityQuery.data) {
      window.location = "/";
    }
  }, [setActivityQuery.data, setActivityQuery.error, setActivityQuery.loading]);

  useEffect(() => {
    if (
      auth.user &&
      auth.user.role === "Associate" &&
      auth.user.hopstackModules.includes(
        "Packing",
        "Outbound Sorting Overview",
        "Inbound Sorting Overview",
        "Picking",
        "Receiving",
        "Putaway"
      ) === false
    ) {
      // logoutQuery.fetchData();
    }
  }, [auth.user]);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(
        "You need to login from a mobile or PDA",
        "error",
        5000
      );
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  if (!!auth.isAuthenticated && !!auth.token) {
    const foundUser = auth.user;
    if (
      foundUser &&
      foundUser.role &&
      (foundUser.role.toLowerCase() === "admin" ||
        foundUser.role.toLowerCase() === "warehouse manager")
    ) {
      return <Redirect to="/simulations" />;
    }
    if (
      foundUser.hopstackModules.findIndex((item) =>
        [
          "Packing",
          "Outbound Sorting Overview",
          "Inbound Sorting Overview",
          "Picking",
          "Picking",
          "Receiving",
          "Putaway",
        ].includes(item)
      ) === -1
    ) {
      // NEED TO LOGIN FROM A PDA
      return null;
    }

    const hopstackModules = foundUser.hopstackModules.filter((item) =>
      [
        "Packing",
        "Outbound Sorting Overview",
        "Inbound Sorting Overview",
        "Picking",
        "Picking",
        "Receiving",
        "Putaway",
      ].includes(item)
    );

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Packing") ||
      foundUser.activity === "Packing"
    ) {
      return <Redirect to="/packer" />;
    }

    if (
      (hopstackModules.length === 1 &&
        hopstackModules[0] === "Outbound Sorting Overview") ||
      foundUser.activity === "Outbound Sorting Overview"
    ) {
      return <Redirect to="/outboundSortingOverview" />;
    }

    if (
      (hopstackModules.length === 1 &&
        hopstackModules[0] === "Inbound Sorting Overview") ||
      foundUser.activity === "Inbound Sorting Overview"
    ) {
      return <Redirect to="/inboundSortingOverview" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Picking") ||
      foundUser.activity === "Picking"
    ) {
      return <Redirect to="/picker" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Receiving") ||
      foundUser.activity === "Receiving"
    ) {
      return <Redirect to="/receiver" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Putaway") ||
      foundUser.activity === "Putaway"
    ) {
      return <Redirect to="/putaway" />;
    }

    return (
      <MultipleSelectActivityForm
        title={"You have multiple Roles. Please choose one."}
        selectedActivity={selectedActivity}
        onClose={() => logoutQuery.fetchData()}
        onSubmit={() => {
          if (selectedActivity) {
            setActivityQuery.fetchData({ activity: selectedActivity });
          } else {
            appState.setAlert("Please select an activity", "error", 5000);
          }
        }}
        hopstackModules={hopstackModules.map((item) => ({
          name: item,
        }))}
        onChangeDropdown={(e) => setSelectedActivity(e)}
      />
    );
  }

  if (appState.loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className="h-screen w-full bg-224E73 flex items-center justify-center">
        {/* Header */}
        <HeaderWithoutSidebar />
        {/*Center Box*/}
        <LoginBox values={values} onSubmit={onSubmit} onChange={onChange} />
      </div>
      <footer className="-mt-8 w-full items-center justify-center flex text-white">
        Copyright ©
        <a href="http://hopstack.io" className="px-2" target="_blank">
          Hopstack Inc.
        </a>
        {new Date().getFullYear()}
      </footer>
    </>
  );
};

export default Login;
