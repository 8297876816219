import withStatsLogic from "#components/HOC/withStatsLogic";
import Stats from "#components/common/Stats";
import ChartRenderer from "#components/dashboard/ChartRenderer";

const OperationsStats = ({ items }) => {
  const dashboardItem = (item) => (
    <ChartRenderer vizState={item.vizState} name={item.name} />
  );
  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Orders",
            stat: 117,
          },
          {
            name: "Total Unprocessed",
            stat: 80,
          },
          {
            name: "Total Completed",
            stat: 20,
          },
          {
            name: "Total in Error",
            stat: 17,
          },
        ]}
      />

      {items.length && items.map(dashboardItem)}
    </>
  );
};

export default withStatsLogic(OperationsStats);
