import moment from "moment-timezone";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";

import TableFieldButton from "#components/utils/TableFieldButton";

const UsersList = ({
  users,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  customers,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-4">
      <div className={`grid grid-cols-${headers.length} flex items-start`}>
        {headers.map((header, headerIdx) =>
          headerIdx === 0 ? (
            <div
              className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
              key={headerIdx}
            >
              {header}
            </div>
          ) : (
            <div
              className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
              key={headerIdx}
            >
              {header}
            </div>
          )
        )}
      </div>
      {users.map((user, index) => (
        <div
          className={`grid grid-cols-${headers.length} ${
            index % 2 === 0 ? "bg-white" : "bg-gray-50"
          }  ${index === 0 ? "mt-0" : "mt-2"}`}
          key={user.id}
        >
          <div
            className={`flex items-center p-5 text-left font-semibold text-224E73 rounded-bl border-l-8  ${
              index % 2 === 0 ? "border-F4C261" : "border-224E73"
            }`}
          >
            {user.name}
          </div>
          <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
            {user.hopstackModules
              ? user.hopstackModules.join(",\n")
              : "Hopstack Headquarters"}
          </div>
          {/* <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
            {user.customers
              ? customers
                  .filter((item) => user.customers.includes(item.id))
                  .map((item) => item.name)
                  .join(", ")
              : ""}
          </div> */}
          <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
            {user.customers
              ? customers
                  .filter((item) => user.customers.includes(item.id))
                  .map((item) => item.name)
                  .join(", ")
              : ""}
          </div>
          <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
            {user.username}
          </div>
          <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
            <div className="flex space-x-2 items-center">
              <TableFieldButton
                onClick={() => editButtonClicked(user)}
                text={<PencilIcon className="w-6 h-6" />}
              />
              <TableFieldButton
                text={<TrashIcon className="w-6 h-6" />}
                onClick={() => deleteButtonClicked(user.id)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UsersList;
