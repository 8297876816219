import { CheckIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Modal from "#components/utils/Modal";
import { Tab } from "@headlessui/react";
const config = require("config");
import QRCode from "react-qr-code";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExpandedBatch = ({ batchItems, negativeAction }) => {
  const headers = [
    "SKU",
    "Bin Location",
    "Name",
    "Collection",
    "Color",
    "Size",
    "Quantity",
    "Status",
  ];

  if (config.NODE_ENV === "development") {
    headers.push("Bin Code");
    headers.push("SKU Code");
  }

  return (
    <Modal
      negativeAction={negativeAction}
      title={`Order Details`}
      maxWidth="max-w-2xl"
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK"
    >
      <div className="bg-EFE9DC p-4">
        <table className="px-2 min-w-full divide-y divide-gray-200">
          <thead className="p-4 rounded-full bg-224E73 px-12">
            <tr className="text-C2D2DF font-montserrat border-left px-12">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}
                  >
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}
                  >
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {batchItems.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
              >
                <td
                  className={`p-5 text-left font-semibold text-224E73 tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-224E73"
                  }`}
                >
                  {item.sku}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.binLocation}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.productName}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {item.attributes?.collection}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {item.attributes?.color}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {item.attributes?.size}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {item.quantity}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {item.status}
                </td>
                {config.NODE_ENV === "development" && (
                  <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <QRCode value={item.binLocation} size="100" />
                  </td>
                )}
                {config.NODE_ENV === "development" && (
                  <td className="px-2 pl-4 py-4 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <QRCode value={item.sku} size="100" />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
export default ExpandedBatch;
