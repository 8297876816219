import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";

const UserForm = ({
  onClose,
  title,
  masterData,
  onChange,
  onChangeDropdown,
  selectedUser,
  onSubmit,
  onChangeMultiSelect,
  customers,
}) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={onSubmit}
    id="userFormModal"
  >
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Select Role
        </span>
        <div className="mt-2 flex space-x-5">
          {masterData.roles.map((master, idx) => (
            <Checkbox
              role="radio"
              label={master.name}
              checked={selectedUser.role === master.name}
              onChange={onChange}
              key={idx}
              name="role"
              value={master.name}
            />
          ))}
        </div>
      </div>
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          User Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedUser.name}
        />
      </div>
      <div>
        <TextField
          type="password"
          id="password"
          label="Password"
          placeholder="Password"
          onChange={onChange}
          value={selectedUser.password}
        />
      </div>

      <div>
        {selectedUser.role === "Associate" && (
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Stations
              </span>
            </label>
            <MultiSelectAutoComplete
              options={masterData.hopstackModules}
              labelKey={"name"}
              valueKey={"name"}
              setValues={(e) => onChangeMultiSelect("hopstackModules", e)}
              values={selectedUser.hopstackModules || []}
            />
          </div>
        )}
      </div>
      <div>
        <div id="selectingStations">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Select Customers
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey={"name"}
            valueKey={"id"}
            setValues={(e) => onChangeMultiSelect("customers", e)}
            values={selectedUser.customers || []}
          />
        </div>
      </div>
      {/*{selectedUser.id && (
          <div>
          <TextField
            type="password"
            id="password"
            label="Password"
            placeholder="Password"
            onChange={onChange}
            value={selectedUser.password}
          />
        </div>
      )}*/}
    </div>
  </Modal>
);

export default UserForm;
